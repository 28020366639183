/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/
import React, { Component } from 'react'
import _ from '../../../../../services/utils/BfLodash'
import { NumberInput } from '@patron/patron-react/numberinput'
import BfDebounceSubject from '../../../../common/BfDebounceSubject'
import './BfNumberInputFilter.scss'

/**
 * selectedItem and items accept array of the same objects types {id,text}
 */
class BfNumberInputFilter extends Component {
  state = { numberInput: undefined }

  bfDebounceSubject = new BfDebounceSubject()

  constructor(props) {
    super(props)
    this.bfDebounceSubject.subscribe((data) => this.props.onFilterApplied(data))
  }

  _initValue = () => {
    const numberInput = this.props.initValue
    if (!_.isEmpty(numberInput) || _.isNumber(numberInput)) {
      this.setState({ numberInput: numberInput })
    }
  }

  componentDidMount() {
    this._initValue()
  }

  _getSelectedValue = (numberInput) => {
    return numberInput
  }

  _onKeyDown = (e) => {
    if (e.key === '-') {
      e.preventDefault()
    }
  }

  _onChange = (value) => {
    const { bfFilter, onValueSelected } = this.props
    this.setState(() => {
      onValueSelected(value)
      this.bfDebounceSubject.next(bfFilter.get())
      return { numberInput: this._getSelectedValue(value) }
    })
  }

  render() {
    const { label, maxValue, minValue, inputStep, defaultInput, helperText, hideStepper = false } = this.props

    return (
      <NumberInput
        className={hideStepper ? 'bfHideStepper' : ''}
        defaultValue={defaultInput}
        disabled={false}
        errorMessage={{
          max: null,
          min: null,
          required: null
        }}
        helperText={helperText}
        label={label}
        max={maxValue}
        min={minValue || 0}
        onChange={this._onChange}
        required={false}
        step={inputStep}
        value={this.state.numberInput}
        onKeyDown={this._onKeyDown}
      />
    )
  }
}

export default BfNumberInputFilter
