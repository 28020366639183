/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import { map, mergeMap } from 'rxjs/operators'
import moment from 'moment'
import BfConsole from 'loglevel'
import _ from '../utils/BfLodash'
import BfHttpClient from './../authClient/BfHttpClient'
import BfCurrentUserInfoService from './../user/BfCurrentUserInfoService'
import BfCachedRequest from '../utils/BfCachedRequest'

class BfSendMessageService {
  bfCachedRequest = new BfCachedRequest()
  httpClient = new BfHttpClient()
  bfCurrentUserInfoService = new BfCurrentUserInfoService()
  SSA_VERSION = '3.1.0'
  initialDescription = '<wbr>'
  descriptionOptions = {
    minHeight: 60
  }

  maxSubjectLength = 240

  _getExpirationDays = () => {
    return this.bfCachedRequest.get('/framework/messageexpirationdays').pipe(map((res) => parseInt(_.isUndefined(res) ? 2 : res)))
  }

  _createPostActionRequest = (userInfo, selectedDevices, subject, description, expirationDays) => {
    const request = {
      devices: {
        dynamically: {
          groups: {
            manual: [],
            automatic: [],
            members: [],
            groupRelevantMemberCount: 0,
            relevantMembersCount: 0
          }
        },
        statically: selectedDevices,
        activeTargetMethod: 'device'
      },
      itemInformation: [
        {
          action: {
            description: description, // TODO: sanitize
            isDefault: true,
            type: 'application/x-Fixlet-Windows-Shell',
            actionId: 'Action1',
            script: '//Nothing to do',
            paramRequired: false,
            successCriteria: 'RunToCompletion'
          },
          relevance: [
            'if (windows of operating system) then (exists key whose ((it as string = "IBM BigFix Self Service Application" OR it as string = "IBM BigFix Self-Service Application" OR it as string = "BigFix Self-Service Application") of value "DisplayName" of it AND value "DisplayVersion" of it as string as version >= ("' +
              this.SSA_VERSION +
              '" as version)) of key "HKLM\\Software\\Microsoft\\Windows\\CurrentVersion\\Uninstall" of x32 registry) else if (mac of operating system) then (exists application "BigFixSSA.app" whose (version of it >= "' +
              this.SSA_VERSION +
              '")) else false',
            'exists line whose (it = "disableMessagesTab: false") of file (if (windows of operating system) then (pathname of parent folder of parent folder of client) & "\\BigFix Self Service Application\\resources\\ssa.config" else "/Library/Application Support/BigFix/BigFixSSA/ssa.config")'
          ]
        }
      ],

      configure: {
        titleIsLoaded: true,
        start: moment().unix(),
        end: moment().add(expirationDays, 'days').valueOf(),
        title: subject,
        fixletTitle: subject,
        startInPast: false,
        startIsReset: false,
        utc: false,
        runNow: {
          selected: true
        },
        policyAction: {
          selected: false
        },
        isBaseline: false,
        options: {
          mimeFields: {
            'action-ui-metadata': '{"type":"notification", "sender":"' + userInfo.userName + '", "expirationDays":' + expirationDays + '}'
          },
          requiredAction: {
            name: subject,
            selected: true,
            display: false,
            tooltip: '',
            message: description, // TODO: sanitize
            deadline: {
              behavior: {
                selected: { value: 'ForceToRun' }
              },
              localOffset: {
                selected: {
                  value: moment.duration(expirationDays, 'days').toISOString()
                }
              },
              type: 'Absolute'
            }
          },
          staggered: { selected: false },
          download: { selected: false },
          restart: { selected: false },
          notification: { selected: false },
          runningMsg: { selected: false },
          runWithError: { selected: false },
          retry: { selected: false },
          reapply: { selected: false },
          offer: { selected: false, options: [{ selected: false }] }
        }
      }
    }

    return request
  }

  send = (selectedDevices, subject, description) => {
    let expirationDays = 2
    return this._getExpirationDays().pipe(
      mergeMap((_expirationDays) => {
        BfConsole.debug('expiration days are ', _expirationDays)
        expirationDays = _expirationDays
        return this.bfCurrentUserInfoService.get()
      }),
      mergeMap((userInfo) => {
        const request = this._createPostActionRequest(userInfo, selectedDevices, subject, description, expirationDays)
        return this.httpClient.post('/takeaction/postAction', request)
      })
    )
  }
}
export default BfSendMessageService
