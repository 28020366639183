/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import React from 'react'
import _ from '../../../services/utils/BfLodash'
import { DataTable } from '@patron/patron-react/datatable'
import { Checkbox } from '@patron/patron-react/checkbox'
import BfCellRenderer from '../bfCellTemplates/BfCellRenderer'
import './BfDataGrid.scss'
import logConsole from 'loglevel'
import { BfTrans } from '../../../services/i18n/bfI18Utils'
import { ReactComponent as NoResultsImg } from './imgNoResult.svg'
import BfCellWidth from '../bfCellTemplates/BfCellWidth'
/**
 * Patronus data grid
 */

export const CHECKBOX_WIDTH = 40

class BfDataGrid extends React.Component {
  bfCellRenderer = new BfCellRenderer()
  noResultsRef = React.createRef()

  constructor(props, context) {
    super(props, context)
    this.state = { allSelected: false }
  }

  _getLabel = (header) => {
    return _.isUndefined(header.suffix) ? header.label : `${header.label} (${header.suffix})`
  }

  /**
   * Renders Device Headers
   * @method
   * @returns {Array} Cell Headers
   */

  _getHeaders() {
    const headers = this.props.headers.map((header) => {
      const newHeader = { ...header }
      newHeader.label = this._getLabel(header)
      newHeader.renderHtml = this.bfCellRenderer.getRenderer(newHeader)
      newHeader.title = newHeader.label
      return newHeader
    })
    if (this.props.selectableRow) this._addSelectionHeaderCheckbox(headers)
    return headers
  }

  _addSelectionHeaderCheckbox = (headers) => {
    const CHECKBOX_PIXEL_WIDTH = CHECKBOX_WIDTH + 'px'
    headers.unshift({
      field: 'checkbox',
      styles: {
        minWidth: CHECKBOX_PIXEL_WIDTH,
        padding: '0px',
        width: CHECKBOX_PIXEL_WIDTH,
        maxWidth: CHECKBOX_PIXEL_WIDTH
      },
      pinned: 'left',
      renderHtml: this._rendereHtmlSelectionRow
    })
    if (_.size(headers) > 2 && headers[1].pinned) {
      headers[1].styles = {
        ...headers[1].styles,
        left: CHECKBOX_PIXEL_WIDTH
      }
    }
  }

  _onRowSelect = (row) => {
    this.props.onRowSelect && this.props.onRowSelect(row)
  }

  _rendereHtmlSelectionRow = (row) => {
    return (
      <BfCellWidth header={{ width: '40px' }}>
        <Checkbox
          aria-label='row checkbox'
          className='ml-2'
          checked={row._isSelected}
          disabled={row._isDisabled}
          tooltipTitle={row._tooltipTitle}
          id={`${row.id}`} /** TODO: this is the unique id of the row */
          onChange={() => {
            row._isSelected = _.isUndefined(row._isSelected) ? true : !row._isSelected

            this._onRowSelect(row)
          }}
        />
      </BfCellWidth>
    )
  }

  _calculateSelectAllState = () => {
    return _.every(_.map(this.props.rows, '_isSelected'), (selected) => selected)
  }

  _onHeaderSelectAllCheckboxSelected = (ev) => {
    const isSelectAllChecked = ev.target.checked

    const selectedRows = this.props.rows.filter((row) => {
      if (row._isDisabled || row._isSelected === isSelectAllChecked) {
        return
      }
      row._isSelected = isSelectAllChecked
      return row
    })
    this.props.onRowSelect(selectedRows)
  }

  _getSelectionHeader = (tableId) => {
    const { disableSelectAll = false } = this.props

    return (
      <BfCellWidth header={{ width: '40px' }}>
        <Checkbox
          className='ml-2'
          aria-label='header checkbox'
          checked={_.isNotEmpty(this.props.rows) && this._calculateSelectAllState()}
          disabled={_.isEmpty(this.props.rows) || disableSelectAll}
          id={tableId + 'header_selection_all'}
          label=''
          onChange={this._onHeaderSelectAllCheckboxSelected}
        />
      </BfCellWidth>
    )
  }

  _onColumnReorder = (headers) => {
    if (_.isDefined(this.props.onColumnReorder)) {
      this.props.onColumnReorder(headers)
    }
  }

  /** Receives teh {field: "name", width: "251.40000915527344px"} object */
  _onColumnAfterResize = (fieldWith) => {
    if (_.isDefined(this.props.onColumnAfterResize)) {
      this.props.onColumnAfterResize(fieldWith)
    }
  }

  _appendNoImageRows = () => {
    const datagridContainer = this.props.forwardedRef.current
    const imgNoRowsImage = this.noResultsRef.current
    if (imgNoRowsImage && datagridContainer && _.isNotEmpty(datagridContainer.childNodes)) {
      const datagridDiv = _.first(datagridContainer.childNodes)
      datagridDiv.appendChild(imgNoRowsImage)
    }
  }

  componentDidMount = () => {
    logConsole.debug('componentDidMount bfdatagrid')
    this._appendNoImageRows()
  }

  componentDidUpdate = () => {
    this._appendNoImageRows()
  }

  _noDataCondition = () => {
    const displayNone = this.props.rows.length === 0 ? 'bf-display-container' : 'bf-display-container-none'
    const { areFiltersApplied } = this.props
    return (
      <div className={'bfContainerImgNoResults ' + displayNone} ref={this.noResultsRef}>
        <NoResultsImg className='bfImgNoResults' />
        <div className='bfTextImgNoResults'>{areFiltersApplied ? BfTrans('NoFilterResultsFound') : BfTrans('NoResultsFound')}</div>
      </div>
    )
  }

  render() {
    const draggable = !_.isNull(this.props.onColumnReorder)
    const resizeble = _.isDefined(this.props.onColumnAfterResize)
    const tableId = this.props.id || Math.random().toString()
    logConsole.debug('Rendering BfDataGrid')
    const bfClassName = _.isDefined(this.props.bfClassName) ? this.props.bfClassName : ''
    const noCheckboxClass = this.props.selectableRow ? 'BfHasCheckbox' : 'BfNoCheckbox'
    return (
      <div ref={this.props.forwardedRef} tabIndex={0}>
        <DataTable
          className={'BfDataGrid ' + bfClassName + ' ' + noCheckboxClass}
          headerSelection={this.props.selectableRow ? this._getSelectionHeader(tableId) : null}
          isHeaderSticky
          columnDraggable={draggable}
          showDraggableIcon={draggable}
          showDraggableIconOnHover={draggable}
          resizable={resizeble}
          onColumnAfterResize={this._onColumnAfterResize}
          onColumnReorder={this._onColumnReorder}
          id={tableId}
          selectedItem={this.props.selectedItem}
          uniqueKey={this.props.uniqueKey}
          tableConfig={this._getHeaders()}
          tableData={this.props.rows}
          triStateSorting={false}
          onSort={this.props.onSort}
          initSortedColumn={this.props.sortedColumn}
          type='compact'
        />
        {this._noDataCondition()}
      </div>
    )
  }
}

export default React.forwardRef((props, ref) => <BfDataGrid forwardedRef={ref} {...props} />)
