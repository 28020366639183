/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import BfDropDownSearchBasicFilter from '../../common/bfBasicFilters/BfDropDownSearchFilter/BfDropDownSearchBasicFilter'

class BfIpAddressFilter extends BfDropDownSearchBasicFilter {
  static NAME = 'ipAddresses'
  static PATH = `filter.${BfIpAddressFilter.NAME}`
  constructor(data = {}) {
    super(BfIpAddressFilter.PATH, BfIpAddressFilter.NAME)
    this[BfIpAddressFilter.NAME] = data
  }

  getSearchURL = () => {
    return '/devices/ipaddresses'
  }
}

export default BfIpAddressFilter
