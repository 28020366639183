/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import { HttpMethod, BfHttpClient } from '../authClient/BfHttpClient'
import { of, forkJoin } from 'rxjs'
import { map, mergeMap } from 'rxjs/operators'
import _ from '../../services/utils/BfLodash'
import BfCloudFilterFactory from './BfCloudFilterFactory'
import BfCachedRequest from '../utils/BfCachedRequest'

/**
 * Provides functionalities to fetch device data
 */
class BfDevicesService {
  bfCachedRequest = new BfCachedRequest(true)
  http = new BfHttpClient()
  bfCloudFilterFactory = new BfCloudFilterFactory()

  /**
   * Returns Pagination Object
   * @method
   * @param {Object} options Pagination options like items per page and page number
   * @returns {Object} Page options offset and items per page.
   */
  _getPaginationOption = (options) => {
    return {
      page: {
        itemsPerPage: options.itemsPerPage,
        offset: options.pageNumber * options.itemsPerPage
      }
    }
  }

  /**
   * Returns Sort Options
   * @method
   * @param {Object} options Sorting options like field to be sorting anchor and asc or desc sorting method
   * @returns {Object} Sort options method and order.
   */
  _getSortOption = (options) => {
    return {
      method: options.method,
      order: options.order
    }
  }

  _getParams = (options) => {
    return options
  }

  /**
   * Triggers http request to fetch device data from server
   * @method
   * @param {Object} options Pagination and Sorting options
   * @returns {Promise} Device data
   */
  getDevices(options) {
    let count = 0
    const params = this._getParams(options)
    return this.http.doRequest(params.filter && params.filter.onlySelected ? HttpMethod.POST : HttpMethod.GET, '/devices?ext=true', params).pipe(
      map((data) => {
        count = data.count
        return data.data
      }),
      mergeMap((devices) => {
        return this._updateCloudData(devices)
      }),
      map((devices) => {
        return { count: count, devices: devices }
      })
    )
  }

  _updateCloudData(devices) {
    if (_.isEmpty(devices)) {
      return of([])
    }
    return forkJoin(devices.map((device) => this.bfCloudFilterFactory.setItemAgentType(device)))
  }

  getDeviceInfo = (deviceId) => {
    return this.bfCachedRequest.get('/devices/' + deviceId).pipe(
      map((deviceInfo) => {
        deviceInfo.agentCorrelatedTypes = _.map(deviceInfo.correlatedRepresentations, function (value) {
          return { agentType: value.agentType }
        })
        deviceInfo.agentCorrelatedTypes.push({
          agentType: deviceInfo.agentType
        })
        return deviceInfo
      }),
      mergeMap((deviceInfo) => {
        return this.bfCloudFilterFactory.setItemAgentType(deviceInfo)
      })
    )
  }
}

export default BfDevicesService
