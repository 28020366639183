/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import { forkJoin } from 'rxjs'
import { map } from 'rxjs/operators'
import BfCurrentUserInfoService from '../user/BfCurrentUserInfoService'
import BfGearAppsService from './BfGearAppsService'

class BfGearService {
  bfGearAppsService = new BfGearAppsService()
  tabs = []
  showGearIcon = false
  /**
   * Stores User information
   */
  user = {
    name: '',
    isMaster: false
  }

  constructor(_bfCurrentUserInfoService = new BfCurrentUserInfoService()) {
    this.bfCurrentUserInfoService = _bfCurrentUserInfoService
  }

  /**
   * Initiates an HTTP request to fetch the gear app information from the server
   * @method
   * @returns {Promise} HTTP request.
   */
  getGearApps = () => {
    return forkJoin([this.bfGearAppsService.get(), this.bfCurrentUserInfoService.getUserMenu()]).pipe(
      map((values) => {
        const gearApps = values[0]
        const userMenu = values[1]
        if (gearApps.showSSA) {
          gearApps.showSSA.route = '/swd/ssa/new?referrer=%2Fframework%2Fdevices'
        }
        return { gearApps: gearApps, userMenu: userMenu }
      })
    )
  }
}

export default BfGearService
