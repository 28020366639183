/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import BfCachedRequest from '../utils/BfCachedRequest'
import { map } from 'rxjs/operators'
import _ from '../../services/utils/BfLodash'

class BfMoreApps {
  cachedRequest = new BfCachedRequest()

  getPermittedApps = () => {
    return this.cachedRequest.get('/getMoreApps')
  }

  getPermittedAppsConfiguration = () => {
    return this.getPermittedApps().pipe(
      map((apps) => {
        return _.filter(apps, (app) => {
          return app !== 'mdm'
        })
      })
    )
  }
}
export default BfMoreApps
