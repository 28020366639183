/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

export const makeContentKey = (prop) => {
  return prop.siteID + '_' + prop.contentID + '_' + prop.propertyID
}

export const createCustomPropertyColumn = (propertyName, contentKey) => {
  return {
    label: propertyName,
    field: contentKey.siteID + '_' + contentKey.contentID + '_' + contentKey.propertyID,
    title: propertyName,
    propertyID: contentKey.propertyID,
    contentID: contentKey.contentID,
    siteID: contentKey.siteID,
    _isVisible: true,
    type: 'arrayCell',
    columnHtmlType: 'customColumnHTML'
  }
}
