/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import _ from '../../services/utils/BfLodash'
import BfConsole from 'loglevel'
import BfHelpers from '../utils/BfHelpers'

class BfUrlHandler {
  enableUrl = true
  constructor(searchObj = {}, enableUrl = true) {
    this.enableUrl = enableUrl
    if (_.isEmpty(searchObj) && this.enableUrl) {
      this.searchObj = this._getSearchObj()
    } else {
      this.searchObj = _.cloneDeep(_.isNull(searchObj) ? {} : searchObj)
    }
  }

  setEnableUrl = (enableUrl) => {
    this.enableUrl = enableUrl
  }

  parseURL = () => {
    this.searchObj = this._getSearchObj()
  }

  resetSearch = () => {
    this.searchObj = {}
    return this._updateURL()
  }

  getSearchPath = (path, consumeUrl = false) => {
    const searchValue = _.get(this.getSearch(), path)
    if (searchValue && consumeUrl) {
      this.setSearch(_.omit(this.getSearch(), path))
    }
    return searchValue
  }

  setSearch = (newSearch) => {
    this.searchObj = newSearch
  }

  getSearch = () => {
    return this.searchObj
  }

  _updateURL = () => {
    const searchStr = this._prepareURLfilter(this.searchObj)
    if (this.enableUrl) {
      window.history.replaceState(null, '', '?' + searchStr)
    }
    BfConsole.log('searchStr is ', searchStr)
    return searchStr
  }

  _removeNullValues = (searchObj) => {
    const searchObjWithoutNull = {}
    _.each(searchObj, (value, key) => {
      if (!_.isEmpty(value)) {
        searchObjWithoutNull[key] = value
      }
    })
    return searchObjWithoutNull
  }

  _getFilter = () => {
    return this.searchObj.filter
  }

  addFilter = (filter) => {
    let filterObj = this._getFilter()
    if (_.isEmpty(filterObj)) {
      filterObj = filter
    } else {
      _.assign(filterObj, filter)
    }

    const filterWithoutNull = this._removeNullValues(filterObj)
    if (_.isEmpty(filterWithoutNull)) {
      delete this.searchObj.filter
    } else {
      this.searchObj.filter = filterWithoutNull
    }

    return this._updateURL()
  }

  addSearch = (newSearchObj) => {
    if (!_.isEmpty(newSearchObj)) {
      const searchObj = this.getSearch()
      _.assign(searchObj, newSearchObj)
      this.searchObj = this._removeNullValues(searchObj)
    }

    return this._updateURL()
  }

  _convertObjToStr = (searchObj) => {
    const helpers = new BfHelpers()
    const searchObjStr = {}
    _.each(searchObj, (value, key) => {
      searchObjStr[key] = _.isString(value) ? value : JSON.stringify(value)
      searchObjStr[key] = helpers.encodeHTMLSpecialChars(searchObjStr[key])
    })
    return searchObjStr
  }

  _prepareURLfilter = () => {
    const searchObjStr = this._convertObjToStr(this.searchObj)
    return _.map(searchObjStr, (value, key) => {
      return key + '=' + value
    }).join('&')
  }

  /**
   * return an map of string(key)->string(json stringified)
   */
  _getSearchObj = () => {
    /* const bfHelpers = new BfHelpers() */
    const ret = {}
    const urlParams = new URLSearchParams(window.location.search)

    const urlEntries = urlParams.entries()

    for (let entry = urlEntries.next(); !entry.done; entry = urlEntries.next()) {
      const [key, value] = entry.value

      if (_.isEmpty(value)) {
        ret[key] = {}
      } else {
        try {
          ret[key] = JSON.parse(value)
        } catch (e) {}
      }
    }

    return ret
  }
}
export default BfUrlHandler
