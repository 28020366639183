/*
// Licensed Materials - Property of HCL
// © Copyright HCL Technologies Limited 2001, 2021
// All Rights Reserved
*/

import React, { Component } from 'react'
import BfDataGridTable from '../datagrid/BfDataGridTable'
import BfPolicyGroupsService from '../../services/policyGroups/BfPolicyGroupsService'
import BfReduxStateConstants from '../common/BfReduxStateConstants'
import BfHttpClient from '../../services/authClient/BfHttpClient'
import { Link } from '@patron/patron-react/link'
import { map } from 'rxjs/operators'
import BfShowRelevantItems from '../devices/BfShowRelevantItems'
import _ from '../../services/utils/BfLodash'
import BfConsole from 'loglevel'

class BfPolicyGroupsGrid extends Component {
  bfPolicyGroupsService = new BfPolicyGroupsService()
  http = new BfHttpClient()

  constructor(props) {
    super(props)
    this.id = BfReduxStateConstants.POLICYGROUPS

    try {
      var urlParse = window.location.pathname.replace(/\/$/, '').split('/')
      var policyGroupID = urlParse[urlParse.length - 1]
      var selectedPolicyGroupObj = {}

      selectedPolicyGroupObj = [{ id: JSON.parse(policyGroupID) }]
      this.preSelected = selectedPolicyGroupObj
    } catch (e) {
      BfConsole.debug('error parsing policyGroupID from page', e)
    }

    this.highlightedRows = {}

    this.headers = [
      {
        label: 'Name',
        field: 'id',
        type: 'policyGroup',
        isDefault: true,
        styles: { left: '40px' },
        width: '200px',
        propertyName: 'Policy Search',
        columnHtmlType: 'searchPolicyGroupsHTML'
      },
      {
        label: 'Description',
        field: 'description',
        isDefault: true,
        sortable: true,
        styles: { left: '40px' },
        width: '200px'
      },
      {
        label: 'OS',
        field: 'os',
        isDefault: true,
        sortable: true,
        styles: { left: '40px' },
        width: '100px',
        columnHtmlType: 'simpleOSHTML'
      },
      {
        label: 'Creator',
        field: 'createdBy',
        isDefault: true,
        sortable: true,
        styles: { left: '40px' },
        width: '200px',
        columnHtmlType: 'creatorHTML'
      },
      {
        label: 'Creation Date',
        field: 'creationDate',
        isDefault: true,
        sortable: true,
        styles: { left: '40px' },
        width: '300x'
      },
      {
        label: 'Modified Date',
        field: 'modifiedDate',
        isDefault: true,
        sortable: true,
        styles: { left: '40px' },
        width: '300px'
      },
      {
        label: 'Last Deployed Date',
        field: 'deployedDate',
        isDefault: true,
        sortable: true,
        styles: { left: '40px' },
        width: '300px'
      },
      {
        label: 'Groups Targeted',
        field: 'groupsTargeted',
        isDefault: true,
        styles: { left: '40px' },
        width: '200px',
        columnHtmlType: 'groupsTargetedHTML'
      },
      {
        label: 'Policy Count',
        field: 'policyCount',
        isDefault: true,
        styles: { left: '40px' },
        width: '200px'
      },
      {
        label: 'App Count',
        field: 'appCount',
        isDefault: true,
        styles: { left: '40px' },
        width: '100px'
      },
      {
        label: 'Delete',
        field: 'options',
        isDefault: true,
        styles: { left: '20px' },
        renderHtml: (policygroup) => {
          return (
            <React.Fragment>
              <Link className='pl-7' href='#' onClick={() => this._deletePolicyGroup(policygroup)}>
                <i className='p-hclsw p-hclsw-delete' />
              </Link>
            </React.Fragment>
          )
        },
        width: '80px'
      }
    ]
  }

  _deletePolicyGroup = (policygroup) => {
    this.http.delete('/mdm/api/policyGroup/' + policygroup.id).subscribe(
      () => {
        window.location.reload()
      },
      (error) => {
        console.error('Unable to delete policy group: ', error)
      }
    )
  }

  _getPolicyGroups = (urlOptions, columns, options) => {
    return this.bfPolicyGroupsService.getPolicyGroups(urlOptions).pipe(
      map((data) => {
        const miniTranslations = {
          overAirEnroll: 'Over the Air Enrollment',
          bulkEnroll: 'Bulk Enrollment',
          autopilotEnroll: 'Autopilot Enrollment',
          autoDeviceEnroll: 'Automated Device Enrollment',
          byodEnroll: 'BYOD Enrollment',
          managedQREnroll: 'Full Managed QR Enrollment',
          None: 'None'
        }

        for (var i = 0; i < data.rows.length; i += 1) {
          // modified reformat
          var date = new Date(data.rows[i].modifiedDate * 1000)
          var hours = date.getHours()
          var minutes = '0' + date.getMinutes()
          var seconds = '0' + date.getSeconds()
          var formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2)
          var reformattedTime = date + formattedTime
          data.rows[i].modifiedDate = reformattedTime.slice(0, reformattedTime.indexOf('(') - 1)
          // creation reformat
          var date2 = new Date(data.rows[i].creationDate * 1000)
          var hours2 = date2.getHours()
          var minutes2 = '0' + date2.getMinutes()
          var seconds2 = '0' + date2.getSeconds()
          var formattedTime2 = hours2 + ':' + minutes2.substr(-2) + ':' + seconds2.substr(-2)
          var reformattedTime2 = date2 + formattedTime2
          data.rows[i].creationDate = reformattedTime2.slice(0, reformattedTime2.indexOf('(') - 1)
          // deployed reformat
          if (data.rows[i].deployedDate) {
            var date3 = new Date(data.rows[i].deployedDate * 1000)
            var hours3 = date3.getHours()
            var minutes3 = '0' + date3.getMinutes()
            var seconds3 = '0' + date3.getSeconds()
            var formattedTime3 = hours3 + ':' + minutes3.substr(-2) + ':' + seconds3.substr(-2)
            var reformattedTime3 = date3 + formattedTime3
            data.rows[i].deployedDate = reformattedTime3.slice(0, reformattedTime3.indexOf('(') - 1)
          }

          if (data.rows[i].groupsTargeted !== '') {
            let translatedArray = data.rows[i].groupsTargeted.split(',')
            translatedArray = translatedArray.map((element) => {
              return miniTranslations[element]
            })
            data.rows[i].groupsTargeted = translatedArray.join(', ')
          }
        }
        return data
      })
    )
  }

  render() {
    const {
      showRelevantFilter,
      showPaginationIcons = true,
      options = {
        updateUrl: false,
        showDeployButton: true,
        showSelectedOnly: false
      },
      customActionBar,
      id = null
    } = this.props

    return (
      <BfDataGridTable
        selectableRow
        getRows={this._getPolicyGroups}
        headers={this.headers}
        id={id || BfReduxStateConstants.POLICYGROUPS}
        customActionBar={customActionBar}
        showPaginationIcons={showPaginationIcons}
        paginationBarComponents={
          _.isEmpty(showRelevantFilter)
            ? null
            : [<BfShowRelevantItems key='showRelevantItemsDevices' showRelevantFilter={showRelevantFilter} id={this.id} />]
        }
        onRowSelected={this.props.onRowSelected}
        preSelectedItems={this.preSelected}
        rowGroupPanel
        showSummary={this.props.showSummary}
        options={options}
      />
    )
  }
}

export default BfPolicyGroupsGrid
