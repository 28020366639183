import React from 'react'
import BfCellWidth from './BfCellWidth'
import BfCellWithTooltipRenderer from './BfCellWithTooltipRenderer'
/**
 * Renders Agent Status cells
 */
class BfCellYesNoRender {
  constructor(header) {
    this.header = header
    this.name = header.field
  }

  getRenderer() {
    return this._getYesNoRenderHTML
  }

  /**
   * Renders Yes/No cell
   * @method
   * @param {Object} row field
   * @returns {HTMLElement} Cell HTML
   */
  _getYesNoRenderHTML = (row) => {
    const displayedValue = row[this.name] === 0 ? 'No' : 'Yes'
    return (
      <BfCellWidth header={this.header}>
        <BfCellWithTooltipRenderer textTooltip={displayedValue}>{displayedValue}</BfCellWithTooltipRenderer>
      </BfCellWidth>
    )
  }
}
export default BfCellYesNoRender
