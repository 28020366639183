/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2021
// All Rights Reserved
*/

import React from 'react'
import BfCellWithTooltipRenderer from './BfCellWithTooltipRenderer'
import { withTranslation } from 'react-i18next'
import './BfHtmlCell.scss'
import withBfModal from '../../modals/BfWithModal'
import BfCellModalRenderer from './BfCellModalRenderer'
import BfCellModalHeaderSection from './BfCellModalHeaderSection'
import { BfSafeHTML } from '../../common/BfSafeHTML'
import { bfTranslate } from '../../../services/i18n/bfI18Utils'
import BfReduxStateConstants from '../../common/BfReduxStateConstants'
import { BfDataGridComponentWithContext } from '../BfDataGridContext'

/**
 * Cell renderer for data grid
 */
const BfHtmlCell = (props) => {
  const { id: dataGridType, rowData, value, showModal, title, t } = props

  let valueWithoutHTML = ''
  const tooltipText = bfTranslate(t, 'TooltipIconDescription')

  const descriptionInError = rowData.errorRetrieveDescription === 1

  const _onClickShowModal = () => {
    showModal({
      message: _getModalContent(),
      title: title,
      classStyle: 'bf-modal-description'
    })
  }

  const _getModalContent = () => {
    const { value, title, rowData } = props
    return (
      <BfCellModalRenderer title={title} headerSection={<BfCellModalHeaderSection row={rowData} />}>
        <div className='bf-text-modal'>
          <BfSafeHTML html={value} />
        </div>
      </BfCellModalRenderer>
    )
  }

  const _getInnerText = (text) => {
    text = text
      .replace(/<style.*>.*?<\/style>/gim, '')
      .replace(/<script.*?>.*?<\/script>/gim, '')
      .replace(/<\/?[^>]+(>|$)/g, '')
    return <BfSafeHTML inheritStyle html={text} />
  }

  const _getItemTitle = () => {
    switch (dataGridType) {
      case BfReduxStateConstants.SOFTWARE_DATA_GRID:
        return 'Software name'
      case BfReduxStateConstants.CONTENT_DATA_GRID:
        return 'Content item'
      default:
        return 'Custom item'
    }
  }

  const _getErrorDescription = () => {
    return bfTranslate(t, 'DescriptionOverLimitTooltip', { columnName: _getItemTitle() })
  }

  const _prepareLabels = () => {
    if (descriptionInError) {
      const descriptionError = _getErrorDescription()
      valueWithoutHTML = descriptionError
    } else {
      valueWithoutHTML = _getInnerText(value)
    }
  }

  _prepareLabels()

  return (
    <div className='bf-cell-container'>
      <div className='bf-cell-fill bf-ellipsis'>
        <span className='bf-cell-align-tooltip'>{valueWithoutHTML}</span>
      </div>
      {!descriptionInError && (
        <span onClick={_onClickShowModal} className='bf-icon-openModalContainer'>
          <BfCellWithTooltipRenderer textTooltip={tooltipText} noSplit>
            <i className='p-hclsw p-hclsw-show bf-icon-openModal' />
          </BfCellWithTooltipRenderer>
        </span>
      )}
    </div>
  )
}

export default BfDataGridComponentWithContext(withBfModal(withTranslation()(BfHtmlCell)))
