/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/
import numeral from 'numeral'

class BfShortNumber {
  get(num) {
    num = numeral(num).value()
    if (num > 1000) {
      return numeral(num).format('0.0a')
    } else {
      return num
    }
  }
}
export default BfShortNumber
