import { bfTranslate } from '../../../../services/i18n/bfI18Utils'
import _ from '../../../../services/utils/BfLodash'
import BfConsole from 'loglevel'
import moment from 'moment'
import html2canvas from 'html2canvas'
import { PDFDocument, cmyk } from 'pdf-lib'
import Canvg from 'canvg'
import fontkit from '@pdf-lib/fontkit'
import Blob from 'blob'

export async function createReportPDF(userName, rawFilters, reportTitle, language, t) {
  /*
     72 points per inch
    margins changed to .5/side
    (8.5 - 1) * 72 = 540
  */
  try {
    const nodesToRecover = []
    let chartWidth = 0

    /* convert SVG elements to canvas */
    const svgElems = document.querySelectorAll('.bfChartContainer svg')
    for (let i = 0; i < svgElems.length; i++) {
      const node = svgElems[i]
      const parentNode = node.parentNode
      const svg = parentNode.innerHTML

      const canvasSVG = document.createElement('canvas')
      const ctx = canvasSVG.getContext('2d')
      const canvgObj = await Canvg.fromString(ctx, svg)
      canvgObj.resize(parentNode.clientWidth - 50, parentNode.clientHeight, 'xMidYMid meet')
      chartWidth += parentNode.clientWidth
      await canvgObj.render()
      nodesToRecover.push({
        parent: parentNode,
        svgChild: node,
        canvasChild: canvasSVG,
        canvg: canvgObj
      })
      parentNode.removeChild(node)
      parentNode.appendChild(canvasSVG)
    }

    const summaryContainer = document.getElementsByClassName('bfChartsContainer')[0]
    if (!summaryContainer) {
      throw new Error('Summary container not found - must be open to export')
    }
    const html2canvasOptions = {
      // newer html2canvas seem to get different results in different browsers
      // (specifically Chrome) so we're using an older one
      windowWidth: chartWidth + 40,
      windowHeight: 800,
      scrollX: 0,
      scrollY: 0,

      // output canvas size, needs to be resized to match page
      width: chartWidth + 40,
      height: 480,

      scale: 4,
      logging: false,
      allowTaint: true,
      letterRendering: true,

      backgroundColor: '#f5f5f5'
    }

    BfConsole.debug('html2canvas options', html2canvasOptions)
    const canvas = await html2canvas(summaryContainer, html2canvasOptions)
    const chartPng = canvas.toDataURL('image/png')
    for (let n = 0; n < nodesToRecover.length; n++) {
      const nodeObj = nodesToRecover[n]
      nodeObj.parent.removeChild(nodeObj.canvasChild)
      nodeObj.parent.appendChild(nodeObj.svgChild)
      await nodeObj.canvg.stop()
    }
    const pdfDoc = await PDFDocument.create()
    const page = await pdfDoc.addPage()

    pdfDoc.registerFontkit(fontkit)
    let regularPath = '/fonts/roman/'
    const lowercaseLang = language.toLowerCase()
    if (lowercaseLang === 'ja') {
      regularPath += 'NotoSansJP-Regular.otf'
    } else if (lowercaseLang === 'ko') {
      regularPath += 'NotoSansKR-Regular.otf'
    } else if (lowercaseLang === 'zh-cn') {
      regularPath += 'NotoSansSC-Regular.otf'
    } else if (lowercaseLang === 'zh-tw') {
      regularPath += 'NotoSansTC-Regular.otf'
    } else {
      regularPath += 'Roboto-Regular.ttf'
    }

    const regularFontBytes = await window.fetch(regularPath).then((res) => res.arrayBuffer())
    const pdfFont = await pdfDoc.embedFont(regularFontBytes)
    const pngImage = await pdfDoc.embedPng(chartPng)

    /*
  pdflib x y is from bottom left corner
  y (to page height)
  ^
  |
  +-> x (to page width)
  all margins manual
  objects are placed according to their bottom-left corner, so you
  have to move down by an item's height in order to place it at 
  the current location
   */
    const margin = 36
    const titleSize = 16
    const smallTextSize = 12
    const filterHeaderSize = 16
    const filterTextSize = 12
    const filterTextBackgroundSpacing = 14
    const sectionSpacing = 16
    const chartBorderWidth = 12
    const bgColor = cmyk(0.0, 0.0, 0.0, 0.04)
    const textHeightCorrection = 3

    const titleText = reportTitle
    const authorText = bfTranslate(t, 'author') + ': ' + userName
    const dateStampText =
      bfTranslate(t, 'date') + ': ' + moment().format('LLL ZZ') + ' (' + moment.utc().format('LLL') + ' ' + bfTranslate(t, 'utcTime') + ')'
    const pageSize = page.getSize()
    const pageWidth = pageSize.width
    const pageHeight = pageSize.height
    const pngDims = pngImage.scale((pageWidth - 2 * margin - 2 * chartBorderWidth) / pngImage.width)
    /* eslint-disable no-inner-declarations */
    function writeTextLine(text, fontSize, rightJustify) {
      page.moveDown(fontSize)
      const options = {
        size: fontSize,
        font: pdfFont
      }
      if (rightJustify) {
        options.x = pageWidth - margin - options.font.widthOfTextAtSize(text, fontSize) - 10
      }
      page.drawText(text, options)
      page.moveDown(textHeightCorrection)
    }

    function splitTextLineWidths(textLineArray, writableWidth) {
      const newTextLines = []

      textLineArray.forEach(function (textItem) {
        const currentFont = pdfFont

        if (currentFont.widthOfTextAtSize(textItem.text, textItem.size) <= writableWidth) {
          newTextLines.push(textItem)
        } else {
          const words = textItem.text.split(' ')
          let counter = 0

          while (counter < words.length) {
            let firstWord = true
            const phrase = [words[counter]]
            while (currentFont.widthOfTextAtSize(phrase.join(' '), textItem.size) < writableWidth) {
              counter++
              phrase.push(words[counter])
              firstWord = false
            }
            if (!firstWord) {
              phrase.pop()
            }
            firstWord = true
            // this does not take into account single words that are longer than writeableWidth
            // handling that requires this to be done on a character level which would be much slower
            newTextLines.push({
              text: phrase.join(' '),
              size: textItem.size
            })
          }
        }
      })

      return newTextLines
    }
    /* eslint-enable no-inner-declarations */
    page.moveTo(margin, pageHeight - margin)

    writeTextLine(titleText, titleSize)
    page.moveDown(sectionSpacing)
    writeTextLine(authorText, smallTextSize)
    writeTextLine(dateStampText, smallTextSize)
    page.moveDown(sectionSpacing)

    page.moveDown(pngDims.height + 2 * chartBorderWidth)

    page.drawRectangle({
      width: pageWidth - 2 * margin,
      height: pngDims.height + 2 * chartBorderWidth,
      opacity: 1,
      color: bgColor
    })

    page.moveUp(chartBorderWidth)
    page.moveRight(chartBorderWidth)

    page.drawImage(pngImage, {
      width: pngDims.width,
      height: pngDims.height
    })

    page.moveDown(chartBorderWidth)
    page.moveLeft(chartBorderWidth)

    page.moveDown(sectionSpacing)
    page.moveDown(sectionSpacing)
    let allFilterTexts = []

    if (!_.isEmpty(rawFilters)) {
      allFilterTexts.push({
        text: bfTranslate(t, 'pdfExportFilterHeader'),
        size: filterHeaderSize
      })
      allFilterTexts.push({ text: ' ', size: filterTextSize })

      _.each(rawFilters, function (filter) {
        const title = filter.substring(0, filter.indexOf(': '))
        const value = filter.substring(filter.indexOf(': ') + 1, filter.length)
        allFilterTexts.push({ text: title, size: filterTextSize })
        allFilterTexts.push({ text: value, size: filterTextSize })
      })

      allFilterTexts = splitTextLineWidths(allFilterTexts, pageWidth - 2 * margin - 2 * filterTextBackgroundSpacing)

      const filterTextBlockHeight = allFilterTexts.reduce(function (totalHeight, textLine) {
        return (totalHeight += textLine.size + textHeightCorrection)
      }, 0)

      page.moveDown(filterTextBlockHeight + 2 * filterTextBackgroundSpacing)

      page.drawRectangle({
        width: pageWidth - 2 * margin,
        height: filterTextBlockHeight + 2 * filterTextBackgroundSpacing,
        opacity: 1,
        color: bgColor
      })

      page.moveUp(filterTextBlockHeight + filterTextBackgroundSpacing)
      page.moveRight(filterTextBackgroundSpacing)

      allFilterTexts.forEach(function (textLine) {
        writeTextLine(textLine.text, textLine.size)
      })
    }
    const pdf = await pdfDoc.save()
    const pdfBlob = await new Blob([pdf], { type: 'application/pdf' })
    return pdfBlob
  } catch (error) {
    BfConsole.error('Error in creating pdf:')
    BfConsole.error(error)
    throw error
  }
}
