/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import BfDropDownBasicFilter from '../../common/bfBasicFilters/bfDropDownFilter/BfDropDownBasicFilter'

class BfAgentStatusFilter extends BfDropDownBasicFilter {
  static NAME = 'agentStatus'
  static PATH = `filter.${BfAgentStatusFilter.NAME}`

  constructor(data = {}) {
    super(BfAgentStatusFilter.PATH, BfAgentStatusFilter.NAME, data)
  }

  getItems = () => {
    const res = {
      values: [
        {
          id: 'Native',
          text: 'Installed'
        },
        {
          id: 'agentNotInstalled',
          text: 'Not Installed'
        }
      ]
    }
    return res.values
  }
}

export default BfAgentStatusFilter
