/*
// Licensed Materials - Property of HCL
// © Copyright HCL Technologies Limited 2001, 2021
// All Rights Reserved
*/

import React from 'react'
import BfCellWidth from './BfCellWidth'
import BfCellWithTooltipRenderer from './BfCellWithTooltipRenderer'
import BfLocalizedDate from '../../../filters/BfLocalizedDate'

/**
 * Formats cell value to date, but accepts additional date formats passed to BfLocalizedDate
 */
class BfCellLocalizedDateRender {
  constructor(header, format, options = {}) {
    this.header = header
    this.name = header?.field
    this.format = format
    this.dateOptions = options
  }

  /**
   * returns the cell renderer
   * @returns {Function} Cell renderer
   */
  getRenderer() {
    return this._getDateRenderHTML
  }

  /**
   * Formats row data in Date format
   * @method
   * @param {Object} row Row data to be formatted
   * @returns {HTMLElement} Formatted cell HTML
   */
  _getDateRenderHTML = (row) => {
    const timeField = parseInt(row[this.name], 10)
    let formattedTime = row[this.name]
    if (!isNaN(timeField)) {
      formattedTime = new BfLocalizedDate(this.format).getDate(timeField, this.dateOptions)
    }
    return (
      <BfCellWidth header={this.header}>
        <BfCellWithTooltipRenderer noSplit textTooltip={formattedTime}>
          <span>{formattedTime}</span>
        </BfCellWithTooltipRenderer>
      </BfCellWidth>
    )
  }
}

export default BfCellLocalizedDateRender
