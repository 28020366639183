import React, { Component } from 'react'
import './BfCellEmptyRenderer.scss'
import { withTranslation } from 'react-i18next'
import { bfTranslate } from '../../../services/i18n/bfI18Utils'

class BfCellEmptyRenderer extends Component {
  render() {
    const { t, value } = this.props

    return <span className='empty-cell'>{'<' + bfTranslate(t, value) + '>'}</span>
  }
}

export default withTranslation()(BfCellEmptyRenderer)
