import { useEffect, useRef } from 'react'

/**
 * Cleaner custom react hook for setInterval. Sets up and also cleans up after unmounting.
 * see https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 * @param {Function} callback
 * @param {int} delay
 */
export default function BfUseInterval(callback, delay) {
  const savedCallback = useRef()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}
