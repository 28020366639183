import { map } from 'rxjs/operators'
import BfCachedRequest from '../utils/BfCachedRequest'
import _ from '../utils/BfLodash'

class BfCloudPluginService {
  constructor() {
    this._init()
  }

  _init = () => {
    this.bfPluginDefinitionRequest = new BfCachedRequest()
    this.bfCheckPrerequisiteRequest = new BfCachedRequest()
    this.bfPortalRequest = new BfCachedRequest()
    this.bfPluginStatsRequest = new BfCachedRequest()
  }

  invalidateCache = () => {
    this._init()
  }

  getPluginsDefinition = () => {
    return this.bfPluginDefinitionRequest.get('/framework/plugins/pluginsdefinition')
  }

  getPluginsStats = () => {
    return this.bfPluginStatsRequest.get('/framework/plugins/getpluginsstats')
  }

  _getPluginsStatsByProviderName = (providerName) => {
    return this.getPluginsStats().pipe(map((stats) => stats[providerName]))
  }

  getPluginsSettingsByProviderName = (providerName) => {
    return this._getPluginsStatsByProviderName(providerName).pipe(
      map((configuredProviderStats) => {
        if (configuredProviderStats) {
          const computerIds = _.keys(configuredProviderStats)
          const providersStatsByComputerID = _.map(computerIds, (computerId) => {
            const props = configuredProviderStats[computerId].props
            return {
              device: configuredProviderStats[computerId].device,
              version: this._getPropertyByName(props, 'Version'),
              refreshInterval: this._getPropertyByName(props, 'Refresh Interval (min)'),
              settings: this._getPropertyByName(props, 'Settings')
            }
          })
          return providersStatsByComputerID
        }
        return null
      })
    )
  }

  invalidatePortals = () => {
    this.bfPortalRequest = new BfCachedRequest()
  }

  getPortals = () => {
    return this.bfPortalRequest.get('/framework/plugins/getportals')
  }

  checkPrerequisite = () => {
    return this.bfCheckPrerequisiteRequest.get('/framework/plugins/checkprerequisite').pipe(map((data) => this._checkPrerequisite(data)))
  }

  _checkPrerequisite = (data) => {
    return _.every(data, function (result) {
      return result.isActive
    })
  }

  _getPropertyByName = (props, propertyName) => {
    var foundProp = _.find(props, function (prop) {
      return prop.propertyName === propertyName
    })
    if (foundProp) {
      return foundProp.value
    }
    return null
  }
}

export default BfCloudPluginService
