/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/
import _ from '../../../../../services/utils/BfLodash'
import BfBasicFilter from '../../BfBasicFilter'

class BfPropertyFilter extends BfBasicFilter {
  static NAME = 'property'
  doFilter = true
  constructor(key, text) {
    super()
    this.key = key
    this.value = _.isUndefined(text) ? {} : { [text]: true }
  }

  get = () => {
    return _.isEmpty(this.value) ? { [this.key]: {} } : { [this.key]: this.value }
  }

  buildFromURLHandler = (urlHandler) => {
    const { filter } = urlHandler.getSearch()
    if (!_.isEmpty(filter) && !_.isEmpty(filter[this.key])) {
      this.value = filter[this.key]
    }
  }

  setQuery = (query) => {
    this.value = _.isEmpty(query) ? '' : { [query]: true }
  }

  getQuery = () => {
    if (_.isEmpty(this.value)) {
      return ''
    }
    const keys = _.keys(this.value)
    return _.size(keys) > 0 ? _.first(keys) : ''
  }
}

export default BfPropertyFilter
