/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import _ from '../../../../../services/utils/BfLodash'
import BfBasicFilter from '../../BfBasicFilter'
import { GREATER_OR_EQUAL_THAN, LESS_OR_EQUAL_THAN } from '../../common/bfBasicFilters/BfNumberAndDropDownFilter/BfNumberAndDropDownFilter'

class BfApplicableDevicesFilter extends BfBasicFilter {
  static NAME = 'deviceCount'
  static PATH = `filter.${BfApplicableDevicesFilter.NAME}`

  constructor(data = { isLessSelected: false, val: 0 }) {
    super(BfApplicableDevicesFilter.PATH, BfApplicableDevicesFilter.NAME)
    this[BfApplicableDevicesFilter.NAME] = data
  }

  getCount() {
    return _.isEmpty(this[BfApplicableDevicesFilter.NAME]) ? 0 : this.getSummary()
  }

  getOptions() {
    return [GREATER_OR_EQUAL_THAN, LESS_OR_EQUAL_THAN]
  }

  setVal(val) {
    this[BfApplicableDevicesFilter.NAME].val = val
  }

  setOption(option) {
    this[BfApplicableDevicesFilter.NAME].isLessSelected = option === LESS_OR_EQUAL_THAN
  }

  getSummary() {
    if (this.getVal()) {
      const summary = (this._isLess() ? '\u2264 ' : '\u2265 ') + this.getVal()
      return summary
    }
  }

  getVal() {
    return _.isEmpty(this[BfApplicableDevicesFilter.NAME].val) ? 0 : this[BfApplicableDevicesFilter.NAME].val
  }

  getPreselectedOption() {
    const isLess = this._isLess()
    switch (isLess) {
      case false:
        return GREATER_OR_EQUAL_THAN
      case true:
        return LESS_OR_EQUAL_THAN
    }
  }

  _isLess() {
    return _.isUndefined(this[BfApplicableDevicesFilter.NAME].isLessSelected) ? false : this[BfApplicableDevicesFilter.NAME].isLessSelected
  }
}

export default BfApplicableDevicesFilter
