/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import BfDateRangeBasicFilter from '../../common/bfBasicFilters/bfDateRangeFilter/BfDateRangeBasicFilter'

class BfLastSeenFilter extends BfDateRangeBasicFilter {
  static NAME = 'lastSeen'
  static PATH = `filter.${BfLastSeenFilter.NAME}`

  constructor(data = {}) {
    super(BfLastSeenFilter.PATH, BfLastSeenFilter.NAME)
    this.data = data
  }
}

export default BfLastSeenFilter
