/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import BfBasicFilter from '../../BfBasicFilter'

class BfApplicablePatchesFilter extends BfBasicFilter {
  static NAME = 'applicablePatches'
  static PATH = `filter.${BfApplicablePatchesFilter.NAME}`

  constructor(data = {}) {
    super(BfApplicablePatchesFilter.PATH, BfApplicablePatchesFilter.NAME)
    this[BfApplicablePatchesFilter.NAME] = data
  }
}

export default BfApplicablePatchesFilter
