/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/
import _ from '../../../../services/utils/BfLodash'

import { createDropDownFilterItem } from '../common/bfBasicFilters/bfDropDownFilter/BfDropDownFilter'
import BfDropDownBasicFilter from '../common/bfBasicFilters/bfDropDownFilter/BfDropDownBasicFilter'

class ApStatusFilter extends BfDropDownBasicFilter {
  static NAME = 'status'
  static PATH = `filter.${ApStatusFilter.NAME}`
  constructor(data = {}) {
    super(ApStatusFilter.PATH, ApStatusFilter.NAME, data)
  }

  getItems = (searchString) => {
    const t = this.getT()
    const options = ['active', 'suspended']

    let items = _.map(options, (status) => createDropDownFilterItem(status, t('STATUS_' + status.toUpperCase())))

    if (searchString) {
      const regex = new RegExp(searchString, 'i')

      items = _.filter(items, (item) => {
        return item.label.match(regex)
      })
    }

    return items
  }
}

export default ApStatusFilter
