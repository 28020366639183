/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/
import _ from '../../../../../services/utils/BfLodash'
import BfBasicFilter from '../../BfBasicFilter'

class InsightsSiteTypeFilter extends BfBasicFilter {
  static NAME = 'type'
  static PATH = `filter.${InsightsSiteTypeFilter.NAME}`
  constructor(data = {}) {
    super(InsightsSiteTypeFilter.PATH, InsightsSiteTypeFilter.NAME)
    this.type = data
  }

  get = () => {
    return _.isEmpty(this.type) ? { type: {} } : { type: this.type }
  }

  buildFromURLHandler = (urlHandler, consumeUrl = false) => {
    this.type = this._buildFromURLHandler(urlHandler, consumeUrl, this.type)
  }

  getData = () => {
    return this.type
  }

  setData = (data) => {
    this.type = data
  }
}

export default InsightsSiteTypeFilter
