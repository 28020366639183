/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import { forkJoin, of } from 'rxjs'
import _ from '../../../services/utils/BfLodash'
import BfPlatformVersion from '../../../services/devices/BfPlatformVersion'
import BfCurrentUserInfoService from '../../../services/user/BfCurrentUserInfoService'
import BfSendRefresh from '../../../services/commands/BfSendRefresh'
import { map, mergeMap, catchError } from 'rxjs/operators'
import logConsole from 'loglevel'
import BfActionHandler from '../../../services/takeAction/BfActionHandler'
import BfCloudPluginService from '../../../services/plugins/BfCloudPluginService'
import BfAgentInstallationServices from '../../../services/agentInstallation/BfAgentInstallationServices'
import BfUserCheck from '../../../services/usercheck/BfUserCheck'

export const BF_FALSE = 0
export const BF_TRUE = 1
export const BF_ASYNC_FALSE = 2
export const BF_REDIRECT = 3
export const BF_RUNACTION = 4
class BfActionsService {
  BF_MAX_ALLOWED_DEVICES_FOR_SEND_REFRESH = 100
  BF_MAX_ALLOWED_DEVICES_FOR_DEPLOY = 50

  bfPlatformVersion = new BfPlatformVersion()
  bfCloudPluginService = new BfCloudPluginService()
  bfAgentInstallationServices = new BfAgentInstallationServices()

  constructor(_bfCurrentUserInfoService = new BfCurrentUserInfoService()) {
    this.bfCurrentUserInfoService = _bfCurrentUserInfoService
  }

  _isAgentTypeDefined = (device) => {
    return _.isDefined(device.agentType)
  }

  _isCloudOnly = (device) => {
    return device.cloud && !device.agent && !device.mdm
  }

  _isMdmOnly = (device) => {
    return device.mdm && !device.cloud && !device.agent
  }

  _isAgentOnly = (device) => {
    return device.agent && !device.cloud && !device.mdm
  }

  _isMdm = (device) => {
    return device.mdm
  }

  _isCloud = (device) => {
    return device.cloud
  }

  _isAgent = (device) => {
    return device.agent
  }

  _isCloudGCP = (device) => {
    return device.cloudType === 'Proxy - Google Cloud Platform'
  }

  _isCloudESX = (device) => {
    return device.cloudType === 'Proxy - VMware'
  }

  _isCloudAWS = (device) => {
    return device.cloudType === 'Proxy - Amazon Web Services'
  }

  _isCloudAzure = (device) => {
    return device.cloudType === 'Proxy - Microsoft Azure'
  }

  _isUndefined = (device) => {
    return _.isUndefined(device.agent) && _.isUndefined(device.cloud) && _.isUndefined(device.mdm)
  }

  _onlyMDMDevices = (devicesByProvider, selectedDevices) => {
    return _.size(devicesByProvider.mdm) === _.size(selectedDevices)
  }

  _onlyGCPAndESXDevices = (devicesByProvider, selectedDevices) => {
    const GCP = 'Proxy - Google Cloud Platform'
    const ESX = 'Proxy - VMware'
    return _.size(this._isDefinedOrEmpty(devicesByProvider[GCP])) + _.size(this._isDefinedOrEmpty(devicesByProvider[ESX])) === _.size(selectedDevices)
  }

  _isDefinedOrEmpty = (arr) => {
    return _.isDefined(arr) ? arr : []
  }

  _onlyAzureDevices = (devicesByProvider, selectedDevices) => {
    const AZURE = 'Proxy - Microsoft Azure'
    return _.size(this._isDefinedOrEmpty(devicesByProvider[AZURE])) === _.size(selectedDevices)
  }

  _onlyAWSDevices = (devicesByProvider, selectedDevices) => {
    const AWS = 'Proxy - Amazon Web Services'
    return _.size(this._isDefinedOrEmpty(devicesByProvider[AWS])) === _.size(selectedDevices)
  }

  _devicesOfDifferentTypes = (devicesByProvider) => {
    const cloud = _.size(devicesByProvider.cloud) > 0 ? 1 : 0
    const mdm = _.size(devicesByProvider.mdm) > 0 ? 2 : 0
    const agent = _.size(devicesByProvider.agent > 0) ? 4 : 0
    const res = cloud | mdm | agent
    return !(res !== 0 && (res & (res - 1)) === 0)
  }

  _addDeviceSpecificCloudProvider = (devicesByProvider, device) => {
    const { cloudType } = device
    if (_.isUndefined(devicesByProvider[cloudType])) {
      devicesByProvider[cloudType] = []
    }
    devicesByProvider[cloudType].push(device)
  }

  _addDeviceProvider = (devicesByProvider, device, type) => {
    devicesByProvider[type].push(device)
    if (type === 'cloud') {
      this._addDeviceSpecificCloudProvider(devicesByProvider, device)
    }
  }

  _getDevicesByProvider = (selectedDevices) => {
    const devicesByProvider = { cloud: [], mdm: [], agent: [], other: [] }

    _.each(selectedDevices, (device) => {
      if (this._isAgent(device)) {
        this._addDeviceProvider(devicesByProvider, device, 'agent')
      }
      if (this._isCloud(device)) {
        this._addDeviceProvider(devicesByProvider, device, 'cloud')
      }
      if (this._isMdm(device)) {
        this._addDeviceProvider(devicesByProvider, device, 'mdm')
      }
      if (this._isUndefined(device)) {
        this._addDeviceProvider(devicesByProvider, device, 'other')
      }
    })

    return devicesByProvider
  }

  _getSiteAndContentID = (pluginsDefinition, providerName) => {
    let siteID = 0
    let contentID = 0
    _.each(pluginsDefinition.plugins, (type) => {
      if (type.name === 'Cloud') {
        _.each(type.providers, (provider) => {
          if (provider.name === providerName) {
            siteID = provider.installBigFixAgent?.siteID
            contentID = provider.installBigFixAgent?.contentID
          }
        })
      }
    })
    return { siteID: siteID, contentID: contentID }
  }

  _calculateAsyncResponse = (relevantDevices, response, selectedDevices, task) => {
    const asyncResponse = {
      flag: BF_ASYNC_FALSE,
      modalProperties: {},
      appId: response.appId
    }

    if (relevantDevices.count === selectedDevices.length) {
      asyncResponse.action = {
        siteID: task.siteID,
        contentID: task.contentID
      }
      asyncResponse.flag = BF_RUNACTION
    } else if (relevantDevices.count === 0) {
      asyncResponse.redirectTo = '/framework/deploybesagent'
      asyncResponse.flag = BF_REDIRECT
      asyncResponse.actionID = 'bigfixAgentDeployCloud'
    } else {
      asyncResponse.action = {
        siteID: task.siteID,
        contentID: task.contentID
      }
      asyncResponse.relevantDevices = relevantDevices.data
      asyncResponse.modalProperties.message = {
        key: 'onlySomeRelevant',
        params: {
          count: relevantDevices.count,
          totalDevices: selectedDevices.length
        }
      }
      asyncResponse.modalProperties.title = 'warning'
      asyncResponse.modalProperties.okLabel = 'installPartially'
      asyncResponse.modalProperties.type = 'warning'
    }

    return asyncResponse
  }

  _getProviderName = (devicesByProvider, selectedDevices) => {
    return this._onlyAzureDevices(devicesByProvider, selectedDevices) ? 'Azure' : 'AWS'
  }

  _calculateBigFixAgentDeployResponse = (response, selectedDevices, t) => {
    const devicesByProvider = this._getDevicesByProvider(selectedDevices)

    if (devicesByProvider.agent.length > 0) {
      response.flag = BF_FALSE
      response.modalProperties.message = {
        key: 'agentSelection'
      }
      response.modalProperties.title = 'DeviceMismatch'
      response.modalProperties.type = 'danger'
    } else if (this._onlyMDMDevices(devicesByProvider, selectedDevices)) {
      response.flag = BF_TRUE
      response.redirectTo = '/mdm/action/deployBigFixAgent'
      response.actionID = 'bigfixAgentDeploy'
      response.selectedDevices = selectedDevices
    } else if (this._onlyGCPAndESXDevices(devicesByProvider, selectedDevices)) {
      response.flag = BF_TRUE
      response.redirectTo = '/framework/deploybesagent'
      response.actionID = 'bigfixAgentDeployCloud'
      response.selectedDevices = selectedDevices
    } else if (this._onlyAzureDevices(devicesByProvider, selectedDevices) || this._onlyAWSDevices(devicesByProvider, selectedDevices)) {
      if (selectedDevices.length > this.BF_MAX_ALLOWED_DEVICES_FOR_DEPLOY) {
        response.flag = BF_FALSE
        response.modalProperties.message = {
          key: 'TooManyDeviceDeploy',
          params: {
            max_devices: this.BF_MAX_ALLOWED_DEVICES_FOR_DEPLOY
          }
        }
        response.modalProperties.type = 'danger'
      } else {
        let siteID = 0
        let contentID = 0

        return this.bfCloudPluginService.getPluginsDefinition().pipe(
          mergeMap((pluginsDefinition) => {
            const providerName = this._getProviderName(devicesByProvider, selectedDevices)

            const task = this._getSiteAndContentID(pluginsDefinition, providerName)

            if (task.siteID !== 0 && task.contentID !== 0) {
              siteID = task.siteID
              contentID = task.contentID

              const selectedDevicesID = _.map(selectedDevices, 'id')
              return this.bfAgentInstallationServices.getRelevantCloudDevices(siteID, contentID, selectedDevicesID)
            } else {
              throw new Error('Error retriving siteID and contentID')
            }
          }),
          map((relevantDevices) => {
            return this._calculateAsyncResponse(relevantDevices, response, selectedDevices, { siteID: siteID, contentID: contentID })
          })
        )
      }
    } else {
      response.flag = BF_FALSE
      response.modalProperties.message = {
        key: 'mixedSelection'
      }
      response.modalProperties.type = 'danger'
      response.modalProperties.title = 'DeviceMismatch'
    }

    return of(response)
  }

  handleError = (err, appId) => {
    const modalProperties = {
      message: {},
      type: 'danger',
      appId: appId
    }

    switch (err.type) {
      case 'unsupportedRequiresApprover':
        modalProperties.message.key = err.key
        break
      case 'tooDeviceSelectedPPS':
        modalProperties.message.key = err.key
        modalProperties.message.params = err.params
        break
      case 'TooManyDeviceRefreshes':
        modalProperties.message.key = err.key
        break
      default:
        modalProperties.message.key = 'error'
        modalProperties.message.params = {
          error: JSON.stringify(err)
        }
        break
    }

    return modalProperties
  }

  // selectedDevices here can actually be devices or content (or anything from the datagrid)
  canDevicesRunApp = (selectedDevices, appId, t) => {
    const response = {
      flag: BF_TRUE,
      modalProperties: {},
      appId: appId
    }
    let i = 0

    logConsole.debug('appId: ', appId)

    if (!_.isEmpty(selectedDevices) && this._isAgentTypeDefined(_.first(selectedDevices))) {
      if (appId === 'bigfixAgentDeploy') {
        return this._calculateBigFixAgentDeployResponse(response, selectedDevices, t)
      } else if (appId === 'mdmAgentDeploy') {
        for (i = 0; i < selectedDevices.length; i++) {
          if (!selectedDevices[i].agent) {
            response.modalProperties.message = {
              key: 'nonAgentSelection'
            }
            response.modalProperties.title = 'DeviceMismatch'
            response.modalProperties.type = 'danger'
            response.flag = BF_FALSE
          } else if (selectedDevices[i].os[0].substring(0, 10) !== 'Windows 10' && selectedDevices[i].os[0].substring(0, 5) !== 'Win10') {
            response.modalProperties.message = {
              key: 'nonWinSelection'
            }
            response.modalProperties.title = 'DeviceMismatch'
            response.modalProperties.type = 'danger'
            response.flag = BF_FALSE
          }
        }
      } else if (appId === 'mdm' || appId === 'mdmActionDeploy' || appId === 'mdmUnenrollDeploy' || appId === 'mdmPolicyGroupDeploy3') {
        for (i = 0; i < selectedDevices.length; i++) {
          if (!selectedDevices[i].mdm) {
            response.modalProperties.message = {
              key: 'nonMdmSelection'
            }
            response.modalProperties.title = 'DeviceMismatch'
            response.modalProperties.type = 'danger'
            response.flag = BF_FALSE
          }
        }
      } else if (appId === 'prfmgr') {
        let canDeployProfile = true
        for (i = 0; i < selectedDevices.length; i++) {
          if (!selectedDevices[i].agent) {
            canDeployProfile = false
          }
        }
        if (canDeployProfile) {
          response.modalProperties.message = {
            key: 'deprecateWarningMessage'
          }
          response.modalProperties.title = 'deprecateWarningTitle'
          response.modalProperties.type = 'warning'
          response.flag = BF_TRUE
        } else {
          response.modalProperties.message = {
            key: 'nonAgentSelection'
          }
          response.modalProperties.title = 'DeviceMismatch'
          response.modalProperties.type = 'danger'
          response.flag = BF_FALSE
        }
      } else if (appId === 'frameworkRefresh') {
        if (selectedDevices.length > this.BF_MAX_ALLOWED_DEVICES_FOR_SEND_REFRESH) {
          response.flag = BF_FALSE
          response.modalProperties.message = {
            key: 'TooManyDeviceRefreshes'
          }
          response.modalProperties.type = 'danger'
        } else {
          return this._checkDevicesLimitNmo(selectedDevices, 'frameworkRefresh')
        }
      } else if (appId === 'custom') {
        let agentWithNoMdm = false
        let mdmWithNoAgent = false
        let cloudOnly = false

        for (i = 0; i < selectedDevices.length; i++) {
          if (selectedDevices[i].agent && !selectedDevices[i].mdm) {
            agentWithNoMdm = true
          }
          if (!selectedDevices[i].agent && selectedDevices[i].mdm) {
            mdmWithNoAgent = true
          }

          if (!selectedDevices[i].agent && !selectedDevices[i].mdm && selectedDevices[i].cloud) {
            cloudOnly = true
          }
          if (agentWithNoMdm && mdmWithNoAgent) {
            response.modalProperties.message = {
              key: 'mixedSelection'
            }
            response.modalProperties.title = 'DeviceMismatch'
            response.modalProperties.type = 'danger'
            response.flag = BF_FALSE
          }

          if (cloudOnly) {
            response.modalProperties.message = {
              key: 'cloudSelection'
            }
            response.modalProperties.title = 'DeviceMismatch'
            response.modalProperties.type = 'danger'
            response.flag = BF_FALSE
          }
        }
      } else {
        // all other apps

        if (_.some(selectedDevices, (device) => !device.agent)) {
          response.modalProperties.message = {
            key: 'nonAgentSelection'
          }
          response.modalProperties.title = 'DeviceMismatch'
          response.modalProperties.type = 'danger'
          response.flag = BF_FALSE
        }

        if (appId === 'swd' && response.flag === BF_TRUE) {
          return this._checkDevicesLimitNmo(selectedDevices, 'swd')
        }
      }
    } else {
      response.flag = BF_TRUE
      if (appId === 'prfmgr') {
        response.modalProperties.message = {
          key: 'deprecateWarningMessage'
        }
        response.modalProperties.title = 'deprecateWarningTitle'
        response.modalProperties.type = 'warning'
      } else if (appId === 'mdmPolicyGroupServerDeploy') {
        // selectedDevices here are actually selected policy groups
        if (selectedDevices.some((policyGroup) => policyGroup.groupsTargeted === '')) {
          response.modalProperties.message = {
            key: 'mdmPolicyGroupServerDeployErrorModalMessage'
          }
          response.modalProperties.title = 'mdmPolicyGroupServerDeployErrorModalTitle'
          response.modalProperties.type = 'danger'
          response.flag = BF_FALSE
        }
      }
    }
    logConsole.debug('response is ', response)
    return of(response)
  }

  _checkDevicesLimitNmo = (selectedDevices, appId) => {
    const bfUserCheck = new BfUserCheck(this.bfCurrentUserInfoService)

    return bfUserCheck.checkNmoLimitRequirements(selectedDevices).pipe(
      mergeMap(() => {
        const response = { flag: BF_TRUE, modalProperties: {}, appId: appId }
        return of(response)
      }),
      catchError((e) => {
        const response = { modalProperties: { message: {} }, flag: BF_FALSE }
        const modalProperties = this.handleError(e, appId)
        response.modalProperties.title = 'permissionError'
        response.modalProperties.type = 'danger'
        response.modalProperties.message = {
          key: modalProperties.message.key,
          params: modalProperties.message.params
        }
        return of(response)
      })
    )
  }

  _sendRefreshCheckRequirements = (selectedDevices, userInfo) => {
    return !(
      (_.size(selectedDevices) > 1 && !userInfo.userPermission.CanSendMultipleRefreshes) ||
      !userInfo.userPermission.CanCreateCustomContent ||
      !userInfo.userPermission.CanCreateActions
    )
  }

  _getCompurterIDOfAllCorrelatedTypes = (selectedDevices) => {
    const computerIDArray = []

    _.each(selectedDevices, (selectedDevice) => {
      _.each(selectedDevice.agentCorrelatedTypes, (agentCorrelatedType) => {
        computerIDArray.push(agentCorrelatedType)
      })
    })

    return _.map(computerIDArray, 'computerID')
  }

  _calculateSelectedDevicesIds = (selectedDevices) => {
    const selectedIDs = []
    _.each(selectedDevices, (selectedDevice) => {
      selectedIDs.push(selectedDevice.id)
    })
    return selectedIDs
  }

  _sendRefreshAction = (selectedIds) => {
    const bfSendRefresh = new BfSendRefresh()
    return forkJoin({
      sendRefreshData: bfSendRefresh.send(selectedIds),
      isGreaterTen: this.bfPlatformVersion.isGreaterThanTen()
    }).pipe(
      map(({ sendRefreshData, isGreaterTen }) => {
        if (isGreaterTen) {
          return {
            flag: true,
            modalProperties: {
              message: { key: 'SendRefreshSent' },
              type: 'success'
            }
          }
        } else {
          return {
            flag: true,
            redirectTo: '/framework/deployments/' + sendRefreshData
          }
        }
      })
    )
  }

  sendRefresh = (selectedDevices) => {
    return this.bfCurrentUserInfoService.get().pipe(
      mergeMap((userInfo) => {
        if (this._sendRefreshCheckRequirements(selectedDevices, userInfo)) {
          const selectedIds = this._calculateSelectedDevicesIds(selectedDevices)
          return this._sendRefreshAction(selectedIds)
        } else if (!userInfo.userPermission.CanCreateCustomContent || !userInfo.userPermission.CanCreateActions) {
          return of({
            flag: false,
            modalProperties: {
              message: {
                key: 'needPermissionsForRefresh'
              },
              type: 'danger'
            }
          })
        } else {
          return of({
            flag: false,
            modalProperties: {
              message: {
                key: 'sendRefreshMultipleError',
                params: {
                  totalDevices: selectedDevices.length
                }
              },
              type: 'danger'
            }
          })
        }
      })
    )
  }

  _getSelectedDeployableIds = (selectedDevices) => {
    return _.map(selectedDevices, (item) => {
      return {
        id: item.id,
        name: item.name,
        ipAddresses: item.ipAddresses,
        dnsName: item.dnsName
      }
    })
  }

  sendBigFixAgentDeploy = (appId, selectedDevices, t) => {
    const bfActionHandler = new BfActionHandler()
    const deployableIds = this._getSelectedDeployableIds(selectedDevices)
    return bfActionHandler.takeAction(appId, deployableIds, [], {})
  }
}
export default BfActionsService
