/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import { UPDATE_SHOW_SUMMARY, UPDATE_SUMMARY_LOADED } from './BfChartsActionTypes'

/**
 * Update boolean used to display inline charts
 * @param {string} namespace - Slice of state to use
 * @param {boolean} showSummary - Display inline charts
 */
export const updateShowSummaryAction = (namespace, showSummary) => ({
  type: `${namespace}/${UPDATE_SHOW_SUMMARY}`,
  showSummary: showSummary
})

export const updateIsSummaryLoadedAction = (namespace, isSummaryLoaded) => ({
  type: `${namespace}/${UPDATE_SUMMARY_LOADED}`,
  isSummaryLoaded: isSummaryLoaded
})
