/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/
import React from 'react'
import _ from '../../../services/utils/BfLodash'
import BfCellWidth from './BfCellWidth'

import BfCellWithTooltipRenderer from './BfCellWithTooltipRenderer'

/**
 * Formats cell value as Date
 */
class BfCellSizeRender {
  constructor(header) {
    this.header = header
    this.name = header.field
  }

  _convertSize = (bytes, decimals = 2) => {
    if (parseInt(bytes) === 0) {
      return '0 Bytes'
    }

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
  }

  /**
   * returns the cell renderer
   * @returns {Function} Cell renderer
   */
  getDefaultRenderer = (row) => {
    const value = _.isUndefined(row[this.name]) ? 'none' : this._convertSize(row[this.name].toString())
    return (
      <BfCellWidth header={this.header}>
        <BfCellWithTooltipRenderer textTooltip={value}>{value}</BfCellWithTooltipRenderer>
      </BfCellWidth>
    )
  }

  getRenderer = () => {
    return this.getDefaultRenderer
  }
}

export default BfCellSizeRender
