/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2021
// All Rights Reserved
*/

import _ from '../../../../../services/utils/BfLodash'
import BfBasicFilter from '../../BfBasicFilter'

class ReportDescriptionFilter extends BfBasicFilter {
  static NAME = 'description'
  static PATH = `filter.${ReportDescriptionFilter.NAME}`
  doFilter = true
  constructor(query = '') {
    super(ReportDescriptionFilter.PATH, ReportDescriptionFilter.NAME, query)
  }

  get = () => {
    return _.isEmpty(this[ReportDescriptionFilter.NAME])
      ? { [ReportDescriptionFilter.NAME]: {} }
      : { [ReportDescriptionFilter.NAME]: this[this.getName()] }
  }

  setQuery = (query) => {
    this[this.getName()] = query
  }

  getQuery = () => {
    return this[this.getName()]
  }
}

export default ReportDescriptionFilter
