/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/
import React, { Component } from 'react'
import _ from '../../../services/utils/BfLodash'
import { Overflowmenu } from '@patron/patron-react/overflowmenu'
import BfActionsService, { BF_TRUE, BF_REDIRECT, BF_RUNACTION, BF_ASYNC_FALSE } from './BfActionsService'
import { BfTrans, bfTranslate } from '../../../services/i18n/bfI18Utils'
import { withTranslation } from 'react-i18next'
import logConsole from 'loglevel'
import BfToggleButton from '../../common/BfToggleButton'
import BfAgentInstallationServices from '../../../services/agentInstallation/BfAgentInstallationServices'
import BfActionHandler from '../../../services/takeAction/BfActionHandler'
import './BfActionButtons.scss'
import withShowMessage from '../../common/bfMessage/BfShowMessage'

class BfDeviceAdministratonButton extends Component {
  bfActionService = new BfActionsService(this.props.bfCurrentUserInfoService)
  bfAgentInstallationServices = new BfAgentInstallationServices()
  bfActionHandler = new BfActionHandler()

  _getSelectedDevices = () => {
    const { selectedDevices } = this.props
    return _.values(selectedDevices)
  }

  _sendRefreshAction = (app) => {
    const { selectedItems: selectedDevices } = this.props

    const selectedDevicesArray = this._getSelectedDevices(selectedDevices)
    this.bfActionService.sendRefresh(selectedDevicesArray).subscribe({
      next: (response) => {
        logConsole.debug('reposnse is ', response)
        if (_.isNotEmpty(response.redirectTo)) {
          window.location.href = response.redirectTo
        } else {
          this._showMessage(response.modalProperties, app.id)
        }
      },
      error: (e) => {
        const modalProperties = this.bfActionService.handleError(e, app.id)
        this._showMessage(
          {
            message: {
              key: modalProperties.message.key,
              params: modalProperties.message.params
            },
            type: 'danger'
          },
          app.id
        )
      }
    })
  }

  _getSelectedDeployableIds = (selectedDevices) => {
    return _.map(selectedDevices, (item) => {
      return {
        id: item.id,
        name: item.name,
        ipAddresses: item.ipAddresses,
        dnsName: item.dnsName
      }
    })
  }

  _takeActionAgentDeploy = (app, response) => {
    const { selectedDevices } = this.props
    const selectedDevicesArray = this._getSelectedDevices(selectedDevices)
    this.bfActionHandler.takeAction(response.actionID, selectedDevicesArray).subscribe({
      next: (redirect) => {
        window.location.href = redirect.redirectTo
      },
      error: (e) => {
        const modalProperties = this.bfActionService.handleError(e, app.id)
        this._showMessage(
          {
            message: {
              key: modalProperties.message.key,
              params: modalProperties.message.params
            },
            type: 'danger'
          },
          app.id
        )
      }
    })
  }

  _sendBigFixAgentDeploy = (app, response) => {
    const { t } = this.props
    this.bfActionHandler._checkAgentInstallationPermission(response.actionID).subscribe({
      next: (permission) => {
        if (_.isEmpty(permission)) {
          this._takeActionAgentDeploy(app, response)
        } else {
          this.props.showModal({
            t: t,
            message: permission.createActions ? bfTranslate(t, 'needCreateActionAgent') : bfTranslate(t, 'needCreateActionsAndCustomContentAgent'),
            title: bfTranslate(t, 'deployBigFixAgent'),
            type: 'danger'
          })
        }
      }
    })
  }

  _enrollMDM = (app) => {
    const { selectedDevices } = this.props
    const selectedDevicesArray = this._getSelectedDevices(selectedDevices)
    this.bfActionHandler.takeAction(app.id, selectedDevicesArray).subscribe({
      next: (redirect) => {
        window.location.href = redirect.redirectTo
      },
      error: (e) => {
        const modalProperties = this.bfActionService.handleError(e, app.id)
        this._showMessage(
          {
            message: {
              key: modalProperties.message.key,
              params: modalProperties.message.params
            },
            type: 'danger'
          },
          app.id
        )
      }
    })
  }

  _unenrollMDM = (app) => {
    const { selectedDevices } = this.props
    const selectedDevicesArray = this._getSelectedDevices(selectedDevices)
    this.bfActionHandler.takeAction(app.id, selectedDevicesArray).subscribe({
      next: (redirect) => {
        window.location.href = redirect.redirectTo
      },
      error: (e) => {
        const modalProperties = this.bfActionService.handleError(e, app.id)
        this._showMessage(
          {
            message: {
              key: modalProperties.message.key,
              params: modalProperties.message.params
            },
            type: 'danger'
          },
          app.id
        )
      }
    })
  }

  _addAction = (adminAction) => {
    switch (adminAction.id) {
      case 'frameworkRefresh':
        adminAction.action = this._sendRefreshAction
        break
      case 'bigfixAgentDeploy':
        adminAction.action = this._sendBigFixAgentDeploy
        break
      case 'mdmAgentDeploy':
        adminAction.action = this._enrollMDM
        break
      case 'mdmUnenrollDeploy':
        adminAction.action = this._unenrollMDM
        break
    }
  }

  _getAdminActions = (adminActions) => {
    return _.map(adminActions, (adminAction) => {
      this._addAction(adminAction)
      return adminAction
    })
  }

  _runTakeAction = (action, selectedDevicesArray, app) => {
    const selectedTask = [
      {
        contentID: action.contentID,
        siteID: action.siteID,
        id: action.contentID
      }
    ]

    this.bfActionHandler.takeAction('content', selectedDevicesArray, selectedTask).subscribe({
      next: (redirect) => {
        window.location.href = redirect.redirectTo
      },
      error: (e) => {
        const modalProperties = this.bfActionService.handleError(e, app.id)
        this._showMessage(
          {
            message: {
              key: modalProperties.message.key,
              params: modalProperties.message.params
            },
            type: 'danger'
          },
          app.id
        )
      }
    })
  }

  _handleClick = (app) => {
    const { t, selectedDevices } = this.props
    const selectedDevicesArray = this._getSelectedDevices(selectedDevices)
    return this.bfActionService.canDevicesRunApp(selectedDevicesArray, app.id, t).subscribe({
      next: (response) => {
        const { flag } = response

        if (flag === BF_TRUE || flag === BF_REDIRECT) {
          app.action(app, response)
        } else if (flag === BF_RUNACTION) {
          this._runTakeAction(response.action, selectedDevicesArray, app)
        } else if (flag === BF_ASYNC_FALSE) {
          this._showMessage(response.modalProperties, app.id, () => this._runTakeAction(response.action, response.relevantDevices, app))
        } else {
          this._showMessage(response.modalProperties, app.id)
        }
      }
    })
  }

  _calculateMessageTitle = (appId) => {
    const mapTitle = {
      frameworkRefresh: 'SendRefreshAction',
      bigfixAgentDeploy: 'DeployBigFixAgentTitle',
      mdmActionDeploy: 'mdmAction'
    }

    return mapTitle[appId]
  }

  _showMessage = (modalProperties, appId, onOkCallback) => {
    const { message, type = 'success', okLabel, cancelLabel, title = this._calculateMessageTitle(appId) } = modalProperties

    const translatedMessage = BfTrans(message.key, message.params)

    this.props.showMessage(type, BfTrans(title), translatedMessage, onOkCallback, okLabel, cancelLabel)
  }

  render() {
    const { t, adminActions } = this.props
    return (
      <Overflowmenu
        className='ml-4 bfAdministrationButton'
        direction='bottom-left'
        ellipsisType='vertical'
        customTemplate={<BfToggleButton className='' title={bfTranslate(t, 'deviceAdministration')} />}
        listItems={this._getAdminActions(adminActions)}
        onClick={this._handleClick}
      />
    )
  }
}
export default withTranslation()(withShowMessage(BfDeviceAdministratonButton))
