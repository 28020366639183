import React from 'react'
import BfCellArray from './BfCellArray'
import BfCellWidth from './BfCellWidth'

class BfCellArrayRenderer {
  constructor(header, showNA = false, delimiter = null) {
    this.header = header
    this.name = header.field
    this.title = header.title || header.field
    this.showNA = showNA
    this.delimiter = delimiter
  }

  /**
   * returns the cell renderer
   * @returns {Function} Cell renderer
   */
  getRenderer() {
    return this._getRenderHTML
  }

  _getRenderHTML = (row) => {
    if (this.delimiter && row[this.name] && !Array.isArray(row[this.name])) {
      row[this.name] = row[this.name]?.split(this.delimiter)
    }
    return (
      <BfCellWidth header={this.header}>
        <BfCellArray rowData={row} cellName={this.name} title={this.title} showNA={this.showNA} />
      </BfCellWidth>
    )
  }
}

export default BfCellArrayRenderer
