/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import BfDateRangeBasicFilter from '../../common/bfBasicFilters/bfDateRangeFilter/BfDateRangeBasicFilter'

class BfReleaseDateFilter extends BfDateRangeBasicFilter {
  static NAME = 'releaseDate'
  static PATH = `filter.${BfReleaseDateFilter.NAME}`

  constructor(data = {}) {
    super(BfReleaseDateFilter.PATH, BfReleaseDateFilter.NAME)
    this.data = data
  }
}

export default BfReleaseDateFilter
