/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/
import React, { Component } from 'react'
import _ from '../../../services/utils/BfLodash'
import { withTranslation } from 'react-i18next'
import BfConsole from 'loglevel'
import { bfTranslate } from '../../../services/i18n/bfI18Utils'
import BfActionHandler from '../../../services/takeAction/BfActionHandler'
import BfMoreApps from '../../../services/devices/BfMoreApps'
import BfDeployButton from './BfDeployButton'
import { Notification } from '@patron/patron-react/notification'
import BfDeviceAdministratonButton from './BfDeviceAdministratonButton'
import BfDeviceConfigurationButton from './BfDeviceConfigurationButton'
import { BfWithServicesConsumer } from '../../providers/BfWithServicesProvider'

class BfActionButtons extends Component {
  bfActionHandler = new BfActionHandler()
  bfMoreApps = new BfMoreApps()
  state = {
    deployAppsListItems: null,
    permittedApps: null,
    adminActions: null
  }

  componentDidMount() {
    this.bfActionHandler.getDeployableApps(this.props).subscribe(({ deployableApps, adminActions }) => {
      this._buildDeployAppsListItems(deployableApps)
      this._buildAdminActionsItems(adminActions)
    })

    this.bfMoreApps.getPermittedAppsConfiguration().subscribe({
      next: (permittedApps) => {
        this.setState({ permittedApps: permittedApps })
      }
    })
  }

  _buildAdminActionsItems = (adminActions) => {
    BfConsole.log('adminActions ', adminActions)
    const permittedAdminActions = _.map(adminActions, (app) => ({
      name: bfTranslate(this.props.t, app.label),
      route: app.route,
      id: app.id
    }))

    this.setState({ adminActions: permittedAdminActions })
  }

  _buildDeployAppsListItems = (apps) => {
    BfConsole.log('apps ', apps)
    let deployAppsListItems = _.map(apps, (app) => ({
      name: bfTranslate(this.props.t, app.label),
      route: app.route,
      id: app.id
    }))
    deployAppsListItems = _.orderBy(deployAppsListItems, ['name'], ['asc'])
    this.setState({ deployAppsListItems: deployAppsListItems })
  }

  _getDeployButton = (selectedItems) => {
    // dont allow multiple policy groups to be deployed at once
    if (Object.keys(selectedItems).length > 1 && selectedItems[Object.keys(selectedItems)[0]].policyGroupData !== undefined) {
      return (
        <Notification
          subtitle={bfTranslate(this.props.t, 'SelectionLimitOne')}
          title={bfTranslate(this.props.t, 'warning')}
          type='warning'
          closable={false}
          visible
        />
      )
    } else {
      return (
        <BfDeployButton
          selectedItems={selectedItems}
          deployApps={this.state.deployAppsListItems}
          bfCurrentUserInfoService={this.props.getCurrentUserInfo()}
        />
      )
    }
  }

  _getAdministrationButton = (selectedItems) => {
    return (
      <BfDeviceAdministratonButton
        selectedDevices={selectedItems}
        adminActions={this.state.adminActions}
        bfCurrentUserInfoService={this.props.getCurrentUserInfo()}
      />
    )
  }

  _getConfigurationButton = (selectedItems) => {
    return (
      <BfDeviceConfigurationButton
        selectedDevices={selectedItems}
        permittedApps={this.state.permittedApps}
        bfCurrentUserInfoService={this.props.getCurrentUserInfo()}
      />
    )
  }

  render() {
    const { selectedItems } = this.props
    return (
      <React.Fragment>
        {this.state.deployAppsListItems && this.state.deployAppsListItems.length > 0 ? this._getDeployButton(selectedItems) : ''}
        {this.state.adminActions &&
        this.state.adminActions.length > 0 &&
        this.props.selectedItems[Object.keys(this.props.selectedItems)[0]].policyGroupData === undefined
          ? this._getAdministrationButton(selectedItems)
          : ''}
        {this.state.permittedApps &&
        this.state.permittedApps.length > 0 &&
        this.props.selectedItems[Object.keys(this.props.selectedItems)[0]].policyGroupData === undefined
          ? this._getConfigurationButton(selectedItems)
          : ''}
      </React.Fragment>
    )
  }
}
export default withTranslation()(BfWithServicesConsumer(BfActionButtons))
