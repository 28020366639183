/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2021
// All Rights Reserved
*/

import _ from '../../services/utils/BfLodash'
import { isMoreThanAllowedTime } from '../../filters/BfUnixDateTime'

export const isOffline = (lastReportTime) => {
  return isMoreThanAllowedTime(lastReportTime)
}

export const calculateDeviceOffline = (device) => {
  _.each(device.agentCorrelatedTypes, (agentCorrelated) => {
    agentCorrelated.isOffline = isOffline(agentCorrelated.LastReportTime)
  })
}

export const isDeviceOffline = (device) => {
  if (_.isUndefined(device.agentCorrelatedTypes)) {
    return isOffline(device.lastReportTime)
  }
  return _.every(device.agentCorrelatedTypes, (agentCorrelated) => isOffline(agentCorrelated.LastReportTime))
}
