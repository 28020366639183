/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import BfHttpClient from '../authClient/BfHttpClient'

class BfGetDeviceProperty {
  http = new BfHttpClient()

  getByContentKey(deviceId, contentKey) {
    const query = {
      filter: JSON.stringify(contentKey)
    }

    return this.http.get('/devices/properties/' + deviceId, query)
  }

  getValuesByProperties(deviceId, properties) {
    return this.http.get('/framework/properties/' + deviceId, properties)
  }

  getAllPropertiesWithAggregated(options) {
    return this.http.get('/framework/propertieswithaggregated/', options)
  }

  getReservedPropertiesIds(propertiesNames) {
    return this.http.get('/framework/properties/defaultids', propertiesNames)
  }

  getReservedAndAggregatedIds(propertiesNames) {
    return this.http.get('/framework/properties/reservedaggregatedids', propertiesNames)
  }
}
export default BfGetDeviceProperty
