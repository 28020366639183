/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import { HttpMethod, BfHttpClient } from '../authClient/BfHttpClient'

class BfTaskService {
  bfHttpClient = new BfHttpClient()
  getTasks = (options) => {
    return this.bfHttpClient.doRequest(options.filter && options.filter.onlySelected ? HttpMethod.POST : HttpMethod.GET, '/custom/api/tasks', options)
  }

  getContentTasks = (site, options) => {
    return this.bfHttpClient.doRequest(
      options.filter && options.filter.onlySelected ? HttpMethod.POST : HttpMethod.GET,
      '/content/api/tasks/' + site,
      options
    )
  }
}
export default BfTaskService
