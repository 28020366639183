/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2021
// All Rights Reserved
*/
import BfDateRangeBasicFilter from '../common/bfBasicFilters/bfDateRangeFilter/BfDateRangeBasicFilter'

class BfPatchReleaseDateFilter extends BfDateRangeBasicFilter {
  static NAME = 'issueDate'
  static PATH = `filter.${BfPatchReleaseDateFilter.NAME}`

  constructor(data = {}) {
    super(BfPatchReleaseDateFilter.PATH, BfPatchReleaseDateFilter.NAME)
    this[this.getName()] = data
  }
}

export default BfPatchReleaseDateFilter
