import React from 'react'

import _ from '../../services/utils/BfLodash'
import { useField } from 'formik'
import './BfForm.scss'
import BfTextArea from '../common/inputs/BfTextArea'

function BfTextAreaField(props) {
  const { ...rest } = props
  const [field, meta] = useField(props)
  const isInvalid = meta.touched && _.isNotEmpty(meta.error)

  return <BfTextArea field={field} isInvalid={isInvalid} errorMsg={meta.error} {...rest} />
}

export default BfTextAreaField
