/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2021
// All Rights Reserved
*/

import React from 'react'
import { DateSelector } from '@patron/patron-react/dateselector'
import { useTranslation } from 'react-i18next'
import { SHORT_DAYS_T, MONTHS_T } from './BfDateString'
import _ from '../../../services/utils/BfLodash'
import { getCurrentDateLocaleFormat } from '../../../services/i18n/bfI18Utils'

const BfDateSelector = (props) => {
  const { t } = useTranslation()
  let { months, weekDays, ...rest } = props

  if (_.isUndefined(months)) {
    months = MONTHS_T(t)
  }
  if (_.isUndefined(weekDays)) {
    weekDays = SHORT_DAYS_T(t)
  }

  return <DateSelector format={_.toLower(getCurrentDateLocaleFormat())} months={months} weekDays={weekDays} {...rest} />
}
export default BfDateSelector
