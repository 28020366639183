/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import BfConsole from 'loglevel'
import _ from '../utils/BfLodash'

class BfClientStorage {
  saveJSON = (name, object) => {
    window.sessionStorage.setItem(name, JSON.stringify(object))
  }

  loadJSON = (name) => {
    let json = null
    const obj = window.sessionStorage.getItem(name)
    if (_.isNil(obj)) {
      return null
    }
    try {
      json = JSON.parse(obj)
    } catch (err) {
      BfConsole.error(`Error parsing object ${name}`)
      BfConsole.error('   Error is: ', err)
    }

    return json
  }

  remove = (name) => {
    window.sessionStorage.removeItem(name)
  }
}
export default BfClientStorage
