/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

class BfBase64StringHandler {
  /**
   *  Better implementation of atob, supports unicode
   *  https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding#Solution_3_%E2%80%93_JavaScript's_UTF-16_%3E_binary_string_%3E_base64
   */
  atobUTF16(sBase64) {
    const sBinaryString = Buffer.from(sBase64, 'base64').toString()
    const aBinaryView = new Uint8Array(sBinaryString.length)
    Array.prototype.forEach.call(aBinaryView, function (el, idx, arr) {
      arr[idx] = sBinaryString.charCodeAt(idx)
    })
    return String.fromCharCode.apply(null, new Uint16Array(aBinaryView.buffer))
  }

  /**
   *  Better implementation of btoa, supports unicode
   *  https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding#Solution_3_%E2%80%93_JavaScript's_UTF-16_%3E_binary_string_%3E_base64
   */
  btoaUTF16(sString) {
    var aUTF16CodeUnits = new Uint16Array(sString.length)
    Array.prototype.forEach.call(aUTF16CodeUnits, function (el, idx, arr) {
      arr[idx] = sString.charCodeAt(idx)
    })
    return window.btoa(String.fromCharCode.apply(null, new Uint8Array(aUTF16CodeUnits.buffer)))
  }

  convertBase64ToCssUrl(b64Data) {
    const objectURL = URL.createObjectURL(this.base64ToBlob(b64Data))
    return "url('" + objectURL + "')"
  }

  base64ToBlob(b64Data, sliceSize = 512) {
    const withoutURIHeader = b64Data.substring(b64Data.indexOf(',') + 1)
    const byteCharacters = window.atob(withoutURIHeader)
    const byteArrays = []

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize)

      const byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      const byteArray = new Uint8Array(byteNumbers)
      byteArrays.push(byteArray)
    }
    // eslint-disable-next-line no-undef
    const blob = new Blob(byteArrays)
    return blob
  }
}

export default BfBase64StringHandler
