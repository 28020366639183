/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2021
// All Rights Reserved
*/
import React from 'react'
import { Field } from 'formik'
import PropTypes from 'prop-types'
import './BfForm.scss'

import { Toggle } from '@patron/patron-react/toggle'

function BfToggle(props) {
  const { id, name, label = '', labelOn = '', labelOff = '', disabled = false, small = true, toggled, defaultChecked = false, ...rest } = props

  return (
    <div className='hcl-form-group'>
      <Field name={name} {...rest}>
        {({ field, form }) => (
          <div>
            <Toggle
              disabled={disabled}
              id={id || name}
              labelOff={labelOff}
              labelOn={labelOn || label}
              onChange={(value) => {
                form.setFieldValue(name, value)
              }}
              small={small}
              toggled={toggled}
              defaultChecked={defaultChecked}
            />
          </div>
        )}
      </Field>
    </div>
  )
}

BfToggle.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  labelOn: PropTypes.string,
  labelOff: PropTypes.string,
  small: PropTypes.bool,
  toggled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool
}

export default BfToggle
