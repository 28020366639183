/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/
import _ from '../../../../../services/utils/BfLodash'
import BfBasicFilter from '../../BfBasicFilter'

class InsightsContentCountFilter extends BfBasicFilter {
  static NAME = 'contentCount'
  static PATH = `filter.${InsightsContentCountFilter.NAME}`

  constructor(data = {}) {
    super(InsightsContentCountFilter.PATH, InsightsContentCountFilter.NAME)
    this.contentCount = data
  }

  get = () => {
    return _.isEmpty(this.contentCount) ? { contentCount: {} } : { contentCount: this.contentCount }
  }

  buildFromURLHandler = (urlHandler, consumeUrl = false) => {
    this.contentCount = this._buildFromURLHandler(urlHandler, consumeUrl, this.contentCount)
  }

  getData = () => {
    return this.contentCount
  }

  setData = (data) => {
    this.contentCount = data
  }
}

export default InsightsContentCountFilter
