/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import BfDropDownSearchBasicFilter from '../../common/bfBasicFilters/BfDropDownSearchFilter/BfDropDownSearchBasicFilter'

class BfPublisherNameFilter extends BfDropDownSearchBasicFilter {
  static NAME = 'publisher'
  static PATH = `filter.${BfPublisherNameFilter.NAME}`
  constructor(data = {}) {
    super(BfPublisherNameFilter.PATH, BfPublisherNameFilter.NAME, data)
  }

  getSearchURL = () => {
    return '/swd/api/packages/publishers'
  }
}

export default BfPublisherNameFilter
