/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import React from 'react'
import _ from '../../../../../services/utils/BfLodash'

import BfSearch from './BfSearch'

const BfSearchWithFilter = (WrappedComponent) => {
  return class extends React.Component {
    _onSearch = (text) => {
      this.props.filter.setQuery(_.isEmpty(text) ? null : text)
      this.props.onSearch(this.props.filter.get())
    }

    render = () => {
      return <WrappedComponent {...this.props} searchText={this.props.filter.getQuery()} onSearch={this._onSearch} />
    }
  }
}

export default BfSearchWithFilter(BfSearch)
