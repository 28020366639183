/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import BfHttpClient from '../authClient/BfHttpClient'

/**
 * Provides data services and Http request
 */
class BfWebuiDataService {
  http = new BfHttpClient()
  namespace = 'framework'

  constructor(site) {
    this.namespace = site || 'framework'
  }

  /**
   * To save data on webuidata
   * @param {*} key key url to save data
   * @param {*} data data to store in servier
   * @param  {...any} callbacks OnLoad OnError and OnComplete functions after save operations
   */
  save(key, data, ...callbacks) {
    const [onLoad, onError, onComplete] = callbacks
    return this.http
      .put('/framework/webuidata/' + this.namespace + '/' + key, {
        data: data
      })
      .subscribe(onLoad, onError, onComplete)
  }

  get(key) {
    return this.http.get('/framework/webuidata/' + this.namespace + '/' + key)
  }

  /**
   * To fetch all saved data from webuidata
   */
  getAll() {
    return this.http.get('/framework/webuidata/' + this.namespace)
  }

  /**
   * To delete data from webuidata
   * @param {*} key key url to save data
   */
  delete(key) {
    return this.http.delete('/framework/webuidata/' + this.namespace + '/' + key)
  }
}

export default BfWebuiDataService
