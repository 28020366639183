/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import BfHttpClient from '../authClient/BfHttpClient'
import { map } from 'rxjs/operators'
import { of } from 'rxjs'

/**
 * Provides functionalities to get current running app information
 */
class BfCurrentAppsInfoService {
  /**
   * Stores current app information
   */
  currentAppsInfo = null
  http = new BfHttpClient()

  /**
   * Initiates an HTTP request to fetch the currrent app information from the server
   * @method
   * @returns {Promise} HTTP request.
   */
  getCurrentAppsInfo() {
    return this.http.get('/apps').pipe(
      map((res) => {
        this.currentAppsInfo = res.data
        return this.currentAppsInfo
      })
    )
  }

  /**
   * Returns current app information if already retrieved from server else initiate HTTP call from getCurrentAppsInfo
   * @method
   * @returns {Promise}
   */
  get() {
    return this.currentAppsInfo ? of(this.currentAppsInfo) : this.getCurrentAppsInfo()
  }
}

export default BfCurrentAppsInfoService
