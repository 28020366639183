/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2021
// All Rights Reserved
*/

import { map } from 'rxjs/operators'
import BfCachedRequest from '../../../../../services/utils/BfCachedRequest'
import _ from '../../../../../services/utils/BfLodash'
import BfBasicFilter from '../../BfBasicFilter'
import { createOsDropDownItem } from '../../common/BfCheckboxTree/BfCheckboxTree'

class BfPolicyTypes extends BfBasicFilter {
  bfCachedRequest = new BfCachedRequest()
  static NAME = 'policyType'
  static PATH = `filter.${BfPolicyTypes.NAME}`
  constructor(data = {}) {
    super(BfPolicyTypes.PATH, BfPolicyTypes.NAME, data)
  }

  getSelectedItems = () => {
    return _.size(this.policyType)
  }

  getItems = () => {
    return this.bfCachedRequest.get('/api/policiesUnpaginated').pipe(
      map((res) => {
        var policyTypeOptions = []
        for (var i = 0; i < res.data.length; i += 1) {
          if (policyTypeOptions.filter((e) => e.id === res.data[i].type).length === 0) {
            policyTypeOptions.push({
              name: res.data[i].type,
              id: res.data[i].type
            })
          }
        }
        return createOsDropDownItem(policyTypeOptions)
      })
    )
  }
}

export default BfPolicyTypes
