import React, { Component } from 'react'
import './BfModalPolicyGroupsGrid.scss'
import logConsole from 'loglevel'
import BfPolicyGroupsGrid from './BfPolicyGroupsGrid'
import BfModalDataGrid from '../modals/BfModalDataGrid'

class BfModalPolicyGroupsGrid extends Component {
  selectedItems = []

  _onRowSelected = (rowSelected, rows) => {
    logConsole.debug('Selected row is ', rowSelected)
    logConsole.debug('rows are ', rows)
    this.selectedItems = rows
  }

  render() {
    const { onClose, onSave, title } = this.props
    return (
      <BfModalDataGrid
        onClose={onClose}
        onSave={onSave}
        heading={title}
        datagridTable={BfPolicyGroupsGrid}
        id='modal_policy_groups'
        options={{ updateUrl: false }}
        onRowSelected={this._onRowSelected}
      />
    )
  }
}

export default BfModalPolicyGroupsGrid
