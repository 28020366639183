import React, { Component } from 'react'
import { I18nextProvider } from 'react-i18next'
import { i18n as bfi18n } from '../../services/i18n/bfI18n'

class BfI18NProvider extends Component {
  render() {
    return <I18nextProvider i18n={bfi18n}>{this.props.children}</I18nextProvider>
  }
}

export default BfI18NProvider
