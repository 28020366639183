/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import BfBasicFilter from '../../BfBasicFilter'

class BfShowRelevantComputersFilter extends BfBasicFilter {
  static NAME = 'applicableToComputers'
  static PATH = `filter.${BfShowRelevantComputersFilter.NAME}`

  constructor() {
    super(BfShowRelevantComputersFilter.PATH, BfShowRelevantComputersFilter.NAME)
  }
}

export default BfShowRelevantComputersFilter

export class BfShowRelevantGroupsFilter extends BfBasicFilter {
  static NAME = 'applicableToGroups'
  static PATH = `filter.${BfShowRelevantGroupsFilter.NAME}`

  constructor() {
    super(BfShowRelevantGroupsFilter.PATH, BfShowRelevantGroupsFilter.NAME)
  }
}
