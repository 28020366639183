/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2021
// All Rights Reserved
*/

import _ from '../../../services/utils/BfLodash'

// Make each bar go from 0-1 instead of compared to each other
export const getNormalizedCounts = function (countsObj, overflowValues, typeKeys, overflowTypeName) {
  let sum = 0
  for (const obj in countsObj) {
    sum += countsObj[obj]
  }

  function _roundToTwo(number) {
    return Math.ceil((number + Number.EPSILON) * 100) / 100
  }

  const normalizedCounts = {}
  for (const objType in countsObj) {
    let newPatchCount = 0
    if (countsObj[objType] !== 0) {
      newPatchCount = _roundToTwo(countsObj[objType] / sum)
    }

    if (_.includes(overflowValues, objType)) {
      normalizedCounts[overflowTypeName] = normalizedCounts[overflowTypeName] ? normalizedCounts[overflowTypeName] + newPatchCount : newPatchCount
    } else {
      normalizedCounts[objType] = newPatchCount
    }
  }

  // add patch keys that aren't in this column (fixes chart errors issues)
  for (const key of typeKeys) {
    !(key in normalizedCounts) && (normalizedCounts[key] = 0)
  }
  return normalizedCounts
}
