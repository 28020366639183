/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import BfDateRangeBasicFilter from '../../common/bfBasicFilters/bfDateRangeFilter/BfDateRangeBasicFilter'

class ReportLastAccessedFilter extends BfDateRangeBasicFilter {
  static NAME = 'lastAccessed'
  static PATH = `filter.${ReportLastAccessedFilter.NAME}`
  constructor(data = {}) {
    super(ReportLastAccessedFilter.PATH, ReportLastAccessedFilter.NAME, data)
  }
}

export default ReportLastAccessedFilter
