/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import React, { useEffect, useRef, useState } from 'react'
import { Button } from '@patron/patron-react/button'
import './BfDropDownButton.scss'
import BfButtonCountTag from './BfButtonCountTag'
import _ from '../../services/utils/BfLodash'

const BfDropDownButton = (props) => {
  const [isOpen, setIsOpen] = useState(false)
  const containerButton = useRef(null)

  const cleanItemSelected = (event) => {
    event.stopPropagation()
    props.cleanItemSelected()
  }

  const _onClick = (ev) => {
    ev.currentTarget = containerButton.current
    setIsOpen((isOpen) => !isOpen)
    props.onClick(ev)
  }

  useEffect(() => {
    setIsOpen(props.open)
  }, [props.open])

  const { title = '', itemsSelected } = props

  const containerStyle = isOpen ? 'hcl-overlay-wrapper hcl-dropdown hcl-overlay-wrapper-active' : 'hcl-overlay-wrapper hcl-dropdown'

  return (
    <div className={containerStyle} ref={containerButton}>
      {_.isNil(itemsSelected) || itemsSelected === 0 ? (
        <Button className='hcl-dropdown-toggle' onClick={(ev) => _onClick(ev)}>
          <span className='bf-button-ellipsis'>{title}</span>
        </Button>
      ) : (
        <BfButtonCountTag closable onClose={cleanItemSelected} tabIndex={0} onClick={(ev) => _onClick(ev)} itemsCount={itemsSelected} />
      )}
    </div>
  )
}

export default BfDropDownButton
