import React from 'react'

import BfCellWidth from './BfCellWidth'
import BfCellWithTooltipRenderer from './BfCellWithTooltipRenderer'
import _ from '../../../services/utils/BfLodash'
import BfCellEmptyRenderer from './BfCellEmptyRenderer'
import moment from 'moment'

/**
 * Formats cell value as Date
 */
class BfCellDateRender {
  constructor(header) {
    this.header = header
    this.name = header.field
  }

  /**
   * returns the cell renderer
   * @returns {Function} Cell renderer
   */
  getRenderer() {
    return this._getDateRenderHTML
  }

  /**
   * Formats row data in Date format
   * @method
   * @param {Object} row Row data to be formatted
   * @returns {HTMLElement} Formatted cell HTML
   */
  _getDateRenderHTML = (row) => {
    const momentTime = moment.unix(row[this.name]).isValid() ? moment.unix(row[this.name]) : moment(row[this.name])

    if (_.isNil(row[this.name])) {
      return <BfCellEmptyRenderer value='none' />
    }

    return (
      <BfCellWidth header={this.header}>
        <BfCellWithTooltipRenderer noSplit textTooltip={momentTime.format()}>
          <span>{momentTime.fromNow()}</span>
        </BfCellWithTooltipRenderer>
      </BfCellWidth>
    )
  }
}

export default BfCellDateRender
