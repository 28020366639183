/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import { MESSAGE_SUCCESS, REMOVE_MESSAGE } from './BfMessageActionType'

export const sendMessageAction = (message) => ({
  type: MESSAGE_SUCCESS,
  message: message
})

export const sendDetailedMessageAction = (caption, title, subtitle, type) => ({
  type: MESSAGE_SUCCESS,
  message: { ...{}, caption, title, subtitle, type }
})

export const removeMessageByIdAction = (id) => ({
  type: REMOVE_MESSAGE,
  id: id
})
