/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import './BfDataGridTable.scss'
import React from 'react'
import _ from '../../services/utils/BfLodash'
import { connect } from 'react-redux'
import { map, filter } from 'rxjs/operators'
import BfDataGrid, { CHECKBOX_WIDTH } from './bfDataGrid/BfDataGrid'
import BfActionBar from './bfActionBar/BfActionBar'
import { LoadingState } from '@patron/patron-react/loadingstate'
import HTML_COLUMN_TYPES from './filters/BfHTMLFiltersMap'
import withBfModal from '../modals/BfWithModal'

import {
  UPDATE_FILTER_URL,
  UPDATE_SEARCH_URL,
  UPDATE_COLUMN,
  RESET_SEARCH_URL,
  SET_NEW_FILTERS_URL,
  SHOW_ONLY_SELECTED_ROWS,
  REFRESH_ROWS,
  DESELECT_ROWS,
  SELECT_ROWS,
  TOGGLE_SELECTED_ONLY,
  REFRESH_SELECTED_ROWS_IDS
} from './redux/BfFilterListActionTypes'
import {
  updateSearchListUrlAction,
  updateColumnListAction,
  updateSearchOptionsAction,
  showOnlySelectedRowsAction,
  updateRowCountAction,
  refreshRowsAction
} from './redux/BfFilterListUpdateURLAction'
import logConsole from 'loglevel'
import BfFilterManager, { createFilterManagerFromReduxState } from './filters/BfFilterManager'
import BfPaginationBar from './bfPaginationBar/BfPaginationBar'
import BfCurrentUserSessionData from '../../services/user/BfCurrentUserSessionData'
import { updateSearchListReducer } from './redux/BfUpdateFilterListReducer'
import { BfDataGridComponentWithProvider } from './BfDataGridContext'
import { BfWithReduxStoreConsumer } from '../mainAppContainer/bfReduxStore/BfReduxStore'
import BfSortFilter from './filters/common/bfSort/BfSortFilter'
import { BfTrans } from '../../services/i18n/bfI18Utils'
import { BfWithServicesConsumer } from '../providers/BfWithServicesProvider'
import BfCurrentUserInfoService from '../../services/user/BfCurrentUserInfoService'
import BfReduxStateConstants from '../../components/common/BfReduxStateConstants'

/**
 * @component
 * @example
 * const header = ['Name', 'Age' ,'Location']
 * const fetchRows = () => {return new Promise(function(resolve, reject) {
 * resolve({name: 'Ram', age: '30', location: 'India'})
 * })}
 * <BfDataGridTable headers={header} getRows={fetchRows} getValue={getValue}></BfDataGridTable>
 */

const OLD_DEFAULT_COLUMN_WIDTH = '100px'
const DEFAULT_COLUMN_WIDTH = '150px'
const DEFAULT_MIN_COLUMN_WIDTH = 150
const BF_PARTIAL_LAYOUT_CLASS = 'bf-datagrid-in-partial-layout'
const BF_PARTIAL_LAYOUT_CLASS_2 = 'bf-datagrid-in-partial-layout-2'
const BF_PARTIAL_LAYOUT_CLASS_3 = 'bf-datagrid-in-partial-layout-3'
const DEFAULT_ROW_LIMIT = 1
const PPS_NO_LIMIT = -1

class BfDataGridTableComponent extends React.Component {
  bfCurrentUserSessionData = new BfCurrentUserSessionData(this.props.getCurrentUserInfo())

  LOADING_DELAY = 2000 // set default delay for show datatable loading state

  constructor(props) {
    super(props)
    this.state = {
      rows: [],
      totalCount: 0,
      selectedItems: {},
      loading: true,
      timeout: 0,
      showResetColumn: false,
      preSelectedShowSelectedOnly: false,
      highlightedRows: {},
      rowLimit: DEFAULT_ROW_LIMIT,
      rowsToDeselect: []
    }

    const { store, initSearchOptions } = props

    this.dataGridRef = React.createRef()
    this.paginationBarRef = React.createRef()
    /* this.noRowsContainerRef = React.createRef() */
    this.namespace = props.id

    _.isUndefined(this.namespace) && logConsole.error('Error missing datagrid table id')

    store.addReducer(this.namespace, updateSearchListReducer(this.namespace, initSearchOptions))

    if (_.isDefined(props.options)) {
      logConsole.debug('Sending options action', props.options)
      store.dispatch(updateSearchOptionsAction(this.namespace, props.options))
    }

    this.updateUrl = _.isUndefined(props.options?.updateUrl) ? true : props.options.updateUrl

    store.addEpicMiddleware(this.namespace, (action$, state$) => {
      logConsole.info('Apply DataTableListEpic to middleware with state ', state$.value)
      return action$.pipe(
        filter(
          (action) =>
            action.type === `${this.namespace}/${UPDATE_FILTER_URL}` ||
            action.type === `${this.namespace}/${UPDATE_SEARCH_URL}` ||
            action.type === `${this.namespace}/${RESET_SEARCH_URL}` ||
            action.type === `${this.namespace}/${SET_NEW_FILTERS_URL}` ||
            action.type === `${this.namespace}/${UPDATE_COLUMN}` ||
            action.type === `${this.namespace}/${SHOW_ONLY_SELECTED_ROWS}` ||
            action.type === `${this.namespace}/${DESELECT_ROWS}` ||
            action.type === `${this.namespace}/${SELECT_ROWS}` ||
            action.type === `${this.namespace}/${REFRESH_ROWS}` ||
            action.type === `${this.namespace}/${TOGGLE_SELECTED_ONLY}` ||
            action.type === `${this.namespace}/${REFRESH_SELECTED_ROWS_IDS}`
        ),
        map((action) => {
          logConsole.info('STATE IS state$', state$.value)
          logConsole.info('action is ', action)
          if (action.type === `${this.namespace}/${TOGGLE_SELECTED_ONLY}`) {
            this._onShowSelectedOnly(action.toggle)
          } else if (action.type === `${this.namespace}/${UPDATE_COLUMN}`) {
            if (action.reset) {
              const headers = this._initializeHeaders()
              this.props.updateColumnListAction(this.namespace, headers, false)
              this._deleteUserSessionData(this.namespace)
            }
            if (action.save && _.get(props.options, 'save', true)) {
              this._saveColumns(state$.value[this.namespace]?.columns.cols)
            }
          } else if (action.type === `${this.namespace}/${REFRESH_SELECTED_ROWS_IDS}`) {
            store.dispatch(action.dispatchFunction(this._getSelectedItemsIds()))
          } else if (action.type === `${this.namespace}/${DESELECT_ROWS}`) {
            const selectedRows = this.state.selectedItems
            const rowsIds = action.rowsIds
            const deSelectedItems = this.deselectRows(selectedRows, rowsIds)
            this._onRowSelect(deSelectedItems)
            this.setState({ selectedRows: selectedRows })
          } else if (action.type === `${this.namespace}/${SELECT_ROWS}`) {
            const selectedRows = this.state.selectedItems
            const rows = [...this.state.rows]
            const rowsIds = action.rowsIds
            this.selectRows(rows, selectedRows, rowsIds)
            this._onRowSelect(selectedRows)
            this.setState({ rows: rows, selectedRows: selectedRows })
          } else {
            if (action.type === `${this.namespace}/${REFRESH_ROWS}`) {
              if (action.clearSelected) {
                this.setState({ selectedItems: {} })
              }
            }
            return this._getRows(state$.value[this.namespace]?.search.urlSearch, state$.value[this.namespace]?.search.options)
          }

          return action
        }),
        map(() => {
          logConsole.info('STATE IS state$', state$.value)
          return {
            result: 'OK', // TODO: this can be used by the UI to handle progress query execution
            type: 'COMPLETED_QUERY'
          }
        })
      )
    })
  }

  _saveColumns = (columns) => {
    const visibleColumns = this._getVisibleColumns(columns)
    this._saveVisibleHeaders(visibleColumns)
  }

  _addColumnIndexOrderToVisibleColumns = (columns, visibleColumns) => {
    _.each(columns, (column, index) => {
      const visibleColumn = _.find(visibleColumns, (visibleColumn) => visibleColumn.field === column.field)
      if (_.isDefined(visibleColumn)) {
        visibleColumn.index = index
      }
    })
  }

  _onColumnReorder = (columns) => {
    const visibleColumns = this._getVisibleColumns(this.props.columns)
    this._addColumnIndexOrderToVisibleColumns(columns, visibleColumns)
    this._saveVisibleHeaders(visibleColumns)
  }

  _findColumnByField = (columns, field) => {
    return _.find(columns, (column) => column.field === field)
  }

  _onColumnAfterResize = (fieldWith) => {
    const visibleColumns = this._getVisibleColumns(this.props.columns)
    const column = this._findColumnByField(visibleColumns, fieldWith.field)
    column.width = fieldWith.width
    this._saveVisibleHeaders(visibleColumns)
    this.forceUpdate()
  }

  _setColumnHTML = (header) => {
    if (_.isFunction(HTML_COLUMN_TYPES[header.columnHtmlType])) {
      header.columnHtml = HTML_COLUMN_TYPES[header.columnHtmlType](header)
    } else {
      header.columnHtml = HTML_COLUMN_TYPES[header.columnHtmlType]
    }
  }

  _setInitialColumnWidth = (header) => {
    if (header.isDefault && _.isUndefined(header.width)) {
      header.minResizeWidth = DEFAULT_MIN_COLUMN_WIDTH
      header.width = DEFAULT_COLUMN_WIDTH
    }
  }

  _isLastHeader = (headers, header) => {
    const last = _.last(headers)
    return header.field === last.field
  }

  _getHeaderByField = (headers, field) => {
    return _.find(headers, (header) => header.field === field)
  }

  _setRenderColumnsWidth = (visibleHeaders, propHeaders) => {
    _.each(visibleHeaders, (visibleHeader) => {
      const isLast = this._isLastHeader(visibleHeaders, visibleHeader)
      if (_.isUndefined(visibleHeader.width) && !isLast) {
        const propHeader = this._getHeaderByField(propHeaders, visibleHeader.field)
        if (_.isUndefined(propHeader) || _.isUndefined(propHeader.width)) {
          visibleHeader.width = DEFAULT_COLUMN_WIDTH
        } else {
          visibleHeader.width = propHeader.width
        }
      }
      // header.styles = { ...header.styles, 'max-width': header.width }
      if (isLast) {
        /** Set last column width */
        if (_.isDefined(visibleHeader.pinned)) {
          delete visibleHeader.width
        } else {
          this._setLastColumnWidth(visibleHeaders)
        }
      }
      visibleHeader.minResizeWidth = DEFAULT_MIN_COLUMN_WIDTH
    })
  }

  _getDefaultHeaders = (headers) => {
    return _.filter(headers, (header) => header.isDefault)
  }

  _buildDefaultHeaders = (headers) => {
    const defaultHeaders = this._getDefaultHeaders(headers)
    _.each(defaultHeaders, (defaultHeader, index) => {
      defaultHeader._isVisible = defaultHeader.isDefault
      if (_.isUndefined(defaultHeader.index)) {
        defaultHeader.index = index // TODO:change this
      }
      if (!this._isLastHeader(defaultHeaders, defaultHeader)) {
        this._setInitialColumnWidth(defaultHeader)
      }
    })
    return headers
  }

  _setHeaderProperties = (header, visibleColumn) => {
    header.index = visibleColumn.index
    if (_.isDefined(visibleColumn.width)) {
      header.width = visibleColumn.width
    }
  }

  _mergeWithDefaultHeaders = (headers, visibleColumnsFields) => {
    _.each(headers, (header) => {
      const visibleColumnsField = visibleColumnsFields[header.field]

      if (_.isUndefined(visibleColumnsField)) {
        header._isVisible = false
        visibleColumnsFields[header.field] = header
      } else {
        // If column is custom, use renderHtml function from header
        if (_.isUndefined(visibleColumnsField.type) && header.renderHtml) {
          visibleColumnsField.renderHtml = header.renderHtml
        }
        // update label of default fields if changed
        if (visibleColumnsField.label !== header.label) {
          visibleColumnsField.label = header.label
        }
      }
    })
    return visibleColumnsFields
  }

  _setLastColumnWidth = (visibleColumns) => {
    if (!this.paginationBarRef.current || this.paginationBarRef.current.offsetWidth === 0) {
      return
    }
    const lastColumn = _.last(visibleColumns)
    const datagridWidth = this.paginationBarRef.current.offsetWidth
    const allColumnsWidth = this._getVisibleColumnsWidth()
    const diff = datagridWidth - allColumnsWidth

    if (diff >= DEFAULT_MIN_COLUMN_WIDTH) {
      lastColumn.width = '100%'
    } else {
      lastColumn.width = DEFAULT_COLUMN_WIDTH
    }
  }

  _handleVisibleColumnCompatibility = (visibleColumnsFields) => {
    _.each(visibleColumnsFields, (visibleColumn, key) => {
      if (visibleColumn.width === OLD_DEFAULT_COLUMN_WIDTH) {
        visibleColumn.width = DEFAULT_COLUMN_WIDTH
      }
    })
    return visibleColumnsFields
  }

  _initializeHeaders = (userSessionData) => {
    let newHeaders = _.cloneDeep(this.props.headers)
    if (_.isDefined(userSessionData) && _.isDefined(userSessionData[this.namespace]?.visibleColumnsFields)) {
      const visibleColumnsFields = this._handleVisibleColumnCompatibility(userSessionData[this.namespace].visibleColumnsFields)
      newHeaders = _.values(this._mergeWithDefaultHeaders(newHeaders, visibleColumnsFields))
    } else {
      newHeaders = _.values(this._buildDefaultHeaders(newHeaders))
    }

    _.each(newHeaders, (header) => this._setColumnHTML(header))

    return newHeaders
  }

  _saveSetting = (settingData, settingName) => {
    this._loadUserSessionData((userSessionData) => {
      if (_.isUndefined(this.namespace)) {
        logConsole.warn('Cannot load saved column data because empty datagrid id')
      } else {
        logConsole.log('saving datagrid setting')

        this.bfCurrentUserSessionData.update(
          {
            [this.namespace]: { [settingName]: settingData }
          },
          (userSessionData) => {
            this.userSessionData = userSessionData
            this._setResetColumn()
          }
        )
      }
    })
  }

  _saveVisibleHeaders = (visibleHeaders) => {
    if (!_.get(this.props.options, 'save', true)) {
      return
    }
    const visibleHeadersFields = _.reduce(
      visibleHeaders,
      (result, visibleHeader) => {
        result[visibleHeader.field] = visibleHeader
        return result
      },
      {}
    )
    this._saveSetting(visibleHeadersFields, 'visibleColumnsFields')
  }

  _getInitialSelectedItemsIds = () => {
    if (this.props.preSelectedItems) {
      return _.reduce(
        this.props.preSelectedItems,
        (result, value) => {
          result[value.id] = value
          return result
        },
        {}
      )
    }
    return []
  }

  _initializeSelectedItems = () => {
    const preSelectedItemsIds = this._getInitialSelectedItemsIds()
    const preSelectedShowSelectedOnly = _.isDefined(this.props.options.preSelectedShowSelectedOnly)
      ? this.props.options.preSelectedShowSelectedOnly
      : false
    if (_.size(preSelectedItemsIds) > 0) {
      this.setState({
        preSelectedShowSelectedOnly: preSelectedShowSelectedOnly,
        selectedItems: preSelectedItemsIds
      })
    }
  }

  deselectRows(rows, deselectedRowsIds) {
    return _.filter(rows, (row) => {
      const foundRowIdx = _.findIndex(deselectedRowsIds, (rowId) => rowId.id === row.id)
      if (foundRowIdx > -1) {
        row._isSelected = false
      }
      return foundRowIdx > -1
    })
  }

  selectRows(rows, selectedRows, selectedRowsIds) {
    _.each(selectedRowsIds, (selectedRowId) => {
      selectedRowId._isSelected = true
      selectedRows[selectedRowId.id] = selectedRowId
    })
    _.each(rows, (row) => {
      if (_.isDefined(selectedRowsIds[row.id])) {
        row._isSelected = true
      }
    })
  }

  componentWillUnmount() {
    /** Remove datagrid reducers */
    const { store } = this.props

    store.removeReducer(this.namespace)

    window.removeEventListener('resize', this._resizeDataGrid)
  }

  _getSortMethodByField = (field) => {
    const visibleColumns = this._getVisibleColumns(this.props.columns)
    const columnHeader = _.find(visibleColumns, { field: field })
    const methodName = columnHeader.sortMethod ? columnHeader.sortMethod : field

    return methodName
  }

  _getSortFieldByMethod = (method) => {
    const visibleColumns = this._getVisibleColumns(this.props.columns)
    const columnHeader = _.find(visibleColumns, { sortMethod: method })
    const fieldName = columnHeader ? columnHeader.field : method

    return fieldName
  }

  _getSortedColumn = () => {
    const sortedColum = {}
    const { bfSortFilter } = this.props
    const sortMethod = bfSortFilter.getMethod()
    const fieldName = this._getSortFieldByMethod(sortMethod)

    if (_.isNotEmpty(sortMethod)) {
      sortedColum.order = bfSortFilter.getOrder()
      sortedColum.name = fieldName
    }
    return sortedColum
  }

  _loadUserSessionData = (cbk) => {
    if (_.isEmpty(this.userSessionData)) {
      this.bfCurrentUserSessionData.loadUserSessionData().subscribe({
        next: (userSessionData) => {
          this.userSessionData = userSessionData
          cbk(this.userSessionData)
        },
        error: (err) => {
          logConsole.error('Error retrieving user session data', err)
          cbk()
        }
      })
    } else {
      cbk(this.userSessionData)
    }
  }

  _setResetColumn = () => {
    this.setState({
      showResetColumn: _.isDefined(this.userSessionData) && _.isNotEmpty(this.userSessionData[this.namespace])
    })
  }

  _deleteUserSessionData = (namespace) => {
    if (_.isNotEmpty(this.userSessionData)) {
      this.userSessionData = _.omit(this.userSessionData, namespace)
      this.bfCurrentUserSessionData.save(this.userSessionData, () => {
        this._setResetColumn()
      })
    }
  }

  componentDidMount() {
    const { displayContainer = { type: 'default', containerRef: null } } = this.props

    if (displayContainer.type === 'modal') {
      this.setState({ bfClassName: BF_PARTIAL_LAYOUT_CLASS })
    }
    window.addEventListener('resize', this._resizeDataGrid)
    this._loadUserSessionData((userSessionData) => {
      logConsole.debug('userSessionData is ', userSessionData)
      this._setResetColumn()
      const headers = this._initializeHeaders(userSessionData)
      this.props.updateColumnListAction(this.namespace, headers)

      this._initializeSelectedItems()

      const bfFilterManager = new BfFilterManager(null, this.updateUrl)

      this.props.updateSearchListUrlAction(this.namespace, bfFilterManager.getSearch())

      const { ppsUserLimit = false } = this.props.options

      if (ppsUserLimit) {
        const bfCurrentUserInfoService = new BfCurrentUserInfoService()
        bfCurrentUserInfoService.getUserActionsLimit().subscribe((userLimits) => {
          let rowLimit = DEFAULT_ROW_LIMIT
          switch (this.namespace) {
            case BfReduxStateConstants.DEVICES:
              rowLimit = userLimits.device_target_limit
              break
            case BfReduxStateConstants.CUSTOM_DATA_GRID:
            case BfReduxStateConstants.CONTENT_DATA_GRID:
            case BfReduxStateConstants.PATCHES:
            case BfReduxStateConstants.SOFTWARE_DATA_GRID:
              rowLimit = userLimits.content_target_limit
              break
          }

          this.setState({ rowLimit: rowLimit })
        })
      }
    })
  }

  _getVisibleColumns = (columns) => {
    return _.sortBy(
      _.filter(columns, (column) => column._isVisible),
      (column) => column.index
    )
  }

  _fillSelectedOnlyOptions = (options) => {
    const filledOptions = { ...options }
    if (filledOptions.showOnlySelected) {
      filledOptions.selectedItemsIds = this._getSelectedItemsIds()
    }
    return filledOptions
  }

  /**
   * Retrieves the row data
   * @method
   * @param {*} options options to fetch the row data from server
   * @returns {Promise} props.getrows functions is called
   */
  _getRows = (search, options) => {
    logConsole.info('search are ', search)
    const timer = setTimeout(() => this.setState({ loading: true }), this.state.timeout)
    const visibleColumns = this._getVisibleColumns(this.props.columns)
    options = this._fillSelectedOnlyOptions(options)
    logConsole.info('options are ', options)
    this.props.store.dispatch(updateSearchOptionsAction(this.namespace, options))
    this.props.getRows(search, visibleColumns, options).subscribe(
      (value) => {
        this.props.updateRowCountAction(this.namespace, value.rows.length)
        this._processRows(value.rows)
        clearTimeout(timer)
        this.setState({
          rows: value.rows,
          totalCount: value.totalCount,
          timeout: this.LOADING_DELAY,
          loading: false
        })
      },
      (error) => {
        logConsole.error('error is', error)
        if (this.props.handleError) {
          this.props.handleError(error)
        }
      },
      () => {
        logConsole.info('done')
      }
    )
  }

  _checkRowStyle = (row, highlightedRows) => {
    if (row.activeStyle) {
      row.uniqueKey = 'id'
      highlightedRows[row.id] = true
    }
  }

  _processRows = (rows) => {
    let updateStateForSelectedItems = false
    const { permanentRows, customRowProcessor } = this.props
    const { permanentRowTooltipTitle } = this.props?.options
    const selectedItems = { ...this.state.selectedItems }

    const highlightedRows = {}

    if (_.isEmpty(selectedItems) === 0) {
      return
    }

    if (customRowProcessor) {
      rows = customRowProcessor(rows)
    }

    _.each(rows, (row) => {
      if (selectedItems[row.id]) {
        row._isSelected = true
        selectedItems[row.id] = row
        updateStateForSelectedItems = true
      }
      if (_.isDefined(permanentRows) && permanentRows[row.id]) {
        row._isDisabled = true
        if (_.isDefined(permanentRowTooltipTitle)) {
          row._tooltipTitle = permanentRowTooltipTitle
        }
      }
      this._checkRowStyle(row, highlightedRows)
    })

    if (_.size(highlightedRows) > 0) {
      this.setState({ highlightedRows: highlightedRows })
    }
    if (updateStateForSelectedItems) {
      logConsole.debug('update selected items ', selectedItems)
      this.setState({ selectedItems: selectedItems })
    }
  }

  _recalculateShowSelectedOnly = (state, selectedItems) => {
    if (state.preSelectedShowSelectedOnly && _.isEmpty(selectedItems)) {
      this.props.showOnlySelectedRowsAction(this.namespace, false)
      return false
    }
    return state.preSelectedShowSelectedOnly
  }

  _updateSelectedItems = (selectedItems, selectedItem) => {
    if (selectedItem._isSelected) {
      selectedItems[selectedItem.id] = selectedItem
    } else {
      delete selectedItems[selectedItem.id]
    }
    return selectedItems
  }

  _deselectExceededItems = () => {
    const { rowsToDeselect } = this.state
    _.each(rowsToDeselect, (rowToDeselect) => {
      rowToDeselect._isSelected = false
    })
    this.setState({ rowsToDeselect: undefined })
  }

  _onRowSelect = (selectedItem) => {
    const { onRowSelected, onRowsSelected, maxSelectableItems, onError } = this.props
    const { ppsUserLimit = false } = this.props.options
    const rowLimit = this.state.rowLimit
    const selectedItems = _.isArray(selectedItem) ? selectedItem : [selectedItem]

    if (ppsUserLimit && rowLimit !== PPS_NO_LIMIT && _.first(selectedItems)._isSelected) {
      const prevSelectedItems = _.values(this.state.selectedItems)
      const displayPPsModal = _.size(prevSelectedItems) + _.size(selectedItems) > rowLimit

      if (displayPPsModal) {
        this.setState({ rowsToDeselect: selectedItems })
        this.props.showModal({
          title: BfTrans('permissionError'),
          labelConfirm: 'ok',
          labelCancel: null,
          showClose: false,
          message: BfTrans(`${this.namespace}SelectedPPS`, { max: rowLimit }),
          type: 'danger',
          onClose: () => {
            this._deselectExceededItems()
          },
          onOk: () => {
            this._deselectExceededItems()
          }
        })
        return
      }
    }

    this.setState((state) => {
      const updatedSelectedItems = { ...state.selectedItems }
      const selectedItems = _.isArray(selectedItem) ? selectedItem : [selectedItem]

      _.each(selectedItems, (itemSelected) => {
        this._updateSelectedItems(updatedSelectedItems, itemSelected)
        if (_.isDefined(onRowSelected)) {
          onRowSelected(itemSelected, updatedSelectedItems, itemSelected._isSelected)
        }
      })

      if (_.isDefined(onRowsSelected)) {
        onRowsSelected(selectedItems, updatedSelectedItems)
      }

      if (_.isDefined(maxSelectableItems) && _.isDefined(onError)) {
        if (_.size(updatedSelectedItems) > maxSelectableItems) {
          onError(
            1,
            BfTrans('errorMaxColumns', {
              max: maxSelectableItems
            }),
            'warning'
          )
        } else {
          onError(0, null, null)
        }
      }
      /** If selectedItems are are empty handle the selected only flag */
      const showSelectedOnlyFlag = this._recalculateShowSelectedOnly(state, updatedSelectedItems)
      return {
        selectedItems: updatedSelectedItems,
        preSelectedShowSelectedOnly: showSelectedOnlyFlag
      }
    })
  }

  _resizeModalDataGrid() {
    const modalRef = this.props.displayContainer.containerRef

    if (!modalRef) {
      return
    }

    const footer = modalRef.querySelector('.bfmodalstyle > .hcl-modal-footer')

    const dataGridHeight = footer.offsetTop - this.dataGridRef.current.offsetTop

    this.dataGridRef.current.style.height = dataGridHeight + 'px'
  }

  _resizeFullDataGrid() {
    // 32px is to account for the bottom padding of bfMainAppContainer
    let dataGridHeight = window.innerHeight - this.dataGridRef.current.offsetTop - 1 - 32 // Warning remove the border in bf-datagrid-container
    // add an approximation of the summary chart height
    if (this.props.showSummary) {
      dataGridHeight += 400
    }
    this.dataGridRef.current.style.height = dataGridHeight + 'px'
    let bfClassName = ''
    if (dataGridHeight / window.innerHeight < 0.5) {
      bfClassName = BF_PARTIAL_LAYOUT_CLASS
    } else if (dataGridHeight / window.innerHeight < 0.7) {
      bfClassName = BF_PARTIAL_LAYOUT_CLASS_2
    } else {
      bfClassName = BF_PARTIAL_LAYOUT_CLASS_3
    }
    if (bfClassName !== this.state.bfClassName) {
      this.setState({ bfClassName: bfClassName })
    }
    /* logConsole.debug('datagrid width ', this.dataGridRef.current.offsetWidth)
    logConsole.debug('datagrid height ', dataGridHeight + 'px')
    logConsole.debug('dataGridHeight / window.innerHeight ', dataGridHeight / window.innerHeight) */
  }

  _resizeDataGrid = () => {
    if (!this.dataGridRef.current) {
      return
    }

    const { displayContainer = { type: 'default', containerRef: null } } = this.props

    switch (displayContainer.type) {
      case 'modal':
        this._resizeModalDataGrid()
        break
      case 'default':
        this._resizeFullDataGrid()
        break
    }
  }

  _getVisibleColumnsWidth = () => {
    const visibleColumns = this._getVisibleColumns(this.props.columns)
    let visibleColumnsWidth = _.reduce(
      visibleColumns,
      (sum, visibleColumn) => {
        sum = sum + (_.isDefined(visibleColumn.width) ? parseInt(visibleColumn.width, 10) : 0)
        return sum
      },
      0
    )
    const lastColumn = _.last(visibleColumns)
    visibleColumnsWidth -= parseInt(_.isDefined(lastColumn.width) ? lastColumn.width : 0, 10)
    // Add checkbox width 40
    visibleColumnsWidth += CHECKBOX_WIDTH

    /* logConsole.debug(this.namespace + ':visibleColumnsWidth = ', visibleColumnsWidth) */
    return visibleColumnsWidth
  }

  componentDidUpdate() {
    logConsole.debug('componentDidUpdate')
    this._resizeDataGrid()
  }

  _onSort = (method, order) => {
    logConsole.debug(`sorting by method: ${method} and order: ${order}`)
    const methodName = this._getSortMethodByField(method)
    const bfSortFilter = new BfSortFilter(order, methodName)
    this.props.updateSearchListUrlAction(this.namespace, bfSortFilter.get())
  }

  _getSelectedItemsIds = () => {
    const selectedItemsIds = {}
    if (_.size(this.state.selectedItems) > 0) {
      const _selectedItemsIds = _.reduce(
        this.state.selectedItems,
        (acc, value) => {
          acc[value.id] = true
          return acc
        },
        {}
      )
      _.extend(selectedItemsIds, _selectedItemsIds)
    }
    const onlySelectedItemsIds = _.keys(selectedItemsIds)
    logConsole.debug('onlySelectedItemsIds are ', onlySelectedItemsIds)
    return onlySelectedItemsIds
  }

  _onShowSelectedOnly = (toggle) => {
    if (_.isUndefined(toggle) || toggle !== this.state.preSelectedShowSelectedOnly) {
      const newPreSelectedShowSelectedOnly = !this.state.preSelectedShowSelectedOnly
      this.props.showOnlySelectedRowsAction(this.namespace, newPreSelectedShowSelectedOnly)
      this.setState({
        preSelectedShowSelectedOnly: newPreSelectedShowSelectedOnly
      })
    }
  }

  _onChangePagination = () => {
    if (this.dataGridRef && _.isNotEmpty(this.dataGridRef.current.childNodes)) {
      const datagridDiv = _.first(this.dataGridRef.current.childNodes)
      datagridDiv.scrollTo(0, 0)
    }
  }

  render() {
    const {
      addColumnButtonCustomModal,
      options = {
        updateUrl: false,
        showDeployButton: false,
        showSelectedOnly: false,
        paginationBarFilterHTML: '',
        save: true
      },
      showPaginationIcons,
      customActionBar,
      headers,
      paginationBarComponents
    } = this.props

    const visibleColumns = this._getVisibleColumns(this.props.columns)

    this._setRenderColumnsWidth(visibleColumns, headers)
    const paginationBarFilter = _.isNotEmpty(options.paginationBarFilterHTML) ? HTML_COLUMN_TYPES[options.paginationBarFilterHTML] : undefined
    return (
      <BfDataGridComponentWithProvider namespace={this.namespace}>
        <div className={this.state.loading ? 'bf-datagrid-container bf-datagrid-loading' : 'bf-datagrid-container'}>
          <BfPaginationBar
            headers={headers}
            visibleColumns={visibleColumns}
            pref={this.paginationBarRef}
            id={this.namespace}
            totalItems={this.state.totalCount}
            addColumnButtonCustomModal={addColumnButtonCustomModal}
            showPaginationIcons={showPaginationIcons}
            paginationBarComponents={paginationBarComponents}
            showResetColumn={this.state.showResetColumn}
            isLoading={this.state.loading}
            updateUrl={options.updateUrl}
            checkboxFilterObj={options.checkboxFilterObj}
            paginationBarFilter={paginationBarFilter}
            showManualTargeting={options.showManualTargeting}
            addNewItemLabel={options.addNewItemLabel}
            addNewItemLink={options.addNewItemLink}
            onChangePagination={this._onChangePagination}
          />
          <BfActionBar
            showDeployButton={options.showDeployButton}
            selectedItems={this.state.selectedItems}
            preSelectedShowSelectedOnly={this.state.preSelectedShowSelectedOnly}
            onShowSelectedOnly={this._onShowSelectedOnly}
            showSelectedOnly={options.showSelectedOnly}
            customActionBar={customActionBar}
          />
          {this.state.loading ? (
            <LoadingState className='hcl-data-table hcl-loading' height={null} tableConfig={visibleColumns} type='datatable' width={null} />
          ) : (
            <BfDataGrid
              ref={this.dataGridRef}
              headers={visibleColumns}
              rows={this.state.rows}
              disableSelectAll={options?.disableSelectAll}
              bfClassName={this.state.bfClassName}
              onRowSelect={this.props.selectableRow ? this._onRowSelect : null}
              selectableRow={this.props.selectableRow}
              selectedItem={this.state.highlightedRows}
              uniqueKey='id'
              id={this.namespace}
              onSort={this._onSort}
              onColumnAfterResize={this._onColumnAfterResize}
              onColumnReorder={_.isDefined(this.props.noDraggableColumn) ? null : this._onColumnReorder}
              sortedColumn={this._getSortedColumn()}
              areFiltersApplied={this.props.areFiltersApplied}
            />
          )}
        </div>
      </BfDataGridComponentWithProvider>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const bfFilterManager = createFilterManagerFromReduxState(state, ownProps.id)
  const bfSortFilter = bfFilterManager.getSortFilter()
  const areFiltersApplied = bfFilterManager.areFiltersApplied()

  if (_.isUndefined(state[ownProps.id]?.columns)) {
    return {
      columns: [],
      saveHeaders: false,
      bfSortFilter: bfSortFilter,
      areFiltersApplied: areFiltersApplied
    }
  }
  return {
    columns: state[ownProps.id].columns.cols,
    saveHeaders: state[ownProps.id].columns.save,
    bfSortFilter: bfSortFilter,
    areFiltersApplied: areFiltersApplied
  }
}

const BfDataGridTableReduxComponent = connect(mapStateToProps, {
  updateSearchListUrlAction,
  updateColumnListAction,
  showOnlySelectedRowsAction,
  updateRowCountAction,
  refreshRowsAction
})(BfWithServicesConsumer(BfDataGridTableComponent))

export default withBfModal(BfWithReduxStoreConsumer(BfDataGridTableReduxComponent))
