/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import BfDropDownSearchBasicFilter from '../../common/bfBasicFilters/BfDropDownSearchFilter/BfDropDownSearchBasicFilter'

class BfCriticalPatchesFilter extends BfDropDownSearchBasicFilter {
  static NAME = 'has_critical_patch_vulnerabilities'
  static PATH = `filter.${BfCriticalPatchesFilter.NAME}`

  constructor(data = {}) {
    super(BfCriticalPatchesFilter.PATH, BfCriticalPatchesFilter.NAME)
    this[BfCriticalPatchesFilter.NAME] = data
  }

  getItems = () => {
    const res = {
      values: [
        {
          id: 'CriticalPatches',
          text: 'Yes'
        },
        {
          id: 'NoCriticalPatches',
          text: 'No'
        }
      ]
    }
    return res.values
  }
}

export default BfCriticalPatchesFilter
