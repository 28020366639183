/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

class BfBytes {
  toBytes(size) {
    const byteSize = ['B', 'KB', 'MB', 'GB', 'TB', 'PB']
    let index = 0
    size = parseInt(size)

    while (size > 1024) {
      size = size / 1024
      index++
    }

    const fileSize = '' + (size === null ? 0 : size.toFixed(2)) + ' ' + byteSize[index]
    return fileSize
  }
}
export default BfBytes
