/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/
import _ from './BfLodash'

export const bfSetPageTitle = (t, kCatalog, catalogParams) => {
  document.title = _.isDefined(catalogParams) ? t(kCatalog, catalogParams) : t(kCatalog)
}
