/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2021
// All Rights Reserved
*/

import { HttpMethod, BfHttpClient } from '../authClient/BfHttpClient'

class BfGroupService {
  http = new BfHttpClient()

  getGroups = (params) => {
    return this.http.doRequest(params.filter && params.filter.onlySelected ? HttpMethod.POST : HttpMethod.GET, '/devices/groups/data', params)
  }
}
export default BfGroupService
