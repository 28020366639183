/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2021
// All Rights Reserved
*/
import BfReduxStateConstants from '../../../common/BfReduxStateConstants'

import BfSearchStringFilter from '../common/bfSearch/BfSearchStringFilter'

class BfPatchSoftwareSearchFilter extends BfSearchStringFilter {
  static NAME = 'software_search'
  static PATH = `filter.${BfPatchSoftwareSearchFilter.NAME}`
  static NAMESPACE = BfReduxStateConstants.PATCHES
  doFilter = true
  constructor(query) {
    super(BfPatchSoftwareSearchFilter.NAME, query)
  }
}

export default BfPatchSoftwareSearchFilter
