/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2021
// All Rights Reserved
*/

import BfDropDownSearchBasicFilter from '../../common/bfBasicFilters/BfDropDownSearchFilter/BfDropDownSearchBasicFilter'

class InsightsAttributeCategoryFilter extends BfDropDownSearchBasicFilter {
  static NAME = 'attribute_category'
  static PATH = `filter.${InsightsAttributeCategoryFilter.NAME}`
  fixedParams = { search: { query: '%', limit: 20 } }

  constructor(data = {}) {
    super(InsightsAttributeCategoryFilter.PATH, InsightsAttributeCategoryFilter.NAME)
    this[InsightsAttributeCategoryFilter.NAME] = data
  }

  getSearchURL = () => {
    return '/insights/api/customAttribute/category'
  }
}

export default InsightsAttributeCategoryFilter
