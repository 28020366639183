/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import BfConsole from 'loglevel'
import _ from '../../../services/utils/BfLodash'
import moment from 'moment'
import { MESSAGE_SUCCESS, MESSAGE_WARNING, REMOVE_MESSAGE } from './BfMessageActionType'

const initialState = { messages: [] }

const _onAddNewMessage = (messages, newMessage) => {
  newMessage.id = moment().valueOf()
  messages.push(newMessage)
  return messages
}

const messageReducer = (state = initialState, action) => {
  BfConsole.debug('messageReducer action ', action)
  BfConsole.debug('messageReducer state ', state)
  let _messages

  switch (action.type) {
    case MESSAGE_SUCCESS:
    case MESSAGE_WARNING:
      _messages = [...state.messages]
      _messages = _onAddNewMessage(_messages, action.message)
      return { ...state, messages: _messages }

    case REMOVE_MESSAGE:
      _messages = _.filter(state.messages, (message) => message.id !== action.id)
      return { ...state, messages: _messages }
    default: {
      return state
    }
  }
}

export default messageReducer
