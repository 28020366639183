/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import BfBasicFilter from '../../BfBasicFilter'

class BfShowRelevantPackagesFilter extends BfBasicFilter {
  static NAME = 'packageIds'
  static PATH = `filter.${BfShowRelevantPackagesFilter.NAME}`

  constructor() {
    super(BfShowRelevantPackagesFilter.PATH, BfShowRelevantPackagesFilter.NAME)
  }
}

export default BfShowRelevantPackagesFilter
