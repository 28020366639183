import React from 'react'

import BfCellWidth from './BfCellWidth'
import _ from '../../../services/utils/BfLodash'

import BfHtmlCell from './BfHtmlCell'

import BfCellEmptyRenderer from './BfCellEmptyRenderer'

/**
 * Cell renderer for data grid
 */
class BfHtmlRenderer {
  constructor(header) {
    this.header = header

    this.name = header.field
    this.title = header.title || header.label
  }

  getRenderer() {
    return this._getRenderHTML
  }

  _getRenderHTML = (row) => {
    if (_.isEmpty(row[this.name])) {
      return <BfCellEmptyRenderer value={`${this.name}Empty`} />
    }
    const value = row[this.name].toString()
    return (
      <BfCellWidth header={this.header}>
        <BfHtmlCell value={value} rowData={row} title={this.title} />
      </BfCellWidth>
    )
  }
}

export default BfHtmlRenderer
