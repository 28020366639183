/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2021
// All Rights Reserved
*/
import _ from '../../../../../services/utils/BfLodash'
import BfBasicFilter from '../../BfBasicFilter'

// For adding single string search filter
// Ex. filter = { name_search: "<querygoeshere>" }
class BfSearchStringFilter extends BfBasicFilter {
  constructor(key, text) {
    super()
    this.key = key
    this.value = _.isUndefined(text) ? null : text
  }

  get = () => {
    return _.isEmpty(this.value) ? { [this.key]: null } : { [this.key]: this.value }
  }

  buildFromURLHandler = (urlHandler) => {
    const { filter } = urlHandler.getSearch()
    if (!_.isEmpty(filter) && !_.isEmpty(filter[this.key])) {
      this.value = filter[this.key]
    }
  }

  setQuery = (query) => {
    this.value = _.isEmpty(query) ? '' : query
  }

  getQuery = () => {
    if (_.isEmpty(this.value)) {
      return ''
    }
    return this.value
  }
}

export default BfSearchStringFilter
