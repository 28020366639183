/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import React, { Component } from 'react'
import { BfWithReduxStoreConsumer } from '../../mainAppContainer/bfReduxStore/BfReduxStore'
import messageReducer from './BfMessageReducer'
import BfMessagesPane from './BfMessagesPane'

const BfWithMessageConsumer = (WrappedComponent) => {
  class BfWithMessageConsumerConnected extends Component {
    componentDidMount = () => {
      this.props.store.addReducer('messages', messageReducer)
    }

    componentWillUnmount = () => {
      this.props.store.removeReducer('messages')
    }

    render() {
      return (
        <React.Fragment>
          <WrappedComponent {...this.props} />
          <BfMessagesPane />
        </React.Fragment>
      )
    }
  }
  return BfWithReduxStoreConsumer(BfWithMessageConsumerConnected)
}

export default BfWithMessageConsumer
