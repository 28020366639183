/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/
import _ from '../../../../services/utils/BfLodash'
import { of } from 'rxjs'

import { bfTranslate } from '../../../../services/i18n/bfI18Utils'
import BfReduxStateConstants from '../../../common/BfReduxStateConstants'
import BfBasicFilter from '../BfBasicFilter'
import { OS_VERSIONS } from '../BfFiltersConstants'
import { createOsDropDownItem } from '../common/BfCheckboxTree/BfCheckboxTree'
class ApOperatingSystemFilter extends BfBasicFilter {
  static NAME = 'os'
  static PATH = `filter.${ApOperatingSystemFilter.NAME}`
  static NAMESPACE = BfReduxStateConstants.AP_POLICIES
  constructor(data = {}) {
    super(ApOperatingSystemFilter.PATH, ApOperatingSystemFilter.NAME, data)
  }

  getSelectedItems = () => {
    return _.size(this[ApOperatingSystemFilter.NAME])
  }

  getItems = () => {
    const t = this.getT()

    const items = [
      {
        id: 'LINUX',
        name: bfTranslate(t, 'Linux'),
        items: [
          {
            id: 'CENTOS', // id of OS in autopatch
            constantsId: 'CentOS', // id of OS in filter constants
            name: bfTranslate(t, 'CentOS')
          },
          {
            id: 'ORACLE',
            constantsId: 'OracleLinux',
            name: bfTranslate(t, 'OracleLinux')
          },
          {
            id: 'RHEL',
            constantsId: 'RHEL',
            name: bfTranslate(t, 'RHEL')
          },
          {
            id: 'SLE',
            constantsId: 'SUSE',
            name: bfTranslate(t, 'SUSE')
          },
          {
            id: 'UBUNTU',
            constantsId: 'Ubuntu',
            name: bfTranslate(t, 'Ubuntu')
          }
        ]
      },
      {
        id: 'WINDOWS',
        name: bfTranslate(t, 'Windows')
      }
    ]

    // Populate individual linux os versions from BfFiltersConstants
    _.each(items[0].items, (linuxOS) => {
      linuxOS.items = _.map(OS_VERSIONS[linuxOS.constantsId], (osVersionId) => {
        return { id: osVersionId, name: bfTranslate(t, osVersionId) }
      })
    })

    // Populate windows os versions from BfFiltersConstants
    items[1].items = _.map(OS_VERSIONS.Windows, (winOSVersion) => {
      return { id: winOSVersion, name: bfTranslate(t, winOSVersion) }
    })

    return of(createOsDropDownItem(items))
  }
}

export default ApOperatingSystemFilter
