/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import BfHttpClient from './BfHttpClient'

class bfAuthClient {
  run = () => {
    var pollInterval = 30000

    setInterval(() => {
      const bfHttpClient = new BfHttpClient()
      bfHttpClient.get('/loggedin')
    }, pollInterval)
  }
}
export default bfAuthClient
