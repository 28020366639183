/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import BfSearchBasicFilter from './BfSearchBasicFilter'

class BfSearchPolicyGroupsFilter extends BfSearchBasicFilter {
  static PATH = 'search'
  static NAME = 'name'
  constructor(query) {
    super(BfSearchPolicyGroupsFilter.PATH, BfSearchPolicyGroupsFilter.NAME, query)
  }
}

export default BfSearchPolicyGroupsFilter
