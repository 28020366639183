/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import _ from '../../services/utils/BfLodash'
import { map } from 'rxjs/operators'
import BfAgentTypeInfo from './BfAgentTypeInfo'

/**
 * Cloud based device filters
 */
class BfCloudFilterFactory {
  bfAgentTypeInfo = new BfAgentTypeInfo()

  /**
   * Sets Agent type for the device/item
   * @method
   * @param {Object} device
   */
  setItemAgentType(device) {
    return this._internalSetItemAgentType(device)
  }

  /**
   * Sets Agent type for the device/item
   * @method
   * @param {Object} device
   */
  _internalSetItemAgentType(device) {
    return this.bfAgentTypeInfo
      .getCategoryByAgentType()
      .pipe(map((categoryByAgentType) => this._updateDeviceWithAgentType(device, categoryByAgentType)))
  }

  /**
   * Update Agent type for the device/item
   * @method
   * @param {Object} device
   * @param {Object} categoryByAgentType
   */
  _updateDeviceWithAgentType(device, categoryByAgentType) {
    if (device.agentCorrelatedTypes) {
      var isFirstCloud = true
      _.each(device.agentCorrelatedTypes, function (value) {
        if (/^\w* - MDM/.test(value.agentType)) {
          device.mdm = true
          device.lastReportTimeMDM = value.LastReportTime
        }
        if (categoryByAgentType[value.agentType] === 'Cloud') {
          device.cloud = true
          device.cloudType = value.agentType
          if (isFirstCloud) {
            device.lastReportTimeCloud = value.LastReportTime
            isFirstCloud = false
          }
        }
        if (categoryByAgentType[value.agentType] === 'Native') {
          device.agent = true
          device.lastReportTimeAgent = value.LastReportTime
        }
      })
    } else {
      device.mdm = false
      device.cloud = false
      device.agent = false
    }
    return device
  }
}

export default BfCloudFilterFactory
