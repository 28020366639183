/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2021
// All Rights Reserved
*/

import React, { useState } from 'react'
import { Field } from 'formik'
import PropTypes from 'prop-types'
import './BfForm.scss'

import { Dropdown } from '@patron/patron-react/dropdown'
import { Tag } from '@patron/patron-react/tag'

import _ from '../../services/utils/BfLodash'
import { ReactComponent as AddCircleIcon } from './add-circle.svg'

import './BfDropdownTags.scss'

function BfDropdownTags(props) {
  const {
    defaultText = '',
    name,
    id = name,
    label = '',
    subLabel = '',
    containerClass = '',
    labelClass = '',
    disabled = false,
    required = false,
    items = []
  } = props

  const [isEditing, setEditing] = useState(true)
  const [editClicked, setEditClicked] = useState(false)

  const addFieldValue = (form, values) => {
    const newOptions = values.map((optionId) => optionsFromIds[optionId])
    form.setFieldValue(name, newOptions)
  }

  const createTags = (field, form) => {
    if (_.isUndefined(field.value) && _.isEmpty(field.value)) {
      return null
    }

    return field.value.map((item, index) => {
      return (
        <Tag
          closable
          onClick={(e) => {
            e.stopPropagation()
            const newItems = field.value.filter((option) => {
              return option.id !== item.id
            })
            form.setFieldValue(name, newItems)
            form.setFieldTouched(name, true)
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.stopPropagation()
              const newItems = field.value.filter((option) => {
                return option.id !== item.id
              })
              form.setFieldValue(name, newItems)
              form.setFieldTouched(name, true)
            }
          }}
          key={`tag${name}${index}`}
          type='secondary'
          disabled={disabled}
        >
          {item.text}
        </Tag>
      )
    })
  }

  const optionsFromIds = {}
  items.forEach((option) => {
    optionsFromIds[option.id] = option
  })

  return (
    <div className='hcl-form-group bfDropdownTags'>
      <Field name={name}>
        {({ field, form, meta }) => (
          <div className={containerClass}>
            {label && (
              <div className='bfInputLabel'>
                <label htmlFor={id || name} className={labelClass}>
                  {label} {required ? <i className='bfRequired'> *</i> : ''}
                </label>
              </div>
            )}

            {subLabel && <div className='hcl-helper-text hcl-text-left'>{subLabel}</div>}

            {meta.touched && _.isNotEmpty(meta.error) && <div className='hcl-error-msg hcl-text-left'>{meta.error}</div>}

            {!field.value || field.value.length === 0 || isEditing || editClicked ? (
              <div className='bfInlineContainer'>
                <Dropdown
                  id={id}
                  disabled={disabled}
                  type='bottom'
                  items={items}
                  label={defaultText}
                  key={name}
                  dropdownType='multi'
                  selectedItem={field.value}
                  onChange={(changed, selected) => {
                    form.setFieldTouched(name, true)
                    addFieldValue(form, selected)
                  }}
                  onVisibleChange={(e) => {
                    if (editClicked) {
                      setEditClicked(false)
                      setEditing(true)
                      document.getElementById(id).firstChild.click()
                    } else {
                      setEditing(e)
                    }
                  }}
                />
              </div>
            ) : (
              <div
                className='bfInlineWrapper'
                onClick={() => {
                  setEditClicked(true)
                }}
                tabIndex='0'
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setEditClicked(true)
                  }
                }}
              >
                {createTags(field, form)}
                {field.value.length < items.length && <AddCircleIcon className='bfAddCircleIcon' />}
              </div>
            )}
          </div>
        )}
      </Field>
    </div>
  )
}

BfDropdownTags.propTypes = {
  name: PropTypes.string.isRequired,
  defaultText: PropTypes.string,
  containerClass: PropTypes.string,
  labelClass: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  subLabel: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      text: PropTypes.string
    })
  )
}

export default BfDropdownTags
