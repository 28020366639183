/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import BfReduxStateConstants from '../../../../common/BfReduxStateConstants'
import BfBasicCustomFilter from '../BfBasicCustomFilter'
import _ from '../../../../../services/utils/BfLodash'

class BfCategoryFilter extends BfBasicCustomFilter {
  static NAME = 'category'
  static NAMESPACE = BfReduxStateConstants.DEVICES
  static PATH = `filter.${BfCategoryFilter.NAME}`
  constructor(data = {}) {
    super(BfCategoryFilter.PATH, BfCategoryFilter.NAME, data)
  }

  processResponse = (res) => {
    const data = { values: [] }
    data.values = _.map(res.data, 'category').filter((category) => _.isNotEmpty(category))

    return data
  }

  getSearchURL = () => {
    return '/custom/api/tasks/categories'
  }
}

export default BfCategoryFilter
