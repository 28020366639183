/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import BfReduxStateConstants from '../../../../common/BfReduxStateConstants'
import BfDropDownBasicFilter from '../../common/bfBasicFilters/bfDropDownFilter/BfDropDownBasicFilter'

class BfOSFilter extends BfDropDownBasicFilter {
  static NAME = 'os'
  static PATH = `filter.${BfOSFilter.NAME}`
  static NAMESPACE = BfReduxStateConstants.DEVICES

  constructor(data = {}) {
    super(BfOSFilter.PATH, BfOSFilter.NAME, data)
  }

  getItems = () => {
    const res = {
      values: [
        {
          id: 'linux',
          text: 'Linux'
        },
        {
          id: 'windows',
          text: 'Windows'
        },
        {
          id: 'osx',
          text: 'OSX'
        },
        {
          id: 'solaris',
          text: 'Solaris'
        },
        {
          id: 'other',
          text: 'Other'
        }
      ]
    }
    return res.values
  }
}

export default BfOSFilter
