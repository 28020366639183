import React from 'react'

import './BfInput.scss'
import { TextInput } from '@patron/patron-react/textinput'
import { Password } from '@patron/patron-react/password'
import { withTranslation } from 'react-i18next'
import { bfTranslate } from '../../../services/i18n/bfI18Utils'

const BfInput = (props) => {
  const { required, ellipsis = false, type, labelClass = 'hcl-label', t, errorMsg, isInvalid, field, className = '', ...rest } = props
  const { name, id = name } = rest

  const ellipsisStyle = ellipsis ? ' bf-ellipsis' : ''

  const InputType = type === 'password' ? Password : TextInput
  const tooltipMessage =
    type === 'password'
      ? {
          hidePassword: bfTranslate(t, 'HidePassword'),
          showPassword: bfTranslate(t, 'ShowPassword')
        }
      : null

  return (
    <div className={`hcl-form-group ${className}`}>
      <InputType data-invalid={isInvalid} className={ellipsisStyle} tooltipMessage={tooltipMessage} {...field} {...rest} />
      {props.label && (
        <div className='bf-input-label'>
          <label htmlFor={id} className={labelClass}>
            {props.label} {required ? <i className='bf-required'> *</i> : ''}
          </label>
          {props.maxLength && <div className='hcl-text-right bf-input-count'>{field.value.length + '/' + props.maxLength}</div>}
        </div>
      )}

      {props.subLabel && <div className='hcl-helper-text hcl-text-left'>{props.subLabel}</div>}
      {isInvalid && <div className='hcl-error-msg hcl-text-left'>{errorMsg}</div>}
    </div>
  )
}

export default withTranslation()(BfInput)
