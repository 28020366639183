import React, { useState } from 'react'
import Overlay from '@patron/patron-react/export/atoms/Overlay/Overlay'
import { Button } from '@patron/patron-react/button'
import _ from '../../../services/utils/BfLodash'
import BfManualTargetingContent from './BfManualTargetingContent'
import './BfManualTargeting.scss'
import Tooltip from '@patron/patron-react/export/atoms/Tooltip/Tooltip'
import { useTranslation } from 'react-i18next'
import { bfTranslate } from '../../../services/i18n/bfI18Utils'

const BfManualTargeting = () => {
  const { t } = useTranslation()
  const [manualTargetingStatus, setManualTargetingStatus] = useState(false)
  const [manualTargetingElement, setManualTargetingElement] = useState(null)

  const _showOverlay = (e) => {
    if (_.isUndefined(e)) {
      _toggleOverlay()
    } else {
      setManualTargetingStatus((status) => !status)
      setManualTargetingElement(e.currentTarget)
    }
  }
  const _toggleOverlay = (status) => {
    if (!status) {
      setManualTargetingStatus(status)
      setManualTargetingElement(null)
    }
  }

  return (
    <div className='hcl-mt-wrapper'>
      <Tooltip direction='top' content={bfTranslate(t, 'manuallyTargeting')}>
        <Button id='mtButton' className='bf-action-icon' disabled={false} kind='button' small={false} type='ghost' onClick={_showOverlay}>
          <i className='p-hclsw p-hclsw-target icon-in-tad-button' />
          <span className='label-in-tad-button'>{bfTranslate(t, 'manuallyTargeting')}</span>
        </Button>
      </Tooltip>
      <Overlay showOverlay={manualTargetingStatus} targetElement={manualTargetingElement} closeOnEscape onToggle={_toggleOverlay}>
        <div className='hcl-mt-content'>
          <BfManualTargetingContent onCancelClicked={_showOverlay} />
        </div>
      </Overlay>
    </div>
  )
}

export default BfManualTargeting
