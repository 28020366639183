/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import BfConsole from 'loglevel'
import * as Yup from 'yup'
import { Button } from '@patron/patron-react/button'
import BfForm from '../../form/BfForm'
import BfInputField from '../../form/BfInputField'
import BfTextArea from '../../form/BfTextAreaField'
import BfUrlHandler from '../../../services/url/BfUrlHandler'
import BfHttpClient from '../../../services/authClient/BfHttpClient'

import { useFormik } from 'formik'
import { Notification } from '@patron/patron-react/notification'
import { Slideout } from '@patron/patron-react/slideout'
import { ContentSwitcher, Switch } from '@patron/patron-react/contentswitcher'
import { withTranslation } from 'react-i18next'
import { bfTranslate } from '../../../services/i18n/bfI18Utils'
import _ from '../../../services/utils/BfLodash'
import './BfReportsStyle.scss'
import '@patron/patron-css/patron/index.css'

const REPORT_ERRORS = Object.freeze({
  REPORT_NAME_EXISTS: 'REPORT_NAME_EXISTS'
})
const REPORT_MODAL_MODES = Object.freeze({
  SAVE: 'save',
  EDIT: 'edit'
})
function BfReportModal(props) {
  const state = {
    hasCopiedBeenClickedRecently: false,
    errorMessage: '',
    showEditModal: true
  }

  const bfUrlHandler = new BfUrlHandler(null, true)
  const http = new BfHttpClient()

  function _getFilters() {
    let filters = Object.assign({}, bfUrlHandler._getSearchObj())
    // lodash omit causes post request to break
    const omit = (keys, obj) => Object.fromEntries(Object.entries(obj).filter(([k]) => !keys.includes(k)))
    filters = omit(['reportDetails', 'pagination'], filters)
    return filters
  }

  const filters = _getFilters()
  const { mode, contentType, reports, reportUrlLink, t } = props

  state.errorMessage = _.isEmpty(filters) && mode === REPORT_MODAL_MODES.SAVE ? 'noFiltersError' : ''

  let reportLink = window.location
  const [values, setValues] = useState(state)

  let validationSchema = Yup.object().shape({
    name: Yup.string(),
    description: Yup.string(),
    isPublic: Yup.boolean()
  })
  let editMultipleReports = false
  /** Save mode modal requires:
   *  contentType (string)
   */
  let initialReport = {}
  if (mode === REPORT_MODAL_MODES.SAVE) {
    initialReport = {
      contentType: contentType,
      name: '',
      description: '',
      isFavorite: false,
      isPublic: 0
    }
    /** Edit mode modal requires:
     *  reports (array)
     */
  } else if (mode === REPORT_MODAL_MODES.EDIT) {
    if (!_.isArray(reports) || _.size(reports) < 1) {
      setValues({ ...values, errorMessage: 'reportMissingError' })
    } else {
      if (_.size(reports) > 1) {
        editMultipleReports = true
        const reportIds = _.map(reports, 'id')
        initialReport = {
          isPublic: 0,
          ids: reportIds
        }
        validationSchema = Yup.object().shape({
          isPublic: Yup.boolean()
        })
      } else {
        const report = reports[0]
        initialReport = {
          name: report.name,
          description: report.description,
          isPublic: report.isPublic ? 1 : 0,
          id: report.id
        }

        if (reportUrlLink) {
          reportLink = reportUrlLink
        }
      }
    }
  } else {
    setValues({
      ...values,
      errorMessage: 'modalMissingError'
    })
  }

  const [name, setName] = useState(initialReport.name)

  function _handleError(error) {
    if (error.data === REPORT_ERRORS.REPORT_NAME_EXISTS) {
      setValues({ ...values, errorMessage: 'reportNameExistsError' })
    } else {
      let errorText = error.statusText
      errorText = error.data ? errorText + ': ' + error.data : errorText
      setValues({ ...values, errorMessage: errorText })
    }
  }

  /**
   * Saves or edits the report filters with the form values
   * formValues are populated by formiks useFormik hook
   */
  function _handleSaveAndEdit(values) {
    initialReport.name = values.name
    if (mode === REPORT_MODAL_MODES.SAVE) {
      const { closeModal } = props
      const postData = Object.assign({}, values)
      postData.name = name

      const filters = _getFilters()
      postData.filters = JSON.stringify(filters)
      postData.name = name
      const postReportCall = http.post('/api/reports', postData)
      postReportCall.subscribe(
        (res) => {
          BfConsole.log('Save report successful. New report id: ', res.id)
          postData.id = res.id
          closeModal(postData)
        },
        (error) => {
          _handleError(error)
        }
      )
    } else {
      const { closeModal, reports } = props
      const postData = Object.assign({}, values)
      postData.name = name
      if (_.size(reports) > 1) {
        postData.ids = _.map(reports, 'id')
      }

      const postReportCall = _.size(reports) > 1 ? http.put('/api/reports/editReports', postData) : http.post('/api/reports/editReport', postData)

      postReportCall.subscribe(
        () => {
          BfConsole.log('Edit report(s) successful')
          closeModal(postData)
        },
        (error) => {
          _handleError(error)
        }
      )
    }
  }

  const formik = useFormik({
    initialValues: initialReport,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: _handleSaveAndEdit
  })

  function _copyLink() {
    // setCopied(true)
    setValues({ ...values, hasCopiedBeenClickedRecently: true })
    navigator.clipboard.writeText(reportLink)

    setTimeout(() => {
      // setCopied(false)
      setValues({ ...values, hasCopiedBeenClickedRecently: false })
    }, 2000)
  }

  function _closeSlideout() {
    const { closeModal } = props
    closeModal()
  }

  return (
    <BfForm initialValues={initialReport} validationSchema={validationSchema} noMargin>
      {(form) => (
        <Slideout
          actions={[
            {
              label: bfTranslate(t, 'save'),
              type: 'primary',
              handler: formik.handleSubmit,
              disabled: name === '' || values.errorMessage === 'noFiltersError'
            },
            {
              label: bfTranslate(t, 'cancel'),
              type: 'secondary',
              handler: _closeSlideout
            }
          ]}
          header={mode === REPORT_MODAL_MODES.SAVE ? bfTranslate(t, 'saveReport') : bfTranslate(t, 'editReport')}
          direction='right'
          varient='large'
          isOpen={values.showEditModal}
          onClose={_closeSlideout}
          onOutsideClick={_closeSlideout}
          errorMessage={bfTranslate(t, values.errorMessage)}
          disableSubmit={!form.isValid}
          className='report-modal'
        >
          {editMultipleReports && (
            <React.Fragment>
              <div className='hcl-form-group'>
                <label className='hcl-label'>{bfTranslate(t, 'reportName')}</label>
                <div>
                  {bfTranslate(t, 'reportSelection', {
                    count: _.size(reports)
                  })}
                </div>
              </div>
              <ContentSwitcher
                name={formik.values.isPublic}
                value={formik.values.isPublic}
                className='hcl-content-switcher blue_active_blue_light'
                onChange={(e) => {
                  formik.values.isPublic = e.switchIndex
                }}
              >
                <Switch label={bfTranslate(t, 'private')} value={0} />
                <Switch label={bfTranslate(t, 'allUsers')} value={1} />
              </ContentSwitcher>
            </React.Fragment>
          )}
          {!editMultipleReports && (
            <React.Fragment>
              <Notification
                subtitle={bfTranslate(t, values.errorMessage)}
                title={bfTranslate(t, 'error')}
                type='danger'
                visible={values.errorMessage}
                closable={false}
                icon={<i className='p-hclsw p-hclsw-close-circle selected bfErrorNotificationColor' />}
                required
              />
              <BfInputField
                name='name'
                value={formik.values.name}
                label={bfTranslate(t, 'reportName')}
                maxLength='50'
                onChange={(changed) => {
                  setTimeout(() => {
                    setName(changed.target.defaultValue)
                  }, 0)
                  formik.handleChange(changed)
                }}
              />
              <BfTextArea name='description' value={formik.values.description} label={bfTranslate(t, 'reportDesc')} onChange={formik.handleChange} />
              <ContentSwitcher
                name={formik.values.isPublic}
                value={formik.values.isPublic}
                className='hcl-content-switcher blue_active_blue_light'
                onChange={(e) => {
                  formik.values.isPublic = e.switchIndex
                }}
              >
                <Switch label={bfTranslate(t, 'private')} value={0} />
                <Switch label={bfTranslate(t, 'allUsers')} value={1} />
              </ContentSwitcher>
              <div className='bfCopyUrlSection'>
                <input type='text' className='hcl-form-control' id='report-url' disabled value={reportLink} />
                <Button title={bfTranslate(t, 'copyLink')} type='primary' onClick={_copyLink}>
                  <i className='p-hclsw p-hclsw-copy' />
                  {values.hasCopiedBeenClickedRecently ? bfTranslate(t, 'copied') : bfTranslate(t, 'copyLink')}
                </Button>
              </div>
            </React.Fragment>
          )}
        </Slideout>
      )}
    </BfForm>
  )
}

BfReportModal.propTypes = {
  contentType: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  reports: PropTypes.array,
  mode: PropTypes.string.isRequired
}

export default withTranslation()(BfReportModal)
