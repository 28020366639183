import React, { Component } from 'react'
import { Modal } from '@patron/patron-react/modal'
import { withTranslation } from 'react-i18next'
import './BfModalOkCancel.scss'
import { bfTranslate } from '../../services/i18n/bfI18Utils'
import _ from '../../services/utils/BfLodash'
import { Notification } from '@patron/patron-react/notification'

class BfModalOkCancel extends Component {
  _showErrorNotification = (message, type) => {
    const { t } = this.props
    return (
      <Notification
        subtitle={message}
        title={type === 'warning' ? bfTranslate(t, 'warning') : bfTranslate(t, 'error')}
        type={type}
        closable={false}
        visible
      />
    )
  }

  render() {
    const {
      onlyDismiss,
      labelConfirm = 'ok',
      onOk,
      labelCancel = 'cancel',
      onClose,
      type = 'default',
      title,
      t,
      classStyle,
      errorMessage = '',
      errorType = 'danger',
      ...rest
    } = this.props

    const actionModal = []

    if (_.isDefined(onOk)) {
      if (_.isNotNull(labelCancel)) {
        actionModal.push({
          label: bfTranslate(t, labelCancel),
          primary: false,
          handler: onClose || onlyDismiss
        })
      }
      actionModal.push({
        disabled: _.isNotEmpty(errorMessage),
        label: bfTranslate(t, labelConfirm),
        primary: true,
        [type]: true,
        handler: () => {
          onOk()
          onlyDismiss && onlyDismiss()
        }
      })
    } else {
      if (type === 'danger') {
        actionModal.push({
          label: bfTranslate(t, 'ok'),
          primary: true,
          handler: onClose || onlyDismiss,
          [type]: true
        })
      } else {
        actionModal.push({
          label: bfTranslate(t, labelCancel),
          primary: true,
          handler: onClose || onlyDismiss,
          [type]: true
        })
      }
    }

    return (
      <Modal
        actions={actionModal}
        className={'bfModalConfirm ' + classStyle}
        heading={title}
        keyboard
        onClose={onClose || onlyDismiss}
        type={type}
        {...rest}
      >
        {_.isNotEmpty(errorMessage) && this._showErrorNotification(errorMessage, errorType)}

        {this.props.children}
      </Modal>
    )
  }
}

export default withTranslation()(BfModalOkCancel)
