/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import {
  UPDATE_FILTER_URL,
  UPDATE_SEARCH_URL,
  UPDATE_COLUMN,
  UPDATE_OPTIONS,
  RESET_SEARCH_URL,
  SHOW_ONLY_SELECTED_ROWS,
  SET_NEW_FILTERS_URL,
  UPDATE_INLINE_REPORT_URL,
  UPDATE_ROW_COUNT,
  REFRESH_ROWS,
  DESELECT_ROWS,
  SELECT_ROWS,
  TOGGLE_SELECTED_ONLY,
  REFRESH_SELECTED_ROWS_IDS
} from './BfFilterListActionTypes'

export const toggleSelectedOnly = (namespace, toggle) => ({
  type: `${namespace}/${TOGGLE_SELECTED_ONLY}`,
  toggle: toggle
})

export const updateFilterListUrlAction = (namespace, filter) => ({
  type: `${namespace}/${UPDATE_FILTER_URL}`,
  filter: filter
})

export const resetSearchListUrlAction = (namespace, filter) => ({
  type: `${namespace}/${RESET_SEARCH_URL}`,
  search: filter
})

export const updateSearchListUrlAction = (namespace, search) => ({
  type: `${namespace}/${UPDATE_SEARCH_URL}`,
  search: search
})

export const updateColumnListAction = (namespace, headers, save = false, reset = false) => ({
  type: `${namespace}/${UPDATE_COLUMN}`,
  columns: headers,
  save: save,
  reset: reset
})

export const updateSearchOptionsAction = (namespace, options) => ({
  type: `${namespace}/${UPDATE_OPTIONS}`,
  options: options
})

export const showOnlySelectedRowsAction = (namespace, showOnlySelected, selectedRowsIds = {}) => ({
  type: `${namespace}/${SHOW_ONLY_SELECTED_ROWS}`,
  showOnlySelected: showOnlySelected,
  selectedRowsIds: selectedRowsIds
})

export const setNewFiltersUrlAction = (namespace, filters) => ({
  type: `${namespace}/${SET_NEW_FILTERS_URL}`,
  filter: filters.filter,
  sort: { sort: filters.sort },
  search: { search: filters.search }
})

export const updateInlineReportUrlAction = (namespace, reportDetails) => ({
  type: `${namespace}/${UPDATE_INLINE_REPORT_URL}`,
  reportDetails: reportDetails
})

export const updateRowCountAction = (namespace, rowCount) => ({
  type: `${namespace}/${UPDATE_ROW_COUNT}`,
  rowCount
})

export const refreshRowsAction = (namespace, clearSelected = false) => ({
  type: `${namespace}/${REFRESH_ROWS}`,
  clearSelected: clearSelected
})

export const deselectRowsAction = (namespace, rowsIds) => ({
  type: `${namespace}/${DESELECT_ROWS}`,
  rowsIds: rowsIds
})

export const selectRowsAction = (namespace, rowsIds) => ({
  type: `${namespace}/${SELECT_ROWS}`,
  rowsIds: rowsIds
})

export const dispatchAction = (dispatch) => {
  return {
    dispatchAction: dispatch
  }
}

export const refreshSelectedRowsIdsAction = (namespace, dispatchFunction) => ({
  type: `${namespace}/${REFRESH_SELECTED_ROWS_IDS}`,
  dispatchFunction: dispatchFunction
})

export const RESET_ACTIONS = {
  updateColumnListAction: updateColumnListAction,
  updateFilterListUrlAction: updateFilterListUrlAction
}
