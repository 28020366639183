/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import BfReduxStateConstants from '../../../../common/BfReduxStateConstants'
import BfBasicCustomFilter from '../../custom/BfBasicCustomFilter'
import _ from '../../../../../services/utils/BfLodash'

class BfContentCategoryFilter extends BfBasicCustomFilter {
  static NAME = 'category'
  static NAMESPACE = BfReduxStateConstants.CONTENT_DATA_GRID
  static PATH = `filter.${BfContentCategoryFilter.NAME}`
  constructor(args) {
    super(BfContentCategoryFilter.PATH, BfContentCategoryFilter.NAME, {})
    this.siteID = args.siteID
  }

  processResponse = (res) => {
    const data = { values: [] }
    data.values = _.map(res.data, 'category').filter((category) => _.isNotEmpty(category))

    return data
  }

  getSearchURL = () => {
    return '/content/api/tasks/' + this.siteID + '/categories'
  }
}

export default BfContentCategoryFilter
