/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/
import _ from '../../../../services/utils/BfLodash'

import { createDropDownFilterItem } from '../common/bfBasicFilters/bfDropDownFilter/BfDropDownFilter'
import BfDropDownBasicFilter from '../common/bfBasicFilters/bfDropDownFilter/BfDropDownBasicFilter'

class ApPatchTypeFilter extends BfDropDownBasicFilter {
  static NAME = 'patchType'
  static PATH = `filter.${ApPatchTypeFilter.NAME}`
  constructor(data = {}) {
    super(ApPatchTypeFilter.PATH, ApPatchTypeFilter.NAME, data)
  }

  getItems = (searchString) => {
    const t = this.getT()
    const options = ['EXT_APP_UPDATES', 'OS_UPDATES', 'APP_UPDATES']

    let items = _.map(options, (patchType) => createDropDownFilterItem(patchType, t('TYPE_' + patchType)))

    if (searchString) {
      const regex = new RegExp(searchString, 'i')
      items = _.filter(items, (item) => {
        return item.label.match(regex)
      })
    }

    return items
  }
}

export default ApPatchTypeFilter
