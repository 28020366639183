/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import React, { Component } from 'react'
import { Button } from '@patron/patron-react/button'
import { Spinner } from '@patron/patron-react/spinner'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { map } from 'rxjs/operators'
import PropTypes from 'prop-types'
import BfConsole from 'loglevel'
import _ from '../../../services/utils/BfLodash'
import BfSaveReportButton from './BfSaveReportButton'
import BfFavoriteReportsDropdown from './BfFavoriteReportsDropdown'
import BfCurrentUserInfoService from '../../../services/user/BfCurrentUserInfoService'
import BfHttpClient from '../../../services/authClient/BfHttpClient'
import { bfTranslate } from '../../../services/i18n/bfI18Utils'
import { updateInlineReportUrlAction } from '../../datagrid/redux/BfFilterListUpdateURLAction'
import { BfWithReduxStoreConsumer } from '../../mainAppContainer/bfReduxStore/BfReduxStore'
import { createFilterManagerFromReduxState } from '../../datagrid/filters/BfFilterManager'
import './BfReportsStyle.scss'

class BfReportsBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      favoritesList: [
        {
          text: bfTranslate(this.props.t, 'defaultReport'),
          id: null,
          isDefault: true
        }
      ],
      currentReport: {},
      canUpdateReport: false,
      filtersMatchReport: true,
      updatingReports: false,
      userId: -1
    }
  }

  bfCurrentUserInfoService = new BfCurrentUserInfoService()
  http = new BfHttpClient()

  /**
   * updates the current report that propagates to the rest
   * of the report components
   * @param {object} reportObj a report object 
   * {
        id: INT
        name: STRING,
        ownerId: INT, (optional)
        filters: OBJ (optional)
      }
   * @param {boolean} newReport  flag for if it's a new report or not
   * @param {boolean} checkMatchingFilters flag to check if the current search filters match report filters
   */
  _updateCurrentReport = (reportObj, newReport = false, checkMatchingFilters = false) => {
    BfConsole.log('current report: ', JSON.stringify(reportObj))
    const currentReport = _.cloneDeep(reportObj)
    if (newReport) {
      currentReport.ownerId = _.clone(this.state.userId)
    }
    const canUpdateReport = this.state.userId === currentReport.ownerId
    this.setState({
      currentReport: currentReport,
      canUpdateReport: canUpdateReport,
      updatingReports: false
    })
    if (checkMatchingFilters) {
      // For checking filters in scenario of refreshing page with report loaded but different filters
      const reportSearchObj = currentReport.filters ? currentReport.filters : {}
      const urlSearchObj = this.props.searchObj
      this._checkForNewFiltersForReport(reportSearchObj, urlSearchObj)
    } else {
      this.setState({ filtersMatchReport: true })
    }
    const urlUpdate = { id: currentReport.id, ownerId: currentReport.ownerId }
    this.props.updateInlineReportUrlAction(this.props.namespace, {
      reportDetails: urlUpdate
    })
  }

  /**
   * updates list of reports for favorites dropdown
   * @param {array} favoritesList
   */
  _updateFavoritesList = (favoritesList) => {
    this.setState({
      favoritesList: favoritesList
    })
  }

  /**
   * updates the current selected report's filters
   */
  _updateReportFilters = () => {
    const newCurrentReport = _.cloneDeep(this.state.currentReport)
    const newFilters = _.cloneDeep(this.props.searchObj)
    delete newFilters.reportDetails
    newCurrentReport.filters = newFilters
    var postData = {
      id: newCurrentReport.id,
      filters: JSON.stringify(newFilters)
    }
    this.setState({
      updatingReports: true
    })
    const updateReportCall = this.http.post('/api/reports/editReport', postData)
    updateReportCall.subscribe(
      () => {
        BfConsole.log('Updated report filters successfully ')
        const indexFavorite = _.findIndex(this.state.favoritesList, (fav) => {
          return fav.id === postData.id
        })
        if (indexFavorite > -1) {
          const newFavoritesList = _.cloneDeep(this.state.favoritesList)
          newFavoritesList[indexFavorite].filters = postData.filters
          this._updateFavoritesList(newFavoritesList)
        }
        this._updateCurrentReport(newCurrentReport)
      },
      (error) => {
        BfConsole.error('Unable to update report filters. Error: ', error)
        this.setState({
          updatingReports: false
        })
      }
    )
  }

  /**
   * Runs comparisons between current report and the search params
   * to see if there is a change between the filters
   * @param {Object} reportSearchObj
   * @param {Object} newSearchObj
   */
  _checkForNewFiltersForReport(reportSearchObj, newSearchObj) {
    const isFiltersEqual = _.isEqual(newSearchObj.filter, reportSearchObj.filter)
    const isSearchEqual = _.isEqual(newSearchObj.search, reportSearchObj.search)
    const isSortEqual = _.isEqual(newSearchObj.sort, reportSearchObj.sort)
    this.setState({
      filtersMatchReport: isFiltersEqual && isSearchEqual && isSortEqual
    })
  }

  componentDidMount() {
    this.bfCurrentUserInfoService
      .get()
      .pipe(
        map((userInfo) => {
          if (userInfo.userPermission) {
            return userInfo.userPermission.UserID
          }
          throw new Error('Unable to grab user ID')
        })
      )
      .subscribe(
        (userId) => {
          this.setState({ userId: userId })
        },
        (error) => {
          BfConsole.error('Unable to get userId. Error: ', error)
        }
      )
  }

  componentDidUpdate(prevProps) {
    if (this.state.canUpdateReport && this.props.searchObj !== prevProps.searchObj) {
      const reportSearchObj = this.state.currentReport.filters ? this.state.currentReport.filters : {}
      const newSearchObj = this.props.searchObj
      this._checkForNewFiltersForReport(reportSearchObj, newSearchObj)
    }
  }

  render() {
    const { t, contentType, namespace } = this.props
    let updateButton, loadingSpinner
    let saveReportButtonText = bfTranslate(t, 'saveReport')
    if (this.state.canUpdateReport && !this.state.filtersMatchReport) {
      updateButton = (
        <Button className='hcl-float-left hcl-primary bar-button' disabled={this.state.updatingReports} onClick={() => this._updateReportFilters()}>
          {bfTranslate(t, 'update')}
        </Button>
      )
      saveReportButtonText = bfTranslate(t, 'saveNew')
    }
    if (this.state.updatingReports) {
      loadingSpinner = <Spinner className='hcl-float-left' small />
    }

    return (
      <React.Fragment>
        <BfFavoriteReportsDropdown
          contentType={contentType}
          namespace={namespace}
          currentReport={this.state.currentReport}
          favoritesList={this.state.favoritesList}
          updatingReports={this.state.updatingReports}
          updateCurrentReport={this._updateCurrentReport}
          updateFavorites={this._updateFavoritesList}
        />
        <span className='bfReportsBar'>
          {updateButton}
          <BfSaveReportButton
            contentType={contentType}
            updateCurrentReport={this._updateCurrentReport}
            saveButtonText={saveReportButtonText}
            updatingReports={this.state.updatingReports}
          />
          {loadingSpinner}
        </span>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const bfFilterManager = createFilterManagerFromReduxState(state, ownProps.namespace)
  const searchObj = bfFilterManager.getSearch()
  return {
    searchObj
  }
}

BfReportsBar.propTypes = {
  contentType: PropTypes.string.isRequired,
  namespace: PropTypes.string.isRequired
}

const BfReportsBarReduxComponent = connect(mapStateToProps, {
  updateInlineReportUrlAction
})(withTranslation()(BfReportsBar))

export default BfWithReduxStoreConsumer(BfReportsBarReduxComponent)
