/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2021
// All Rights Reserved
*/
import React from 'react'
import { Field } from 'formik'
import './BfForm.scss'
import { NumberInput } from '@patron/patron-react/numberinput'
import _ from '../../services/utils/BfLodash'
import PropTypes from 'prop-types'

const BfNumberInput = (props) => {
  const {
    containerClass = '',
    disabled = false,
    helperText = '',
    id,
    label = '',
    labelClass = '',
    name,
    max = null,
    min = null,
    step = 1,
    subLabel = '',
    required = false,
    defaultValue,
    onChange,
    ...rest
  } = props

  return (
    <div className='hcl-form-group'>
      <Field name={name} {...rest}>
        {({ field, form, meta }) => (
          <div className={containerClass}>
            {label && (
              <div className='bfInputLabel'>
                <label htmlFor={id} className={labelClass || 'hcl-label'}>
                  {label} {required ? <i className='bfRequired'> *</i> : ''}
                </label>
              </div>
            )}
            {subLabel && <div className='hcl-helper-text hcl-text-left'>{subLabel}</div>}
            {meta.touched && _.isNotEmpty(meta.error) && <div className='hcl-error-msg hcl-text-left'>{meta.error}</div>}
            <NumberInput
              defaultValue={defaultValue}
              disabled={disabled}
              helperText={helperText}
              id={id || name}
              max={max}
              min={min}
              onChange={(value) => {
                form.setFieldValue(name, value)
                form.setFieldTouched(name, true, false)
                onChange && onChange(value)
              }}
              required={required}
              step={step}
            />
          </div>
        )}
      </Field>
    </div>
  )
}

BfNumberInput.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  subLabel: PropTypes.string,
  helperText: PropTypes.string,
  inputClass: PropTypes.string,
  labelClass: PropTypes.string,
  containerClass: PropTypes.string,
  name: PropTypes.string.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  required: PropTypes.string
}

export default BfNumberInput
