/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import React from 'react'
import './BfAddColumnStyle.scss'
import { Button } from '@patron/patron-react/button'
import Tooltip from '@patron/patron-react/export/atoms/Tooltip/Tooltip'
import { withTranslation } from 'react-i18next'
import { bfTranslate } from '../../../services/i18n/bfI18Utils'

class BfAddColumn extends React.Component {
  state = {
    showModal: false,
    treeOverlayStatus: false,
    treeTargetElement: null,
    updateColumns: false
  }

  showTreeOverlay = (e) => {
    this.setState({
      treeOverlayStatus: !this.state.treeOverlayStatus,
      treeTargetElement: e.currentTarget
    })
  }

  toggleTreeOverlay = (status) => {
    if (!status) {
      this.setState({
        treeOverlayStatus: status,
        treeTargetElement: null
      })
    }
  }

  _showModal = () => {
    this.setState({ showModal: true, treeOverlayStatus: false })
  }

  _closeModal = () => {
    this.setState({ showModal: false })
  }

  _renderModal = () => {
    const { addColumnButtonCustomModal } = this.props
    return (
      <React.Fragment>
        {React.cloneElement(addColumnButtonCustomModal, {
          onCloseModal: this._closeModal
        })}
      </React.Fragment>
    )
  }

  render() {
    const { t } = this.props
    return (
      <React.Fragment>
        {this.state.showModal && this._renderModal()}
        {/* TODO: Patronus temporary waiting for offical fix */}
        <div className='hcl-overlay-wrapper'>
          <Tooltip direction='top' content={bfTranslate(t, 'ManageColumn')}>
            <Button className='BfButtonAdd bf-action-icon' disabled={false} small={false} onClick={this._showModal} type='ghost'>
              <i className='p-hclsw p-hclsw-table-settings icon-in-tad-button' />
              <span className='label-in-tad-button'>{bfTranslate(t, 'ManageColumn')}</span>
            </Button>
          </Tooltip>
        </div>
      </React.Fragment>
    )
  }
}

export default withTranslation()(BfAddColumn)
