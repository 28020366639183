/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import React from 'react'

import PropTypes from 'prop-types'

import BfHelpers from '../../../../services/utils/BfHelpers'
import _ from '../../../../services/utils/BfLodash'
import './BfChartTable.scss'

function BfChartTable(props) {
  const { headerLabel, columnData, rowLabels, onTableClick } = props
  const helpers = new BfHelpers()

  const getColumnTotal = function (group) {
    return _.reduce(
      group,
      function (aggr, count) {
        if (typeof count !== 'number') {
          return aggr
        }
        return aggr + count
      },
      0
    )
  }

  const isColumnTotalZero = function (col) {
    return getColumnTotal(col) === 0
  }

  const isRowTotalZero = function (row) {
    const rowTotal = columnData.reduce((total, col) => {
      if (!col[row.key]) return total
      return col[row.key] + total
    }, 0)
    return rowTotal === 0
  }

  const isUndefinedOrZero = function (value) {
    return _.isUndefined(value) || value === 0
  }

  return (
    <table className='bfChartTable'>
      <thead>
        <tr>
          <th>{headerLabel}</th>
          {columnData.map((col) => {
            return (
              <th
                key={col.description}
                title={col.description}
                onClick={() => (isColumnTotalZero(col) ? undefined : onTableClick(null, col))}
                className={isColumnTotalZero(col) ? 'defaultCursor' : undefined}
              >
                {getColumnTotal(col)}
              </th>
            )
          })}
        </tr>
      </thead>

      <tbody>
        {rowLabels.map((rowObj) => {
          return (
            <tr key={rowObj.key} style={{ backgroundColor: helpers.hexToRgba(rowObj.color, 0.2) }}>
              <td onClick={() => !isRowTotalZero(rowObj) && onTableClick(rowObj)} className={isRowTotalZero(rowObj) ? 'defaultCursor' : undefined}>
                <span style={{ backgroundColor: rowObj.color }} className='bfColorIndicatorLeft' />
                <span title={rowObj.label}>{rowObj.label}</span>
              </td>
              {columnData.map((col) => {
                return (
                  <td
                    key={rowObj.key + col.description}
                    onClick={() => !isUndefinedOrZero(col[rowObj.key]) && onTableClick(col, rowObj)}
                    className={isUndefinedOrZero(col[rowObj.key]) ? 'defaultCursor' : undefined}
                  >
                    {col[rowObj.key] || 0}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

BfChartTable.propTypes = {
  onTableClick: PropTypes.func,
  columnData: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      filters: PropTypes.object
      // the rest of the keys correspond to the row labels
    })
  ).isRequired,
  rowLabels: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      key: PropTypes.string,
      color: PropTypes.string, // ex '#264d60'
      filters: PropTypes.object
    })
  ).isRequired,
  headerLabel: PropTypes.string // Bolded label for top row containing column totals
}

export default BfChartTable
