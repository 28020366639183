/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import BfConsole from 'loglevel'
import _ from '../../../services/utils/BfLodash'
import { BfDataGridComponentWithContext } from '../../../components/datagrid/BfDataGridContext'
import { Overflowmenu, MenuItem } from '@patron/patron-react/overflowmenu'
import HTML_COLUMN_TYPES, { getFilterFromFilterPath, HTMLFiltersByNames } from '../filters/BfHTMLFiltersMap'
import Button from '@patron/patron-react/export/atoms/Button/Button'
import { forkJoin, isObservable, of } from 'rxjs'
import { toArray } from 'rxjs/operators'
import { bfTranslate } from '../../../services/i18n/bfI18Utils'
import BfUnknowFilter from '../filters/BfUnknowFilter'
import { createFilterManagerFromReduxState } from '../filters/BfFilterManager'
import { dispatchAction } from '../redux/BfFilterListUpdateURLAction'
import BfResetColumnsService from '../services/BfResetColumnsService'
import BfColumnSelectionFilter from '../filters/common/bfHeaderSelection/BfColumnSelectionFilter'
import { createCustomPropertyColumn } from '../bfAddColumn/BfAddColumnHelper'
import BfWithMessageProvider from '../../common/bfMessage/BfWithMessageProvider'
import BfCustomOption from '../../../services/customoptions/BfCustomOption'

const BfFiltersCheck = (props) => {
  const { t } = useTranslation()
  const [listItems, setListItems] = useState({})
  const [prevActiveFiltersCount, setPrevActiveFiltersCount] = useState(0)
  const [maxFiltersNumber, setMaxFiltersNumber] = useState(5)

  const _consumeAllActiveFilters = (urlHandler) => {
    const activeFilters = []
    const { visibleColumns } = props
    _.each(visibleColumns, (visibleColumn) => {
      if (_.isDefined(HTMLFiltersByNames[visibleColumn.columnHtmlType])) {
        const filter = HTMLFiltersByNames[visibleColumn.columnHtmlType].instance(visibleColumn)

        if (_.isDefined(filter)) {
          activeFilters.push(filter)
          filter.buildFromURLHandler(urlHandler, true)
        }
      }
    })
    return activeFilters
  }

  const _buildFilterPath = (filterKey, key) => {
    return `${filterKey}.${key}`
  }

  const _getUnresolvedFilter = (urlHandler, filterKey, key) => {
    let unresolvedFilter
    let filter
    const filterPath = _buildFilterPath(filterKey, key)
    const { filterObject, filterTypeHTML } = getFilterFromFilterPath(filterPath, props.id)
    if (_.isNotNull(filterObject)) {
      if (_.isDefined(filterObject.instanceFromUrl)) {
        filter = filterObject.instanceFromUrl(urlHandler)
      } else {
        filter = filterObject.instance(urlHandler.getSearchPath(filterPath))
      }
      if (filter && filter.isApplied()) {
        unresolvedFilter = { filter: filter, filterTypeHTML: filterTypeHTML }
      }
    } else {
      /* unresolvedFilter = new BfUnknowFilter(filterKey, key) */
      BfConsole.warn('Warning: Unknown filter found')
    }
    return unresolvedFilter
  }

  const _findUnresolvedColumnbyFilterHTML = (headers, filterTypeHTML) => {
    const column = _.find(headers, (header) => {
      return header.columnHtmlType === filterTypeHTML
    })
    return column
  }

  const _buildUnResolvedFilters = (urlHandler) => {
    const searchObj = urlHandler.getSearch()
    const filterKeys = ['filter', 'search']
    const unResolvedFilters = []
    _.each(filterKeys, (filterKey) => {
      if (searchObj[filterKey]) {
        _.each(_.keys(searchObj[filterKey]), (key) => {
          const unresolvedFilter = _getUnresolvedFilter(urlHandler, filterKey, key)
          unresolvedFilter && unResolvedFilters.push(unresolvedFilter)
        })
      }
    })
    return unResolvedFilters
  }

  const _createFilterObservable = (unResolvedFilter) => {
    const { filter } = unResolvedFilter
    const name = filter.getName()
    const isObservableName = isObservable(name)
    if (!isObservableName) {
      return of({
        name: name,
        id: name,
        field: unResolvedFilter.field
      })
    }
    const observableName = name

    return observableName.pipe(toArray())
  }

  const _createNameFilterObservable = (unResolvedFilter) => {
    const { filterTypeHTML } = unResolvedFilter
    const unResolvedColumn = _findUnresolvedColumnbyFilterHTML(props.headers, filterTypeHTML)
    if (_.isDefined(unResolvedColumn)) {
      return of({
        name: unResolvedColumn.label,
        id: unResolvedColumn.label,
        field: unResolvedColumn.field
      })
    }
    return _createFilterObservable(unResolvedFilter)
  }

  const _buildUnresolvedFiltersNames = (unResolvedFilters) => {
    return _.map(unResolvedFilters, (unResolvedFilter) => {
      return _createNameFilterObservable(unResolvedFilter)
    })
  }

  const _addNameAndIds = (listItems, namesAndIds) => {
    _.each(namesAndIds, (nameAndId) => {
      if (_.isArray(nameAndId)) {
        _addNameAndIds(listItems, nameAndId)
      } else {
        listItems[nameAndId.id.toString()] = nameAndId
      }
    })
  }

  const _buildUnresolvedFiltersListItems = (unResolvedFilters) => {
    const observableNames = _buildUnresolvedFiltersNames(unResolvedFilters)
    BfConsole.debug(`Unresolved filters are ${_.size(observableNames)}`)
    if (_.size(observableNames) > 0) {
      forkJoin(observableNames).subscribe({
        next: (namesAndIds) => {
          setListItems((listItems) => {
            listItems = {}
            _addNameAndIds(listItems, namesAndIds)
            return listItems
          })
        }
      })
    } else {
      setListItems({})
    }
  }

  useEffect(() => {
    const bfCustomOption = new BfCustomOption()
    bfCustomOption.getOptionValue('/framework/maxfiltersnumber').subscribe({
      next: (optionValue) => {
        setMaxFiltersNumber(parseInt(_.isDefined(optionValue) ? optionValue : 5))
      }
    })
  }, [])

  const _getFiltersCount = (filters) => {
    return _.reduce(
      filters,
      (count, filter) => {
        count += filter.getFilterCount()
        return count
      },
      0
    )
  }

  const _notifyAppliedFiltersCount = (activeFilters, unResolvedFilters) => {
    const allFiltersCount = _getFiltersCount(activeFilters) + _getFiltersCount(unResolvedFilters)
    if (allFiltersCount !== prevActiveFiltersCount) {
      BfConsole.debug('Active filters count are  ', allFiltersCount)
      setPrevActiveFiltersCount(allFiltersCount)

      if (allFiltersCount > maxFiltersNumber) {
        props.sendDetailedMessage('', '', bfTranslate(t, 'maxFilterApplied'), 'warning')
      }
    }
  }

  useEffect(() => {
    const { bfFilterManager } = props
    const urlHandler = bfFilterManager.getUrlHandler()
    BfConsole.debug('BfFiltersCheck search obj is  ', JSON.stringify(urlHandler.getSearch()))

    const consumedFilters = _consumeAllActiveFilters(urlHandler)
    const activeFilters = bfFilterManager.getActiveFilters(consumedFilters)
    const unResolvedFilters = _buildUnResolvedFilters(urlHandler)
    _notifyAppliedFiltersCount(
      activeFilters,
      _.map(unResolvedFilters, (unresolvedFilter) => unresolvedFilter.filter)
    )
    _buildUnresolvedFiltersListItems(unResolvedFilters)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.visibleColumns])

  const _getUnknownListItem = (unknowFilter) => {
    _.extend(unknowFilter, { separator: true })
    _.extend(unknowFilter, { className: 'bf-missing-filter-entry' })
    return [
      {
        className: 'bf-missing-filter-header',
        name: bfTranslate(t, 'unknownFilter'),
        separator: true,
        id: 'unknownFilter'
      }
    ]
  }

  const _onClickItemList = (itemList) => {
    BfConsole.debug('itemList is ', itemList)
    if (itemList.id === 'missingFilters') {
      return
    }
    const toAddCustomProperties = []
    const toAddDefaultProperties = {}
    if (itemList.isCustomProperty) {
      const contentKey = _.pick(itemList, ['siteID', 'contentID', 'propertyID'])
      const customColum = createCustomPropertyColumn(itemList.name, contentKey)

      customColum.columnHtml = HTML_COLUMN_TYPES.customColumnHTML(contentKey)
      toAddCustomProperties.push(customColum)
    } else {
      toAddDefaultProperties[itemList.field] = true
    }
    const bfResetColumnsService = new BfResetColumnsService(
      props.dispatchAction,
      props.parentId,
      toAddDefaultProperties,
      toAddCustomProperties,
      props.bfColumnSelectionFilter,
      props.bfCustomPropertiesFilter
    )
    bfResetColumnsService.changeColumns(false)
  }

  const _getItemsList = () => {
    if (_.isEmpty(listItems)) {
      return []
    }
    const unknowFilter = _.get(listItems, BfUnknowFilter.NAME)
    const knownFilters = _.omit(listItems, BfUnknowFilter.NAME)
    let _listItems = _.values(knownFilters)
    const firstItem = _.first(_listItems)
    if (_.isDefined(firstItem)) {
      _listItems = _.map(_listItems, (listItem) => {
        return _.extend(listItem, { className: 'bf-missing-filter-entry' })
      })
      _.extend(firstItem, { separator: true })
    }
    return _.concat(
      _.concat(
        _.isEmpty(_listItems)
          ? []
          : [
              {
                className: 'bf-missing-filter-header',
                name: bfTranslate(t, 'missingFilters', {
                  columns: _.size(_listItems),
                  count: _.size(_listItems)
                }),
                id: 'missingFilters'
              }
            ],
        _listItems
      ),
      _.isEmpty(unknowFilter) ? [] : _getUnknownListItem(unknowFilter)
    )
  }

  const _getItemsListElements = () => {
    const _listItems = _getItemsList()
    return _.map(_listItems, (_listItem) => {
      return (
        <MenuItem className={_listItem.className} item={_listItem} separator={_listItem.separator} onClick={_onClickItemList}>
          {_listItem.id !== 'missingFilters' && <i className='p-hclsw p-hclsw-add bf-add-column-filter' />}
          {_listItem.name}
        </MenuItem>
      )
    })
  }

  return (
    _.isNotEmpty(listItems) && (
      <Overflowmenu
        attachElementToBody={false}
        className='bf-missing-filters-container'
        customIcon={null}
        onClick={_onClickItemList}
        customTemplate={
          <Button className='BfButtonResetFilters' type='ghost'>
            <i className='p-hclsw p-hclsw-hide selected BfButtonResetFiltersIcon bf-warning' />
            {_.size(listItems)}
          </Button>
        }
        direction='bottom-left'
        ellipsisType='vertical'
        scrollListner={false}
      >
        {_getItemsListElements()}
      </Overflowmenu>
    )
  )
}

const mapStateToProps = (state, ownProps) => {
  const bfFilterManager = createFilterManagerFromReduxState(state, ownProps.id)
  const bfCustomPropertiesFilter = bfFilterManager.getCustomPropertiesFilter()

  return {
    parentId: ownProps.id,
    bfFilterManager: bfFilterManager,
    bfColumnSelectionFilter: new BfColumnSelectionFilter(state[ownProps.id].columns?.cols),
    bfCustomPropertiesFilter: bfCustomPropertiesFilter
  }
}

const BfFiltersCheckWithRedux = connect(mapStateToProps, dispatchAction)(BfFiltersCheck)

export default BfDataGridComponentWithContext(BfWithMessageProvider(BfFiltersCheckWithRedux))
