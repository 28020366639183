/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { BfDataGridComponentWithContext } from '../../../BfDataGridContext'
import { updateSearchListUrlAction, updateFilterListUrlAction } from '../../../redux/BfFilterListUpdateURLAction'
import { createFilterManagerFromReduxState } from '../../BfFilterManager'
import BfSearchWithFilter from './BfSearchWithFilter'

const BfSearchWithReduxAction = (WrappedComponent) => {
  return class extends Component {
    _makeSearch = () => {
      const searchApi = this.props.filter.doFilter ? this.props.updateFilterListUrlAction : this.props.updateSearchListUrlAction
      searchApi(this.props.id, this.props.filter.get())
    }

    render() {
      return <WrappedComponent onSearch={this._makeSearch} filter={this.props.filter} {...this.props} />
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const bfFilterManager = createFilterManagerFromReduxState(state, ownProps.id)

  return {
    filter: bfFilterManager.getFilterFromURLByName(ownProps.filterName, ownProps.args)
  }
}

const BfSearchWithRedux = connect(mapStateToProps, {
  updateSearchListUrlAction,
  updateFilterListUrlAction
})(BfSearchWithReduxAction(BfSearchWithFilter))

export default BfDataGridComponentWithContext(BfSearchWithRedux)
