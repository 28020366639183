/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2021
// All Rights Reserved
*/

import { useState, useEffect } from 'react'
import BfHttpClient from '../authClient/BfHttpClient'
import _ from '../utils/BfLodash'
import BfConsole from 'loglevel'

const useBfDevicesExist = (request) => {
  const [realTargets, setRealTargets] = useState({})
  const [invalidTargets, setInvalidTargets] = useState([])
  const [status, setStatus] = useState('')

  useEffect(() => {
    const _calculateInvalidTargetsById = (targetsFound, uniqueInputTargets, id) => {
      const _getConditionForIpAddresses = (targetFound, uniqueInputTarget) => _.includes(_.get(targetFound, id).split(', '), uniqueInputTarget)
      const _getDefaultCondition = (targetFound, uniqueInputTarget) => _.trim(_.toUpper(_.get(targetFound, id))) === _.toUpper(uniqueInputTarget)
      const fnCondition = id === 'ipAddresses' ? _getConditionForIpAddresses : _getDefaultCondition
      return _.filter(uniqueInputTargets, (uniqueInputTarget) => !_.some(targetsFound, (targetFound) => fnCondition(targetFound, uniqueInputTarget)))
    }

    const _handleInvalidTargets = (targetsFound, uniqueInputTargets) => {
      const _invalidTargets = _calculateInvalidTargetsById(targetsFound, uniqueInputTargets, request.id)
      BfConsole.debug('Invalid targets are ', _invalidTargets)

      setInvalidTargets(_invalidTargets)
    }

    if (_.isNotEmpty(request.type)) {
      const { type, description } = request
      const bfHttpClient = new BfHttpClient()
      const splittedDescription = _.split(_.replace(description, /\n/g, ''), ',')
      const uniqueInputTargets = _.chain(splittedDescription).map(_.trim).uniq().compact().value()
      setStatus('loading')
      bfHttpClient.get(`/devices/exists/${type}`, { inputText: uniqueInputTargets.join(',') }).subscribe({
        next: (response) => {
          _handleInvalidTargets(response.values, uniqueInputTargets)
          setRealTargets(response.values)
        },
        error: () => setStatus('error'),
        complete: () => setStatus('complete')
      })
    }
  }, [request])

  return { realTargets, invalidTargets, status }
}

export default useBfDevicesExist
