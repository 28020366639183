/*
// Licensed Materials - Property of HCL
// © Copyright HCL Technologies Limited 2001, 2021
// All Rights Reserved
*/

import React, { Component } from 'react'
import BfDataGridTable from '../datagrid/BfDataGridTable'
import BfPreappsService from '../../services/preapps/BfPreappsService'
import BfReduxStateConstants from '../common/BfReduxStateConstants'
import { map } from 'rxjs/operators'
import BfShowRelevantItems from '../devices/BfShowRelevantItems'
import _ from '../../services/utils/BfLodash'

class BfPreappsGrid extends Component {
  bfPreappsService = new BfPreappsService()

  constructor(props) {
    super(props)

    this.highlightedRows = {}

    this.headers = [
      {
        label: 'ID',
        field: 'id',
        isDefault: true,
        styles: { left: '40px' },
        width: '200px'
      },
      {
        label: 'App Name',
        field: 'appName',
        isDefault: true,
        styles: { left: '40px' },
        width: '200px',
        columnHtmlType: 'searchPolicyGroupsHTML'
      },
      {
        label: 'OS',
        field: 'os',
        isDefault: true,
        styles: { left: '40px' },
        width: '200px',
        columnHtmlType: 'simpleOSAppsHTML'
      },
      {
        label: 'MDM Server',
        field: 'mdmServer',
        isDefault: true,
        styles: { left: '40px' },
        width: '200px'
      }
    ]
  }

  _getPreapps = (urlOption, visibleColumns, options) => {
    return this.bfPreappsService.getPreapps(urlOption).pipe(
      map((data) => {
        if (data.totalCount === undefined) {
          data.totalCount = 0
        }
        if (data.rows === undefined) {
          data.rows = []
        }
        return data
      })
    )
  }

  render() {
    const {
      showRelevantFilter,
      showPaginationIcons = true,
      options = {
        updateUrl: false,
        showDeployButton: false,
        showSelectedOnly: true
      },
      customActionBar,
      id = null
    } = this.props

    return (
      <BfDataGridTable
        selectableRow
        getRows={this._getPreapps}
        headers={this.headers}
        id={id || BfReduxStateConstants.PREAPPS}
        customActionBar={customActionBar}
        onRowSelected={this.props.onRowSelected}
        onRowsSelected={this.props.onRowsSelected}
        preSelectedItems={this.props.selectedPackages}
        showPaginationIcons={showPaginationIcons}
        paginationBarComponents={
          _.isEmpty(showRelevantFilter)
            ? null
            : [<BfShowRelevantItems key='showRelevantItemsDevices' showRelevantFilter={showRelevantFilter} id={this.id} />]
        }
        showSummary={this.props.showSummary}
        options={options}
      />
    )
  }
}

export default BfPreappsGrid
