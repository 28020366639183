/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/
import React, { Component } from 'react'
import { Search } from '@patron/patron-react/search'
import BfDebounceSubject from '../../../../common/BfDebounceSubject'

import _ from '../../../../../services/utils/BfLodash'
import { withTranslation } from 'react-i18next'
import { bfTranslate } from '../../../../../services/i18n/bfI18Utils'
import BfSimpleSearch from '../../../../common/BfSimpleSearch'

class BfSearch extends Component {
  bfDebounceSubject = new BfDebounceSubject()

  constructor(props) {
    super(props)
    this.bfDebounceSubject.subscribe((query) => this.props.onSearch(query))
  }

  _onChange = (text) => {
    this.bfDebounceSubject.next(text)
  }

  _onReset = () => {
    this.props.onSearch('')
  }

  _renderSearch = () => {
    const { searchText, iconTheme = 'default', type = 'clickable' } = this.props

    return (
      <Search
        ariaLabel='Search'
        defaultValue={searchText}
        iconTheme={iconTheme}
        onChange={this._onChange}
        size='default'
        theme='default'
        type={type}
      />
    )
  }

  _renderTextInput = () => {
    const { searchText } = this.props
    const placeHolder = bfTranslate(this.props.t, 'placeHolderSearch')
    const textValue = _.isDefined(searchText) ? searchText : ''
    return (
      <div className='bf-flex-control-container hcl-form-control bf-control'>
        <BfSimpleSearch
          type='text'
          className='bf-search-input-text'
          placeholder={placeHolder}
          autoComplete='off'
          onChange={(ev) => this._onChange(ev.target.value)}
          value={textValue}
          onReset={this._onReset}
        />
      </div>
    )
  }

  render() {
    const { type = 'clickable' } = this.props
    if (type === 'input') {
      return this._renderTextInput()
    } else {
      return this._renderSearch()
    }
  }
}

export default withTranslation()(BfSearch)
