/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2021
// All Rights Reserved
*/

import React, { useEffect, useState } from 'react'
import Button from '@patron/patron-react/export/atoms/Button/Button'
import { updateFilterListUrlAction } from '../datagrid/redux/BfFilterListUpdateURLAction'
import { useTranslation } from 'react-i18next'
import { bfTranslate } from '../../services/i18n/bfI18Utils'
import { useDispatch } from 'react-redux'

import { Tooltip } from '@patron/patron-react/tooltip'

const BfShowRelevantItems = (props) => {
  const { showRelevantFilter, id: namespace } = props
  const [checked, setChecked] = useState(showRelevantFilter.default === 'checked')
  /* const search = useSelector((state) => getDataGridSearchPath(state, namespace)) */
  const dispatch = useDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    const filter = checked ? showRelevantFilter.checked : showRelevantFilter.unchecked
    dispatch(updateFilterListUrlAction(namespace, filter))
  }, [dispatch, namespace, checked, showRelevantFilter])

  /* useEffect(() => {
    if (_.isEmpty(search)) return
    const bfFilterManager = new BfFilterManager(search.urlSearch, search.options?.updateUrl)
    const bfShowRelevantFilter = bfFilterManager.getFilterFromURLByName(showRelevantFilter.filterPath)
    BfConsole.log('bfShowRelevantFilter is ', bfShowRelevantFilter)
    setChecked(bfShowRelevantFilter.isApplied())
  }, [search, showRelevantFilter.filterPath]) */

  const onToggleShowRelevant = () => {
    setChecked((prevChecked) => !prevChecked)
  }

  return (
    <Tooltip content={<div>{checked ? bfTranslate(t, 'showNoRelevant') : bfTranslate(t, 'hideNoRelevant')}</div>} direction='top' type='definition'>
      <Button id={`${namespace}-show-relevant`} className='bf-action-icon' type='ghost' onClick={onToggleShowRelevant}>
        {checked ? <i className='p-hclsw p-hclsw-hide icon-in-tad-button' /> : <i className='p-hclsw p-hclsw-show icon-in-tad-button' />}
        <span className='label-in-tad-button'>{bfTranslate(t, 'noRelevant')}</span>
      </Button>
    </Tooltip>
  )
}
export default BfShowRelevantItems
