/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2021
// All Rights Reserved
*/

import BfHttpClient from '../authClient/BfHttpClient'
import BfCachedRequest from '../utils/BfCachedRequest'
import { map } from 'rxjs/operators'

/**
 * Provides functionalities to fetch patch data
 */
class BfPatchesService {
  bfCachedRequest = new BfCachedRequest(true)
  http = new BfHttpClient()

  /**
   * Triggers http request to fetch patch data from server
   * @method
   * @param {Object} options Pagination and Sorting options
   * @returns {Promise} Patch data
   */
  getPatches(options = {}) {
    return this.http.get('/patch/api/next/patches', options).pipe(
      map((data) => {
        return { totalCount: data.count, rows: data.data }
      })
    )
  }
}

export default BfPatchesService
