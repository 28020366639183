/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import BfDropDownBasicFilter from '../../common/bfBasicFilters/bfDropDownFilter/BfDropDownBasicFilter'

class BfLockStatusFilter extends BfDropDownBasicFilter {
  static NAME = 'lockStatus'
  static PATH = `filter.${BfLockStatusFilter.NAME}`

  constructor(data = {}) {
    super(BfLockStatusFilter.PATH, BfLockStatusFilter.NAME, data)
  }

  getItems = () => {
    const res = {
      values: [
        {
          id: 'Locked',
          text: 'Yes'
        },
        {
          id: 'Unlocked',
          text: 'No'
        }
      ]
    }
    return res.values
  }
}

export default BfLockStatusFilter
