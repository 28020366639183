/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import BfSearchBasicFilter from './BfSearchBasicFilter'

class BfSearchFilter extends BfSearchBasicFilter {
  static PATH = 'search'
  static NAME = 'search'
  constructor(query) {
    super(BfSearchFilter.PATH, BfSearchFilter.NAME, query)
  }
}

export default BfSearchFilter
