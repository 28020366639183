/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import BfBasicFilter from '../../BfBasicFilter'

class BfDeploymentStateCountFilter extends BfBasicFilter {
  static NAME = 'deploymentsCount'
  static PATH = `filter.${BfDeploymentStateCountFilter.NAME}`

  constructor(data = {}) {
    super(BfDeploymentStateCountFilter.PATH, BfDeploymentStateCountFilter.NAME)
    this[BfDeploymentStateCountFilter.NAME] = data
  }
}

export default BfDeploymentStateCountFilter
