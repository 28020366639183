/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2021
// All Rights Reserved
*/

import BfDateRangeBasicFilter from '../../common/bfBasicFilters/bfDateRangeFilter/BfDateRangeBasicFilter'

class InsightsAttributeLastModifiedFilter extends BfDateRangeBasicFilter {
  static NAME = 'attributeLastModified'
  static PATH = `filter.${InsightsAttributeLastModifiedFilter.NAME}`
  constructor(data = {}) {
    super(InsightsAttributeLastModifiedFilter.PATH, InsightsAttributeLastModifiedFilter.NAME, data)
  }
}

export default InsightsAttributeLastModifiedFilter
