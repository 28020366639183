/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2021
// All Rights Reserved
*/

import React, { useState, useRef } from 'react'
import { Field } from 'formik'
import PropTypes from 'prop-types'
import './BfForm.scss'

import { InlineEdit } from '@patron/patron-react/inlineedit'
import { TextInput } from '@patron/patron-react/textinput'
import { Tag } from '@patron/patron-react/tag'

import { ReactComponent as AddCircleIcon } from './add-circle.svg'
import _ from '../../services/utils/BfLodash'

import './BfInputTags.scss'

function BfInputTags(props) {
  const { disabled = false, id, name, label = '', subLabel = '', containerClass = '', inputClass, labelClass = '', required } = props

  const [isEditing, setEditing] = useState(false)
  const inputTagContainerRef = useRef()

  const addFieldValue = (form, field, value) => {
    if (field.value) {
      form.setFieldValue(name, [...field.value, value])
    } else {
      form.setFieldValue(name, [value])
    }
  }

  const createTags = (form, field, includeIcon) => {
    return (
      field.value && (
        <div ref={inputTagContainerRef} className='bfInputTagContainer'>
          {field.value.map((item) => {
            return (
              <Tag
                closable
                onClick={(e) => {
                  e.stopPropagation()
                  const newItems = field.value.filter((option) => {
                    return option !== item
                  })
                  form.setFieldValue(name, newItems)
                  form.setFieldTouched(name, true)
                }}
                key={`tag-${name}-${item}`}
                type='secondary'
                disabled={disabled}
              >
                {item}
              </Tag>
            )
          })}
          {includeIcon && <AddCircleIcon className='bfAddCircleIcon' />}
        </div>
      )
    )
  }

  return (
    <div className='hcl-form-group bfInputTags'>
      <Field name={name}>
        {({ field, form, meta }) => (
          <div className={containerClass}>
            {label && (
              <div className='bfInputLabel'>
                <label htmlFor={id || name} className={labelClass}>
                  {label} {required ? <i className='bfRequired'> *</i> : ''}
                </label>
              </div>
            )}
            {subLabel && <div className='hcl-helper-text hcl-text-left'>{subLabel}</div>}
            {meta.touched && _.isNotEmpty(meta.error) && <div className='hcl-error-msg hcl-text-left'>{meta.error}</div>}
            {!field.value || field.value.length === 0 || isEditing ? (
              <div className='bfInlineContainer'>
                {createTags(form, field)}
                <div className={inputClass}>
                  {!isEditing ? (
                    <TextInput
                      disabled={disabled}
                      className='hcl-overlay-wrapper'
                      onFocus={() => {
                        setEditing(true)
                      }}
                    />
                  ) : (
                    <InlineEdit
                      onTextUpdate={(value) => {
                        if (!field.value || !field.value.includes(value)) {
                          addFieldValue(form, field, value)
                          if (inputTagContainerRef.current) {
                            inputTagContainerRef.current.scrollTop = inputTagContainerRef.current.scrollHeight
                          }
                        }
                        setEditing(true)
                      }}
                      onBlur={() => {
                        if (inputTagContainerRef.current) {
                          inputTagContainerRef.current.scrollTop = inputTagContainerRef.current.scrollHeight
                        }
                        setEditing(false)
                        form.setFieldTouched(name, true)
                      }}
                    >
                      <TextInput disabled={disabled} />
                    </InlineEdit>
                  )}
                </div>
              </div>
            ) : (
              <div
                className='bfInlineWrapper'
                onClick={() => {
                  setEditing(true)
                }}
                tabIndex='0'
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setEditing(true)
                  }
                }}
              >
                {createTags(form, field, true)}
              </div>
            )}
          </div>
        )}
      </Field>
    </div>
  )
}

BfInputTags.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  subLabel: PropTypes.string,
  inputClass: PropTypes.string,
  labelClass: PropTypes.string,
  containerClass: PropTypes.string,
  name: PropTypes.string.isRequired,
  required: PropTypes.string
}

export default BfInputTags
