/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { BfDataGridComponentWithContext } from '../../../../BfDataGridContext'
import { updateFilterListUrlAction } from '../../../../redux/BfFilterListUpdateURLAction'
import { createFilterManagerFromReduxState } from '../../../BfFilterManager'
import BfCheckBoxTreeFilterInOverlay from './BfCheckBoxTreeFilterInOverlay'
/**
 * Creates Pagination functionality for grid table
 */
const BfCheckBoxTreeWithReduxAction = (WrappedComponent) => {
  return class extends Component {
    _onChange = (data) => {
      this.props.updateFilterListUrlAction(this.props.id, data)
    }

    render() {
      const { filter } = this.props
      return <WrappedComponent filter={filter} onChange={this._onChange} />
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const bfFilterManager = createFilterManagerFromReduxState(state, ownProps.id)

  return {
    filter: bfFilterManager.getFilterFromURLByName(ownProps.filterName)
  }
}
const BfCheckBoxTreeWithRedux = connect(mapStateToProps, {
  updateFilterListUrlAction
})(BfCheckBoxTreeWithReduxAction(BfCheckBoxTreeFilterInOverlay))

export default BfDataGridComponentWithContext(BfCheckBoxTreeWithRedux)
