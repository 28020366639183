/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import React, { Component } from 'react'
import { Button } from '@patron/patron-react/button'
import './BfToggleButton.scss'

class BfToggleButton extends Component {
  constructor(props) {
    super(props)

    this.isOpen = false
    this.containerButton = React.createRef()
  }

  _onClick = (ev) => {
    this._setOpen(!this.isOpen)
    this.props.onClick(ev)
  }

  _setOpen = (newValue) => {
    this.isOpen = newValue
  }

  handleClickOutside = (e) => {
    if (this.containerButton.current.contains(e.target)) {
      return
    }

    this._setOpen(false)
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside, false)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, false)
  }

  render() {
    const { title, hover = false, className } = this.props
    const hoverStyle = hover ? 'BfArrowButtonHover ' : ''
    const toggleBCL = this.isOpen
      ? 'hcl-primary BfArrowButtonActive ' + hoverStyle + className
      : 'hcl-primary BfArrowButton ' + hoverStyle + className

    return (
      <div ref={this.containerButton}>
        <Button className={toggleBCL} onClick={(ev) => this._onClick(ev)}>
          {title}
        </Button>
      </div>
    )
  }
}

export default BfToggleButton
