import React, { Component } from 'react'
import _ from '../../services/utils/BfLodash'

import { withTranslation } from 'react-i18next'

import { bfTranslate } from '../../services/i18n/bfI18Utils'

class BfSubmitButton extends Component {
  render() {
    const { children, t, i18n, ...rest } = this.props

    return (
      <button type='submit' className='hcl-btn hcl-primary' {...rest}>
        {_.isEmpty(children) ? bfTranslate(t, 'Submit') : children}
      </button>
    )
  }
}

export default withTranslation()(BfSubmitButton)
