/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/
import _ from '../../../../../services/utils/BfLodash'
import { map } from 'rxjs/operators'
import { createDropDownFilterItem } from '../../common/bfBasicFilters/bfDropDownFilter/BfDropDownFilter'
import BfCachedRequest from '../../../../../services/utils/BfCachedRequest'
import BfDropDownBasicFilter from '../../common/bfBasicFilters/bfDropDownFilter/BfDropDownBasicFilter'

class BfDeviceTypeFilter extends BfDropDownBasicFilter {
  bfCachedRequest = new BfCachedRequest()

  static NAME = 'deviceType'
  static PATH = `filter.${BfDeviceTypeFilter.NAME}`
  constructor(data = {}) {
    super(BfDeviceTypeFilter.PATH, BfDeviceTypeFilter.NAME, data)
  }

  getItems = () => {
    return this.bfCachedRequest.get('/devices/types').pipe(
      map((res) => {
        return _.map(res.values, (type) => createDropDownFilterItem(type.name))
      })
    )
  }
}

export default BfDeviceTypeFilter
