/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2021
// All Rights Reserved
*/

import React from 'react'
import { withTranslation } from 'react-i18next'
import BfDebounceSubject from '../../../../../common/BfDebounceSubject'
import BfCheckboxTree, { extractCheckedNodes } from '../../../common/BfCheckboxTree/BfCheckboxTree'

class BfCheckBoxTreeWithFilter extends React.Component {
  bfDebounceSubject = new BfDebounceSubject(0)

  constructor(props) {
    super(props)

    this.bfDebounceSubject.subscribe((data) => {
      this.props.filter.setData(data)
      this.props.onChange(this.props.filter.get())
    })
  }

  _onCheckbox = (selectedNodes) => {
    const data = extractCheckedNodes(selectedNodes)
    this.bfDebounceSubject.next(data)
  }

  render() {
    const preselectedItems = this.props.filter.getData()
    const { t, filter } = this.props
    filter.setT(t)
    return <BfCheckboxTree getItemsRequest={this.props.items} onItemSelected={this._onCheckbox} preselectedItems={preselectedItems} />
  }
}

export default withTranslation()(BfCheckBoxTreeWithFilter)
