/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import React from 'react'
import { map } from 'rxjs/operators'
import withBfModal from '../modals/BfWithModal'
import BfTaskService from '../../services/tasks/BfTaskService'
import _ from '../../services/utils/BfLodash'

import BfDataGridTable from '../datagrid/BfDataGridTable'
import { createCustomTaskId, isBaseline } from './BfCustomUtils'
import BfConsole from 'loglevel'
import BfOnlySelectedFilter from '../datagrid/filters/common/bfOnlySelected/BfOnlySelectedFilter'
import { mergeOptions } from '../../filters/BfUtility'
import BfReduxStateConstants from '../common/BfReduxStateConstants'
import { bfTranslate } from '../../services/i18n/bfI18Utils'
import { withTranslation } from 'react-i18next'
import BfWithDataGridTableFeatures from '../datagrid/BfWithDataGridTableFeatures'
import BfShowRelevantItems from '../devices/BfShowRelevantItems'

const BfCustomGrid = (props) => {
  const taskService = new BfTaskService()
  const {
    showModal,
    deselectRows,
    t,
    options = {
      updateUrl: false,
      showDeployButton: false,
      showSelectedOnly: true,
      disableSelectAll: true
    },
    customTaskService = null,
    showRelevantFilter,
    customActionBar,
    customHeaderParser,
    id = null
  } = props
  const customId = id || BfReduxStateConstants.CUSTOM_DATA_GRID

  const DEFAULT_CUSTOM_TABLE_HEADER_NAMES = [
    {
      label: bfTranslate(t, 'customItem'),
      field: 'name',
      propertyName: 'Custom Item',
      isDefault: true,
      pinned: 'left',
      styles: { left: '40px' },
      width: '200px',
      sortable: true,
      columnHtmlType: 'searchHTML',
      type: 'linkWithIcon'
    },
    {
      label: bfTranslate(t, 'Description'),
      field: 'description',
      isDefault: true,
      type: 'html'
    },
    {
      label: bfTranslate(t, 'type'),
      field: 'type',
      propertyName: 'Type',
      isDefault: true,
      columnHtmlType: 'customContentTypeHTML'
    },
    {
      label: bfTranslate(t, 'category'),
      field: 'category',
      isDefault: true,
      columnHtmlType: 'categoryHTML'
    },
    {
      label: bfTranslate(t, 'applicableDevices'),
      field: 'applicableComputerCount',
      propertyName: 'Applicable Computers',
      isDefault: true,
      sortable: true,
      type: 'url',
      url: '/custom/tasks/[SiteID]/[ContentID]#devices',
      columnHtmlType: 'applicableDevicesHTML'
    },
    {
      label: bfTranslate(t, 'openDeployments'),
      field: 'openDeploymentCount',
      propertyName: 'Deployments',
      isDefault: true,
      sortable: true,
      type: 'url',
      url: '/custom/tasks/[SiteID]/[ContentID]?filter={"state":{"Open":true}}#deployments'
    },
    {
      label: bfTranslate(t, 'ID'),
      field: 'contentID',
      isDefault: true
    },
    {
      label: 'Site',
      field: 'siteName',
      isDefault: true,
      columnHtmlType: 'siteNameHTML'
    },
    {
      label: bfTranslate(t, 'createdBy'),
      field: 'creator',
      isDefault: true,
      columnHtmlType: 'createByHTML'
    },
    {
      label: bfTranslate(t, 'patchReleaseDateColumnHeader'),
      field: 'creationTime',
      propertyName: 'ReleaseDate',
      isDefault: true,
      type: 'date',
      columnHtmlType: 'releaseDateTypeHTML'
    },
    {
      label: bfTranslate(t, 'modified'),
      field: 'lastModificationTime',
      propertyName: 'Last Modification Time',
      isDefault: true,
      type: 'date',
      sortable: true
    },
    {
      label: bfTranslate(t, 'modifiedBy'),
      field: 'lastModifiedBy',
      isDefault: true
    }
  ]

  const _getOnlySelectedOptions = (options) => {
    if (options.showOnlySelected) {
      const bfOnlySelectedFilter = new BfOnlySelectedFilter(true, options.selectedItemsIds)
      return bfOnlySelectedFilter.getFilter()
    }
    return null
  }

  const _setRowsContents = (rows) => {
    return _.map(rows, (row) => {
      row.id = createCustomTaskId(row)
      row.link = '/custom/tasks/' + row.SiteID + '/' + row.ContentID
      row.icon = _.isNotEmpty(row.actionUIMetadata) && _.isDefined(row.actionUIMetadata.icon) ? row.actionUIMetadata.icon : ''
      return row
    })
  }

  const _getOptions = (searchOption, visibleColumns, options) => {
    const _searchOption = _.cloneDeep(searchOption)
    const onlySelectedOption = _getOnlySelectedOptions(options)
    if (onlySelectedOption) {
      mergeOptions(_searchOption, onlySelectedOption)
    }
    return _searchOption
  }

  const _getTasks = (searchOption, visibleColumns, options) => {
    BfConsole.debug('options are', searchOption)
    BfConsole.debug('columns are', visibleColumns)
    BfConsole.debug('options are', options)
    searchOption = _getOptions(searchOption, visibleColumns, options)
    BfConsole.debug('after options are', searchOption)
    return taskService.getTasks(searchOption).pipe(
      map((tasks) => {
        const rows = _setRowsContents(tasks.data)
        return { totalCount: tasks.count, rows: rows }
      })
    )
  }

  const _areBaselineSelected = (allOtherSelectedItems) => {
    return _.some(allOtherSelectedItems, (selectedItem) => isBaseline(selectedItem))
  }

  const _needNotification = (currentSelectedItem, allOtherSelectedItems, isSelected) => {
    if (isSelected && _.size(allOtherSelectedItems) > 0) {
      if (isBaseline(currentSelectedItem)) return true
      if (_areBaselineSelected(allOtherSelectedItems)) return true
    }
    return false
  }

  const _onClose = (selectedItem) => {
    /** deselect last selected item */
    BfConsole.debug('on Close selected item is ', selectedItem)
    deselectRows(customId, [selectedItem])
  }

  const _onOk = (currentSelectedItem, allOtherSelectedItems) => {
    /** deselect all already selected items selectedItems expect the selectedItem */
    deselectRows(customId, allOtherSelectedItems)
    props.onRowSelected && props.onRowSelected(currentSelectedItem, allOtherSelectedItems, true)
  }

  const _onRowSelected = (currentSelectedItem, allSelectedItems, isSelected) => {
    /** Inside the custom datagrid check this condition on selected item/s
     * -ONLY one baseline can be selected at once and if baseline is selected all other items if selected shuold be deselected
     */
    const _allOtherSelectedItems = _.filter(allSelectedItems, (selectedItem) => currentSelectedItem.id !== selectedItem.id)

    if (_needNotification(currentSelectedItem, _allOtherSelectedItems, isSelected)) {
      /** Notify user for already selected tasks */
      showModal({
        title: bfTranslate(t, 'ContentProceedQuestion'),
        labelConfirm: bfTranslate(t, 'Ok'),
        type: 'warning',
        onClose: () => _onClose(currentSelectedItem),
        onOk: () => _onOk(currentSelectedItem, _allOtherSelectedItems),
        message: bfTranslate(t, 'ContentProceedDescription')
      })
    } else {
      props.onRowSelected && props.onRowSelected(currentSelectedItem, allSelectedItems, isSelected)
    }
  }

  return (
    <BfDataGridTable
      selectableRow
      customActionBar={customActionBar}
      headers={(customHeaderParser && customHeaderParser(DEFAULT_CUSTOM_TABLE_HEADER_NAMES)) || DEFAULT_CUSTOM_TABLE_HEADER_NAMES}
      getRows={customTaskService || _getTasks}
      id={customId}
      paginationBarComponents={
        _.isEmpty(showRelevantFilter)
          ? null
          : [<BfShowRelevantItems key='showRelevantItemsCustom' showRelevantFilter={showRelevantFilter} id={customId} />]
      }
      options={options}
      showPaginationIcons
      onRowSelected={_onRowSelected}
      preSelectedItems={props.selectedTasks}
    />
  )
}

export default withBfModal(withTranslation()(BfWithDataGridTableFeatures(BfCustomGrid)))
