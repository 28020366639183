import React, { Component } from 'react'
import './BfCellModalRenderer.scss'
import { withTranslation } from 'react-i18next'
import { bfTranslate } from '../../../services/i18n/bfI18Utils'

class BfCellModalRenderer extends Component {
  render() {
    const { t, title } = this.props

    return (
      <div className='hcl-container cell-modal'>
        {this.props.headerSection}
        <div className='hcl-row bf-no-gutters'>
          <div className='hcl-col-12 m-5 modal-subtitle'>
            <span className='hcl-font-bold'>{bfTranslate(t, title)}</span>
          </div>
          <div className='hcl-col-12'>{this.props.children}</div>
        </div>
        {this.props.footerSection}
      </div>
    )
  }
}

export default withTranslation()(BfCellModalRenderer)
