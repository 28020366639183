/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/
import BfDateRangeBasicFilter from '../../common/bfBasicFilters/bfDateRangeFilter/BfDateRangeBasicFilter'

class BfModifiedDateFilter extends BfDateRangeBasicFilter {
  static NAME = 'modifiedDate'
  static PATH = `filter.${BfModifiedDateFilter.NAME}`

  constructor(data = {}) {
    super(BfModifiedDateFilter.PATH, BfModifiedDateFilter.NAME)
    this[this.getName()] = data
  }
}

export default BfModifiedDateFilter
