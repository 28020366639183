/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import React, { Component } from 'react'
import BfManualTargeting from '../../devices/BfManualTargeting/BfManualTargeting'
import BfAddColumn from '../bfAddColumn/BfAddColumn'
import './BfPaginationIcons.scss'

class BfPaginationIcons extends Component {
  render() {
    const { addColumnButtonCustomModal, showManualTargeting } = this.props
    return (
      <div className='hcl-header-wrapper bfFilterWrapper'>
        {showManualTargeting && <BfManualTargeting />}
        <BfAddColumn addColumnButtonCustomModal={addColumnButtonCustomModal} />
      </div>
    )
  }
}

export default BfPaginationIcons
