/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2021
// All Rights Reserved
*/

import _ from '../../../../../services/utils/BfLodash'
import BfDropDownBasicFilter from '../bfBasicFilters/bfDropDownFilter/BfDropDownBasicFilter'
import { createDropDownFilterItem } from '../bfBasicFilters/bfDropDownFilter/BfDropDownFilter'
import { bfTranslate } from '../../../../../services/i18n/bfI18Utils'
import { of } from 'rxjs'

class BfGroupsTargetedFilter extends BfDropDownBasicFilter {
  static NAME = 'groupsTargeted'
  static PATH = `filter.${BfGroupsTargetedFilter.NAME}`
  constructor(data = {}) {
    super(BfGroupsTargetedFilter.PATH, BfGroupsTargetedFilter.NAME, data)
  }

  getSelectedItems = () => {
    return _.size(this.groupsTargeted)
  }

  getItems = () => {
    const t = this.getT()
    const items = ['overAirEnroll', 'bulkEnroll', 'autopilotEnroll', 'autoDeviceEnroll', 'byodEnroll', 'managedQREnroll', 'None'].map((opt) => {
      return createDropDownFilterItem(opt, bfTranslate(t, opt))
    })

    return of(items)
  }
}

export default BfGroupsTargetedFilter
