/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import BfHttpClient from '../authClient/BfHttpClient'
import { of } from 'rxjs'
import _ from '../../services/utils/BfLodash'
import BfCurrentUserInfoService from './BfCurrentUserInfoService'
import BfWebuiDataService from './BfWebuiDataService'
import { catchError, map, mergeMap } from 'rxjs/operators'
import BfReduxStateConstants from '../../components/common/BfReduxStateConstants'

/**
 * Provides functionalities for Current user Session Information
 */
// TODO: BfCurrentUserSessionData should be a singleton
class BfCurrentUserSessionData {
  userID = null
  sessionData = null
  bfWebuiDataService = new BfWebuiDataService()
  http = new BfHttpClient()

  constructor(_bfCurrentUserInfoService) {
    if (_.isDefined(_bfCurrentUserInfoService)) {
      this.bfCurrentUserInfoService = _bfCurrentUserInfoService
    } else {
      this.bfCurrentUserInfoService = new BfCurrentUserInfoService()
    }
  }

  _loadCurrentUserSessionData = () => {
    return this.bfCurrentUserInfoService.get().pipe(
      mergeMap((userInfo) => {
        this.userID = `${userInfo.userName}_${userInfo.userPermission.UserID}`

        return this.bfWebuiDataService.get(this.userID).pipe(
          map((sessionData) => {
            if (_.isEmpty(sessionData)) {
              sessionData = {}
            }
            this.sessionData = sessionData
            return this.sessionData
          })
        )
      })
    )
  }

  update = (data, cbk) => {
    _.assign(this.sessionData, data)
    return this.save(this.sessionData, cbk)
  }

  /**
   * Saves Current User session information from the server
   * @method
   * @params {Object} data
   * @returns {Promise} Http request
   */
  save(data, cbk) {
    return this.bfWebuiDataService.save(this.userID, data, () => {
      this.sessionData = data
      if (cbk) {
        cbk(this.sessionData)
      }
    })
  }

  load() {
    return this._load()
  }

  /**
   * Initiates load functionality for current user's session
   * @method
   * @returns {Promise}
   */
  _load() {
    return this.sessionData ? of(this.sessionData) : this._loadCurrentUserSessionData()
  }

  _sanitizeDevicesColumnsFields = (visibleColumnsFields) => {
    _.each(visibleColumnsFields, (visibleColumn, key) => {
      if (visibleColumn._isCustomProperties && _.endsWith(_.trim(_.lowerCase(key)), _.trim(_.lowerCase(visibleColumn.label)))) {
        visibleColumn.field = visibleColumn.siteID + '_' + visibleColumn.contentID + '_' + visibleColumn.propertyID
        visibleColumnsFields[visibleColumn.field] = visibleColumn
        delete visibleColumnsFields[key]
      }
    })
  }

  loadUserSessionData = () => {
    return this._load().pipe(
      map((userSessionData) => {
        if (_.isDefined(userSessionData) && _.isDefined(userSessionData[BfReduxStateConstants.DEVICES]?.visibleColumnsFields)) {
          const visibleColumnsFields = userSessionData[BfReduxStateConstants.DEVICES].visibleColumnsFields
          this._sanitizeDevicesColumnsFields(visibleColumnsFields)
          return userSessionData
        } else {
          return userSessionData
        }
      }),
      catchError((err) => {
        throw new Error(err)
      })
    )
  }
}

export default BfCurrentUserSessionData
