/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import React from 'react'
import './BfNavigationBar.scss'
import { ReactComponent as Logo } from './bigfix_logo.svg'
/**
 * Returns BigFix Logo
 */
class NavBarLogo extends React.Component {
  render() {
    return (
      <div className='bfLogoContainer'>
        <a href='/'>
          <Logo className='BfLogo' />
        </a>
      </div>
    )
  }
}

export default NavBarLogo
