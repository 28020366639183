/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/
import React, { useState } from 'react'
import BfDebounceSubject from '../../../../../common/BfDebounceSubject'
import { NumberInput } from '@patron/patron-react/numberinput'
import Dropdown from '@patron/patron-react/export/atoms/Dropdown/Dropdown'
import _ from '../../../../../../services/utils/BfLodash'
import './BfNumberGreaterLess.scss'
import { useTranslation } from 'react-i18next'
import { bfTranslate } from '../../../../../../services/i18n/bfI18Utils'

export const GREATER_OR_EQUAL_THAN = 'geq'
export const LESS_OR_EQUAL_THAN = 'leq'
export const EQUAL = 'eq'
const MAX_VALUE_INPUT = 500000
const POSITIVE_NUMBERS_REGEX = /^[0-9]+$/

const BfNumberGreaterLess = (props) => {
  const { onValueChange, onOptionChange, enabledOptions, preSelectedOption, defaultValue } = props
  const { t } = useTranslation()
  const [displayError, setDisplayError] = useState(null)
  const bfDebounceFilter = new BfDebounceSubject()
  const options = [
    {
      id: GREATER_OR_EQUAL_THAN,
      text: bfTranslate(t, GREATER_OR_EQUAL_THAN)
    },
    {
      id: LESS_OR_EQUAL_THAN,
      text: bfTranslate(t, LESS_OR_EQUAL_THAN)
    },
    {
      id: EQUAL,
      text: bfTranslate(t, EQUAL)
    }
  ]

  bfDebounceFilter.subscribe({
    next: (value) => {
      if (POSITIVE_NUMBERS_REGEX.test(value)) {
        if (_.toInteger(value) <= MAX_VALUE_INPUT) {
          setDisplayError(null)
          onValueChange(value)
        } else {
          setDisplayError(bfTranslate(t, 'applicableDevicesNotValidRange'))
        }
      } else {
        setDisplayError(bfTranslate(t, 'applicableDevicesNotValidNumber'))
      }
    }
  })

  const _enabledOptions = _.isDefined(enabledOptions) ? _.filter(options, (opt) => _.includes(enabledOptions, opt.id)) : options

  const _onDropDownSelect = (option) => {
    onOptionChange(option.id)
  }

  const onChange = (value) => {
    bfDebounceFilter.next(value)
  }

  return (
    <div className='filter-container p-2'>
      <div>
        <Dropdown items={_enabledOptions} selectedItem={preSelectedOption} onChange={_onDropDownSelect} />
      </div>
      <div>
        <NumberInput defaultValue={defaultValue} onChange={onChange} min={0} />
      </div>
      {_.isNotEmpty(displayError) ? <span className='hcl-type-caption bf-error mb-1'>{displayError}</span> : null}
    </div>
  )
}

export default BfNumberGreaterLess
