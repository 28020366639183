/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import BfProperiesFilterService from '../../../../../services/properties/BfProperiesFilterService'
import BfDropDownSearchBasicFilter from '../../common/bfBasicFilters/BfDropDownSearchFilter/BfDropDownSearchBasicFilter'

class BfSourceFilter extends BfDropDownSearchBasicFilter {
  static NAME = 'site'
  static PATH = `filter.${BfSourceFilter.NAME}`
  fixedSuggestedOptions = { Aggregated: true, Reserved: true }
  bfProperiesFilterService = new BfProperiesFilterService('source', 'analysis', 20)

  constructor(data = {}) {
    super(BfSourceFilter.PATH, BfSourceFilter.NAME, data)
  }

  getRequiredFilters = () => {
    return ['analysis']
  }

  getSearchURL = () => {}

  getItems = (filterValue) => {
    return this.bfProperiesFilterService.getItems(filterValue, this.analysisFilter.getData())
  }
}

export default BfSourceFilter
