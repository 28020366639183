import _ from '../services/utils/BfLodash'

export const mergeOptions = (options, otherOptions) => {
  _.each(otherOptions, (value, key) => {
    if (options[key]) {
      _.assign(options[key], value)
    } else {
      _.assign(options, { [key]: value })
    }
  })
  return options
}
