/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import React, { Component } from 'react'
import { connect } from 'react-redux'
import './BfPaginationBar.scss'
import { BfTrans } from '../../../services/i18n/bfI18Utils'
import { Button } from '@patron/patron-react/button'
import BfPaginationWithAction from '../filters/common/bfPagination/BfPaginationWithAction'
import BfPaginationIcons from './BfPaginationIcons'
import { BfDataGridComponentWithContext } from '../BfDataGridContext'
import { resetSearchListUrlAction } from '../../datagrid/redux/BfFilterListUpdateURLAction'
import logConsole from 'loglevel'
import { createFilterManagerFromReduxState } from '../filters/BfFilterManager'
import BfColumnSelectionFilter from '../filters/common/bfHeaderSelection/BfColumnSelectionFilter'
import BfResetColumnsService from '../services/BfResetColumnsService'
import _ from '../../../services/utils/BfLodash'
import BfFiltersCheck from './BfFiltersCheck'
import { Tooltip } from '@patron/patron-react/tooltip'
import { Link } from '@patron/patron-react/link'

class BfPaginationBarWithAction extends Component {
  _resetFilters = () => {
    const { id } = this.props
    this.props.dispatch(resetSearchListUrlAction(id))
  }

  _getDefaultProperties = () => {
    const { headers } = this.props
    return _.reduce(
      headers,
      (defaultProperties, header) => {
        defaultProperties[header.field] = _.isDefined(header.isDefault) ? header.isDefault : false
        return defaultProperties
      },
      {}
    )
  }

  _resetColumns = () => {
    logConsole.debug('resetColunms')
    const visibleHeaders = this.props.bfColumnSelectionFilter.getVisibleHeaders()
    const defaultProperties = this._getDefaultProperties()
    const toRemoveCustomProperties = _.filter(visibleHeaders, (propertiesHeader) => {
      if (_.isDefined(propertiesHeader._isCustomProperties) && propertiesHeader._isCustomProperties) {
        propertiesHeader._toBeRemoved = true
        return true
      }
      return false
    })
    const bfResetColumnsService = new BfResetColumnsService(
      this.props.dispatch,
      this.props.id,
      defaultProperties,
      toRemoveCustomProperties,
      this.props.bfColumnSelectionFilter,
      this.props.bfCustomPropertiesFilter
    )
    bfResetColumnsService.resetColumns()
  }

  _areFiltersApplied = () => {
    const { bfFilterManager } = this.props
    return bfFilterManager.areFiltersApplied()
  }

  render() {
    const {
      totalItems,
      addColumnButtonCustomModal,
      showPaginationIcons,
      id,
      pref,
      showResetColumn,
      isLoading,
      showManualTargeting,
      paginationBarFilter,
      paginationBarComponents,
      onChangePagination,
      addNewItemLabel,
      addNewItemLink
    } = this.props
    return (
      <div ref={pref} className='hcl-container bfPaginationContainer'>
        <div className='hcl-row'>
          <div className='hcl-col-md-4 hcl-col-xxxl-5 bfPaginationCenterFlex bfTotalDevices'>
            <div className='bf-total-devices-container'>
              {BfTrans(`Total_${id}`, {
                count: totalItems
              })}
            </div>
            <div className='pl-4 bfPaginationCenterFlex'>
              {_.isDefined(paginationBarFilter) && !isLoading && <React.Fragment>{paginationBarFilter}</React.Fragment>}
            </div>
            <div>
              {this._areFiltersApplied() && !isLoading && (
                <Tooltip direction='top' content={BfTrans('resetFilters')}>
                  <Button className='BfButtonResetFilters bf-action-icon' onClick={this._resetFilters} type='ghost'>
                    <i className='p-hclsw p-hclsw-filter-clear BfButtonResetFiltersIcon icon-in-tad-button' />
                    <span className='label-in-tad-button'>{BfTrans('resetFilters')}</span>
                  </Button>
                </Tooltip>
              )}
            </div>

            <div>
              {showPaginationIcons && !isLoading && showResetColumn && (
                <Tooltip direction='top' content={BfTrans('resetColumns')}>
                  <Button className='BfButtonResetFilters bf-action-icon' onClick={this._resetColumns} type='ghost'>
                    <i className='p-hclsw p-hclsw-table-remove BfButtonResetFiltersIcon icon-in-tad-button' />
                    <span className='label-in-tad-button'>{BfTrans('resetColumns')}</span>
                  </Button>
                </Tooltip>
              )}
            </div>
            {addNewItemLink && addNewItemLabel && (
              <div className='bf-add-item'>
                <div className='pl-4 pr-6 bf-vertical-line-container'>
                  <div className='bf-vertical-line' />
                </div>
                <Link href={addNewItemLink}>{addNewItemLabel}</Link>
              </div>
            )}
            {paginationBarComponents &&
              _.size(paginationBarComponents) > 0 &&
              _.map(paginationBarComponents, (paginationBarComponent) => <div className='pl-0'>{paginationBarComponent}</div>)}
            <div>
              {showPaginationIcons && !isLoading && (
                <BfFiltersCheck id={this.props.id} visibleColumns={this.props.visibleColumns} headers={this.props.headers} />
              )}
            </div>
          </div>
          <div className='hcl-col-md-8 hcl-col-xxxl-7 bfPaginationCenterFlex bf-action-wrapper'>
            {showPaginationIcons && addColumnButtonCustomModal && (
              <BfPaginationIcons showManualTargeting={showManualTargeting} addColumnButtonCustomModal={addColumnButtonCustomModal} />
            )}
            <BfPaginationWithAction totalItems={totalItems} onChangePagination={onChangePagination} />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const bfFilterManager = createFilterManagerFromReduxState(state, ownProps.id)
  const bfCustomPropertiesFilter = bfFilterManager.getCustomPropertiesFilter()
  return {
    bfColumnSelectionFilter: new BfColumnSelectionFilter(state[ownProps.id]?.columns?.cols),
    bfCustomPropertiesFilter: bfCustomPropertiesFilter,
    bfFilterManager: bfFilterManager
  }
}

const BfPaginationBarWithRedux = connect(mapStateToProps)(BfPaginationBarWithAction)

export default BfDataGridComponentWithContext(BfPaginationBarWithRedux)
