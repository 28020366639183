/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2021
// All Rights Reserved
*/
import _ from '../../../../../../services/utils/BfLodash'
import BfConsole from 'loglevel'

import BfBasicFilter from '../../../BfBasicFilter'

class BfDropDownSearchBasicFilter extends BfBasicFilter {
  getQueryItemsIds = (selectedItemsIds) => {
    return _.reduce(
      selectedItemsIds,
      (selectedItems, selectedItemsId) => {
        selectedItems[selectedItemsId] = true
        return selectedItems
      },
      {}
    )
  }

  getSearchURL = () => {
    BfConsole.warn('getSearchURL Not Implemented')
    return ''
  }

  /*
  getSelectedItems = () => {
    return {}
  }
  
  getItems = () => {
    return {}
  } */
}
export default BfDropDownSearchBasicFilter
