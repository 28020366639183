/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import BfHttpClient from '../authClient/BfHttpClient'
import _ from '../utils/BfLodash'
import BfConsole from 'loglevel'

class BfPropertiesService {
  getPropertiesNames = (properties) => {
    const bfHttpClient = new BfHttpClient()
    const contentKeys = _.reduce(
      properties,
      (result, customProperty) => {
        const contentID = _.get(customProperty, 'contentKey.contentID')
        const siteID = _.get(customProperty, 'contentKey.siteID')
        result[`${siteID}_${contentID}_${customProperty.propertyID}`] = {
          siteID: siteID,
          contentID: contentID,
          propertyID: customProperty.propertyID
        }
        return result
      },
      {}
    )

    BfConsole.debug('url Params are ', contentKeys)
    return bfHttpClient.get('/framework/properties/names', contentKeys)
  }
}

export default BfPropertiesService
