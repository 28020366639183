/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import React, { Component } from 'react'
import BfBase64StringHandler from '../../services/utils/BfBase64StringHandler'

import _ from '../../services/utils/BfLodash'
import './BfIconRenderer.scss'

class BfIconRenderer extends Component {
  bfBase64StringHandler = new BfBase64StringHandler()
  render() {
    const { icon } = this.props

    return (
      <React.Fragment>
        {_.isNotEmpty(icon) ? (
          <div
            className='bf-iconRenderer'
            style={{
              backgroundImage: this.bfBase64StringHandler.convertBase64ToCssUrl(icon)
            }}
          />
        ) : (
          <div className='bf-defaultIcon' />
        )}
      </React.Fragment>
    )
  }
}

export default BfIconRenderer
