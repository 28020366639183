const BfExportConstants = Object.freeze({
  status: {
    PENDING: 'PENDING',
    IN_PROGRESS: 'IN_PROGRESS',
    COMPLETE: 'COMPLETE',
    CANCELLED: 'CANCELLED',
    FAILED: 'FAILED'
  }
})

export default BfExportConstants
