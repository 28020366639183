/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import React from 'react'
import _ from '../../services/utils/BfLodash'
import { withTranslation } from 'react-i18next'
import BfTabService from '../../services/navBarServices/BfTabService'
import BfGearService from '../../services/navBarServices/BfGearService'

import NavBarLogo from './NavBarLogo'
import NavBarApps from './NavBarApps'
import NavBarSettings from './NavBarSettings'
import './BfNavigationBar.scss'
import BfWithServicesProvider, { BfWithServicesConsumer } from '../providers/BfWithServicesProvider'

class BfNavigationBar extends React.Component {
  constructor(props) {
    super(props)
    this.bfTabService = new BfTabService(props.getCurrentUserInfo())
    this.bfGearService = new BfGearService(props.getCurrentUserInfo())

    this.state = { tabs: [], gearApps: {} }
  }

  componentDidMount() {
    this.bfTabService.getTabs().subscribe((tabsInfo) => {
      this.setState({ tabs: tabsInfo.tabs })
    })
    this.bfGearService.getGearApps().subscribe((gearApps) => {
      this._addTranslationLabel(gearApps.gearApps)
      this.setState({ gearApps: gearApps.gearApps })
    })
  }

  /**
   * Add Translation Labels to apps
   * @method
   * @param {Array} apps The Apps that are to be displayed
   */
  _addTranslationLabel = (apps) => {
    _.forEach(apps, (app) => {
      app.translationLabel = app.appName + 'Label'
    })
  }

  /**
   * Returns icon class name
   * @method
   * @param {String} label Icon type
   * @returns {String} class name based on label
   */
  _getLinkIconClassName(label) {
    switch (label) {
      case 'Devices':
        return 'pi-desktop'
      case 'Deployments':
        return 'pi-deploy'
      case 'Reports':
        return 'pi-report'
    }
  }

  render() {
    return (
      <div className='hcl-container'>
        <div className='hcl-row BfContainerNav'>
          <div className='hcl-col-9 BfNavBarLeft'>
            <NavBarLogo />
            <NavBarApps tabs={this.state.tabs} />
          </div>
          <NavBarSettings bfCurrentUserInfoService={this.props.getCurrentUserInfo()} gearApps={this.state.gearApps} />
        </div>
      </div>
    )
  }
}
export default withTranslation()(BfWithServicesProvider(BfWithServicesConsumer(BfNavigationBar)))
