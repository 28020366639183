/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/
import _ from '../../../../../services/utils/BfLodash'
import { createDropDownItem } from '../../../../common/BfDropdownSearch'
import BfBasicCustomFilter from '../BfBasicCustomFilter'

class BfSiteNameFilter extends BfBasicCustomFilter {
  static NAME = 'siteName'
  static PATH = `filter.${BfSiteNameFilter.NAME}`
  constructor(data = {}) {
    super(BfSiteNameFilter.PATH, BfSiteNameFilter.NAME)
    this[BfSiteNameFilter.NAME] = data
  }

  getSearchURL = () => {
    return '/custom/api/tasks/sites'
  }

  /** This method receives res data and returns a list of strings */
  processResponse = (res) => {
    return { values: _.map(res.data, (value) => createDropDownItem(value.siteName, value.Type === 3 ? value.OpSiteName : value.siteName)) }
  }

  getQueryItemsIds = (selectedItemsIds, selectedItems) => {
    return _.reduce(
      selectedItems,
      (selectedItemsAcc, selectedItem) => {
        selectedItemsAcc[selectedItem.label] = selectedItem.id
        return selectedItemsAcc
      },
      {}
    )
  }

  getInitialSelectedItems = () => {
    return _.map(this.getData(), (value, key) => createDropDownItem(value, key))
  }
}

export default BfSiteNameFilter
