/*
// Licensed Materials - Property of HCL
// © Copyright HCL Technologies Limited 2001, 2021
// All Rights Reserved
*/

import React, { Component } from 'react'
import BfDataGridTable from '../datagrid/BfDataGridTable'
import BfPrepolicyService from '../../services/prepolicy/BfPrepolicyService'
import BfReduxStateConstants from '../common/BfReduxStateConstants'
import { map } from 'rxjs/operators'
import BfShowRelevantItems from '../devices/BfShowRelevantItems'
import _ from '../../services/utils/BfLodash'

class BfPrepolicyGrid extends Component {
  bfPrepolicyService = new BfPrepolicyService()

  constructor(props) {
    super(props)

    this.highlightedRows = {}

    this.headers = [
      {
        label: 'Name',
        field: 'id',
        type: 'prepolicy',
        isDefault: true,
        styles: { left: '40px' },
        width: '200px',
        columnHtmlType: 'searchPolicyGroupsHTML'
      },
      {
        label: 'OS',
        field: 'mdmOS',
        isDefault: true,
        sortable: true,
        styles: { left: '40px' },
        width: '200px',
        columnHtmlType: 'simpleOSPoliciesHTML'
      },
      {
        label: 'Policy Type',
        field: 'type',
        isDefault: true,
        sortable: true,
        styles: { left: '40px' },
        width: '200px',
        columnHtmlType: 'policyTypesHTML'
      },
      {
        label: 'Modified Date',
        field: 'modifiedDate',
        isDefault: true,
        styles: { left: '40px' },
        width: '200px',
        type: 'localizedDate2',
        sortable: true
      }
    ]
  }

  _getPrepolicy = (urlOption, visibleColumns, options) => {
    return this.bfPrepolicyService.getPrepolicy(urlOption).pipe(
      map((data) => {
        if (data.totalCount === undefined) {
          data.totalCount = 0
        }
        if (data.rows === undefined) {
          data.rows = []
        }
        return data
      })
    )
  }

  render() {
    const {
      showRelevantFilter,
      showPaginationIcons = true,
      options = {
        updateUrl: false,
        showDeployButton: false,
        showSelectedOnly: true
      },
      customActionBar,
      id = null
    } = this.props

    return (
      <BfDataGridTable
        selectableRow
        getRows={this._getPrepolicy}
        headers={this.headers}
        id={id || BfReduxStateConstants.PREPOLICY}
        customActionBar={customActionBar}
        onRowSelected={this.props.onRowSelected}
        onRowsSelected={this.props.onRowsSelected}
        preSelectedItems={this.props.selectedPackages}
        showPaginationIcons={showPaginationIcons}
        paginationBarComponents={
          _.isEmpty(showRelevantFilter)
            ? null
            : [<BfShowRelevantItems key='showRelevantItemsDevices' showRelevantFilter={showRelevantFilter} id={this.id} />]
        }
        showSummary={this.props.showSummary}
        options={options}
      />
    )
  }
}

export default BfPrepolicyGrid
