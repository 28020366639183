import React from 'react'
import _ from '../../../services/utils/BfLodash'
import BfCellWidth from './BfCellWidth'
import BfLinkWithTooltip from './BfLinkWithTooltip'

/**
 * Renders Applicable Patches cells
 */
class BfCellApplicablePatchesRender {
  constructor(header) {
    this.header = header
    this.name = header.field
  }

  getRenderer() {
    return this._getApplicablePatchesRenderHTML
  }

  /**
   * Renders Applicable Patches cell
   * @method
   * @param {Object} row Applicable Patches
   * @returns {HTMLElement} Cell HTML
   */
  _getApplicablePatchesRenderHTML = (row) => {
    const value = _.isUndefined(row[this.name]) ? '0' : row[this.name].toString()

    return (
      <BfCellWidth header={this.header}>
        <BfLinkWithTooltip href={'/framework/devices/' + row.id + '#patches'} alignRight value={value} toolTipValue={value} />
      </BfCellWidth>
    )
  }
}
export default BfCellApplicablePatchesRender
