/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import React, { Component } from 'react'
import _ from '../../services/utils/BfLodash'
import { map } from 'rxjs/operators'
import BfDataGridTable from '../datagrid/BfDataGridTable'
import BfAddColumnPropertiesWithModal from '../datagrid/bfAddColumn/BfAddColumnPropertiesWithModal'
import BfDevicesService from '../../services/devices/BfDevicesService'
import logConsole from 'loglevel'
import BfCurrentUserSessionData from '../../services/user/BfCurrentUserSessionData'
import BfFilterManager from '../datagrid/filters/BfFilterManager'
import BfGetDeviceProperty from '../../services/devices/BfGetDeviceProperty'
import { makeContentKey } from '../../components/datagrid/bfAddColumn/BfAddColumnHelper'
import BfPlatformVersion from '../../services/devices/BfPlatformVersion'
import BfReduxStateConstants from '../common/BfReduxStateConstants'
import BfOnlySelectedFilter from '../datagrid/filters/common/bfOnlySelected/BfOnlySelectedFilter'
import { withTranslation } from 'react-i18next'
import { bfTranslate } from '../../services/i18n/bfI18Utils'
import { forkJoin } from 'rxjs'
import BfShowRelevantItems from './BfShowRelevantItems'
import { BfWithServicesConsumer } from '../providers/BfWithServicesProvider'

class BfDevicesGrid extends Component {
  bfDevices = new BfDevicesService()
  bfPlatformVersion = new BfPlatformVersion()
  bfCurrentUserSessionData = new BfCurrentUserSessionData(this.props.getCurrentUserInfo())

  constructor(props) {
    super(props)
    const { t } = props
    this.id = BfReduxStateConstants.DEVICES
    this.ORDERED_HEADERS = {
      name: 0,
      criticalPatches: 1,
      relevantPatchCount: 2,
      deploymentState: 3,
      deviceType: 4,
      os: 5,
      groupNames: 6,
      ipAddresses: 7,
      dnsName: 8,
      agent: 9,
      user: 10,
      lastReportTime: 11,
      agentDisplay: 12,
      locked: 13,
      cloudTags: 14
    }

    this.CLOUD_HEADERS = [
      {
        label: bfTranslate(t, 'AgentStatus'),
        field: 'agent',
        propertyName: 'Agent Status',
        isDefault: true,
        columnHtmlType: 'agentStatusHTML',
        type: 'agentStatus'
      },

      {
        label: bfTranslate(t, 'Managedby'),
        field: 'agentDisplay',
        propertyName: 'Managed By',
        type: 'arrayCell',
        isDefault: true,
        columnHtmlType: 'agentDisplayHTML'
      },
      {
        label: bfTranslate(t, 'cloudTags'),
        field: 'cloudTags',
        propertyName: 'Cloud Tags',
        optionsURL: { cloudTags: { show: true } },
        type: 'arrayCell',
        columnHtmlType: 'cloudTagsHTML'
      }
    ]

    this.DEFAULT_DEVICES_TABLE_HEADER_NAMES = [
      {
        label: bfTranslate(t, 'ComputerName'),
        field: 'name',
        propertyName: 'Computer Name',
        type: 'device',
        isDefault: true,
        pinned: 'left',
        styles: { left: '40px' },
        width: '200px',
        sortable: true,
        columnHtmlType: 'searchHTML'
      },
      {
        label: bfTranslate(t, 'ApplicablePatches'),
        field: 'relevantPatchCount',
        propertyName: 'Applicable Patches',
        isDefault: true,
        sortable: true,
        type: 'applicablePatches',
        columnHtmlType: 'applicablePatchesHTML'
      },
      {
        label: bfTranslate(t, 'Deployments'),
        field: 'deploymentState',
        propertyName: 'Deployments',
        isDefault: true,
        columnHtmlType: 'deploymentStateHTML',
        type: 'deploymentState'
      },
      {
        label: bfTranslate(t, 'DeviceType'),
        field: 'deviceType',
        propertyName: 'Device Type',
        isDefault: true,
        columnHtmlType: 'deviceTypeHTML',
        type: 'arrayCell'
      },
      {
        label: bfTranslate(t, 'OS'),
        field: 'os',
        propertyName: 'OS',
        isDefault: true,
        columnHtmlType: 'operatingSystemHTML'
      },
      {
        label: bfTranslate(t, 'Groups'),
        field: 'groupNames',
        propertyName: 'Groups',
        isDefault: true,
        columnHtmlType: 'deviceGroupHTML',
        type: 'arrayCell'
      },
      {
        label: bfTranslate(t, 'UserName'),
        field: 'user',
        propertyName: 'User Name',
        isDefault: true,
        columnHtmlType: 'recentUsersHTML',
        type: 'arrayCell'
      },
      {
        label: bfTranslate(t, 'IPAddress'),
        field: 'ipAddresses',
        propertyName: 'IP Address',
        isDefault: true,
        columnHtmlType: 'ipAddressHTML',
        type: 'arrayCellNotEmpty'
      },
      {
        label: bfTranslate(t, 'DNSName'),
        field: 'dnsName',
        propertyName: 'DNS Name',
        isDefault: true,
        columnHtmlType: 'dnsNameHTML'
      },
      {
        label: bfTranslate(t, 'LastReportTime'),
        field: 'lastReportTime',
        propertyName: 'Last Report Time',
        isDefault: true,
        type: 'date',
        columnHtmlType: 'dateRangeHTML',
        sortable: true
      },

      {
        label: bfTranslate(t, 'Locked'),
        field: 'locked',
        propertyName: 'Locked',
        isDefault: true,
        columnHtmlType: 'lockStatusHTML'
      },
      {
        label: bfTranslate(t, 'CriticalPatches'),
        field: 'criticalPatches',
        propertyName: 'Critical Patches',
        isDefault: true,
        columnHtmlType: 'criticalPatchesHTML',
        type: 'criticalPatches'
      }
    ]

    this.state = { rows: [], loading: true }
    this.orderedHeaders = this._sortHeaders(this.DEFAULT_DEVICES_TABLE_HEADER_NAMES)
  }

  _loadUserSessionData = (cbk) => {
    if (_.isEmpty(this.userSessionData)) {
      this.bfCurrentUserSessionData.loadUserSessionData().subscribe({
        next: (userSessionData) => {
          this.userSessionData = userSessionData
          cbk(this.userSessionData)
        },
        error: (err) => {
          logConsole.error('Error retrieving user session data', err)
          cbk()
        }
      })
    } else {
      cbk(this.userSessionData)
    }
  }

  _sortHeaders = (...inputArray) => {
    const ia = _.concat(...inputArray)
    const orderedArray = _.reduce(
      ia,
      (orderedArray, header) => {
        const position = this.ORDERED_HEADERS[header.field]
        orderedArray[position] = header
        return orderedArray
      },
      new Array(_.size(ia))
    )
    return _.filter(orderedArray, (e) => _.isDefined(e))
  }

  _getHeadersProperty = (propertyName) => {
    return _.map(this.orderedHeaders, propertyName)
  }

  _getNonDefaultHeaders = (userSessionData) => {
    const defaultKeys = this._getHeadersProperty('field')
    return _.omit(userSessionData[this.id]?.visibleColumnsFields, defaultKeys)
  }

  componentDidMount = () => {
    this.bfPlatformVersion.isGreaterThanTen().subscribe({
      next: (isGreaterTen) => {
        if (isGreaterTen) {
          this.orderedHeaders = this._sortHeaders(this.DEFAULT_DEVICES_TABLE_HEADER_NAMES, this.CLOUD_HEADERS)
        }
        this._loadUserSessionData((userSessionData) => {
          this._getDevicesHeaders(userSessionData).subscribe({
            next: (allHeaders) => {
              this.setState({
                defaultHeadersWithIds: allHeaders,
                loading: false
              })
            }
          })
        })
      }
    })
  }

  _addIdsToHeaders = (propertiesIds) => {
    return _.reduce(
      this.orderedHeaders,
      (acc, header) => {
        if (propertiesIds[header.propertyName]) {
          _.assign(header, propertiesIds[header.propertyName])
          header.id = makeContentKey(header)
        }
        acc[header.propertyName] = header
        return acc
      },
      {}
    )
  }

  _updateNonDefaultHeaders = (properties, nonDefaultHeaders) => {
    // update label and title for non default headers
    return _.reduce(
      properties,
      (headers, prop) => {
        headers[prop.id] = nonDefaultHeaders[prop.id]
        headers[prop.id].label = prop.propertyName
        headers[prop.id].title = prop.propertyName
        return headers
      },
      {}
    )
  }

  _removeCloudHeaders = () => {
    this.DEFAULT_DEVICES_TABLE_HEADER_NAMES = _.remove(this.DEFAULT_DEVICES_TABLE_HEADER_NAMES, (header) =>
      _.include(this.CLOUD_HEADERS, header.field)
    )
  }

  _getDevicesHeaders = (userSessionData) => {
    const defaultHeaders = this._getHeadersProperty('propertyName')
    const nonDefaultHeaders = this._getNonDefaultHeaders(userSessionData)
    const bfDeviceProperties = new BfGetDeviceProperty()
    let propertiesObs = {
      propertiesIds: bfDeviceProperties.getReservedAndAggregatedIds(defaultHeaders)
    }
    if (_.size(nonDefaultHeaders) > 0) {
      // filter={"onlySelected":{"Enabled":true,"data":["-3_1_6","-3_1_1","2299744174_26_1"]}
      // const filterIdsOption = new BfOnlySelectedFilter(true, _.keys(nonDefaultHeaders)).getFilter()
      const filterIdsOption = { filter: { onlySelected: { Enabled: true, data: _.keys(nonDefaultHeaders) } } }
      propertiesObs = _.extend(propertiesObs, {
        translatedProperties: bfDeviceProperties.getAllPropertiesWithAggregated(filterIdsOption)
      })
    }
    return forkJoin(propertiesObs).pipe(
      map((properties) => {
        return _.extend(
          this._addIdsToHeaders(properties.propertiesIds),
          this._updateNonDefaultHeaders(properties?.translatedProperties?.data, nonDefaultHeaders)
        )
      })
    )
  }

  _getPropertiesColumns = (columns) => {
    return _.filter(columns, (column) => _.isDefined(column._isCustomProperties) && column._isCustomProperties)
  }

  _getOnlySelectedOptions = (options) => {
    if (options.showOnlySelected) {
      const bfOnlySelectedFilter = new BfOnlySelectedFilter(true, options.selectedItemsIds)
      return bfOnlySelectedFilter.getFilter()
    }
    return null
  }

  _getCustomPropertiesOptions = (columns, urlOptions, options) => {
    let ccOptions = {}

    const propertiesColumns = this._getPropertiesColumns(columns)
    if (_.size(propertiesColumns) > 0) {
      const filterManager = new BfFilterManager(urlOptions, options.updateUrl)

      const bfCustomPropertiesFilter = filterManager.getCustomPropertiesFilter()

      bfCustomPropertiesFilter.add(propertiesColumns)

      ccOptions = bfCustomPropertiesFilter.getFilter()
    }
    return ccOptions
  }

  _getDefaultColumns = (columns) => {
    return _.filter(columns, (column) => _.isUndefined(column._isCustomProperties) || !column._isCustomProperties)
  }

  _getDefaultColumnsOptions = (colums) => {
    let options = {}
    const defaultColumns = this._getDefaultColumns(colums)
    _.each(defaultColumns, (defaultColumn) => {
      if (defaultColumn.optionsURL) {
        const optURL = defaultColumn.optionsURL
        options = _.assign(options, optURL)
      }
    })

    return options
  }

  _mergeOptions = (options, otherOptions) => {
    if (_.isEmpty(otherOptions)) return options

    _.each(otherOptions, (value, key) => {
      if (options[key]) {
        _.assign(options[key], value)
      } else {
        _.assign(options, { [key]: value })
      }
    })
    return options
  }

  _getOptions = (urlOptions, columns, options) => {
    if (_.isEmpty(columns)) {
      return urlOptions
    }
    const _urlOptions = _.cloneDeep(urlOptions)
    const cpOptions = this._getCustomPropertiesOptions(columns, urlOptions, options)
    const dcOptions = this._getDefaultColumnsOptions(columns)
    const selectedOnlyOptions = this._getOnlySelectedOptions(options)
    logConsole.debug('selectedOptions are ', selectedOnlyOptions)
    this._mergeOptions(_urlOptions, cpOptions)
    this._mergeOptions(_urlOptions, dcOptions)
    if (selectedOnlyOptions) {
      this._mergeOptions(_urlOptions, selectedOnlyOptions)
    }
    return _urlOptions
  }

  _getDevices = (urlOptions, columns, options) => {
    logConsole.debug('urlOptions are', urlOptions)
    logConsole.debug('columns are', columns)
    logConsole.debug('options are', options)
    urlOptions = this._getOptions(urlOptions, columns, options)
    logConsole.debug('after options are', urlOptions)

    if (options.customFilter) {
      this._mergeOptions(urlOptions, options.customFilter)
    }

    return this.bfDevices.getDevices(urlOptions).pipe(
      map((data) => {
        return { totalCount: data.count, rows: data.devices }
      })
    )
  }

  render() {
    const {
      showPaginationIcons = true,
      showRelevantFilter,
      options = {
        updateUrl: false,
        showDeployButton: false,
        showSelectedOnly: true
      }
    } = this.props
    const { defaultHeadersWithIds } = this.state
    if (this.state.loading) {
      return null
    }

    return (
      <BfDataGridTable
        selectableRow
        headers={defaultHeadersWithIds}
        getRows={this._getDevices}
        options={options}
        id={this.id}
        onRowSelected={this.props.onRowSelected}
        showPaginationIcons={showPaginationIcons}
        paginationBarComponents={
          _.isEmpty(showRelevantFilter)
            ? null
            : [<BfShowRelevantItems key='showRelevantItemsDevices' showRelevantFilter={showRelevantFilter} id={this.id} />]
        }
        preSelectedItems={this.props.preSelectedItems}
        showSummary={this.props.showSummary}
        addColumnButtonCustomModal={
          _.isDefined(defaultHeadersWithIds) && (
            <BfAddColumnPropertiesWithModal
              id='modal_properties'
              parentId={this.id}
              defaultHeaders={defaultHeadersWithIds}
              permanentRows={{
                [defaultHeadersWithIds['Computer Name'].id]: defaultHeadersWithIds['Computer Name']
              }}
            />
          )
        }
        displayContainer={this.props.displayContainer}
      />
    )
  }
}

export default withTranslation()(BfWithServicesConsumer(BfDevicesGrid))
