/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2021
// All Rights Reserved
*/

import { map } from 'rxjs/operators'
import BfCachedRequest from '../../../../../services/utils/BfCachedRequest'
import _ from '../../../../../services/utils/BfLodash'
import BfBasicFilter from '../../BfBasicFilter'
import { createOsDropDownItem } from '../../common/BfCheckboxTree/BfCheckboxTree'

class BfSimpleOSFilterPolicies extends BfBasicFilter {
  bfCachedRequest = new BfCachedRequest()
  static NAME = 'operatingSystemPolicies'
  static PATH = `filter.${BfSimpleOSFilterPolicies.NAME}`
  constructor(data = {}) {
    super(BfSimpleOSFilterPolicies.PATH, BfSimpleOSFilterPolicies.NAME, data)
  }

  getSelectedItems = () => {
    return _.size(this.operatingSystemPolicies)
  }

  getItems = () => {
    return this.bfCachedRequest.get('/api/policiesUnpaginated').pipe(
      map((res) => {
        var osOptions = []
        var osValues = Object.keys(res.data[0].mdmOS)
        for (var i = 0; i < osValues.length; i += 1) {
          if (osValues[i] !== 'iPadOS') {
            osOptions.push({
              name: osValues[i],
              id: osValues[i]
            })
          }
        }
        return createOsDropDownItem(osOptions)
      })
    )
  }
}

export default BfSimpleOSFilterPolicies
