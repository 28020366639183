/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2021
// All Rights Reserved
*/
import { useState, useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import _ from '../../../services/utils/BfLodash'
import { removeMessageByIdAction } from './BfReduxMessageActions'

const DEFAULT_TIMEOUT_MESSAGE_ALIVE = 5000

const useBfMessageTimerQueue = () => {
  const [timer, setTimer] = useState({})
  const dispatch = useDispatch()
  const messages = useSelector((state) => state.messages?.messages)
  const _removeMessageById = useCallback((id) => dispatch(removeMessageByIdAction(id)), [dispatch])

  useEffect(() => {
    const _timer = {}
    _.each(messages, (message) => {
      /** Set new timeout */
      _timer[message.id] = setTimeout(() => _onCloseMessage(message.id), DEFAULT_TIMEOUT_MESSAGE_ALIVE)
    })
    setTimer((prevTimer) => {
      /** Removes all previous timeout */
      _.each(prevTimer, (timeoutHandle) => clearTimeout(timeoutHandle))
      return _timer
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages])

  const _onCloseMessage = (id) => {
    clearTimeout(timer[id])
    const _timer = _.omit(timer, id)
    setTimer(_timer)
    _removeMessageById(id)
  }

  return { onCloseMessage: _onCloseMessage, consumeMessage: _removeMessageById, idleMessages: messages }
}
export default useBfMessageTimerQueue
