/*
// Licensed Materials - Property of HCL
// © Copyright HCL Technologies Limited 2001, 2021
// All Rights Reserved
*/

import React, { Component } from 'react'
import logConsole from 'loglevel'
import _ from '../../services/utils/BfLodash'
import { withTranslation } from 'react-i18next'

import { bfTranslate } from '../../services/i18n/bfI18Utils'
import BfPatchesService from '../../services/patches/BfPatchesService'
import BfDataGridTable from '../datagrid/BfDataGridTable'
import BfReduxStateConstants from '../common/BfReduxStateConstants'
import BfOnlySelectedFilter from '../datagrid/filters/common/bfOnlySelected/BfOnlySelectedFilter'
import BfShowRelevantItems from '../devices/BfShowRelevantItems'

class BfPatchesGrid extends Component {
  bfPatchesService = new BfPatchesService()

  constructor(props) {
    super(props)

    const { t } = props

    this.highlightedRows = {}

    this.headers = [
      {
        label: bfTranslate(t, 'patchNameColumnHeader'),
        field: 'name',
        propertyName: 'Patch Name',
        isDefault: true,
        pinned: 'left',
        styles: { left: '40px' },
        width: '200px',
        sortable: true,
        columnHtmlType: 'patchNameSearchHTML',
        type: 'patch'
      },
      {
        label: bfTranslate(t, 'patchVulnerableDevicesColumnHeader'),
        field: 'relevant_device_count',
        propertyName: 'Vulnerable Devices',
        isDefault: true,
        styles: { left: '40px' },
        width: '200px',
        sortable: true,
        columnHtmlType: 'patchVulnerableDevicesHTML',
        type: 'url',
        url: '/patch/patches/[site_id]/[contentId]#devices'
      },
      {
        label: bfTranslate(t, 'patchOpenActionsColumnHeader'),
        field: 'open_action_count',
        propertyName: 'Open Actions',
        isDefault: true,
        styles: { left: '40px' },
        width: '200px',
        sortable: true,
        columnHtmlType: 'patchOpenActionsHTML',
        type: 'url',
        url: '/patch/patches/[site_id]/[contentId]#deployments'
      },
      {
        label: bfTranslate(t, 'patchIdColumnHeader'),
        field: 'contentId',
        propertyName: 'ID',
        isDefault: true,
        styles: { left: '40px' },
        width: '200px',
        columnHtmlType: 'patchContentIDSearchHTML'
      },
      {
        label: bfTranslate(t, 'patchSiteNameColumnHeader'),
        title: 'patchSiteNameColumnHeader',
        field: 'site_display_name',
        propertyName: 'Site Display Name',
        isDefault: true,
        styles: { left: '40px' },
        width: '200px',
        columnHtmlType: 'patchSiteIDsSearchHTML'
      },
      {
        label: bfTranslate(t, 'patchSeverityColumnHeader'),
        field: 'source_severity',
        propertyName: 'Severity',
        filterKey: 'severity',
        isDefault: true,
        styles: { left: '40px' },
        width: '200px',
        columnHtmlType: 'sourceSeverityHTML'
      },
      {
        label: bfTranslate(t, 'patchSoftwareColumnHeader'),
        title: 'OS', // used in array modal, actually a translation key
        field: 'os',
        propertyName: 'OS',
        isDefault: true,
        styles: { left: '40px' },
        width: '200px',
        type: 'arrayCellPipe',
        filterKey: 'software_search',
        columnHtmlType: 'patchSoftwareSearchHTML'
      },
      {
        label: bfTranslate(t, 'patchCveIdsColumnHeader'),
        title: 'patchCveIdsColumnHeader',
        field: 'cve_id_list',
        propertyName: 'CVE IDs',
        isDefault: true,
        styles: { left: '40px' },
        width: '200px',
        columnHtmlType: 'patchCVEIDSearchHTML',
        type: 'arrayCellSemicolon'
      },
      {
        label: bfTranslate(t, 'patchCategoryColumnHeader'),
        field: 'category',
        propertyName: 'Category',
        isDefault: true,
        styles: { left: '40px' },
        width: '200px',
        columnHtmlType: 'patchCategoryHTML'
      },
      {
        label: bfTranslate(t, 'patchReleaseDateColumnHeader'),
        field: 'source_release_date',
        propertyName: 'Release Date',
        filterKey: 'issueDate', // used for export
        isDefault: true,
        styles: { left: '40px' },
        width: '200px',
        sortable: true,
        type: 'localizedDate',
        columnHtmlType: 'patchReleaseDateHTML'
      }
    ]
  }

  _getOnlySelectedOptions = (options) => {
    if (options.showOnlySelected) {
      const bfOnlySelectedFilter = new BfOnlySelectedFilter(true, options.selectedItemsIds)
      return bfOnlySelectedFilter.getFilter()
    }
    return null
  }

  _mergeOptions = (options, otherOptions) => {
    _.each(otherOptions, (value, key) => {
      if (options[key]) {
        _.assign(options[key], value)
      } else {
        _.assign(options, { [key]: value })
      }
    })
    return options
  }

  _getOptions = (urlOptions, columns, options) => {
    if (_.isEmpty(columns)) {
      return urlOptions
    }
    const _urlOptions = _.cloneDeep(urlOptions)
    const selectedOnlyOptions = this._getOnlySelectedOptions(options)
    logConsole.debug('selectedOptions are ', selectedOnlyOptions)
    if (selectedOnlyOptions) {
      this._mergeOptions(_urlOptions, selectedOnlyOptions)
    }
    return _urlOptions
  }

  _parsePatchesResult = (patches) => {
    return _.map(patches, (patch) => {
      if (patch.is_hidden === 1) {
        patch.activeStyle = true
      }
      patch.contentId = patch.id
      patch.id = `${patch.contentId}-${patch.site_id}`
      return patch
    })
  }

  _getPatches = (urlOptions, visibleColumns, options) => {
    urlOptions = this._getOptions(urlOptions, visibleColumns, options)
    return this.bfPatchesService.getPatches(urlOptions)
  }

  render() {
    const {
      options = {
        updateUrl: false,
        showDeployButton: false,
        showSelectedOnly: true
      },
      showRelevantFilter,
      customPatchService = null,
      customActionBar,
      id = null,
      customHeaderParser = null
    } = this.props

    return (
      <BfDataGridTable
        selectableRow
        getRows={customPatchService || this._getPatches}
        headers={(customHeaderParser && customHeaderParser(this.headers)) || this.headers}
        id={id || BfReduxStateConstants.PATCHES}
        paginationBarComponents={
          _.isEmpty(showRelevantFilter)
            ? null
            : [<BfShowRelevantItems key='showRelevantItemsPatch' showRelevantFilter={showRelevantFilter} id={id || BfReduxStateConstants.PATCHES} />]
        }
        customRowProcessor={this._parsePatchesResult}
        customActionBar={customActionBar}
        onRowSelected={this.props.onRowSelected}
        onRowsSelected={this.props.onRowsSelected}
        preSelectedItems={this.props.selectedTasks}
        showPaginationIcons
        showSummary={this.props.showSummary}
        options={options}
      />
    )
  }
}

export default withTranslation()(BfPatchesGrid)
