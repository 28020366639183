/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import React, { useEffect, useReducer, useRef } from 'react'

import BfConsole from 'loglevel'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { map } from 'rxjs/operators'

import './BfDeviceSummaryCharts.scss'
import BfHttpClient from '../../../services/authClient/BfHttpClient'
import { bfTranslate } from '../../../services/i18n/bfI18Utils'
import _ from '../../../services/utils/BfLodash'
import BfReduxStateConstants from '../../common/BfReduxStateConstants'
import { createFilterManagerFromReduxState } from '../../datagrid/filters/BfFilterManager'
import { BfWithReduxStoreConsumer } from '../../mainAppContainer/bfReduxStore/BfReduxStore'
import { updateFilterListUrlAction } from '../../datagrid/redux/BfFilterListUpdateURLAction'
import BfDeviceTypeChart from './BfDeviceTypeChart'
import BfGroupChart from './BfGroupChart'
import BfOSFamilyChart from '../../common/BfCharts/BfOSFamilyChart'
import { updateIsSummaryLoadedAction } from '../../common/BfCharts/redux/BfChartUpdateAction'
import BfChartsContainer from '../../common/BfCharts/BfChartsContainer'

const DEVICE_SUMMARY_API = '/api/framework/deviceSummary'
const DEVICE_TYPE_ID = 'deviceType'
const OS_FAMILY_ID = 'osFamily'
const LARGEST_GROUP_ID = 'largestGroup'

const initialState = {
  gettingChartData: false,
  deviceTypeByReportTime: undefined,
  osData: undefined,
  groupListData: undefined,
  chartsLoadedBefore: false,
  chartsLoaded: {}
}

function reducer(state, action) {
  switch (action.type) {
    case 'beforeReload':
      return { ...state, gettingChartData: true, chartsLoaded: {} }
    case 'loadedCharts':
      return {
        ...action.payload,
        gettingChartData: false,
        chartsLoadedBefore: true
      }
    case 'updateChartLoaded':
      if (!action.payload) {
        throw new Error('Unknown chart id. Unable to update chart loading state.')
      }
      var updatedChart = state.chartsLoaded ? _.cloneDeep(state.chartsLoaded) : {}
      updatedChart[action.payload] = true
      return { ...state, chartsLoaded: updatedChart }
  }
}

const bfHttpClient = new BfHttpClient()

function BfDeviceSummaryCharts(props) {
  const [state, dispatch] = useReducer(reducer, initialState)

  const { searchObj, showSummary, updateIsSummaryLoadedAction, updateFilterListUrlAction, isExportingType, isSummaryLoaded, t } = props
  const previousSearchObjRef = useRef()
  useEffect(() => {
    previousSearchObjRef.current = _.cloneDeep(searchObj)
  })
  const prevSearchObj = previousSearchObjRef.current

  const updateChartLoaded = (chartId) => {
    dispatch({ type: 'updateChartLoaded', payload: chartId })
  }

  useEffect(() => {
    const _getSummaryData = () => {
      dispatch({ type: 'beforeReload' })
      // avoid mutating original searchObj
      const searchCopy = JSON.parse(JSON.stringify(searchObj))
      delete searchCopy.reportDetails
      searchCopy.pagination = { page: {} } // set page to empty to use the unpaginated query
      bfHttpClient
        .get(DEVICE_SUMMARY_API, searchCopy)
        .pipe(
          map((summaryObject) => {
            return summaryObject
          })
        )
        .subscribe(
          (response) => {
            dispatch({ type: 'loadedCharts', payload: response })
          },
          (error) => BfConsole.error(error)
        )
    }

    if (showSummary && !state.chartsLoadedBefore && !state.gettingChartData) {
      _getSummaryData()
    } else if (!_.isUndefined(searchObj) && !_.isEqual(prevSearchObj, searchObj) && state.chartsLoadedBefore) {
      _getSummaryData()
    }
  }, [searchObj, prevSearchObj, showSummary, state.chartsLoadedBefore, state.gettingChartData])

  useEffect(() => {
    if (state.chartsLoaded && state.chartsLoaded[DEVICE_TYPE_ID] && state.chartsLoaded[OS_FAMILY_ID] && state.chartsLoaded[LARGEST_GROUP_ID]) {
      if (!isSummaryLoaded) {
        updateIsSummaryLoadedAction(BfReduxStateConstants.DEVICE_CHARTS, true)
      }
    } else {
      if (isSummaryLoaded) {
        updateIsSummaryLoadedAction(BfReduxStateConstants.DEVICE_CHARTS, false)
      }
    }
  }, [state.chartsLoaded, updateIsSummaryLoadedAction, isSummaryLoaded])

  return (
    <BfChartsContainer
      className='bfDeviceSummaryCharts'
      showSummary={showSummary}
      isExportingType={isExportingType}
      isSummaryLoaded={isSummaryLoaded}
    >
      <div className='bfDeviceTypeContainer'>
        <BfDeviceTypeChart
          deviceTypeByReportTime={state.deviceTypeByReportTime}
          gettingChartData={state.gettingChartData}
          chartTitle={bfTranslate(t, 'deviceTypeByReportTime')}
          updateChartLoaded={updateChartLoaded}
          updateFilterListUrlAction={updateFilterListUrlAction}
          chartId={DEVICE_TYPE_ID}
          namespace={BfReduxStateConstants.DEVICES}
          isLoaded={state.chartsLoaded ? state.chartsLoaded[DEVICE_TYPE_ID] : false}
        />
      </div>

      <div className='bfOSContainer'>
        <BfOSFamilyChart
          osData={state.osData}
          gettingChartData={state.gettingChartData}
          chartTitle={bfTranslate(t, 'byOSFamily')}
          updateChartLoaded={updateChartLoaded}
          updateFilterListUrlAction={updateFilterListUrlAction}
          chartId={OS_FAMILY_ID}
          namespace={BfReduxStateConstants.DEVICES}
          isLoaded={state.chartsLoaded ? state.chartsLoaded[OS_FAMILY_ID] : false}
        />
      </div>

      <div className='bfGroupContainer'>
        <BfGroupChart
          groupListData={state.groupListData}
          gettingChartData={state.gettingChartData}
          chartTitle={bfTranslate(t, 'byLargestGroup')}
          updateChartLoaded={updateChartLoaded}
          updateFilterListUrlAction={updateFilterListUrlAction}
          chartId={LARGEST_GROUP_ID}
          namespace={BfReduxStateConstants.DEVICES}
          isLoaded={state.chartsLoaded ? state.chartsLoaded[LARGEST_GROUP_ID] : false}
        />
      </div>
    </BfChartsContainer>
  )
}

BfDeviceSummaryCharts.propTypes = {
  showSummary: PropTypes.bool.isRequired,
  isSummaryLoaded: PropTypes.bool.isRequired,
  isExportingType: PropTypes.bool.isRequired,
  /** From mapStateToProps, contains filters/search for devices */
  updateIsSummaryLoadedAction: PropTypes.func,
  /** updates  */
  searchObj: PropTypes.object,
  /** i18next translation function */
  t: PropTypes.func
}

const mapStateToProps = (state) => {
  const bfFilterManager = createFilterManagerFromReduxState(state, BfReduxStateConstants.DEVICES)
  const searchObj = bfFilterManager.getSearch()
  return {
    searchObj
  }
}

const BfDeviceSummaryReduxComponent = connect(mapStateToProps, {
  updateIsSummaryLoadedAction,
  updateFilterListUrlAction
})(withTranslation()(BfDeviceSummaryCharts))

export default BfWithReduxStoreConsumer(BfDeviceSummaryReduxComponent)
