/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2021
// All Rights Reserved
*/

import React from 'react'
import { TextArea } from '@patron/patron-react/textarea'
import './BfTextArea.scss'

function BfTextArea(props) {
  const { required, isInvalid, errorMsg, field, labelClass = 'hcl-label', className = '', ...rest } = props
  const { name, id = name } = rest

  return (
    <div className='hcl-form-group '>
      <TextArea className={className} {...field} {...rest} data-invalid={isInvalid} />
      {props.label && (
        <label htmlFor={id} className={labelClass}>
          {props.label} {required ? <i className='bf-required'> *</i> : ''}
        </label>
      )}
      <div className='hcl-helper-text hcl-text-left'> {props.subLabel}</div>
      {isInvalid && <div className='hcl-error-msg hcl-text-left'>{errorMsg}</div>}
    </div>
  )
}

export default BfTextArea
