/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import React, { useState, useEffect } from 'react'
import { ContentSwitcher, Switch } from '@patron/patron-react/contentswitcher'
import { Label } from '@patron/patron-react/label'
import { Button } from '@patron/patron-react/button'
import { TextArea } from '@patron/patron-react/textarea'
import { Spinner } from '@patron/patron-react/spinner'
import { Notification } from '@patron/patron-react/notification'
import _ from '../../../services/utils/BfLodash'
import BfWithMessageProvider from '../../common/bfMessage/BfWithMessageProvider'
import { withTranslation } from 'react-i18next'
import BfDevicesNotRecognized from './BfDevicesNotRecognized'
import BfWithDataGridTableFeatures from '../../datagrid/BfWithDataGridTableFeatures'
import BfReduxStateConstants from '../../common/BfReduxStateConstants'
import useBfDevicesExist from '../../../services/manualTargeting/BfUseDevicesExist'
import { bfTranslate } from '../../../services/i18n/bfI18Utils'
import BfDebounceSubject from '../../common/BfDebounceSubject'

const BfManualTargetingContent = (props) => {
  const bfDebounceSubjectInputChange = new BfDebounceSubject()
  const { onCancelClicked, sendDetailedMessage, selectRows, t } = props
  const [manualTargetingInput, setManualTargetingInput] = useState({
    type: '',
    description: '',
    id: ''
  })

  const { realTargets, invalidTargets, status } = useBfDevicesExist(manualTargetingInput)

  const switches = [
    {
      id: 'name',
      type: 'name',
      label: bfTranslate(t, 'nameLabel'),
      placeholder: bfTranslate(t, 'namePlaceholder')
    },
    {
      id: 'ipAddresses',
      type: 'ip',
      label: bfTranslate(t, 'ipAddressLabel'),
      placeholder: bfTranslate(t, 'ipAddressPlaceholder')
    },
    {
      id: 'dnsName',
      type: 'dns',
      label: bfTranslate(t, 'dnsLabel'),
      placeholder: bfTranslate(t, 'dnsPlaceholder')
    }
  ]
  const [idManualTargeting, setIdManualTargeting] = useState(_.head(switches).type)
  const [descriptionManualTargeting, setDescriptionManualTargeting] = useState('')
  const [placeholder, setPlaceholder] = useState(_.head(switches).placeholder)

  const _onSwitchChange = (e) => {
    const selection = _.find(switches, (el) => el.label === e.label)
    setIdManualTargeting(selection.type)
    setPlaceholder(selection.placeholder)
  }

  bfDebounceSubjectInputChange.subscribe({
    next: (value) => {
      setDescriptionManualTargeting(value.replace(/\s*,\s*/g, ','))
    }
  })

  const _onDescriptionChange = (e) => {
    bfDebounceSubjectInputChange.next(e.target.value)
  }

  useEffect(() => {
    if (_.isNotEmpty(realTargets)) {
      selectRows(BfReduxStateConstants.DEVICES, realTargets)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realTargets])

  useEffect(() => {
    if (_.isNotEmpty(invalidTargets)) {
      sendDetailedMessage('', '', <BfDevicesNotRecognized invalidDevices={invalidTargets} />, 'warning')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invalidTargets])

  useEffect(() => {
    status === 'complete' && onCancelClicked()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])

  const _onSendManualTargeting = () => {
    setManualTargetingInput({
      type: idManualTargeting,
      description: descriptionManualTargeting,
      id: _.find(switches, ['type', idManualTargeting]).id
    })
  }

  const _showErrorNotification = (errorMessage) => {
    const icon = <i className='p-hclsw p-hclsw-close-circle selected bfErrorNotificationColor' />
    return <Notification subtitle={errorMessage} title={bfTranslate(t, 'error')} type='danger' visible closable={false} icon={icon} />
  }

  const _showContent = () => {
    return (
      <div>
        <TextArea aria-label='description' className='mt-4' onChange={_onDescriptionChange} placeholder={placeholder} />
        {status === 'error' && _showErrorNotification(bfTranslate(t, 'SendMessageErrorMessage'))}
      </div>
    )
  }

  return (
    <React.Fragment>
      <Label className='hcl-type-zeta'>{bfTranslate(t, 'manualTargetingTitle')}</Label>
      <ContentSwitcher activeIndex={0} onChange={(event) => _onSwitchChange(event)}>
        {_.map(switches, (el) => {
          return <Switch active={false} key={el.id} icon={null} isDisabled={false} label={el.label} />
        })}
      </ContentSwitcher>
      {status === 'loading' ? <Spinner className='mt-5 mb-5' label={bfTranslate(t, 'loading')} small /> : _showContent()}
      <div className='button-container'>
        <Button
          className='mr-2'
          disabled={status === 'loading'}
          kind='button'
          small={false}
          title='Default'
          type='secondary'
          onClick={onCancelClicked}
        >
          {bfTranslate(t, 'cancel')}
        </Button>
        <Button
          disabled={_.isEmpty(descriptionManualTargeting) || status === 'loading'}
          kind='button'
          small={false}
          title='Default'
          type='primary'
          onClick={_onSendManualTargeting}
        >
          {bfTranslate(t, 'add')}
        </Button>
      </div>
    </React.Fragment>
  )
}

export default BfWithMessageProvider(withTranslation()(BfWithDataGridTableFeatures(BfManualTargetingContent)))
