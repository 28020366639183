/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/
import React, { Component } from 'react'
import './BfEditor.scss'
import _ from '../../../../services/utils/BfLodash'
import SunEditor from 'suneditor-react'
import 'suneditor/dist/css/suneditor.min.css'

class BfEditor extends Component {
  editorRef = React.createRef()
  OLD_STYLE_OPTIONS = {
    addTagsWhitelist: 'font',
    attributesWhitelist: { font: 'color|style' }
  }

  DEFAULT_OPTIONS = {
    showPathLabel: false,
    resizingBar: false,
    buttonList: [
      ['bold', 'italic', 'underline', 'removeFormat'],
      ['strike', 'superscript', 'subscript'],
      ['fontColor', 'hiliteColor'],
      ['list', 'align'],
      ['link', 'horizontalRule', 'table'],
      ['fullScreen', 'codeView']
    ],
    colorList: [
      '#ff0000',
      '#ff5e00',
      '#ffe400',
      '#abf200',
      '#00d8ff',
      '#0055ff',
      '#6600ff',
      '#ff00dd',
      '#000000',
      '#f15f5f',
      '#f29661',
      '#e5d85c',
      '#bce55c',
      '#5cd1e5',
      '#6699ff',
      '#a366ff',
      '#f261df',
      '#8c8c8c',
      '#980000',
      '#993800',
      '#998a00',
      '#6b9900',
      '#008299',
      '#003399',
      '#3d0099',
      '#990085',
      '#353535',
      '#670000',
      '#662500',
      '#665c00',
      '#476600',
      '#005766',
      '#002266',
      '#290066',
      '#660058',
      '#222222'
    ]
  }

  _fApplyColor(color) {
    if (!color) return

    const newNode = this.util.createElement('FONT')
    newNode.color = color
    newNode.style.color = color
    this.nodeChange(newNode, ['color'], null, null)

    this.submenuOff()
  }

  _fAppendTable() {
    const oTable = this.util.createElement('TABLE')
    oTable.className += 'table table-bordered'
    const createCells = this.plugins.table.createCells

    const x = this.context.table._tableXY[0]
    let y = this.context.table._tableXY[1]
    let tableHTML = '<tbody>'
    while (y > 0) {
      tableHTML += '<tr>' + createCells.call(this, 'td', x) + '</tr>'
      --y
    }
    tableHTML += '</tbody>'
    oTable.innerHTML = tableHTML

    const changed = this.insertComponent(oTable, false, true, false)

    if (changed) {
      const firstTd = oTable.querySelector('td div')
      this.setRange(firstTd, 0, firstTd, 0)
      this.plugins.table.reset_table_picker.call(this)
    }
  }

  componentDidMount = () => {
    if (this.props.oldStyle) {
      this.editorRef.current.editor.core.plugins.fontColor.applyColor = this._fApplyColor
      this.editorRef.current.editor.core.plugins.table.appendTable = this._fAppendTable
    }
  }

  render() {
    const {
      className,
      placeholder = '',
      onChange = null,
      toggleCodeView = null,
      options,
      value,
      oldStyle,
      label,
      isInvalid,
      errorMsg,
      required,
      id,
      ...rest
    } = this.props

    const editorOptions = _.isDefined(options) ? _.assign(this.DEFAULT_OPTIONS, options) : this.DEFAULT_OPTIONS

    if (oldStyle) {
      _.assign(editorOptions, this.OLD_STYLE_OPTIONS)
    }

    return (
      <div className={isInvalid ? 'bf-bfeditor-error' : null}>
        {label && (
          <label htmlFor={id} className='hcl-label'>
            {label} {required ? <i className='bf-required'> *</i> : ''}
          </label>
        )}
        <SunEditor
          ref={this.editorRef}
          onChange={(value) => {
            if (onChange) {
              onChange(value, this.editorRef.current.editor.getText())
            }
          }}
          toggleCodeView={(isCodeView) => {
            const text = this.editorRef.current.editor.getText()
            const contents = this.editorRef.current.editor.getContents()
            if (toggleCodeView) {
              toggleCodeView(isCodeView, text, contents)
            }
          }}
          setDefaultStyle='font-family: Roboto; background-color: #e6e6e6;'
          setContents={value}
          placeholder={placeholder}
          setOptions={editorOptions}
          {...rest}
        />
        {isInvalid && <div className='hcl-error-msg hcl-text-left'>{errorMsg}</div>}
      </div>
    )
  }
}
export default BfEditor
