/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/
import React from 'react'
import BfOverlayWithButton from '../../../../../modals/BfOverlayWithButton'
import BfNumberGreaterLess from './BfNumberGreaterLess'
import './BfNumberGreaterLess.scss'

export const GREATER_OR_EQUAL_THAN = 'geq'
export const LESS_OR_EQUAL_THAN = 'leq'
export const EQUAL = 'eq'

const BfNumberInputAndDropDownFilter = (props) => {
  const { filter, onChange } = props

  const _onValueChange = (val) => {
    filter.setVal(val)
    onChange(filter.get())
  }

  const _cleanAllSelection = () => {
    filter.setData({})
    onChange(filter.get())
  }

  const _onOptionChange = (option) => {
    filter.setOption(option)
    onChange(filter.get())
  }

  return (
    <BfOverlayWithButton itemsSelected={filter.getCount()} cleanItemSelected={_cleanAllSelection}>
      <div className='bf-greater-less-container'>
        <BfNumberGreaterLess
          onValueChange={_onValueChange}
          onOptionChange={_onOptionChange}
          enabledOptions={filter.getOptions()}
          preSelectedOption={filter.getPreselectedOption()}
          defaultValue={filter.getVal()}
        />
      </div>
    </BfOverlayWithButton>
  )
}

export default BfNumberInputAndDropDownFilter
