/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import BfHttpClient from '../authClient/BfHttpClient'
import { map } from 'rxjs/operators'
import { of } from 'rxjs'

class BfGearAppsService {
  /**
   * Stores current gear app information
   */
  gearAppsInfo = null
  http = new BfHttpClient()

  /**
   * Initiates an HTTP request to fetch the gear app information from the server
   * @method
   * @returns {Promise} HTTP request.
   */
  getGearAppsInfo() {
    return this.http.get('/getGearApps').pipe(
      map((res) => {
        this.gearAppsInfo = res
        return this.gearAppsInfo
      })
    )
  }

  /**
   * Returns Gear app information if already retrieved from server else initiate HTTP call from getGearAppsInfo
   * @method
   * @returns {Promise}
   */
  get() {
    return this.gearAppsInfo ? of(this.gearAppsInfo) : this.getGearAppsInfo()
  }
}

export default BfGearAppsService
