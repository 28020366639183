/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import React, { cloneElement } from 'react'
import _ from '../../../services/utils/BfLodash'
import './BfActionBar.scss'
import { BfTrans, bfTranslate } from '../../../services/i18n/bfI18Utils'
import { useTranslation } from 'react-i18next'
import { BfDataGridComponentWithContext } from '../BfDataGridContext'
import BfActionButtons from '../../device/bfActionButtons/BfActionButtons'
import { Checkbox } from '@patron/patron-react/checkbox'

const BfActionBar = (props) => {
  const { selectedItems, showDeployButton, onShowSelectedOnly, preSelectedShowSelectedOnly, showSelectedOnly, customActionBar, id } = props
  const { t } = useTranslation()
  const selectedItemsNum = _.size(selectedItems)

  if (selectedItemsNum === 0) {
    return null
  }

  return (
    <div className='bfActionBar'>
      <div className='hcl-container'>
        <div className='hcl-row bfActionRow'>
          <div className='hcl-col-12'>
            <div className='bfInlineBlock'>
              {BfTrans('ItemsSelected', {
                count: selectedItemsNum
              })}
            </div>
            {showSelectedOnly && (
              <div className='bfInlineBlock bfOnlySelected'>
                <Checkbox
                  checked={preSelectedShowSelectedOnly}
                  key={_.toString(preSelectedShowSelectedOnly)}
                  id={`selectedOnly_${id}`}
                  label={bfTranslate(t, 'ViewSelectedOnly')}
                  onChange={() => onShowSelectedOnly()}
                />
              </div>
            )}
            {_.isDefined(customActionBar) && (
              <React.Fragment>
                <div className='bfInlineBlock vertical-divider ml-5' />
                <div className='bfInlineBlock bfDeployButtonRow'>{cloneElement(customActionBar, { ...props })}</div>
              </React.Fragment>
            )}
            {showDeployButton && (
              <React.Fragment>
                <div className='bfInlineBlock vertical-divider ml-5' />
                <div className='bfInlineBlock bfDeployButtonRow'>
                  <BfActionButtons selectedItems={selectedItems} />
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default BfDataGridComponentWithContext(BfActionBar)
