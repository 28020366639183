/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2021
// All Rights Reserved
*/

import BfSearchStringFilter from '../common/bfSearch/BfSearchStringFilter'

class BfPatchNameSearchFilter extends BfSearchStringFilter {
  static NAME = 'name_search'
  static PATH = `filter.${BfPatchNameSearchFilter.NAME}`
  doFilter = true
  constructor(query) {
    super(BfPatchNameSearchFilter.NAME, query)
  }
}

export default BfPatchNameSearchFilter
