/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import React from 'react'
import BfAddColumnProperties from './BfAddColumnProperties'
import logConsole from 'loglevel'
import { connect } from 'react-redux'
import _ from '../../../services/utils/BfLodash'
import BfColumnSelectionFilter from '../filters/common/bfHeaderSelection/BfColumnSelectionFilter'
import { dispatchAction } from '../redux/BfFilterListUpdateURLAction'
import { BfDataGridComponentWithContext } from '../BfDataGridContext'
import { createFilterManagerFromReduxState } from '../filters/BfFilterManager'
import BfModalDataGrid from '../../modals/BfModalDataGrid'
import { withTranslation } from 'react-i18next'
import { makeContentKey } from './BfAddColumnHelper'
import { bfTranslate } from '../../../services/i18n/bfI18Utils'
import BfResetColumnsService from '../services/BfResetColumnsService'

class BfAddColumnPropertiesWithModal extends React.Component {
  toAddCustomProperties = []
  toAddDefaultProperties = {}

  _onCloseModal = () => {
    const { onCloseModal } = this.props
    onCloseModal()
  }

  _onSaveModal = () => {
    const bfResetColumnsService = new BfResetColumnsService(
      this.props.dispatchAction,
      this.props.parentId,
      this.toAddDefaultProperties,
      this.toAddCustomProperties,
      this.props.bfColumnSelectionFilter,
      this.props.bfCustomPropertiesFilter
    )
    bfResetColumnsService.changeColumns()
    this._onCloseModal()
  }

  _addCustomProperty = (selectedHeaderProperty) => {
    const ix = _.findIndex(this.toAddCustomProperties, (prop) => prop.field === selectedHeaderProperty.field)
    if (ix > -1) {
      this.toAddCustomProperties[ix]._toBeRemoved = false
    } else {
      selectedHeaderProperty._toBeRemoved = false
      this.toAddCustomProperties.push(selectedHeaderProperty)
    }
  }

  _removeCustomProperty = (selectedHeaderProperty) => {
    const ix = _.findIndex(this.toAddCustomProperties, (prop) => prop.field === selectedHeaderProperty.field)
    if (ix > -1) {
      this.toAddCustomProperties[ix]._toBeRemoved = true
    } else {
      selectedHeaderProperty._toBeRemoved = true
      this.toAddCustomProperties.push(selectedHeaderProperty)
    }
  }

  _onRowSelected = (selectedHeaderProperty, isSelected) => {
    logConsole.debug('Selected row is ', selectedHeaderProperty)

    if (selectedHeaderProperty._isCustomProperties) {
      if (isSelected) {
        this._addCustomProperty(selectedHeaderProperty)
      } else {
        this._removeCustomProperty(selectedHeaderProperty)
      }
    } else {
      const key = selectedHeaderProperty.defaultField
      this.toAddDefaultProperties[key] = isSelected
    }
  }

  _initializeSelectedProperties = (propertiesHeaders) => {
    const headers = _.reduce(
      propertiesHeaders,
      (obj, header) => {
        if (_.isDefined(header._isCustomProperties) && header._isCustomProperties) {
          obj.customProperties.push(header)
        } else {
          obj.defaultProperties[header.field] = true
        }
        return obj
      },
      { customProperties: [], defaultProperties: {} }
    )

    this.toAddCustomProperties = _.concat(this.toAddCustomProperties, headers.customProperties)

    this.toAddDefaultProperties = _.extend(this.toAddDefaultProperties, headers.defaultProperties)
  }

  _getSelectedProperties = () => {
    const propertiesHeaders = this.props.bfColumnSelectionFilter.getVisibleHeaders()
    return _.map(propertiesHeaders, (propHeader) => {
      if (_.isUndefined(propHeader.id)) {
        propHeader.id = makeContentKey(propHeader)
      }
      return propHeader
    })
  }

  componentDidMount = () => {
    const propertiesHeaders = this.props.bfColumnSelectionFilter.getVisibleHeaders()
    this._initializeSelectedProperties(propertiesHeaders)
  }

  render() {
    logConsole.debug('Render modal addColumnProperties')
    return (
      <BfModalDataGrid
        onClose={this._onCloseModal}
        onSave={this._onSaveModal}
        heading={bfTranslate(this.props.t, 'ManageColumn')}
        datagridTable={BfAddColumnProperties}
        defaultHeaders={this.props.defaultHeaders}
        permanentRows={this.props.permanentRows}
        id='modal_properties'
        labelConfirm='save'
        onRowSelected={this._onRowSelected}
        selectedItems={this._getSelectedProperties()}
        noDraggableColumn
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const bfFilterManager = createFilterManagerFromReduxState(state, ownProps.parentId)
  const bfCustomPropertiesFilter = bfFilterManager.getCustomPropertiesFilter()
  return {
    bfColumnSelectionFilter: new BfColumnSelectionFilter(state[ownProps.parentId].columns?.cols),
    bfCustomPropertiesFilter: bfCustomPropertiesFilter
  }
}

const BfAddColumnPropertiesWithModalRedux = connect(mapStateToProps, dispatchAction)(withTranslation()(BfAddColumnPropertiesWithModal))

export default BfDataGridComponentWithContext(BfAddColumnPropertiesWithModalRedux)
