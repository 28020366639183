/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import BfDropDownSearchBasicFilter from '../../common/bfBasicFilters/BfDropDownSearchFilter/BfDropDownSearchBasicFilter'

class BfDnsNameFilter extends BfDropDownSearchBasicFilter {
  static NAME = 'dnsName'
  static PATH = `filter.${BfDnsNameFilter.NAME}`
  constructor(data = {}) {
    super(BfDnsNameFilter.PATH, BfDnsNameFilter.NAME)
    this[BfDnsNameFilter.NAME] = data
  }

  getSearchURL = () => {
    return '/devices/dns'
  }
}

export default BfDnsNameFilter
