/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import React from 'react'
import _ from '../../services/utils/BfLodash'
import { Overflowmenu } from '@patron/patron-react/overflowmenu'
import { BfTrans } from '../../services/i18n/bfI18Utils'
import './BfNavigationBar.scss'
import { Link } from '@patron/patron-react/link'

/**
 * Returns navbar apps
 */
class NavBarApps extends React.Component {
  /**
   * Maps the routing of tabs
   * @method
   * @param {String} route  routes to Tabs
   * @returns {String} route of the tab
   */
  _routeRemapping = (route) => {
    return route.replace('/framework', '/framework/react').replace('fwplugins', 'plugins')
  }

  /**
   * Returns Application tab links
   * @method
   * @param {*} tab Application tabs
   * @returns {HTMLElement} Application tabs link
   */
  _getApplicationIcon(tab) {
    return (
      <div key={tab.label} className='appsContainer'>
        <Overflowmenu
          className=''
          direction='bottom-left'
          ellipsisType='vertical'
          customTemplate={
            <Link className='hcl hcl-primary hcl-btn BfNavLinkButton'>
              {BfTrans(tab.label)}
              <i className='p-hclsw p-hclsw-angle-down selected ml-1 bf-arrow-style' />
            </Link>
          }
          listItems={tab.subtabs.map((tab) => {
            return { name: tab.label, link: this._routeRemapping(tab.route) }
          })}
        />
        {this._isActiveTab(tab) ? <div className='bfDashBar' /> : ''}
      </div>
    )
  }

  _isActiveTab = (tab) => {
    return _.some(tab.subtabs, (subtab) => this._isActiveApp(subtab))
  }

  _isActiveApp = (app) => {
    return window.location.pathname === app.route
  }

  /**
   * Returns App icons
   * @method
   * @returns {HTMLElement} tabs with updated icons and links
   */
  _getAppIcons() {
    return this.props.tabs.map((tab) => {
      return _.isUndefined(tab.subtabs) ? (
        <div key={tab.label} className='appsContainer'>
          <Link href={tab.route} className='hcl hcl-primary hcl-btn BfNavLinkButton'>
            {tab.label}
          </Link>
          {this._isActiveApp(tab) ? <div className='bfDashBar' /> : ''}
        </div>
      ) : (
        this._getApplicationIcon(tab)
      )
    })
  }

  render() {
    return <div className='BfNavAppsContainer'>{this._getAppIcons()}</div>
  }
}

export default NavBarApps
