/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import React from 'react'
import BfAuthClient from '../../services/authClient/BfAuthClient'
import BfWithMessageConsumer from '../common/bfMessage/BfWithMessageConsumer'
import BfMainPageHeaderTitle from './BfMainPageHeaderTitle'

/**
 * Main landing component.
 * Uses BfMainPageHeaderTitle to display title and summary
 * @component
 * @example
 * const title="BfCore"
 * const summary="Contains the core components to be used across the webui"
 * <BfMainAppContainer title={title} summary={summary}></BfMainAppContainer>
 */
class BfMainAppContainer extends React.Component {
  bfAuthClient = new BfAuthClient()

  constructor(props) {
    super(props)
    this.bfAuthClient.run()
  }

  componentWillMount = function () {
    document.body.classList.add('blue_active_blue_light', 'bigfix')
  }

  render() {
    const { title, children, titleView } = this.props
    return (
      <div className='bfMainAppContainer'>
        <div className='bfMainAppContents'>
          <BfMainPageHeaderTitle title={title}>{titleView}</BfMainPageHeaderTitle>

          <div className='bfContainer'>{children}</div>
        </div>
      </div>
    )
  }
}

export default BfWithMessageConsumer(BfMainAppContainer)
