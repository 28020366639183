/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/
import React from 'react'
import _ from '../../../services/utils/BfLodash'
import BfCellWidth from './BfCellWidth'
import BfCellWithTooltipRenderer from './BfCellWithTooltipRenderer'
import BfCellEmptyRenderer from './BfCellEmptyRenderer'
import './BfCellDefaultRender.scss'

/**
 * Formats cell value
 */
class BfCellDefaultRender {
  constructor(header) {
    this.header = header
    this.name = header.field
  }

  /**
   * returns the cell renderer
   * @returns {Function} Cell renderer
   */
  getDefaultRenderer = (row) => {
    let value
    let emptyCell = false
    let alignRight = false

    if (
      _.isUndefined(row[this.name]) ||
      (!_.isNumber(row[this.name]) && _.isEmpty(row[this.name])) ||
      (_.isString(row[this.name]) && _.isEmpty(row[this.name].trim()))
    ) {
      emptyCell = true
      if (this.header.emptyCellValue) {
        value = this.header.emptyCellValue
      } else {
        value = 'none'
      }
    } else {
      value = row[this.name].toString()
    }
    if (!isNaN(value)) {
      alignRight = true
    }
    if ('isRightAligned' in this.header) {
      alignRight = this.header.isRightAligned
    }

    return (
      <BfCellWidth header={this.header}>
        <span className={`bf-default-cell-tooltip hcl-flex ${this.header.className} ${alignRight ? 'bf-row-reverse' : ''}`}>
          {emptyCell && <BfCellEmptyRenderer value={value} />}
          {!emptyCell && (
            <BfCellWithTooltipRenderer textTooltip={value}>
              <div className='bf-ellipsis bf-value-wrapper'>{value}</div>
            </BfCellWithTooltipRenderer>
          )}
        </span>
      </BfCellWidth>
    )
  }

  getRenderer = () => {
    return this.getDefaultRenderer
  }
}

export default BfCellDefaultRender
