/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/
import BfDropDownBasicFilter from '../../common/bfBasicFilters/bfDropDownFilter/BfDropDownBasicFilter'
import { bfTranslate } from '../../../../../services/i18n/bfI18Utils'

class ReportVisibilityFilter extends BfDropDownBasicFilter {
  static NAME = 'visibility'
  static PATH = `filter.${ReportVisibilityFilter.NAME}`

  constructor(data = {}) {
    super(ReportVisibilityFilter.PATH, ReportVisibilityFilter.NAME, data)
  }

  getItems = () => {
    const t = this.getT()

    const items = {
      values: [
        { id: 'private', text: bfTranslate(t, 'private') },
        { id: 'public', text: bfTranslate(t, 'allUsers') }
      ]
    }

    return items.values
  }
}

export default ReportVisibilityFilter
