/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2021
// All Rights Reserved
*/

import React, { Component } from 'react'
import { Checkbox } from '@patron/patron-react/checkbox'
import _ from '../../../../../services/utils/BfLodash'
import { bfTranslate } from '../../../../../services/i18n/bfI18Utils'
import { withTranslation } from 'react-i18next'
import BfGenericFilterWithReduxAction from '../../common/bfBasicFilters/bfGeneric/BfGenericFilterWithAction'

class ReportFavoritesWithAction extends Component {
  constructor(props) {
    super(props)

    this.next = () => {
      this.props.onChange(this.props.filter.get())
    }
  }

  _handleChecked = (event) => {
    let data = {}
    if (event.target.checked) {
      data = { show: true }
    }
    this.props.filter.setData(data)
    this.next()
  }

  render() {
    const { t, id } = this.props

    return (
      <React.Fragment>
        <Checkbox
          checked={_.isNotEmpty(this.props.filter.getData())}
          id={'reportFavoritesOnly_' + id}
          label={bfTranslate(t, 'viewFavoriteOnly')}
          onChange={this._handleChecked}
          tooltipDirection='bottom'
          tooltipTitle={bfTranslate(t, 'viewFavoriteOnly')}
        />
        <i className='pl-1 p-hclsw p-hclsw-heart selected' />
      </React.Fragment>
    )
  }
}

export default BfGenericFilterWithReduxAction(withTranslation()(ReportFavoritesWithAction))
