/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/
import BfHttpClient from '../authClient/BfHttpClient'
import _ from '../../services/utils/BfLodash'
import { map } from 'rxjs/operators'

class BfDashboardVariable {
  http = new BfHttpClient()

  get(dashboard, variable) {
    return this.http.get('/framework/dashboardvariable/' + dashboard + '/' + variable).pipe(
      map((res) => {
        return _.isEmpty(res) ? null : JSON.parse(res)
      })
    )
  }

  save = (dashboard, variable, values, hidden) => {
    const stringifiedValues = JSON.stringify(values)
    const postDataObj = {
      dbid: dashboard,
      varname: variable,
      hidden: hidden,
      data: {}
    }

    postDataObj.data[variable] = stringifiedValues
    return this.http.post('/framework/dashboardvariables', postDataObj)
  }

  delete = (dashboard, variable) => {
    const deleteUrl = _.isEmpty(variable) ? '/framework/dashboardvariables/' : '/framework/dashboardvariable/'
    return this.http.delete(deleteUrl + dashboard + (_.isEmpty(variable) ? '' : '/' + variable))
  }
}

export default BfDashboardVariable
