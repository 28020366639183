/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import React from 'react'
import { Spinner } from '@patron/patron-react/spinner'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { bfTranslate } from '../../../../services/i18n/bfI18Utils'
import './BfChartContainer.scss'

const BfChartContainer = (props) => {
  const { children, chartTitle, isLoading } = props
  const { t } = useTranslation()
  return (
    <div className='bfChartContainer p-5'>
      <div className='bfChartTitle'>{chartTitle}</div>
      {isLoading && (
        <div className='bfSpinnerContainer'>
          <Spinner title={bfTranslate(t, 'loading')} />
        </div>
      )}
      {!isLoading && children}
    </div>
  )
}

BfChartContainer.propTypes = {
  chartTitle: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
}

export default BfChartContainer
