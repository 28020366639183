/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/
import React, { Component } from 'react'
import BfConsole from 'loglevel'

const BfWithReset = (WrapperComponent, setFilter) => {
  return class extends Component {
    key = false
    render() {
      const filter = setFilter(this.props)
      const reset = filter.getReset()
      if (reset) {
        BfConsole.debug('reset element')
        this.key = !this.key
        filter.setReset(false) // Reset the filter reset flag to apply this only once
      }

      return <WrapperComponent key={this.key.toString()} filter={filter} {...this.props} />
    }
  }
}
export default BfWithReset

export class BfReset extends Component {
  state = { key: false }

  render() {
    const { filter } = this.props
    const reset = filter.getReset()
    if (reset) {
      this.state.key = !this.state.key
      this.setState((state) => {
        return { key: state.key }
      })
      filter.setReset(false) // Reset the filter reset flag to apply this only once
    }

    BfConsole.debug('reset element is ', this.state.key.toString())
    return this.props.children(this.state.key.toString())
  }
}
