/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/
import React from 'react'
import './BfMessagesPane.scss'
import _ from '../../../services/utils/BfLodash'
import BfConsole from 'loglevel'
import withBfModal from '../../modals/BfWithModal'
import { Toast } from '@patron/patron-react/toast'
import useBfMessageTimerQueue from './useBfMessageTimerQueue'
import { withTranslation } from 'react-i18next'
import { bfTranslate } from '../../../services/i18n/bfI18Utils'

const BfMessagesPane = (props) => {
  const { onCloseMessage, consumeMessage, idleMessages } = useBfMessageTimerQueue()
  const { t } = props
  const _showMessage = (message) => {
    BfConsole.debug('show message ', message)
    const { caption, title, subtitle, type = 'info', id } = message

    if (type === 'modal') {
      consumeMessage(id)
      props.showModal({ title: title, message: subtitle, labelCancel: bfTranslate(t, 'close'), type: 'warning' })
    }
    return (
      <Toast
        caption={caption}
        closable
        onClose={() => onCloseMessage(id)}
        iconDescription='close'
        subtitle={subtitle}
        title={title}
        type={type}
        visible
      />
    )
  }
  return (
    <div id='bf-message-container' className='bf-message-pane-container'>
      {idleMessages && _.size(idleMessages) > 0 && _.map(idleMessages, (message) => _showMessage(message))}
    </div>
  )
}

export default withBfModal(withTranslation()(BfMessagesPane))
