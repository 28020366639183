/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import BfCachedRequest from '../utils/BfCachedRequest'

class BfCustomOption {
  bfCachedRequest = new BfCachedRequest(false)

  getOptionValue = (optionPath) => {
    return this.bfCachedRequest.get(optionPath)
  }
}
export default BfCustomOption
