/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import BfConsole from 'loglevel'
import { Dropdown } from '@patron/patron-react/dropdown'
import { map } from 'rxjs/operators'
import BfHttpClient from '../../../services/authClient/BfHttpClient'
import { withTranslation } from 'react-i18next'
import { bfTranslate } from '../../../services/i18n/bfI18Utils'
import _ from '../../../services/utils/BfLodash'
import './BfReportsStyle.scss'
import { BfWithReduxStoreConsumer } from '../../mainAppContainer/bfReduxStore/BfReduxStore'
import { setNewFiltersUrlAction, resetSearchListUrlAction } from '../../datagrid/redux/BfFilterListUpdateURLAction'

/*
handling of filters (see loadReports)
*/
class BfFavoriteReportsDropdown extends Component {
  constructor(props) {
    super(props)
    this.state = {
      firstLoad: true
    }
  }

  http = new BfHttpClient()

  /**
   * Gets list of favorite reports from server
   * @method
   * @param {String} type what report type: 'device', 'patch' or 'deployment'
   * @returns {Promise} list of report items
   */
  _getReports(type) {
    const params = {
      filter: { contentType: { [type]: true }, showFavorites: { show: true } },
      sort: { method: 'name', order: 'asc' }
    }
    return this.http
      .get('/api/reports', params)
      .pipe(
        map((res) => {
          return _.map(res.data, (report) => {
            return {
              id: report.id,
              text: report.name,
              ownerId: report.ownerId,
              filters: report.filters
            }
          })
        })
      )
      .subscribe(
        (favs) => {
          var urlParams = new URLSearchParams(window.location.search)
          var selectedReport = _.isNull(urlParams.get('reportDetails')) ? {} : JSON.parse(urlParams.get('reportDetails'))
          if (_.isFinite(selectedReport.id)) {
            const selectedFav = _.find(favs, (fav) => {
              return fav.id === selectedReport.id
            })
            if (selectedFav) {
              this._loadReport(selectedFav)
            } else {
              this._getReportById(selectedReport.id)
            }
          } else {
            this.setState({ firstLoad: false })
          }
          const defaultFavorites = _.cloneDeep(this.props.favoritesList)
          this.props.updateFavorites(defaultFavorites.concat(favs))
        },
        (error) => {
          BfConsole.error('Error retrieving favorite reports: ')
          BfConsole.error(error)
        }
      )
  }

  /**
   * Updates the last time the report was accessed
   * @param {int} reportId
   */
  _updateLastAccessed(reportId) {
    const putReportCall = this.http.put('/api/reports/updateLastAccessedTime/' + reportId)
    putReportCall.subscribe(
      () => {
        BfConsole.log('Successfully updated access time for report id: ', reportId)
      },
      (error) => {
        BfConsole.error('Issue with updating access time for report id: ', reportId)
        BfConsole.error(error)
      }
    )
  }

  /**
   * get report by id
   * @param {int} reportId
   */
  _getReportById(reportId) {
    return this.http
      .get('/api/report/' + reportId)
      .pipe(
        map((res) => {
          return res
        })
      )
      .subscribe(
        (report) => {
          const selectedReport = {
            id: report.id,
            text: report.name,
            ownerId: report.ownerId,
            filters: report.filters
          }
          this._loadReport(selectedReport)
        },
        (error) => {
          BfConsole.error('Issue with getting report with id: ', reportId)
          BfConsole.error(error)
        }
      )
  }

  /**
   * Loads a report by updating the redux store
   * @param {Object} selection a report object 
   * {
        id: INT
        name: STRING,
        ownerId: INT,
        filters: OBJ (optional)
      }
   */
  _loadReport(selectedReport) {
    const selection = _.cloneDeep(selectedReport)
    const filters = {}
    if (selection.filters) {
      var savedParams = JSON.parse(selection.filters)
      if (!_.isUndefined(savedParams.filter)) {
        filters.filter = savedParams.filter
      }
      if (!_.isUndefined(savedParams.sort)) {
        filters.sort = savedParams.sort
      }
      if (!_.isUndefined(savedParams.search)) {
        filters.search = savedParams.search
      }
      // don't overwrite current filters on first load
      if (!this.state.firstLoad) {
        this.props.setNewFiltersUrlAction(this.props.namespace, filters)
      } else {
        this.setState({ firstLoad: false })
      }
    } else {
      this.props.resetSearchListUrlAction(this.props.namespace)
    }
    let reportDetails = { name: bfTranslate(this.props.t, 'defaultReport') }
    if (_.isFinite(selection.id)) {
      this._updateLastAccessed(selection.id)
      reportDetails = {
        id: selection.id,
        name: selection.text,
        ownerId: selection.ownerId,
        filters: filters
      }
    }
    this.props.updateCurrentReport(reportDetails, false, this.state.firstLoad)
  }

  componentDidMount() {
    this._getReports(this.props.contentType)
  }

  render() {
    const { t, currentReport, updatingReports, favoritesList } = this.props
    return (
      <Dropdown
        id='bf-favorite-reports-dropdown'
        className='hcl-col-3 hcl-float-left bfFavoriteReportsDropdown'
        disabled={_.isBoolean(updatingReports) ? updatingReports : false}
        items={favoritesList}
        selectedItem={currentReport.id}
        label={currentReport.name ? currentReport.name : bfTranslate(t, 'selectFavoriteReport')}
        onChange={(selection) => this._loadReport(selection)}
      />
    )
  }
}

BfFavoriteReportsDropdown.propTypes = {
  contentType: PropTypes.string.isRequired,
  namespace: PropTypes.string.isRequired,
  currentReport: PropTypes.object.isRequired,
  favoritesList: PropTypes.arrayOf(PropTypes.object),
  updatingReports: PropTypes.bool,
  updateCurrentReport: PropTypes.func,
  updateFavorites: PropTypes.func
}

const BfFavoriteReportsDropdownReduxComponent = connect(null, {
  setNewFiltersUrlAction,
  resetSearchListUrlAction
})(withTranslation()(BfFavoriteReportsDropdown))

export default BfWithReduxStoreConsumer(BfFavoriteReportsDropdownReduxComponent)
