import React from 'react'
import './BfDeployButton.scss'
import { Overflowmenu } from '@patron/patron-react/overflowmenu'
import { withTranslation } from 'react-i18next'
import { BfTrans, bfTranslate } from '../../../services/i18n/bfI18Utils'
import BfActionHandler from '../../../services/takeAction/BfActionHandler'
import _ from '../../../services/utils/BfLodash'
import BfConsole from 'loglevel'
import BfActionsService, { BF_TRUE } from './BfActionsService'
import withShowMessage from '../../common/bfMessage/BfShowMessage'
import BfToggleButton from '../../common/BfToggleButton'

class BfDeployButton extends React.Component {
  state = {
    showModal: false,
    errorMessage: ''
  }

  constructor(props) {
    super(props)
    this.bfActionHandler = new BfActionHandler(props.bfCurrentUserInfoService)
    this.bfActionService = new BfActionsService(props.bfCurrentUserInfoService)
  }

  _runTakeAction = (appId, selectedDevices, selectedItems, t) => {
    this.bfActionHandler.takeAction(appId, selectedDevices, selectedItems).subscribe({
      next: ({ redirectTo }) => {
        window.location.href = redirectTo
      },
      error: (e) => {
        const modalProperties = this.bfActionService.handleError(e, appId)
        this._showMessage({
          title: 'permissionError',
          message: {
            key: modalProperties.message.key,
            params: modalProperties.message.params
          },
          type: 'danger'
        })
      }
    })
  }

  _showMessage = (modalProperties, onOkCallback) => {
    const { message, type = 'success', okLabel, cancelLabel, title = '' } = modalProperties

    const translatedMessage = BfTrans(message.key, message.params)

    this.props.showMessage(type, BfTrans(title), translatedMessage, onOkCallback, okLabel, cancelLabel)
  }

  _handleDeploy = (app) => {
    BfConsole.debug('selected items are ', this.props.selectedItems)
    const { selectedItems, t } = this.props
    const selectedDevices = _.values(selectedItems)
    this.bfActionService.canDevicesRunApp(selectedDevices, app.id, t).subscribe(({ flag, modalProperties }) => {
      if (flag === BF_TRUE) {
        if (modalProperties.message) {
          this._showMessage(modalProperties, () => this._runTakeAction(app.id, selectedDevices, selectedItems, t))
        } else {
          this._runTakeAction(app.id, selectedDevices, selectedItems, t)
        }
      } else {
        this._showMessage({
          title: modalProperties.title,
          message: {
            key: modalProperties.message.key,
            params: modalProperties.message.params
          },
          type: modalProperties.type
        })
      }
    })
  }

  render() {
    const { t } = this.props
    return (
      <React.Fragment>
        <Overflowmenu
          className=''
          direction='bottom-left'
          ellipsisType='vertical'
          customTemplate={<BfToggleButton title={bfTranslate(t, 'deploy')} />}
          listItems={this.props.deployApps}
          onClick={this._handleDeploy}
        />
      </React.Fragment>
    )
  }
}
export default withShowMessage(withTranslation()(BfDeployButton))
