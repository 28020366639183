/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import React, { useEffect, useState } from 'react'

import { StackedBarChart } from '@patron/patron-react-chart/stackedbarchart'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { bfTranslate } from '../../../services/i18n/bfI18Utils'
import _ from '../../../services/utils/BfLodash'
import { CHART_COLORS } from '../../common/BfCharts/BfChartConstants'
import BfChartList from '../../common/BfCharts/BfChartList/BfChartList'
import BfChartContainer from '../../common/BfCharts/BfChartContainer/BfChartContainer'

const BfGroupChart = ({
  groupListData,
  gettingChartData,
  updateFilterListUrlAction,
  updateChartLoaded,
  chartId,
  namespace,
  isLoaded,
  chartTitle
}) => {
  const [state, setState] = useState(undefined)
  const { t } = useTranslation()
  const OTHER_NAME = 'Other'
  const MAX_ITEMS = 10

  const _onListClick = (item) => {
    updateFilterListUrlAction(namespace, item.filters)
  }

  const _onBarClick = (bar) => {
    const groupFilters = { groups: {} }
    if (bar.Subgroup === OTHER_NAME) {
      groupFilters.groups = bar.moreInfo.overflowFilters
    } else {
      groupFilters.groups = { [bar.Subgroup]: true }
    }
    updateFilterListUrlAction(namespace, groupFilters)
  }

  useEffect(() => {
    if (!_.isUndefined(groupListData) && gettingChartData === false) {
      let sortedGroupData = _.orderBy(groupListData, ['relevantCount', 'name'], ['desc', 'asc'])

      const overflowFilters = {}
      if (sortedGroupData.length > MAX_ITEMS) {
        let overflowItems = []
        overflowItems = sortedGroupData.slice(MAX_ITEMS - 1)
        sortedGroupData = sortedGroupData.slice(0, MAX_ITEMS - 1)
        let overflowCount = 0
        overflowItems.forEach((item) => {
          overflowCount += item.relevantCount
          overflowFilters[item.name] = true
        })
        sortedGroupData.push({
          name: OTHER_NAME,
          relevantCount: overflowCount
        })
      }

      const newColorArr = []
      let i = 0
      const newRowData = sortedGroupData.map((data) => {
        newColorArr.push({
          color: CHART_COLORS[i],
          key: data.name
        })
        return {
          key: data.name,
          color: CHART_COLORS[i++],
          value: data.relevantCount,
          ...(data.name !== OTHER_NAME && {
            filters: { groups: { [data.name]: true } },
            label: data.name
          }),
          ...(data.name === OTHER_NAME && {
            filters: { groups: overflowFilters },
            label: bfTranslate(t, OTHER_NAME)
          })
        }
      })

      const arrDataObj = {
        bigfixgroupslist: 'Groups',
        overflowFilters: overflowFilters
      }
      sortedGroupData.forEach((group) => {
        arrDataObj[group.name] = group.relevantCount
      })

      const sortedChartKeys = sortedGroupData.map((group) => group.name)

      setState({
        rowData: newRowData,
        arrData: [arrDataObj],
        colorArr: newColorArr,
        chartKeys: sortedChartKeys
      })
    }
  }, [groupListData, gettingChartData, t])

  useEffect(() => {
    if (gettingChartData) {
      setState(undefined)
    }
  }, [gettingChartData])

  useEffect(() => {
    if (state && !gettingChartData && !isLoaded) {
      updateChartLoaded(chartId)
    }
  }, [state, gettingChartData, updateChartLoaded, chartId, isLoaded])

  function _isLoading() {
    return !state || gettingChartData
  }

  return (
    <BfChartContainer isLoading={_isLoading()} chartTitle={chartTitle}>
      {!_isLoading() && (
        <React.Fragment>
          <div className='pr-7'>
            <StackedBarChart
              chartdata={state.arrData}
              barSize={0.1}
              chartHeight={150}
              chartLabel={{
                keys: {
                  mapsTo: state.chartKeys
                },
                label: {
                  mapsTo: 'bigfixgroupslist'
                },
                info: {
                  mapsTo: ['overflowFilters']
                },
                symbol: '%'
              }}
              chartType='bar'
              colordata={state.colorArr}
              hideYaxis
              hideXaxis
              onBarClick={_onBarClick}
              showLegend={false}
            />
          </div>
          <div>
            {state?.rowData?.length !== 0 && <BfChartList rowData={state?.rowData} onListClick={_onListClick} />}
            {state?.rowData?.length === 0 && <div>{bfTranslate(t, 'noGroupsAvailable')}</div>}
          </div>
        </React.Fragment>
      )}
    </BfChartContainer>
  )
}

BfGroupChart.propTypes = {
  gettingChartData: PropTypes.bool,
  groupListData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      relevantCount: PropTypes.number
    })
  ),
  updateFilterListUrlAction: PropTypes.func,
  updateChartLoaded: PropTypes.func,
  namespace: PropTypes.string,
  chartId: PropTypes.string,
  isLoaded: PropTypes.bool
}

export default BfGroupChart
