/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import React, { Component } from 'react'
import { Button } from '@patron/patron-react/button'
import PropTypes from 'prop-types'
import BfReportModal from './BfReportModal'
import { withTranslation } from 'react-i18next'
import './BfReportsStyle.scss'

class BfSaveReportButton extends Component {
  constructor(props) {
    super(props)
    this.state = { showSaveReportModal: false }
  }

  _renderSaveReportModal = () => {
    return (
      <BfReportModal closeModal={this._closeModal} contentType={this.props.contentType} validationSchema={this.props.validationSchema} mode='save' />
    )
  }

  _closeModal = (reportObj) => {
    if (reportObj) {
      reportObj.filters = JSON.parse(reportObj.filters)
      this.props.updateCurrentReport(reportObj, true)
    }
    this.setState({ showSaveReportModal: false })
  }

  _showModal = () => {
    this.setState({ showSaveReportModal: true })
  }

  render() {
    const { saveButtonText, updatingReports } = this.props
    return (
      <React.Fragment>
        {this.state.showSaveReportModal && this._renderSaveReportModal()}
        <Button
          className='hcl-float-left hcl-primary bar-button'
          onClick={this._showModal}
          disabled={updatingReports || this.state.showSaveReportModal}
        >
          {saveButtonText}
        </Button>
      </React.Fragment>
    )
  }
}

BfSaveReportButton.propTypes = {
  contentType: PropTypes.string.isRequired,
  saveButtonText: PropTypes.string.isRequired,
  updatingReports: PropTypes.bool,
  updateCurrentReport: PropTypes.func
}

export default withTranslation()(BfSaveReportButton)
