import React, { Component } from 'react'
import { Modal } from '@patron/patron-react/modal'
import { Spinner } from '@patron/patron-react/spinner'
import { Notification } from '@patron/patron-react/notification'
import { withTranslation } from 'react-i18next'
import { bfTranslate } from '../../services/i18n/bfI18Utils'
import _ from '../../services/utils/BfLodash'
import './BfModalOkCancel.scss'

class BfModalSubmit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      showMsg: false,
      errorMsg: '',
      successMessage: ''
    }
  }

  _handleSubmission = async () => {
    try {
      this.setState({
        isLoading: true,
        showMsg: false,
        errorMsg: '',
        successMessage: ''
      })
      await this.props.onSubmit()
      this.setState({ isLoading: false })
    } catch (err) {
      this._showError(err.message)
    }
  }

  _showError = (err) => {
    this.setState({
      isLoading: false,
      showMsg: true,
      errorMsg: err,
      successMessage: ''
    })
  }

  _showSuccess = (message) => {
    this.setState({
      isLoading: false,
      showMsg: true,
      errorMsg: '',
      successMessage: message
    })
  }

  _showErrorNotification = () => {
    const { t } = this.props
    const icon = <i className='p-hclsw p-hclsw-close-circle selected bfErrorNotificationColor' />
    return (
      <Notification
        subtitle={this.state.errorMsg}
        title={bfTranslate(t, 'error')}
        type='danger'
        visible={this.state.showMsg}
        closable={false}
        icon={icon}
      />
    )
  }

  _showSuccessNotification = () => {
    const { t } = this.props

    return (
      <Notification
        subtitle={this.state.successMessage}
        title={bfTranslate(t, 'success')}
        type='success'
        visible={this.state.showMsg}
        closable={false}
      />
    )
  }

  render() {
    const {
      onClose,
      labelSubmit = 'submit',
      labelCancel = 'cancel',
      title,
      disableSubmit = false,
      errorMessage = '',
      successMessage = '',
      t,
      classStyle,
      labelLoading
    } = this.props

    const actionModal = [
      {
        label: bfTranslate(t, labelCancel),
        primary: false,
        handler: onClose
      },
      {
        label: bfTranslate(t, labelSubmit),
        primary: true,
        disabled: disableSubmit || this.state.isLoading,
        handler: this._handleSubmission
      }
    ]
    let displayElement = this.props.children
    if (_.isNotEmpty(errorMessage) && !this.state.showMsg) {
      this._showError(errorMessage)
    }
    if (_.isNotEmpty(successMessage) && !this.state.showMsg) {
      this._showSuccess(successMessage)
    }

    if (this.state.isLoading) {
      displayElement = (
        <React.Fragment>
          <Spinner small={false} title={bfTranslate(t, 'loading')} />
          {_.isNotNil(labelLoading) && <div className='bfLabelLoading'>{labelLoading}</div>}
        </React.Fragment>
      )
    }

    return (
      <Modal
        actions={actionModal}
        className={'bfModalConfirm ' + (this.state.isLoading ? 'bfHideFooter ' : '') + classStyle}
        heading={title}
        keyboard
        onClose={onClose}
        type='default'
      >
        <div>
          {this.state.showMsg && _.isNotEmpty(this.state.errorMsg) && this._showErrorNotification()}
          {this.state.showMsg && _.isNotEmpty(this.state.successMessage) && this._showSuccessNotification()}

          {displayElement}
        </div>
      </Modal>
    )
  }
}

export default withTranslation()(BfModalSubmit)
