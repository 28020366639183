/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/
import React from 'react'
import BfModalOkCancel from './BfModalOkCancel'

const withBfModal = (WrappedComponent) => {
  return class extends React.Component {
    state = { showModal: false, modalProps: null }

    _onClose = () => {
      const { onClose } = this.state.modalProps
      onClose && onClose()
      this._onlyDismiss()
    }

    _onlyDismiss = () => {
      this.setState({ showModal: false, modalProps: null })
    }

    _internalShowModal = () => {
      const { message, onClose, ...restProps } = this.state.modalProps
      return (
        <BfModalOkCancel onClose={this._onClose} onlyDismiss={this._onlyDismiss} {...restProps}>
          {message}
        </BfModalOkCancel>
      )
    }

    showModal = (modalProps) => {
      this.setState({ modalProps: modalProps, showModal: true })
    }

    render() {
      return (
        <React.Fragment>
          <WrappedComponent showModal={this.showModal} closeModal={this._onlyDismiss} {...this.props} />
          {this.state.showModal && this._internalShowModal()}
        </React.Fragment>
      )
    }
  }
}
export default withBfModal
