/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

class BfConstants {
  constructor() {
    this.KEYS = this.makeKeysConstantsObject()
    this.URLS = this.makeUrlsConstantsObject()
    this.TIMEFORMAT = this.makeTimeFormatObject()
  }

  makeKeysConstantsObject = () => {
    return {
      TAB: 9,
      UP: 38,
      DOWN: 40,
      ENTER: 13,
      SPACE: 32
    }
  }

  makeUrlsConstantsObject = () => {
    return {
      USER_INFO: '/me',
      CURRENT_APPS: '/apps',
      PERMITTED_APPS: '/permittedApps',
      PLATFORM_VERSION: '/platformversion'
    }
  }

  // Constant for defined date/ time format
  makeTimeFormatObject = () => {
    return {
      DATE: 'DD MMM YYYY',
      TIME: 'HH:mm',
      DATE_TIME: 'DD MMM YYYY HH:mm'
    }
  }
}

export default BfConstants
