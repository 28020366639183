/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import _ from '../../../../services/utils/BfLodash'
import moment from 'moment'
import { bfTranslate } from '../../../../services/i18n/bfI18Utils'

export function specialCriteriaParser(filterName, filter, t) {
  switch (filterName) {
    case 'relevant_device_count':
      return `${filter.isLessSelected ? '<=' : '>='} ${filter.val}`
    case 'lastSeen':
    case 'issueDate':
      var startDate = bfTranslate(t, 'earliest')
      var endDate = moment().format('L')
      if (!_.isUndefined(filter.startDate)) {
        startDate = moment.unix(filter.startDate).format('L')
      }
      if (!_.isUndefined(filter.endDate)) {
        endDate = moment.unix(filter.endDate).format('L')
      }
      return startDate + ' - ' + endDate
    case 'onlySelected':
      if (filter.Enabled) {
        return bfTranslate(t, 'filterOnlySelected', {
          count: _.size(filter.data)
        })
      }
      return
    case 'operatingSystem':
      var listOfOs = []
      _.each(_.keys(filter), (category) => {
        var doubleColonIndex = category.lastIndexOf('::')
        // selecting parent os will also select children. Grab just children.
        if (doubleColonIndex > -1) {
          category = category.slice(doubleColonIndex + 2)
          listOfOs.push(category)
        }
      })
      return listOfOs

    // nit:this filter should probably be changed
    //     to some sort of radio selection.
    //     doesn't make sense with checkbox behavior.
    case 'has_critical_patch_vulnerabilities':
      var hasVuln = []
      if (filter.CriticalPatches) {
        hasVuln.push(bfTranslate(t, 'yes'))
      }
      if (filter.NoCriticalPatches) {
        hasVuln.push(bfTranslate(t, 'no'))
      }
      return hasVuln

    case 'agentStatus':
      var agentStatus = []
      if (filter.Native) {
        agentStatus.push(bfTranslate(t, 'installed'))
      }
      if (filter.agentNotInstalled) {
        agentStatus.push(bfTranslate(t, 'notInstalled'))
      }
      return agentStatus

    default:
      return null
  }
}
