/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2021
// All Rights Reserved
*/

import React from 'react'

import PropTypes from 'prop-types'

import useWindowDimensions from './bfUseWindowDimensions'
import './BfChartList.scss'
import BfHelpers from '../../../../services/utils/BfHelpers'

function BfChartList(props) {
  const { width } = useWindowDimensions()
  const { rowData, onListClick } = props
  const helpers = new BfHelpers()
  const splitWidth = 1770
  const SPLIT_AFTER = Math.ceil(rowData.length / 2)
  const firstHalf = rowData.slice(0, SPLIT_AFTER)
  const secondHalf = rowData.slice(SPLIT_AFTER)

  function createRow(row) {
    return (
      <tr key={row.key} className={row.value === 0 ? 'defaultCursor' : undefined} onClick={() => (row.value === 0 ? undefined : onListClick(row))}>
        <td
          style={{
            backgroundColor: helpers.hexToRgba(row.color, 0.2),
            borderLeft: `4px solid ${row.color}`
          }}
        >
          <span title={row.label}>{row.label}</span>
        </td>
        <td
          style={{
            backgroundColor: helpers.hexToRgba(row.color, 0.2)
          }}
        >
          <span title={row.value}>{row.value}</span>
        </td>
      </tr>
    )
  }

  return (
    <div className='bfChartListTables'>
      <div>
        <table className='bfChartList'>
          <tbody>
            {width < splitWidth && rowData.map((row) => createRow(row))}
            {width >= splitWidth && firstHalf.map((row) => createRow(row))}
          </tbody>
        </table>
      </div>
      {width >= splitWidth && secondHalf.length > 0 && (
        <div>
          <table className='bfChartList'>
            <tbody>
              {secondHalf.map((row) => {
                return createRow(row)
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  )
}

BfChartList.propTypes = {
  onListClick: PropTypes.func.isRequired,
  rowData: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      key: PropTypes.string,
      color: PropTypes.string,
      filters: PropTypes.object,
      value: PropTypes.number
    })
  ).isRequired
}

export default BfChartList
