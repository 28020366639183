/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import { updateColumnListAction, updateFilterListUrlAction } from '../redux/BfFilterListUpdateURLAction'

class BfResetColumnsService {
  constructor(dispatchAction, id, defaultProperties, customProperties, bfColumnSelectionFilter, bfCustomPropertiesFilter) {
    this._dispatchAction = dispatchAction
    this._id = id
    this._bfColumnSelectionFilter = bfColumnSelectionFilter
    this._bfCustomPropertiesFilter = bfCustomPropertiesFilter
    this._defaultProperties = defaultProperties
    this._customProperties = customProperties
  }

  _updateColumnsHeaders = (save = true, reset = false) => {
    this._bfColumnSelectionFilter.setDefaultHeadersVisible(this._defaultProperties)
    this._bfColumnSelectionFilter.addCustomPropertiesHeaders(this._customProperties)
    this._dispatchAction(updateColumnListAction(this._id, this._bfColumnSelectionFilter.getHeaders(), save, reset))
  }

  _updateURLFilters = () => {
    this._bfCustomPropertiesFilter.add(this._customProperties)
    this._dispatchAction(updateFilterListUrlAction(this._id, this._bfCustomPropertiesFilter.get()))
  }

  changeColumns = (updateURL = true) => {
    this._updateColumnsHeaders()
    if (updateURL) {
      this._updateURLFilters()
    }
  }

  resetColumns = () => {
    this._updateColumnsHeaders(false, true)
    this._updateURLFilters()
  }
}

export default BfResetColumnsService
