/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2021
// All Rights Reserved
*/

const handleBeforeUnload = (event) => {
  event.preventDefault()
  event.returnValue = 'Are you sure to leave the page?'
  return event.returnValue
}

export const addBeforeEventListener = () => {
  window.addEventListener('beforeunload', handleBeforeUnload)
}

export const removeBeforeEventListener = () => {
  window.removeEventListener('beforeunload', handleBeforeUnload)
}
