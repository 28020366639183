/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2021
// All Rights Reserved
*/

import BfHttpClient from '../authClient/BfHttpClient'
import BfCachedRequest from '../utils/BfCachedRequest'
import { map } from 'rxjs/operators'

/**
 * Provides functionalities to fetch patch data
 */
class BfPrepolicyService {
  bfCachedRequest = new BfCachedRequest(true)
  http = new BfHttpClient()

  /**
   * Triggers http request to fetch patch data from server
   * @method
   * @param {Object} options Pagination and Sorting options
   * @returns {Promise} Patch data
   */
  getPrepolicy(options = {}) {
    return this.http.get('/api/policies', options).pipe(
      map((data) => {
        for (var x = 0; x < data.data.length; x += 1) {
          var hold = ''
          if (data.data[x].mdmOS.android) {
            hold += 'Android, '
          }
          if (data.data[x].mdmOS.iOS) {
            hold += 'iOS, '
          }
          if (data.data[x].mdmOS.macOS) {
            hold += 'macOS, '
          }
          if (data.data[x].mdmOS.iPadOS) {
            hold += 'iPadOS, '
          }
          if (data.data[x].mdmOS.windows) {
            hold += 'Windows, '
          }
          hold = hold.slice(0, -2)
          data.data[x].mdmOS = hold
        }

        return { totalCount: data.count, rows: data.data }
      })
    )
  }
}

export default BfPrepolicyService
