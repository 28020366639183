import React from 'react'
import PropTypes from 'prop-types'
import { Formik, useField } from 'formik'

const BfForm = (props) => {
  const { initialValues, handleSubmit, validationSchema, children, enableReinitialize, initialErrors, noMargin } = props

  return (
    <Formik
      initialValues={initialValues || {}}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize={enableReinitialize || false}
      initialErrors={initialErrors || {}}
    >
      {(props) => (
        <form className={`${noMargin ? '' : 'm-5'}`} onSubmit={props.handleSubmit}>
          {children({
            ...props
          })}
        </form>
      )}
    </Formik>
  )
}
BfForm.propTypes = {
  initialValues: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  validateFunction: PropTypes.func,
  validationSchema: PropTypes.object,
  enableReinitialize: PropTypes.bool,
  initialErrors: PropTypes.object,
  noMargin: PropTypes.bool
}

const useBfField = useField

export default BfForm
export { BfForm, useBfField }
