/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import React, { useEffect, useState } from 'react'
import './BfDateRangeSelectorWithOptions.scss'
import moment from 'moment'
import { Overlay } from '@patron/patron-react/overlay'
import { Radio, RadioGroup } from '@patron/patron-react/radio'
import BfDateRangeSelector from './BfDateRangeSelector'
import BfDropDownButton from '../BfDropDownButton'
import _ from '../../../services/utils/BfLodash'
import { useTranslation } from 'react-i18next'
import { bfTranslate } from '../../../services/i18n/bfI18Utils'
import BfConsole from 'loglevel'

const BfDateRangeSelectorWithOptions = (props) => {
  const { t } = useTranslation()
  const DEFAULT_DATE_PLACEHOLDER = 'mm/dd/yyyy'
  const NULL_START_DATE_PLACEHOLDER = bfTranslate(t, 'dateRangeFrom')
  const NULL_END_DATE_PLACEHOLDER = bfTranslate(t, 'dateRangeTillNow')

  const DEFAULT_DATE_RANGE_PLACEHOLDER = `${DEFAULT_DATE_PLACEHOLDER} - ${DEFAULT_DATE_PLACEHOLDER}`

  const [treeOverlayStatus, setTreeOverlayStatus] = useState(false)
  const [treeTargetElement, setTreeTargetElement] = useState(null)
  const [openCalendar, setOpenCalendar] = useState(false)

  const [defaultRadio, setDefaultRadio] = useState(props.startDate || props.endDate ? 'Custom' : 'All')

  const [disabledCustom, setDisabledCustom] = useState(!(props.startDate || props.endDate))

  const _getDate = (date) => {
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
  }
  const [reset, setReset] = useState(false)

  const [placeholder, setPlaceholder] = useState('')

  useEffect(() => {
    setPlaceholder(_getPlaceholderString(props.startDate, props.endDate))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const _getPlaceholderString = (startDate, endDate) => {
    const _getPlaceholderStartDate = (startDate) => {
      let placehoderDate = NULL_START_DATE_PLACEHOLDER
      if (_.isNotNull(startDate)) {
        placehoderDate = _getDate(startDate)
      }
      return placehoderDate
    }

    const _getPlaceholderEndDate = (endDate) => {
      let placehoderDate = NULL_END_DATE_PLACEHOLDER
      if (_.isNotNull(endDate)) {
        placehoderDate = _getDate(endDate)
      }
      return placehoderDate
    }

    if (_.isNull(startDate) && _.isNull(endDate)) {
      return DEFAULT_DATE_RANGE_PLACEHOLDER
    }

    const placeholderStartDate = _getPlaceholderStartDate(startDate)
    const placeholderEndDate = _getPlaceholderEndDate(endDate)

    return `${placeholderStartDate} - ${placeholderEndDate}`
  }

  const _showTreeOverlay = (e) => {
    setTreeOverlayStatus((treeOverlayStatus) => !treeOverlayStatus)
    setTreeTargetElement(e.currentTarget)
  }

  const _toggleTreeOverlay = (status) => {
    if (!status) {
      setOpenCalendar(false)
      setReset((reset) => !reset)
      setTreeOverlayStatus(status)
      setTreeTargetElement(null)
    }
  }

  const _onSelectedPredefinedRangeOptions = (startDate, endDate) => {
    setPlaceholder(_getPlaceholderString(startDate, endDate))
    if (props.onDateRangeSelected) {
      props.onDateRangeSelected(startDate, endDate)
    }
  }

  const _resetDateRange = () => {
    setPlaceholder(DEFAULT_DATE_RANGE_PLACEHOLDER)
    setReset((reset) => !reset)
    if (props.onDateRangeSelected) {
      props.onDateRangeSelected(null, null)
    }
  }

  const _onCustomOptionSelected = (optionValue) => {
    const now = new Date()
    let startDateMoment

    setDisabledCustom(optionValue !== 'Custom')
    setOpenCalendar(optionValue === 'Custom')
    setDefaultRadio(optionValue)

    switch (optionValue) {
      case 'All':
        _resetDateRange()
        break
      case 'OneDay':
        startDateMoment = moment(now).subtract(1, 'days')
        _onSelectedPredefinedRangeOptions(startDateMoment.toDate(), now)
        break
      case 'OneWeek':
        startDateMoment = moment(now).subtract(1, 'weeks')
        _onSelectedPredefinedRangeOptions(startDateMoment.toDate(), now)
        break
      case 'OneMonth':
        startDateMoment = moment(now).subtract(1, 'months')
        _onSelectedPredefinedRangeOptions(startDateMoment.toDate(), now)
        break
      case 'OneQuarter':
        startDateMoment = moment(now).subtract(3, 'months')
        _onSelectedPredefinedRangeOptions(startDateMoment.toDate(), now)
        break
    }
  }

  const _onDateRangeSelected = ({ startDate, endDate }) => {
    const _startDate = moment(startDate).startOf('day').toDate()
    const _endDate = moment(endDate).endOf('day').toDate()
    BfConsole.debug('start date is ', _startDate)
    BfConsole.debug('end date is ', _endDate)
    setPlaceholder(_getPlaceholderString(_startDate, _endDate))

    if (props.onDateRangeSelected) {
      props.onDateRangeSelected(_startDate, _endDate)
    }
  }

  const _getSelectedDatePlaceholder = () => {
    if (_.isNil(props.startDate) && _.isNil(props.endDate)) {
      return null
    }
    return _getPlaceholderString(props.startDate, props.endDate)
  }

  const _onResetDateRange = () => {
    _resetDateRange()
    setDefaultRadio('All')
  }

  return (
    <div>
      <BfDropDownButton
        onClick={_showTreeOverlay}
        title={placeholder}
        itemsSelected={_getSelectedDatePlaceholder()}
        cleanItemSelected={_onResetDateRange}
      />
      <Overlay
        // Used to show or hide Overlay Component
        showOverlay={treeOverlayStatus}
        // Used to set the target Element in which overlay should be mount
        targetElement={treeTargetElement}
        // Used to attach element to body tag
        attachElementToBody
        scrollListner
        // remove overlay component on escape key
        closeOnEscape
        // callback function which will return the status of overlay.
        onToggle={_toggleTreeOverlay}
        className='bf-date-range-selector-option-overlay'
      >
        <div className='bf-date-range-selector-option-container blue_active_blue_light'>
          <RadioGroup defaultSelected={defaultRadio} orientation='vertical' onChange={_onCustomOptionSelected}>
            <Radio id='bfdr1' labelText={bfTranslate(t, 'All')} name='bfdr1' tooltipDirection='bottom' tooltipTitle={null} value='All' />
            <Radio id='bfdr2' labelText={bfTranslate(t, 'withinAday')} name='bfdr2' tooltipDirection='bottom' tooltipTitle={null} value='OneDay' />
            <Radio id='bfdr3' labelText={bfTranslate(t, 'withinAweek')} name='bfdr3' tooltipDirection='bottom' tooltipTitle={null} value='OneWeek' />
            <Radio
              id='bfdr4'
              labelText={bfTranslate(t, 'withinAmonth')}
              name='bfdr4'
              tooltipDirection='bottom'
              tooltipTitle={null}
              value='OneMonth'
            />
            <Radio
              id='bfdr5'
              labelText={bfTranslate(t, 'withinAquarter')}
              name='bfdr5'
              tooltipDirection='bottom'
              tooltipTitle={null}
              value='OneQuarter'
            />
            <Radio
              id='bfdrcustom'
              labelText={bfTranslate(t, 'withinCustom')}
              name='bfdrcustom'
              tooltipDirection='bottom'
              tooltipTitle={null}
              value='Custom'
            />
          </RadioGroup>
          <div disabled={disabledCustom}>
            <BfDateRangeSelector
              startDate={props.startDate}
              endDate={props.endDate}
              openCalendar={openCalendar}
              onDateRangeSelected={_onDateRangeSelected}
              key={reset.toString()}
            />
          </div>
        </div>
      </Overlay>
    </div>
  )
}

export default BfDateRangeSelectorWithOptions
