/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import BfConsole from 'loglevel'
import { UPDATE_EXPORTING_TYPE, UPDATE_SELECTED_ROWS_IDS } from './BfReportsActionTypes'

export const BfReportsReducer =
  (namespace) =>
  (state = { isExportingType: undefined }, action) => {
    BfConsole.info('BfReportsReducer action ', action)
    BfConsole.info('BfReportsReducer state ', state)

    switch (action.type) {
      case `${namespace}/${UPDATE_EXPORTING_TYPE}`: {
        return {
          ...state,
          isExportingType: action.isExportingType
        }
      }
      case `${namespace}/${UPDATE_SELECTED_ROWS_IDS}`: {
        return {
          ...state,
          selectedRowsIds: action.selectedRowsIds
        }
      }
      default: {
        return state
      }
    }
  }
