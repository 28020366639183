/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/
import BfDropDownSearchBasicFilter from '../../common/bfBasicFilters/BfDropDownSearchFilter/BfDropDownSearchBasicFilter'

class BfDeviceGroupFilter extends BfDropDownSearchBasicFilter {
  static NAME = 'groups'
  static PATH = `filter.${BfDeviceGroupFilter.NAME}`
  constructor(data = {}) {
    super(BfDeviceGroupFilter.PATH, BfDeviceGroupFilter.NAME)
    this[BfDeviceGroupFilter.NAME] = data
  }

  getSearchURL = () => {
    return '/devices/groups'
  }
}

export default BfDeviceGroupFilter
