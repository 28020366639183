/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import _ from '../../../../../services/utils/BfLodash'
import BfBasicFilter from '../../BfBasicFilter'

class BfOnlySelectedFilter extends BfBasicFilter {
  static NAME = 'onlySelected'
  static PATH = `filter.${BfOnlySelectedFilter.NAME}`

  constructor(onlySelected = false, data = []) {
    super(BfOnlySelectedFilter.PATH, BfOnlySelectedFilter.NAME)
    if (onlySelected) {
      this.onlySelected = { Enabled: onlySelected, data: data }
    } else {
      this.onlySelected = {}
    }
  }

  get = () => {
    return this._isEmpty() ? { onlySelected: {} } : { onlySelected: this.onlySelected }
  }

  getFilter = () => {
    return { filter: this.get() }
  }

  _isEmpty = () => {
    return _.isEmpty(this.onlySelected) && (_.isEmpty(this.onlySelected.Enabled) || !this.onlySelected.Enabled) && _.isEmpty(this.onlySelected.data)
  }

  buildFromURLHandler = (urlHandler, consumeUrl = false) => {
    this.onlySelected = this._buildFromURLHandler(urlHandler, consumeUrl, this.onlySelected)
  }

  getEnabled = () => {
    return this.onlySelected.Enabled
  }

  isEnabled = () => {
    return this.onlySelected.Enabled
  }

  getData = () => {
    return this.onlySelected.data
  }

  setData = (selectedItems) => {
    this.onlySelected.data = selectedItems
  }

  setEnabled = (onlySelected) => {
    if (onlySelected) {
      this.onlySelected = { Enabled: onlySelected, data: [] }
    } else {
      this.onlySelected = {}
    }
  }
}
export default BfOnlySelectedFilter
