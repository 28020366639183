/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/
import { map } from 'rxjs/operators'
import BfCachedRequest from '../utils/BfCachedRequest'
import compareVersions from 'compare-versions'

class BfPlatformVersion {
  PLATFORM_VERSION_10 = '10.0'
  bfCachedRequest = new BfCachedRequest()

  getPlatformVersion = () => {
    return this.bfCachedRequest.get('/platformversion').pipe(map((data) => data.PLATFORM_VERSION))
  }

  isGreaterThanTen = () => {
    return this.getPlatformVersion().pipe(map((version) => compareVersions(version, this.PLATFORM_VERSION_10) > -1))
  }

  isLessThanTen = () => {
    return this.getPlatformVersion().pipe(map((version) => compareVersions(version, this.PLATFORM_VERSION_10) === -1))
  }
}
export default BfPlatformVersion
