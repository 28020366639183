/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/
import React, { useState } from 'react'
import { Field, useField } from 'formik'
import BfEditor from '../common/inputs/wysiwygEditor/BfEditor'
import _ from '../../services/utils/BfLodash'
import { withTranslation } from 'react-i18next'
import { bfTranslate } from '../../services/i18n/bfI18Utils'

const BfEditorField = (props) => {
  const { id, required, t, ...rest } = props
  const { name } = rest
  const [field, meta] = useField(props)
  const [isCodeView, setIsCodeView] = useState(false)
  const isInvalid = meta.touched && !_.isEmpty(meta.error)

  return (
    <Field name={name} id={name} validate={(fieldValue) => (isCodeView ? bfTranslate(t, 'ErrorCodeView') : undefined)}>
      {({ field: { name }, form: { setFieldValue, setFieldTouched, setFieldError } }) => (
        <BfEditor
          id={name}
          {...field}
          toggleCodeView={(codeView, text, htmlText) => {
            setIsCodeView(codeView)
            if (codeView) {
              setFieldTouched(name, true)
              setFieldValue(name, htmlText, false)
              setFieldError(name, bfTranslate(t, 'ErrorCodeView'))
            } else {
              setFieldValue(name, _.size(text) === 0 ? '' : htmlText)
            }
          }}
          onBlur={() => {
            setFieldTouched(name, true)
          }}
          onChange={(htmlText, text) => {
            setFieldValue(name, _.size(text) === 0 ? '' : htmlText)
          }}
          isInvalid={isInvalid}
          required={required}
          errorMsg={meta.error}
          {...rest}
        />
      )}
    </Field>
  )
}
export default withTranslation()(BfEditorField)
