/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

export const BASELINE = 'Baseline'

export const createCustomTaskId = (task) => {
  return `${task.SiteID}_${task.ContentID}`
}

export const isBaseline = (task) => {
  return task.type === BASELINE
}
