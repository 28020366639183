/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import _ from '../../../../../services/utils/BfLodash'
import { bfTranslate } from '../../../../../services/i18n/bfI18Utils'
import BfDropDownFilter, { createDropDownFilterItem } from '../../common/bfBasicFilters/bfDropDownFilter/BfDropDownFilter'
import BfWithReset from '../../BfWithReset'

import InsightsSiteTypeFilter from './InsightsSiteTypeFilter'
class InsightsSiteTypeWithFilter extends Component {
  constructor(props) {
    super(props)
    this.filter = InsightsSiteTypeWithFilter.getFilter(props)
  }

  static getFilter = (props) => {
    return props.filter ? props.filter : new InsightsSiteTypeFilter()
  }

  _getInitialSelectedItems = () => {
    let selectedItems = []
    const data = InsightsSiteTypeWithFilter.getFilter(this.props).getData()

    if (!_.isEmpty(data)) {
      selectedItems = _.keys(data)
    }
    return selectedItems
  }

  _getItems = () => {
    const { t } = this.props
    return [createDropDownFilterItem('external', bfTranslate(t, 'external')), createDropDownFilterItem('custom', bfTranslate(t, 'custom'))]
  }

  _getSiteTypes = (selectedItemsIds) => {
    const siteTypes = {}
    _.each(selectedItemsIds, (selectedItemsId) => {
      siteTypes[selectedItemsId] = true
    })
    return siteTypes
  }

  _onItemsSelected = (item, selectedItemsIds) => {
    const siteTypes = this._getSiteTypes(selectedItemsIds)
    this.filter.setData(siteTypes)
  }

  render() {
    this.filter = InsightsSiteTypeWithFilter.getFilter(this.props)

    return (
      <BfDropDownFilter
        bfFilter={this.filter}
        onItemsSelected={this._onItemsSelected}
        getItemsRequest={this._getItems}
        initialSelectedItems={this._getInitialSelectedItems()}
        onFilterApplied={this.props.onChange}
      />
    )
  }
}

export default BfWithReset(withTranslation()(InsightsSiteTypeWithFilter), InsightsSiteTypeWithFilter.getFilter)
