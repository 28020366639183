/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import BfBasicFilter from '../../BfBasicFilter'

class BfShowRelevantBaselineKeyFilter extends BfBasicFilter {
  static NAME = 'baselineKey'
  static PATH = `filter.${BfShowRelevantBaselineKeyFilter.NAME}`

  constructor() {
    super(BfShowRelevantBaselineKeyFilter.PATH, BfShowRelevantBaselineKeyFilter.NAME)
  }
}

export default BfShowRelevantBaselineKeyFilter
