/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2021
// All Rights Reserved
*/
import React from 'react'
import { Field } from 'formik'
import PropTypes from 'prop-types'
import './BfForm.scss'

import { TimePicker } from '@patron/patron-react/timepicker'

function BfTimePicker(props) {
  const {
    id,
    name,
    label = '',
    subLabel = '',
    disabled = false,
    timeZones = null,
    onChange,
    type = 'HH' // hh = 12 hour, HH 24 hour
  } = props

  return (
    <div className='hcl-form-group'>
      <Field name={name}>
        {({ field, form }) => (
          <div>
            <TimePicker
              defaultTime={{
                time: field.value
              }}
              disabled={disabled}
              helperText={subLabel}
              id={id || name}
              label={label}
              onChange={({ time }) => {
                if (time !== field.value) {
                  form.setFieldValue(name, time)
                  form.setFieldTouched(name, true)
                  onChange && onChange(time)
                }
              }}
              timeZones={timeZones}
              type={type}
            />
          </div>
        )}
      </Field>
    </div>
  )
}

BfTimePicker.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  small: PropTypes.bool,
  subLabel: PropTypes.string,
  timezones: PropTypes.arrayOf(PropTypes.string),
  type: PropTypes.string,
  required: PropTypes.bool
}

export default BfTimePicker
