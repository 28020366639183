/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

const BfReduxStateConstants = Object.freeze({
  AP_POLICIES: 'ap_policies',
  DATASOURCES: 'datasources',
  DEVICE_CHARTS: 'device_charts',
  PATCH_CHARTS: 'patch_charts',
  DEVICES: 'devices',
  MESSAGES: 'messages',
  REPORTS: 'reports',
  REPORT_DATA_GRID: 'report_datagrid',
  CUSTOM_DATA_GRID: 'custom',
  GROUPS_DATA_GROUP: 'groups',
  CONTENT_DATA_GRID: 'content',
  SOFTWARE_DATA_GRID: 'swd',
  PATCHES: 'patches',
  POLICYGROUPS: 'policy_groups',
  PREPOLICY: 'prepolicy',
  PREAPPS: 'preapps'
})

export default BfReduxStateConstants
