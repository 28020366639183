/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import BfDateRangeBasicFilter from '../../common/bfBasicFilters/bfDateRangeFilter/BfDateRangeBasicFilter'

class ReportLastModifiedFilter extends BfDateRangeBasicFilter {
  static NAME = 'lastModified'
  static PATH = `filter.${ReportLastModifiedFilter.NAME}`
  constructor(data = {}) {
    super(ReportLastModifiedFilter.PATH, ReportLastModifiedFilter.NAME, data)
  }
}

export default ReportLastModifiedFilter
