/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/
import React, { Component } from 'react'
import _ from '../../../../../../services/utils/BfLodash'
import BfDropdownSearch, { createDropDownItem } from '../../../../../common/BfDropdownSearch'
import BfDebounceSubject from '../../../../../common/BfDebounceSubject'
import { isObservable, of } from 'rxjs'
import { map } from 'rxjs/operators'

const createItem = (id, text) => {
  if (_.isUndefined(text)) {
    text = id
  }
  return { id: id, text: text }
}
export { createItem as createDropDownFilterItem }

/**
 * selectedItem and items accept array of the same objects types {id,text}
 */
class BfDropDownFilter extends Component {
  state = { items: [], selectedItems: [] }

  bfDebounceSubject = new BfDebounceSubject()

  constructor(props) {
    super(props)
    this.bfDebounceSubject.subscribe((data) => this.props.onFilterApplied(data))
  }

  _getItems = (filterValue = '') => {
    const getItemsRequest = this.props.getItemsRequest()
    const getItems = isObservable(getItemsRequest) ? getItemsRequest : of(getItemsRequest)

    return getItems.pipe(
      map((items) => {
        const regex = new RegExp(`${filterValue}`, `i`)

        return _.map(
          _.filter(items, (item) => regex.test(item.text)),
          (item) => createDropDownItem(item.id, item.text)
        )
      })
    )
  }

  _initSelectedItems = () => {
    const selectedItemIds = this.props.initialSelectedItems
    if (_.isNotNil(selectedItemIds) && _.isNotEmpty(selectedItemIds)) {
      this._getItems().subscribe({
        next: (items) => {
          const dropDownSeletedItems = _.filter(items, (item) => _.isDefined(_.find(selectedItemIds, (selectedItemId) => selectedItemId === item.id)))

          this.setState({ selectedItems: dropDownSeletedItems })
        }
      })
    }
  }

  componentDidMount() {
    this._initSelectedItems()
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.initialSelectedItems, this.props.initialSelectedItems)) {
      this._initSelectedItems()
    }
  }

  _getSelectedItems = (selectedItemsIds) => {
    const _selectedItems = _.filter(this.state.items, (item) => _.includes(selectedItemsIds, item.id))
    return _.map(_selectedItems, (selectedItem) => createDropDownItem(selectedItem.id, selectedItem.label))
  }

  _isAdded = (item, selectedItemsIds) => {
    if (_.isEmpty(item)) {
      return false
    }
    return _.indexOf(selectedItemsIds, item.id) !== -1
  }

  _onChange = (item, selectedItemsIds) => {
    const { bfFilter, onItemsSelected } = this.props

    onItemsSelected(item, selectedItemsIds, this._isAdded(item, selectedItemsIds))
    this.bfDebounceSubject.next(bfFilter.get())
  }

  render() {
    return <BfDropdownSearch onItemSelected={this._onChange} getItems={this._getItems} initialSelectedItems={this.state.selectedItems} />
  }
}

export default BfDropDownFilter
