import React from 'react'
import { TreeView } from '@patron/patron-react/treeview'
import { Checkbox } from '@patron/patron-react/checkbox'
import _ from '../../../../../services/utils/BfLodash'
import BfDebounceSubject from '../../../../common/BfDebounceSubject'

const extractCheckedNodes = (stateTree) => {
  const flatStateTree = (stateTree) => {
    const flatten = (item) => {
      return [item, _.flatMapDeep(item.children, flatten)]
    }

    return _.flatMapDeep(stateTree, flatten)
  }

  const flatState = flatStateTree(stateTree)

  return _.reduce(
    flatState,
    (result, node) => {
      if (node.checked) {
        result[node.id] = true
      }

      return result
    },
    {}
  )
}

const createOsItems = (node) => {
  return _.map(node, (value) => {
    const stateTreeViewStructure = {
      checked: false,
      indeterminate: null,
      displayChildren: false,
      id: value.id,
      value: value.name,
      children: null,
      key: value.id
    }

    if (_.has(value, 'items')) {
      stateTreeViewStructure.children = createOsItems(value.items)
    }

    return stateTreeViewStructure
  })
}
const createAgentTypedByItems = (node) => {
  const createSubGroups = (items) => {
    return _.map(items, function (value) {
      var subCategory = {
        id: value.AgentType,
        value: value.DisplayName,
        checked: false,
        displayChildren: false,
        key: value.AgentType,
        children: null
      }

      return subCategory
    })
  }

  node = _.groupBy(node, 'Category')
  return _.map(node, (agentTypeArray, key) => {
    var itemByCategory = {
      id: key,
      value: key,
      checked: false,
      indeterminate: null,
      displayChildren: false,
      key: key,
      children: null
    }

    if (agentTypeArray.length > 1) {
      itemByCategory.children = createSubGroups(agentTypeArray, false)
    }

    if (agentTypeArray.length === 1) {
      itemByCategory = {
        id: agentTypeArray[0].AgentType,
        value: agentTypeArray[0].DisplayName,
        checked: false,
        indeterminate: null,
        displayChildren: false,
        key: agentTypeArray[0].AgentType,
        children: null
      }
    }
    return itemByCategory
  })
}

export { createOsItems as createOsDropDownItem }
export { createAgentTypedByItems as createAgentTypeDropDownItem }
export { extractCheckedNodes }

class BfCheckboxTree extends React.Component {
  bfDebounceSubject = new BfDebounceSubject()
  state = { stateTree: [], toggleState: {} }

  _handleChecks = (checkedNodes) => {
    this.props.onItemSelected(checkedNodes)
  }

  componentDidMount() {
    const { getItemsRequest } = this.props
    getItemsRequest().subscribe({
      next: (stateTree) => {
        this.setState((state) => {
          const selectedNodes = this._updateSelectedNode(stateTree)
          this._calculateParentStatus(selectedNodes)
          const openedToggle = this.props.expandedNodes ? this._extractToggledNodes(stateTree, true) : this._extractToggledNodes(selectedNodes, false)
          return {
            stateTree: stateTree,
            toggleState: openedToggle
          }
        })
      }
    })
  }

  _isNodeWithChildren = (node) => {
    return _.isNotEmpty(node.children)
  }

  _propagateToChildren = (node) => {
    _.each(node.children, (stateTreeChild) => {
      stateTreeChild.checked = node.checked
      if (this._isNodeWithChildren(stateTreeChild)) {
        this._propagateToChildren(stateTreeChild)
      }
    })
  }

  _isOneChildChecked = (node) => {
    return _.some(node.children, { checked: true }) || _.some(node.children, { indeterminate: true })
  }

  //  From the value selected, it's search the position in the tree
  _calculateParentStatus = (stateTree, recalcuteToggle = false) => {
    const areAllChildrenChecked = (node) => {
      return _.every(node.children, { checked: true })
    }

    const { expandedNodes = false } = this.props

    _.each(stateTree, (value) => {
      if (this._isNodeWithChildren(value)) {
        this._calculateParentStatus(value.children, recalcuteToggle)
        value.checked = areAllChildrenChecked(value)

        value.indeterminate = !value.checked ? this._isOneChildChecked(value) : null

        if (recalcuteToggle) {
          value.displayChildren = value.indeterminate || value.checked || expandedNodes
        }
      }
    })
  }

  // Sync the state of the checkbox component
  // Patronus strange behaviuor : if item checked has no children it's checked state is not update so update it manually
  _updateCheckboxState = (nodeId, stateTree, isChecked) => {
    let ret = true
    _.each(stateTree, (item) => {
      if (item.id === nodeId) {
        item.checked = isChecked
        ret = false
      } else if (this._isNodeWithChildren(item)) {
        ret = this._updateCheckboxState(nodeId, item.children, isChecked)
      }
      return ret
    })
  }

  // Sync the state of the toggle component
  _updateToggleState = (nodeId, stateTree) => {
    let ret = true
    _.each(stateTree, (item) => {
      if (item.id === nodeId) {
        item.displayChildren = !item.displayChildren
        ret = false
      } else if (this._isNodeWithChildren(item)) {
        ret = this._updateToggleState(nodeId, item.children)
      }

      return ret
    })
  }

  _onCheckboxState = (ev, node, selectedNodes) => {
    // Updating node state
    node.checked = ev.currentTarget.checked
    this._propagateToChildren(node)
    this.props.onItemSelected(selectedNodes)
  }

  _expandAllToggle = (stateTree) => {
    const flatState = this._flatStateTree(stateTree)

    return _.reduce(
      flatState,
      (result, node) => {
        result[node.key] = true

        return result
      },
      {}
    )
  }

  _extractToggledNodes = (stateTree, all = false) => {
    const flatState = this._flatStateTree(stateTree)

    return _.reduce(
      flatState,
      (result, node) => {
        if (all) {
          result[node.id] = true
        } else {
          if (node.displayChildren) {
            result[node.id] = true
          }
        }

        return result
      },
      {}
    )
  }

  _flatStateTree = (stateTree) => {
    const flatten = (item) => {
      return [item, _.flatMapDeep(item.children, flatten)]
    }

    return _.flatMapDeep(stateTree, flatten)
  }

  _onCustomTemplateRender = (node, selectedNodes) => {
    return (
      <Checkbox
        id={node.id}
        label={node.value}
        value={node.id}
        checked={node.checked}
        indeterminate={node.indeterminate}
        onChange={(ev) => this._onCheckboxState(ev, node, selectedNodes)}
      />
    )
  }

  _createTree = (stateTree) => {
    return _.map(stateTree, (value) => {
      const treeViewStructure = {
        key: value.key,
        name: value.value,
        children: []
      }

      if (this._isNodeWithChildren(value)) {
        treeViewStructure.children = this._createTree(value.children)
      }

      return treeViewStructure
    })
  }

  _isPreselected = (preselectedItems, value) => {
    return _.isUndefined(preselectedItems[value.id]) ? false : preselectedItems[value.id]
  }

  _updateSelectedNode = (tree) => {
    const stateTree = _.cloneDeep(tree)

    _.forEach(stateTree, (val) => {
      val.checked = this._isPreselected(this.props.preselectedItems, val)
      if (this._isNodeWithChildren(val)) {
        val.children = this._updateSelectedNode(val.children)
      }
    })

    return stateTree
  }

  render() {
    const selectedNodes = this._updateSelectedNode(this.state.stateTree)
    this._calculateParentStatus(selectedNodes)

    return (
      <TreeView
        treeData={selectedNodes}
        expandedNodes={this.state.toggleState}
        config={{ externalExpandNode: true }}
        customTemplate={(node) => {
          return this._onCustomTemplateRender(node, selectedNodes)
        }}
        className='blue_active_blue_light'
      />
    )
  }
}

export default BfCheckboxTree
