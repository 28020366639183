/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/
import { Subject } from 'rxjs'
import { debounceTime } from 'rxjs/operators'

class BfDebounceSubject {
  subject = new Subject()
  observable = null

  constructor(delayTime = 1000) {
    this.observable = this.subject.pipe(debounceTime(delayTime))
  }

  subscribe = (...callbacks) => {
    const [onLoad, onError, onComplete] = callbacks
    this.observable.subscribe(onLoad, onError, onComplete)
  }

  next = (value) => {
    this.subject.next(value)
  }
}
export default BfDebounceSubject
