/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2021
// All Rights Reserved
*/

import BfBasicFilter from '../../BfBasicFilter'

class ReportFavoritesFilter extends BfBasicFilter {
  static NAME = 'showFavorites'
  static PATH = `filter.${ReportFavoritesFilter.NAME}`
  constructor(data = {}) {
    super(ReportFavoritesFilter.PATH, ReportFavoritesFilter.NAME, data)
  }
}

export default ReportFavoritesFilter
