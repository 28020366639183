/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import BfDropDownSearchBasicFilter from '../../common/bfBasicFilters/BfDropDownSearchFilter/BfDropDownSearchBasicFilter'

class ReportOwnerFilter extends BfDropDownSearchBasicFilter {
  static NAME = 'owner'
  static PATH = `filter.${ReportOwnerFilter.NAME}`
  fixedParams = { search: { query: '%', limit: 20 } }

  constructor(data = {}) {
    super(ReportOwnerFilter.PATH, ReportOwnerFilter.NAME)
    this.mostRecentUser = data
  }

  getSearchURL = () => {
    return '/api/reports/owners'
  }
}

export default ReportOwnerFilter
