/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createFilterManagerFromReduxState } from '../../../BfFilterManager'
import { updateFilterListUrlAction } from '../../../../redux/BfFilterListUpdateURLAction'
import BfDropDownSearchWithFilter from './BfDropDownSearchWithFilter'
import { BfDataGridComponentWithContext } from '../../../../BfDataGridContext'
import _ from '../../../../../../services/utils/BfLodash'

const BfDropDownSearchFilterWithAction = (WrappedComponent) => {
  return class extends Component {
    _handleOnChange = (data) => {
      this.props.updateFilterListUrlAction(this.props.id, data)
    }

    render() {
      const { filter, t } = this.props
      filter.setT(t)

      return <WrappedComponent filter={filter} onChange={this._handleOnChange} fixedSuggestedOptions={filter.fixedSuggestedOptions} />
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const bfFilterManager = createFilterManagerFromReduxState(state, ownProps.id)
  const filter = bfFilterManager.getFilterFromURLByName(ownProps.filterName, ownProps.args)

  if (_.isDefined(filter.getRequiredFilters)) {
    _.each(filter.getRequiredFilters(), (filterName) => {
      const filterPropName = `${filterName}Filter`
      filter[filterPropName] = bfFilterManager.getFilterFromURLByName(filterName)
    })
  }
  return { filter: filter }
}

const BfDropDownSearchFilterWithRedux = connect(mapStateToProps, {
  updateFilterListUrlAction
})(BfDropDownSearchFilterWithAction(BfDropDownSearchWithFilter))

export default BfDataGridComponentWithContext(BfDropDownSearchFilterWithRedux)
