/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2021
// All Rights Reserved
*/
import { bfTranslate } from '../../../services/i18n/bfI18Utils'

export const SHORT_DAYS_T = (t) => {
  return [
    bfTranslate(t, 'SundayAbbr'),
    bfTranslate(t, 'MondayAbbr'),
    bfTranslate(t, 'TuesdayAbbr'),
    bfTranslate(t, 'Wednesdaybbr'),
    bfTranslate(t, 'ThursdayAbbr'),
    bfTranslate(t, 'FridayAbbr'),
    bfTranslate(t, 'SaturdayAbbr')
  ]
}

export const MONTHS_T = (t) => {
  return [
    bfTranslate(t, 'January'),
    bfTranslate(t, 'February'),
    bfTranslate(t, 'March'),
    bfTranslate(t, 'April'),
    bfTranslate(t, 'May'),
    bfTranslate(t, 'June'),
    bfTranslate(t, 'July'),
    bfTranslate(t, 'August'),
    bfTranslate(t, 'September'),
    bfTranslate(t, 'October'),
    bfTranslate(t, 'November'),
    bfTranslate(t, 'December')
  ]
}
