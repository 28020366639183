/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import React from 'react'
import { Button } from '@patron/patron-react/button'
import BfModalDevicesGrid from './BfModalDevicesGrid'

class BfDevicePageModalWithButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = { showModal: false }
  }

  _onClickShowDevices = () => {
    this.setState({ showModal: true })
  }

  _onClose = (selectedItems) => {
    this.props.onClose && this.props.onClose(selectedItems)
    this.setState({ showModal: false })
  }

  _showModal = () => {
    if (this.state.showModal) {
      return <BfModalDevicesGrid onClose={this._onClose} />
    }
  }

  render() {
    return (
      <React.Fragment>
        <Button className='' disabled={false} onClick={this._onClickShowDevices} small={false} title='Default' type='primary'>
          Show Devices
        </Button>
        {this._showModal()}
      </React.Fragment>
    )
  }
}

export default BfDevicePageModalWithButton
