/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Overflowmenu } from '@patron/patron-react/overflowmenu'
import _ from '../../../services/utils/BfLodash'
import logConsole from 'loglevel'
import BfActionService, { BF_TRUE } from './BfActionsService'
import { BfTrans, bfTranslate } from '../../../services/i18n/bfI18Utils'
import withShowMessage from '../../common/bfMessage/BfShowMessage'
import BfToggleButton from '../../common/BfToggleButton'
import BfSendMessageModal from './SendMessage/BfSendMessageModal'
import BfClientStorage from '../../../services/clientStorage/BfClientStorage'
class BfDeviceConfigurationButton extends Component {
  state = { showSendMessageModal: false }
  bfActionService = new BfActionService(this.props.bfCurrentUserInfoService)
  bfClientStorage = new BfClientStorage()

  SWD = 'swd'
  QUERY = 'query'
  MORE_AVAILABLE_APPS = [this.SWD, this.QUERY]

  constructor(props) {
    super(props)
    this.CONFIGURATION_ACTIONS = [
      {
        name: bfTranslate(props.t, 'SendMessageTitle'),
        action: this.showSendMessageAction,
        app: this.SWD
      },
      {
        name: bfTranslate(props.t, 'SendFileDropdown'),
        action: this.showSendFileAction,
        app: this.SWD
      },
      {
        name: bfTranslate(props.t, 'Query'),
        action: this.openQueryAppAction,
        app: this.QUERY
      }
    ]
  }

  showSendMessageAction = () => {
    logConsole.debug('showSendMessage')
    const selectedDevices = this._getSelectedDevices()
    const doAlert = true

    return this.bfActionService.canDevicesRunApp(selectedDevices, 'swd', this.props.t).subscribe({
      next: ({ flag: canRun, modalProperties }) => {
        if (canRun === BF_TRUE) {
          logConsole.debug('showSendMessage selectedDevices ', selectedDevices)
          this.setState({ showSendMessageModal: true })
        } else {
          if (doAlert) {
            this._showMessage({
              title: modalProperties.title,
              message: {
                key: modalProperties.message.key,
                params: modalProperties.message.params
              },
              type: modalProperties.type
            })
          }
        }
      }
    })
  }

  showSendFileAction = () => {
    logConsole.debug('showSendFile')
    const selectedDevices = this._getSelectedDevices()
    return this.bfActionService.canDevicesRunApp(selectedDevices, 'swd', this.props.t).subscribe({
      next: ({ flag: canRun, modalProperties }) => {
        if (canRun === BF_TRUE) {
          logConsole.debug('showSendFile selectedDevices ' + selectedDevices)
          this.bfClientStorage.saveJSON('devices', selectedDevices)
          window.location.href = '/swd/sendfile'
        } else {
          this._showMessage({
            title: modalProperties.title,
            message: {
              key: modalProperties.message.key,
              params: modalProperties.message.params
            },
            type: modalProperties.type
          })
        }
      }
    })
  }

  /**
   * For the device get an object like this:
   * {
   *    id: CorelationID if correlated device or ComputerID,
   *    nativeID: CorrelationID if correlated and user see more than 1 representations,
   *              ComputerID of native representation otherwise
   * }
   * */
  _getObjectIDs = (device) => {
    const representations = device.agentCorrelatedTypes || []
    return {
      id: device.id,
      nativeID: _.size(representations) === 1 && representations[0].agentType === 'Native' ? _.first(representations).computerID : device.id
    }
  }

  _getAllIds = (selectedDevices) => {
    return _.map(selectedDevices, this._getObjectIDs)
  }

  openQueryAppAction = () => {
    logConsole.debug('openQueryApp')
    const selectedDevices = this._getSelectedDevices()

    return this.bfActionService.canDevicesRunApp(selectedDevices, 'query', this.props.t).subscribe({
      next: ({ flag: canRun, modalProperties }) => {
        if (canRun === BF_TRUE) {
          logConsole.debug('showQueryApp selectedDevices ' + selectedDevices)
          const selectedDevicesIDs = this._getAllIds(selectedDevices)
          this.bfClientStorage.saveJSON('devices', selectedDevicesIDs)
          window.location.href = '/query'
        } else {
          this._showMessage({
            title: modalProperties.title,
            message: {
              key: modalProperties.message.key,
              params: modalProperties.message.params
            },
            type: modalProperties.type
          })
        }
      }
    })
  }

  _isAgentTypeDefined = (device) => {
    return _.isDefined(device.agentType)
  }

  _canShowApp = (app) => {
    const { permittedApps } = this.props
    return _.some(permittedApps, (permittedApp) => permittedApp === app)
  }

  _getAvailableApps = () => {
    return _.filter(this.MORE_AVAILABLE_APPS, this._canShowApp)
  }

  _getConfigurationsActions = (availableApps) => {
    return _.filter(this.CONFIGURATION_ACTIONS, (configurationAction) => _.includes(availableApps, configurationAction.app))
  }

  _getSelectedDevices = () => {
    const { selectedDevices } = this.props
    return _.values(selectedDevices)
  }

  _getListItems = () => {
    const availableApps = this._getAvailableApps()
    logConsole.debug('available apps are ', availableApps)
    return this._getConfigurationsActions(availableApps)
  }

  _handleClick = (app) => {
    app.action()
  }

  _onCloseSendMessage = () => {
    this.setState({ showSendMessageModal: false })
  }

  _showSendMessageModal = () => {
    return <BfSendMessageModal onClose={this._onCloseSendMessage} selectedDevices={this._getSelectedDevices()} />
  }

  _showMessage = (modalProperties, onOkCallback) => {
    const { message, type = 'success', okLabel, cancelLabel, title = '' } = modalProperties

    const translatedMessage = BfTrans(message.key, message.params)

    this.props.showMessage(type, BfTrans(title), translatedMessage, onOkCallback, okLabel, cancelLabel)
  }

  render() {
    const { t } = this.props

    return (
      <React.Fragment>
        <Overflowmenu
          className='ml-4'
          direction='bottom-left'
          ellipsisType='vertical'
          customTemplate={<BfToggleButton title={bfTranslate(t, 'deviceConfiguration')} className='' />}
          listItems={this._getListItems()}
          onClick={this._handleClick}
        />

        {this.state.showSendMessageModal && this._showSendMessageModal()}
      </React.Fragment>
    )
  }
}
export default withShowMessage(withTranslation()(BfDeviceConfigurationButton))
