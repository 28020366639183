/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import { UPDATE_EXPORTING_TYPE, UPDATE_SELECTED_ROWS_IDS } from './BfReportsActionTypes'
import BfReduxStateConstants from '../../BfReduxStateConstants'

/**
 * @param {string} namespace - Slice of state to use
 * @param {string} isExportingType - which export is being done
 *  Should be based on file type: csv, xlsx or pdf.
 */
export const updateIsExportingTypeAction = (namespace, isExportingType) => ({
  type: `${namespace}/${UPDATE_EXPORTING_TYPE}`,
  isExportingType: isExportingType
})

export const updateSelectedRowsIdsAction = (selectedRowsIds) => ({
  type: `${BfReduxStateConstants.REPORTS}/${UPDATE_SELECTED_ROWS_IDS}`,
  selectedRowsIds: selectedRowsIds
})
