/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2021
// All Rights Reserved
*/

import BfDropDownSearchBasicFilter from '../../common/bfBasicFilters/BfDropDownSearchFilter/BfDropDownSearchBasicFilter'

class InsightsAttributeNameFilter extends BfDropDownSearchBasicFilter {
  static NAME = 'name'
  static PATH = `filter.${InsightsAttributeNameFilter.NAME}`
  fixedParams = { search: { query: '%', limit: 20 } }

  constructor(data = {}) {
    super(InsightsAttributeNameFilter.PATH, InsightsAttributeNameFilter.NAME)
    this[InsightsAttributeNameFilter.NAME] = data
  }

  getSearchURL = () => {
    return '/insights/api/customAttribute/name'
  }
}

export default InsightsAttributeNameFilter
