/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2021
// All Rights Reserved
*/

import BfBasicFilter from '../BfBasicFilter'

class BfPatchContentIDSearchFilter extends BfBasicFilter {
  static NAME = 'content_id_search'
  static PATH = `filter.${BfPatchContentIDSearchFilter.NAME}`
  maxValue = 100000000000
  hideStepper = true

  constructor(data = {}) {
    super(BfPatchContentIDSearchFilter.PATH, BfPatchContentIDSearchFilter.NAME)
    this[BfPatchContentIDSearchFilter.NAME] = data
  }
}

export default BfPatchContentIDSearchFilter
