/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import BfHttpClient from '../authClient/BfHttpClient'
import { AsyncSubject, of } from 'rxjs'
import { map } from 'rxjs/operators'
import BfConsole from 'loglevel'

/**
 * Provides functionalites for retrieving cached data
 */
class BfCachedRequest {
  http = new BfHttpClient()
  subject = null
  data = null

  constructor(enableLog) {
    this.enableLog = enableLog
  }

  get(url, params) {
    this.url = url
    this.params = params
    return this._init()
  }

  _get() {
    this._log(`Make http get to url: ${this.url} with params: ${this.params ? this.params : ''}`)
    return this.http.get(this.url, this.params).pipe(
      map((data) => {
        this._log(`Response is: `, data)
        this.data = data
        return this.data
      })
    )
  }

  _waitForRequest() {
    if (!this.subject) {
      this.subject = new AsyncSubject()
      this._get().subscribe(this.subject)
    }
    return this.subject
  }

  _init() {
    if (!this.data) {
      return this._waitForRequest()
    } else {
      return of(this.data)
    }
  }

  _log(log) {
    if (this.enableLog) {
      BfConsole.log(log)
    }
  }
}

export default BfCachedRequest
