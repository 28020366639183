/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'

const ContentButtons = ({ options, fieldValue, handleClick, disabled }) => (
  <div className='hcl-content-switcher blue_active_blue_light'>
    {options.map((option) => (
      <button
        className={`hcl-content-switcher-btn ${fieldValue === option.value ? 'active' : ''}`}
        type='button'
        onClick={() => handleClick(option.value)}
        key={option.value}
        disabled={disabled}
      >
        {option.label}
      </button>
    ))}
  </div>
)

function BfContentSwitcher(props) {
  const { name, label, subLabel, disabled, options, ...rest } = props
  return (
    <Field name={name} id={name} {...rest}>
      {({ field: { value }, form: { setFieldValue } }) => (
        <div className='hcl-form-group'>
          {label && (
            <label className='hcl-label' htmlFor={name}>
              {label}
            </label>
          )}
          {subLabel && <div className='hcl-helper-text hcl-text-left'>{subLabel}</div>}
          <div>
            <ContentButtons options={options} fieldValue={value} handleClick={(value) => setFieldValue(name, value)} disabled={disabled} />
          </div>
        </div>
      )}
    </Field>
  )
}

BfContentSwitcher.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  options: PropTypes.array // ex. [{label: 'Title', value: 'title'},...]
}

export default BfContentSwitcher
