/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/
import React, { Component } from 'react'
import BfDropDownButton from '../common/BfDropDownButton'
import { Overlay } from '@patron/patron-react/overlay'

class BfOverlayWithButton extends Component {
  thisOverlayWithButtonRef = React.createRef()

  state = {
    showOverlay: false,
    overlayTargetElement: null
  }

  _showOverlay = (e) => {
    this.setState({
      showOverlay: !this.state.showOverlay,
      overlayTargetElement: this.thisOverlayWithButtonRef.current
    })
  }

  _toggleOverlay = (status) => {
    if (!status) {
      this.setState({
        showOverlay: status,
        overlayTargetElement: null
      })
    }
  }

  render() {
    const { itemsSelected, cleanItemSelected } = this.props
    return (
      <div id='bf-overlay-with-button' ref={this.thisOverlayWithButtonRef} className='bf-overlay-with-button'>
        <BfDropDownButton
          onClick={this._showOverlay}
          open={this.state.showOverlay}
          itemsSelected={itemsSelected}
          cleanItemSelected={cleanItemSelected}
        />
        <Overlay
          // Used to show or hide Overlay Component
          showOverlay={this.state.showOverlay}
          // Used to set the target Element in which overlay should be mount
          targetElement={this.state.overlayTargetElement}
          // Used to attach element to body tag
          // remove overlay component on escape key
          closeOnEscape
          // callback function which will return the status of overlay.
          onToggle={this._toggleOverlay}
        >
          {this.props.children}
        </Overlay>
      </div>
    )
  }
}
export default BfOverlayWithButton
