import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'

const RadioInputs = ({ options, fieldValue, handleClick, disabled, ...rest }) => (
  <React.Fragment>
    {options.map((option) => (
      <div className='hcl-radio-item' key={option.label} onClick={() => (disabled ? null : handleClick(option.value))}>
        <input className='hcl-radio' type='radio' checked={fieldValue === option.value} disabled={disabled} readOnly {...rest} />
        <label className='hcl-radio-label' htmlFor={option.label}>
          {option.label}
        </label>
      </div>
    ))}
  </React.Fragment>
)

function BfRadioGroup(props) {
  const { name, label, options, disabled, id, onChange, ...rest } = props
  return (
    <Field name={name} id={id}>
      {({ field: { value }, form: { setFieldValue } }) => (
        <div className='blue_active_blue_light hcl-form-group'>
          {label && <label className='hcl-label'>{label}</label>}
          <div className={`hcl-radio-group ${props.vertical ? 'hcl-stack-vertical' : ''}`}>
            <RadioInputs
              disabled={disabled}
              options={options}
              fieldValue={value}
              handleClick={(value) => {
                setFieldValue(name, value)
                onChange && onChange(value)
              }}
              {...rest}
            />
          </div>
        </div>
      )}
    </Field>
  )
}

BfRadioGroup.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  options: PropTypes.array, // ex. [{label: 'Title', value: 'title'},...]
  vertical: PropTypes.bool, // default is horizontal layout
  disabled: PropTypes.bool
}

export default BfRadioGroup
