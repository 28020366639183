/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/
import BfDropDownSearchBasicFilter from '../../common/bfBasicFilters/BfDropDownSearchFilter/BfDropDownSearchBasicFilter'

class BfRecentUserFilter extends BfDropDownSearchBasicFilter {
  static NAME = 'mostRecentUser'
  static PATH = `filter.${BfRecentUserFilter.NAME}`

  constructor(data = {}) {
    super(BfRecentUserFilter.PATH, BfRecentUserFilter.NAME)
    this[this.getName()] = data
  }

  getSearchURL = () => {
    return '/devices/recentusers'
  }
}
export default BfRecentUserFilter
