/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import React from 'react'
import './bfMainAppContainer.scss'

/**
 * @component
 * Displays the App title and summary
 * @memberof BfMainAppContainer
 * @param {*} props to pass values to the components
 * @property {String} props.title Title of the app as element of props
 * @property {string} props.summary Summary or description about the App as element of props
 */
class BfMainPageHeaderTitle extends React.Component {
  /**
   * To get the summary of the application
   * @method
   * @param {*} props to display the styled summary of the application
   * @returns {HTMLElement} to display the summary
   */
  _getSummary = (props) => {
    if (props.summary) {
      return (
        <span className='bfAppSummary'>
          <br />
          {props.summary}
        </span>
      )
    }
  }

  render() {
    return (
      <div>
        <div className='hcl-container bfAppTitleContainer'>
          <div className='hcl-row'>
            <div className='bfHeaderTitle'>
              <span className='bfAppTitle'>{this.props.title}</span>
              {this._getSummary(this.props)}
            </div>
            <div className='bfHeaderContent alignRight'>{this.props.children}</div>
          </div>
        </div>
      </div>
    )
  }
}

export default BfMainPageHeaderTitle
