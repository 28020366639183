/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { updateFilterListUrlAction } from '../../../redux/BfFilterListUpdateURLAction'
import { BfDataGridComponentWithContext } from '../../../BfDataGridContext'
import { createFilterManagerFromReduxState } from '../../BfFilterManager'
import InsightsDeviceCountWithFilter from './InsightsDeviceCountWithFilter'

const InsightsDeviceCountFilterWithReduxAction = (WrappedComponent) => {
  return class extends Component {
    _handleOnChange = (data) => {
      this.props.updateFilterListUrlAction(this.props.id, data)
    }

    render() {
      return <WrappedComponent filter={this.props.filter} onChange={this._handleOnChange} />
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const bfFilterManager = createFilterManagerFromReduxState(state, ownProps.id)
  const insightsDeviceCountFilter = bfFilterManager.getInsightsDeviceCountFilter()

  return {
    filter: insightsDeviceCountFilter
  }
}
const InsightsDeviceCountFilterWithRedux = connect(mapStateToProps, {
  updateFilterListUrlAction
})(InsightsDeviceCountFilterWithReduxAction(InsightsDeviceCountWithFilter))

export default BfDataGridComponentWithContext(InsightsDeviceCountFilterWithRedux)
