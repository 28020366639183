/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/
import React, { Component } from 'react'
import { BfReset } from '../../../BfWithReset'
import BfNumberInputFilter from '../../BfNumberInputFilter/BfNumberInputFilter'

class BfNumberInputWithFilter extends Component {
  _getInitialValue = () => {
    return this.props.filter.getData()
  }

  _onValueSelected = (numberInputValue) => {
    const overMaximum = this.props.filter?.maxValue && numberInputValue > this.props.filter.maxValue
    if (overMaximum) {
      this.props.filter.setData(null)
    } else {
      this.props.filter.setData(numberInputValue)
    }
  }

  render() {
    return (
      <BfReset filter={this.props.filter}>
        {(key) => (
          <BfNumberInputFilter
            key={key}
            bfFilter={this.props.filter}
            maxValue={this.props.filter?.maxValue ? this.props.filter.maxValue : null}
            inputStep={1}
            onFilterApplied={this.props.onChange}
            onValueSelected={this._onValueSelected}
            initValue={this._getInitialValue()}
            hideStepper={this.props.filter?.hideStepper}
          />
        )}
      </BfReset>
    )
  }
}

export default BfNumberInputWithFilter
