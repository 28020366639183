/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import BfBasicCustomFilter from '../BfBasicCustomFilter'
import _ from '../../../../../services/utils/BfLodash'
import BfReduxStateConstants from '../../../../common/BfReduxStateConstants'

class BfCreateByFilter extends BfBasicCustomFilter {
  static NAME = 'creator'
  static PATH = `filter.${BfCreateByFilter.NAME}`
  static NAMESPACE = BfReduxStateConstants.CUSTOM_DATA_GRID

  constructor(data = {}) {
    super(BfCreateByFilter.PATH, BfCreateByFilter.NAME)
    this[BfCreateByFilter.NAME] = data
  }

  processResponse = (res) => {
    const data = { values: [] }
    _.each(res.data, (value, key) => {
      data.values[key] = value.creator
    })
    return data
  }

  getSearchURL = () => {
    return '/custom/api/tasks/creator'
  }
}

export default BfCreateByFilter
