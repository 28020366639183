/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import React, { Component } from 'react'

import { Button } from '@patron/patron-react/button'

import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import BfPlatformVersion from '../../services/devices/BfPlatformVersion'
import { bfTranslate } from '../../services/i18n/bfI18Utils'
import BfReduxStateConstants from '../common/BfReduxStateConstants'
import { BfChartsReducer } from '../common/BfCharts/redux/BfChartsReducer'
import { BfReportsReducer } from '../common/BfReports/redux/BfReportsReducer'
import { updateShowSummaryAction } from '../common/BfCharts/redux/BfChartUpdateAction'
import BfReportsBar from '../common/BfReports/BfReportsBar'
import { BfWithReduxStoreConsumer } from '../mainAppContainer/bfReduxStore/BfReduxStore'
import BfDevicePageExportReport from './BfDevicePageExportReport'

class BfDevicePageTitleView extends Component {
  bfPlatformVersion = new BfPlatformVersion()

  constructor(props) {
    super(props)

    const { store } = props
    store.addReducer(BfReduxStateConstants.DEVICE_CHARTS, BfChartsReducer(BfReduxStateConstants.DEVICE_CHARTS))
    store.addReducer(BfReduxStateConstants.REPORTS, BfReportsReducer(BfReduxStateConstants.REPORTS))

    this.state = {
      show: false
    }
  }

  componentDidMount = () => {
    this._canRunReports()
  }

  _toggleSummaryCharts = () => {
    this.props.updateShowSummaryAction(BfReduxStateConstants.DEVICE_CHARTS, !this.props.showSummary)
  }

  _buildReports = () => {
    return (
      <React.Fragment>
        <BfReportsBar contentType={this.props.contentType} namespace={BfReduxStateConstants.DEVICES} />
        <BfDevicePageExportReport />
        <Button className='hcl-primary' onClick={this._toggleSummaryCharts} disabled={this.props.isExportingType === 'pdf'}>
          {this.props.showSummary ? bfTranslate(this.props.t, 'hideSummary') : bfTranslate(this.props.t, 'showSummary')}
        </Button>
      </React.Fragment>
    )
  }

  _canRunReports = () => {
    return this.bfPlatformVersion.isGreaterThanTen().subscribe({
      next: (isGreaterTen) => {
        if (isGreaterTen) {
          this.setState({
            show: true
          })
        }
      }
    })
  }

  render() {
    return this.state.show && this._buildReports()
  }
}

const mapStateToProps = (state) => {
  return {
    showSummary: state[BfReduxStateConstants.DEVICE_CHARTS]?.showSummary ? state[BfReduxStateConstants.DEVICE_CHARTS].showSummary : false,
    isSummaryLoaded: state[BfReduxStateConstants.DEVICE_CHARTS]?.isSummaryLoaded ? state[BfReduxStateConstants.DEVICE_CHARTS].isSummaryLoaded : false,
    isExportingType: state[BfReduxStateConstants.REPORTS]?.isExportingType ? state[BfReduxStateConstants.REPORTS].isExportingType : false
  }
}

const BfDevicePageTitleViewReduxComponent = connect(mapStateToProps, {
  updateShowSummaryAction
})(withTranslation()(BfDevicePageTitleView))

export default BfWithReduxStoreConsumer(BfDevicePageTitleViewReduxComponent)
