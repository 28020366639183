/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import React, { useState, useEffect } from 'react'

import { StackedBarChart } from '@patron/patron-react-chart/stackedbarchart'
import BfConsole from 'loglevel'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import { bfTranslate } from '../../../services/i18n/bfI18Utils'
import _ from '../../../services/utils/BfLodash'
import { CHART_COLORS } from '../../common/BfCharts/BfChartConstants'
import BfChartTable from '../../common/BfCharts/BfChartTable/BfChartTable'
import BfChartContainer from '../../common/BfCharts/BfChartContainer/BfChartContainer'
import { getNormalizedCounts } from '../../common/BfCharts/bfChartHelpers.js'

const BfDeviceTypeChart = ({
  deviceTypeByReportTime,
  gettingChartData,
  updateFilterListUrlAction,
  updateChartLoaded,
  chartId,
  namespace,
  isLoaded,
  chartTitle
}) => {
  const [dateFilterMappings, setDateFilterMappings] = useState({})
  const [chartData, setChartData] = useState(undefined)
  const { t } = useTranslation()
  const [overflowFilter, setOverflowFilter] = useState({})

  const OTHER_NAME = 'Other'
  const MAX_ITEMS = 8

  const getDateFilterMappings = function () {
    const oneDayAgo = moment.utc().subtract(1, 'days').startOf('day').unix()
    const twoDaysAgo = moment.utc().subtract(2, 'days').endOf('day').unix()
    const sevenDaysAgo = moment.utc().subtract(7, 'days').startOf('day').unix()
    const eightDaysAgo = moment.utc().subtract(8, 'days').endOf('day').unix()
    const thirtyDaysAgo = moment.utc().subtract(30, 'days').startOf('day').unix()
    const thirtyOneDaysAgo = moment.utc().subtract(31, 'days').endOf('day').unix()

    return {
      underOne: { lastSeen: { startDate: oneDayAgo } },
      underSeven: {
        lastSeen: { startDate: sevenDaysAgo, endDate: twoDaysAgo }
      },
      underThirty: {
        lastSeen: { startDate: thirtyDaysAgo, endDate: eightDaysAgo }
      },
      overThirty: { lastSeen: { endDate: thirtyOneDaysAgo } }
    }
  }

  const _onBarClick = (sectionInfo) => {
    const filters = {
      ...dateFilterMappings[sectionInfo.moreInfo?.group]
    }
    const deviceType = sectionInfo.Subgroup

    if (deviceType === OTHER_NAME) {
      filters.deviceType = overflowFilter
    } else {
      const filterObj = {}
      deviceType.split(', ').forEach((type) => {
        filterObj[type] = true
      })
      filters.deviceType = filterObj
    }

    updateFilterListUrlAction(namespace, filters)
  }
  const _onTableClick = (row, column) => {
    let filters
    if (row?.filters && column?.filters) {
      filters = { ...row.filters, ...column.filters }
    } else if (row?.filters) {
      filters = row.filters
    } else if (column?.filters) {
      filters = column.filters
    } else {
      BfConsole.log('no filters to apply')
    }
    if (filters) {
      updateFilterListUrlAction(namespace, filters)
    }
  }

  useEffect(() => {
    const dateFilters = getDateFilterMappings()
    setDateFilterMappings(dateFilters)

    const _parseChartInfo = function (deviceTypeByReportTime) {
      const reportTimesObj = deviceTypeByReportTime

      const _getSortedDeviceKeys = function (reportTimesObj) {
        const deviceTypesCounts = {}
        for (const timeRange in reportTimesObj) {
          for (const deviceType in reportTimesObj[timeRange]) {
            if (deviceTypesCounts[deviceType]) {
              deviceTypesCounts[deviceType] += reportTimesObj[timeRange][deviceType]
            } else {
              deviceTypesCounts[deviceType] = reportTimesObj[timeRange][deviceType]
            }
          }
        }

        const deviceCountsArr = Object.keys(deviceTypesCounts).map((key) => {
          return { deviceType: key, count: deviceTypesCounts[key] }
        })

        const sorted = _.orderBy(deviceCountsArr, ['count', 'deviceType'], ['desc', 'asc'])
        return _.map(sorted, 'deviceType')
      }

      const _getTranslatedLabels = function (deviceType) {
        let label
        if (deviceType.indexOf(', ') === -1) {
          label = bfTranslate(t, deviceType)
        } else {
          const translations = deviceType.split(', ').map((type) => {
            return bfTranslate(t, type)
          })
          label = translations.join(', ')
        }
        return label
      }

      const _getRowLabelsAndChartColors = function (deviceTypesKeys, overflowFilterObject) {
        const newLabels = []
        const chartColors = []
        let i = 0
        if (deviceTypesKeys.length !== 0) {
          deviceTypesKeys.forEach((deviceType) => {
            const newLabel = {
              label: _getTranslatedLabels(deviceType),
              key: deviceType,
              color: CHART_COLORS[i]
            }
            if (deviceType === OTHER_NAME) {
              newLabel.filters = { deviceType: overflowFilterObject }
            } else {
              const filterObj = {}
              deviceType.split(', ').forEach((type) => {
                filterObj[type] = true
              })
              newLabel.filters = { deviceType: filterObj }
            }
            newLabels.push(newLabel)
            chartColors.push({
              color: CHART_COLORS[i++],
              key: deviceType
            })
          })
        } else {
          const placeholderDeviceKeys = ['Server', 'Desktop', 'Laptop']
          placeholderDeviceKeys.forEach((key) => {
            newLabels.push({
              label: bfTranslate(t, key),
              key: key,
              color: CHART_COLORS[i]
            })
            chartColors.push({ color: CHART_COLORS[i++], key: key })
          })
        }

        return {
          newLabels,
          chartColors
        }
      }

      let deviceTypesKeys = _getSortedDeviceKeys(reportTimesObj)

      let overflowDeviceTypes = []
      if (deviceTypesKeys.length > MAX_ITEMS) {
        overflowDeviceTypes = deviceTypesKeys.slice(MAX_ITEMS - 1)
        deviceTypesKeys = deviceTypesKeys.slice(0, MAX_ITEMS - 1)
        deviceTypesKeys.push(OTHER_NAME)
      }

      const overflowFilterObject = {}
      overflowDeviceTypes.forEach((deviceType) => {
        if (deviceType.indexOf(', ') === -1) {
          overflowFilterObject[deviceType] = true
        } else {
          deviceType.split(', ').forEach((type) => {
            overflowFilterObject[type] = true
          })
        }
      })

      setOverflowFilter(overflowFilterObject)

      const { newLabels, chartColors } = _getRowLabelsAndChartColors(deviceTypesKeys, overflowFilterObject)

      const chartInfo = []
      const columns = []

      let currentChart = 1
      for (const timeRange in reportTimesObj) {
        // make all charts the same height by scaling all values from 0 - 1
        const normalizedCounts = getNormalizedCounts(reportTimesObj[timeRange], overflowDeviceTypes, deviceTypesKeys, OTHER_NAME)

        let otherCount = 0
        for (const deviceType of overflowDeviceTypes) {
          if (reportTimesObj[timeRange][deviceType]) {
            otherCount += reportTimesObj[timeRange][deviceType]
          }
        }

        columns.push({
          ...reportTimesObj[timeRange],
          ...(otherCount > 0 && { [OTHER_NAME]: otherCount }),
          filters: { ...dateFilters[timeRange] },
          description: bfTranslate(t, timeRange + 'Range')
        })

        chartInfo.push({
          label: bfTranslate(t, timeRange + 'Range'),
          group: timeRange,
          order: currentChart++,
          ...normalizedCounts
        })
      }

      setChartData({
        deviceTypeChartData: chartInfo,
        deviceTypeChartKeys: deviceTypesKeys.slice().reverse(),
        deviceTypeChartColors: chartColors,
        rowLabels: newLabels,
        columnData: columns
      })
    }
    if (!_.isUndefined(deviceTypeByReportTime) && gettingChartData === false) {
      _parseChartInfo(deviceTypeByReportTime)
    }
  }, [deviceTypeByReportTime, gettingChartData, t])

  useEffect(() => {
    if (gettingChartData) {
      setChartData(undefined)
    }
  }, [gettingChartData])

  useEffect(() => {
    if (chartData && !gettingChartData && !isLoaded) {
      updateChartLoaded(chartId)
    }
  }, [chartData, chartId, gettingChartData, updateChartLoaded, isLoaded])

  function _isLoading() {
    return !chartData || gettingChartData
  }

  function translateMultipleDeviceTypes(deviceTypesString) {
    const translatedTypes = deviceTypesString
      .split(',')
      .map((type) => type.trim())
      .map((type) => bfTranslate(t, type))
    return translatedTypes.join(', ')
  }

  return (
    <BfChartContainer isLoading={_isLoading()} chartTitle={chartTitle}>
      {!_isLoading() && (
        <React.Fragment>
          <div className='bfDeviceChartWrapper'>
            <StackedBarChart
              chartdata={chartData.deviceTypeChartData}
              barSize={0.3}
              chartHeight={260}
              chartLabel={{
                keys: {
                  mapsTo: chartData.deviceTypeChartKeys
                },
                label: {
                  mapsTo: 'label'
                },
                info: {
                  mapsTo: ['group']
                },
                symbol: '',
                sortBy: {
                  mapsTo: 'order',
                  sortOrder: 'ascending'
                }
              }}
              chartType='column'
              colordata={chartData.deviceTypeChartColors}
              onBarClick={_onBarClick}
              hideYaxis
              onMouseMove={(data) => {
                if (data.length > 0) {
                  return { subgroup: translateMultipleDeviceTypes(data[0]) }
                } else return {}
              }}
              showLegend={false}
              showTooltip={{
                customTooltip: true,
                tooltipDisplay: true
              }}
            />
          </div>
          <div>
            <BfChartTable
              onTableClick={_onTableClick}
              headerLabel={bfTranslate(t, 'totalDevices')}
              columnData={chartData.columnData}
              rowLabels={chartData.rowLabels}
            />
          </div>
        </React.Fragment>
      )}
    </BfChartContainer>
  )
}

BfDeviceTypeChart.propTypes = {
  gettingChartData: PropTypes.bool,
  deviceTypeByReportTime: PropTypes.objectOf(
    PropTypes.shape({
      underOne: PropTypes.objectOf(PropTypes.number),
      underSeven: PropTypes.objectOf(PropTypes.number),
      underThirty: PropTypes.objectOf(PropTypes.number),
      overThirty: PropTypes.objectOf(PropTypes.number)
    })
  ),
  updateFilterListUrlAction: PropTypes.func,
  updateChartLoaded: PropTypes.func,
  namespace: PropTypes.string,
  chartId: PropTypes.string,
  isLoaded: PropTypes.bool
}

export default BfDeviceTypeChart
