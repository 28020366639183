/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import BfBasicFilter from './BfBasicFilter'

class BfUnknowFilter extends BfBasicFilter {
  static NAME = 'Unknown'
  getName = () => {
    return BfUnknowFilter.NAME
  }
}

export default BfUnknowFilter
