import React from 'react'
import BfCellWithTooltipRenderer from './BfCellWithTooltipRenderer'
import _ from '../../../services/utils/BfLodash'
import './BfCellArray.scss'
import BfCellModalRenderer from './BfCellModalRenderer'
import BfSplitTextInLines from '../../common/BfSplitTextInLines'
import { withTranslation } from 'react-i18next'
import withBfModal from '../../modals/BfWithModal'
import BfCellModalHeaderSection from './BfCellModalHeaderSection'
import { bfTranslate } from '../../../services/i18n/bfI18Utils'

/**
 * Cell renderer for data grid
 */
class BfCellArray extends React.Component {
  state = { showModal: false }
  MAX_ELEMENTS = 5

  _onClickShowModal = () => {
    const { t, title } = this.props
    this.props.showModal({
      message: this._getModalContent(),
      title: bfTranslate(t, title)
    })
  }

  _getModalContent = () => {
    const { rowData, cellName, title } = this.props
    return (
      <BfCellModalRenderer title={title} headerSection={<BfCellModalHeaderSection row={rowData} />}>
        {BfSplitTextInLines(rowData[cellName])}
      </BfCellModalRenderer>
    )
  }

  _renderNumberValues = (numOfValues) => {
    return (
      <div className='bf-cell-grow'>
        <span className='hcl-float-right bf-cell-elements-number' onClick={this._onClickShowModal}>
          {'[' + numOfValues + ']'}
        </span>
      </div>
    )
  }

  _isEmptyContent = (array) => {
    return _.isEmpty(array) || _.every(array, (value) => value === '' || _.isNull(value))
  }

  render() {
    const { rowData, cellName, showNA } = this.props
    const cellValues = showNA === true && this._isEmptyContent(rowData[cellName]) ? ['N/A'] : rowData[cellName]
    const haveValues = _.isNotEmpty(cellValues) && _.isArray(cellValues)
    if (haveValues) {
      const numOfValues = _.size(cellValues)
      const csvValues = _.join(cellValues, ', ')
      const withTooltipEllipsis = numOfValues > this.MAX_ELEMENTS
      return (
        <React.Fragment>
          <div className='bf-cell-container'>
            <div className='bf-cell-fill bf-ellipsis hcl-flex'>
              <BfCellWithTooltipRenderer maxLine={this.MAX_ELEMENTS} textTooltip={cellValues}>
                <span className='bf-ellipsis'>{csvValues}</span>
              </BfCellWithTooltipRenderer>
            </div>
            {withTooltipEllipsis && this._renderNumberValues(numOfValues)}
          </div>
        </React.Fragment>
      )
    }
    return null
  }
}

export default withBfModal(withTranslation()(BfCellArray))
