/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/
import { map } from 'rxjs/operators'
import BfCachedRequest from '../../../../../services/utils/BfCachedRequest'
import _ from '../../../../../services/utils/BfLodash'
import BfBasicFilter from '../../BfBasicFilter'
import { createAgentTypeDropDownItem } from '../../common/BfCheckboxTree/BfCheckboxTree'

class BfManagedByFilter extends BfBasicFilter {
  static NAME = 'agentType'
  static PATH = `filter.${BfManagedByFilter.NAME}`
  bfCachedRequest = new BfCachedRequest()

  constructor(data = {}) {
    super(BfManagedByFilter.PATH, BfManagedByFilter.NAME, data)
  }

  getItems = () => {
    return this.bfCachedRequest.get('/api/devices/agenttype').pipe(
      map((res) => {
        const orderedItems = _.sortBy(res.values, 'DisplayName')
        return createAgentTypeDropDownItem(orderedItems)
      })
    )
  }

  getSelectedItems = () => {
    return _.size(this.agentType)
  }
}

export default BfManagedByFilter
