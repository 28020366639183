/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import React from 'react'
import './BfCellPolicyGroupRender.scss'
import _ from '../../../services/utils/BfLodash'
import BfCellCloudRenderer from './BfCellCloudRenderer'
import BfLinkWithTooltip from './BfLinkWithTooltip'
import BfCellWidth from './BfCellWidth'

/**
 * Renders Device cells
 */
class BfCellPolicyGroupRender {
  constructor(header) {
    this.header = header
    this.name = header.field
  }

  /**
   * Renders MDM if device is MDM based
   * @method
   * @returns {Function} Cell Renderer
   */
  getRenderer() {
    return this._getPolicyGroupRenderHTML
  }

  /**
   * Renders PolicyGroup cell
   * @method
   * @param {Object} row Device value
   * @returns {HTMLElement} Cell HTML
   */
  _getPolicyGroupRenderHTML = (row) => {
    // string1, string2, string3
    const _mapNames = _.map(row.agentCorrelatedTypes, (repr) => (repr.ComputerName ? repr.ComputerName.slice(0, -1) : ''))
    const names = row.agentCorrelatedTypes ? _.uniq(_mapNames).join(', ') : row.name
    return (
      <BfCellWidth header={this.header}>
        <div className='bf-deviceRender'>
          <div className='bf-ellipsis'>
            <BfLinkWithTooltip href={'/mdm/next/policygroup/' + row.id} value={row.name} toolTipValue={names} />
          </div>
          <div className='bf-cloudRenderer'>
            <BfCellCloudRenderer device={row} />
          </div>
        </div>
      </BfCellWidth>
    )
  }
}

export default BfCellPolicyGroupRender
