/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/
import './BfCellWidth.scss'

import React from 'react'
const BfCellWidth = (props) => {
  return (
    <div className='bf-cell-container'>
      <div className='bf-cell-fill bf-ellipsis'>{props.children}</div>
    </div>
  )
}

export default BfCellWidth
