/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import { mergeMap } from 'rxjs/operators'
import BfCachedRequest from '../utils/BfCachedRequest'
import BfAgentTypeInfo from './BfAgentTypeInfo'

/**
 * Retrieves from server and returns cloud tags info
 * @component
 */
class BfCloudTagsInfo {
  bfAgentTypeInfo = new BfAgentTypeInfo()
  bfCachedRequest = new BfCachedRequest(true)

  /**
   * Returns an object like this one:
   * @returns {Promise<Object>}
   * {
   *    show: true|false,
   *    agentTypes: {
   *      <AgentTypeName>: {
   *        contentID: <value>,
   *        siteID: <value>,
   *        propertyID: <value>}
   *    } ...
   * }
   * @param {agentTypeValue}
   */
  getShowTags() {
    return this.bfAgentTypeInfo.getAgentTypes().pipe(
      mergeMap((agentTypeValue) => {
        return this.bfCachedRequest.get('/framework/devices/tags/show', {
          agentTypes: agentTypeValue.values
        })
      })
    )
  }
}

export default BfCloudTagsInfo
