/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2021
// All Rights Reserved
*/

import _ from '../../../../services/utils/BfLodash'
import BfBasicFilter from '../BfBasicFilter'

class BfPatchOpenActionsFilter extends BfBasicFilter {
  static NAME = 'open_action_count'
  static PATH = `filter.${BfPatchOpenActionsFilter.NAME}`
  constructor(data = {}) {
    super(BfPatchOpenActionsFilter.PATH, BfPatchOpenActionsFilter.NAME)
    this[BfPatchOpenActionsFilter.NAME] = data
  }

  get = () => {
    const name = this.getName()
    return this[name]?.val || this[name]?.val === 0 ? { [name]: this[name] } : { [name]: {} }
  }

  setData = (data) => {
    this[this.getName()] = { val: _.parseInt(data), isLessSelected: false }
  }

  getData = () => {
    return this[this.getName()]?.val
  }
}

export default BfPatchOpenActionsFilter
