/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import BfBasicFilter from '../../BfBasicFilter'

class BfShowRelevantContentKeyFilter extends BfBasicFilter {
  static NAME = 'contentKey'
  static PATH = `filter.${BfShowRelevantContentKeyFilter.NAME}`

  constructor() {
    super(BfShowRelevantContentKeyFilter.PATH, BfShowRelevantContentKeyFilter.NAME)
  }
}

export default BfShowRelevantContentKeyFilter
