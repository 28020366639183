/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import React, { Component } from 'react'
import '../../../../../modals/BfOverlayWithButton.scss'
import BfOverlayWithButton from '../../../../../modals/BfOverlayWithButton'
import BfCheckBoxTreeWithFilter from './BfCheckBoxTreeWithFilter'

class BfCheckBoxTreeFilterInOverlay extends Component {
  _cleanAllSelection = () => {
    this.props.filter.setData({})
    this.props.onChange(this.props.filter.get())
  }

  _getItems = () => {
    return this.props.filter.getItems()
  }

  render() {
    const { filter, onChange } = this.props

    return (
      <BfOverlayWithButton itemsSelected={filter.getSelectedItems()} cleanItemSelected={this._cleanAllSelection}>
        <div className='overlay-container'>
          <BfCheckBoxTreeWithFilter filter={filter} onChange={onChange} items={this._getItems} />
        </div>
      </BfOverlayWithButton>
    )
  }
}

export default BfCheckBoxTreeFilterInOverlay
