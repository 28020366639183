/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/
import _ from '../../../../../services/utils/BfLodash'

class BfColumnSelectionFilter {
  constructor(headers = []) {
    this.headers = headers
  }

  get = () => {
    return {
      headers: this.headers
    }
  }

  getHeaders = () => {
    return this.headers
  }

  addCustomPropertiesHeaders = (columnHeaders) => {
    const toBeRemoved = []
    _.each(columnHeaders, (columnHeader) => {
      if (columnHeader._toBeRemoved) {
        toBeRemoved.push(columnHeader.field)
      } else {
        if (!_.find(this.headers, (header) => header.field === columnHeader.field)) {
          this._addColumInFirstAvailablePosition(columnHeader)
        }
      }
    })
    if (_.size(toBeRemoved)) {
      _.remove(this.headers, (header) => _.includes(toBeRemoved, header.field))
    }
  }

  _getFirstAvailablePosition = () => {
    let lastPinnedColumnIndex = 0
    _.each(this.headers, (header) => {
      if (_.isDefined(header.pinned)) {
        lastPinnedColumnIndex++
      } else {
        return false
      }
    })
    return lastPinnedColumnIndex
  }

  _addColumInFirstAvailablePosition = (newColumn) => {
    const lastPinnedColumnIndex = this._getFirstAvailablePosition()
    this.headers.splice(lastPinnedColumnIndex, 0, newColumn)
    this._reoderColumns()
  }

  _moveHeaderToTheFirstAvailablePosition = (movedHeader, movedIndex) => {
    _.pullAt(this.headers, [movedIndex])
    this._addColumInFirstAvailablePosition(movedHeader)
  }

  _getNotPinnedColumns = () => {
    const visibleColumns = this.getVisibleHeaders()
    const [, notPinnedColums] = _.partition(visibleColumns, (visibleColumn) => _.isDefined(visibleColumn.pinned))
    return notPinnedColums
  }

  _reoderColumns = () => {
    /** Order left pinned headers */
    /** Order other headers */
    /** Order right pinned headers */
    const notPinned = this._getNotPinnedColumns()
    if (_.isEmpty(notPinned)) return
    const orderedVisibleColumns = _.sortBy(notPinned, 'index')
    let minIndex = _.first(orderedVisibleColumns).index

    const [nullIndexColumns, indexColumns] = _.partition(orderedVisibleColumns, (orderedVisibleColumn) => _.isUndefined(orderedVisibleColumn.index))

    /** Fill no index columns */
    _.each(nullIndexColumns, (nullIndexColumn) => (nullIndexColumn.index = minIndex++))
    _.each(indexColumns, (indexColumn) => (indexColumn.index += minIndex - 1))
  }

  resetHeaders = () => {
    this.headers = []
  }

  getVisibleHeaders = () => {
    return _.filter(this.headers, (header) => header._isVisible)
  }

  existsHeader = (header) => {
    return _.findIndex(this.headers, (currentHeader) => currentHeader.field === header.field)
  }

  _findHeaderByField = (field) => {
    const headerIdx = _.findIndex(this.headers, (header) => header.field === field)
    return {
      index: headerIdx,
      header: headerIdx > -1 ? this.headers[headerIdx] : null
    }
  }

  _needNewDefaultHeaderToBeVisible = (header, isVisible) => {
    return isVisible && header._isVisible !== isVisible
  }

  _updateDefaultVisibleHeader = (headerField, isVisible) => {
    /* let removedHeader = null */
    const { index, header } = this._findHeaderByField(headerField)
    if (index > -1) {
      if (this._needNewDefaultHeaderToBeVisible(header, isVisible)) {
        // New builtin header added
        this._moveHeaderToTheFirstAvailablePosition(header, index)
      }
      header._isVisible = isVisible
    }
  }

  setDefaultHeadersVisible = (headerFields) => {
    _.each(headerFields, (isVisible, field) => {
      this._updateDefaultVisibleHeader(field, isVisible)
    })
    this._reoderColumns()
  }
}

export default BfColumnSelectionFilter
