/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2021
// All Rights Reserved
*/

import React from 'react'
import _ from '../../services/utils/BfLodash'

const BfSplitTextInLines = (content, maxLine = -1) => {
  const contentArray = _.isArray(content) ? content : _.split(content, ', ')
  let trunkeded = contentArray
  if (maxLine > 0 && maxLine < contentArray.length) {
    trunkeded = _.slice(contentArray, 0, maxLine)
    trunkeded[_.size(trunkeded) - 1] = _.last(trunkeded) + '...'
  }

  return _.map(trunkeded, (s, index) => {
    return (
      <React.Fragment key={index}>
        {s}
        <br />
      </React.Fragment>
    )
  })
}

export default BfSplitTextInLines
