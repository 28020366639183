import BfCurrentUserInfoService from '../user/BfCurrentUserInfoService'
import BfCurrentAppsInfoService from '../navBarServices/BfCurrentAppsInfoService'
import BfAgentTypeInfo from '../devices/BfAgentTypeInfo'
import { forkJoin } from 'rxjs'
import { map } from 'rxjs/operators'
import _ from '../utils/BfLodash'

class BfActionHandler {
  constructor(_bfCurrentUserInfoService = new BfCurrentUserInfoService()) {
    this.bfCurrentUserInfoService = _bfCurrentUserInfoService
  }

  bfCurrentAppsInfoService = new BfCurrentAppsInfoService()
  bfAgentTypeInfo = new BfAgentTypeInfo()

  _checkAgentInstallationPermission = (appName) => {
    return this.bfCurrentUserInfoService.get().pipe(
      map((userInfo) => {
        const missingPermission = {}

        if (appName === 'content' && !userInfo.userPermission.CanCreateActions) {
          missingPermission.createActions = true
        } else if (appName === 'bigfixAgentDeploy' || appName === 'bigfixAgentDeployCloud') {
          if (!userInfo.userPermission.CanCreateActions || !userInfo.userPermission.CanCreateCustomContent) {
            missingPermission.createActionsAndCustomContent = true
          }
        }

        return missingPermission
      })
    )
  }

  /**
   * Takes action on the specified devices using the specified content.
   *
   * This function sets some data in session storage (e.g. selected devices/content) and then redirects to the app's own /ui/workflow URL.
   * There, bfworkflow will use the data in session storage to pre-poulate settings in the workflow pages.
   *
   * @param appName {String} - The name of the invoking application
   * @param selectedDevices {Array} - An array of the devices on which the specified content is to be actioned
   * @param selectedContent {Array} - An array of the content (fixlets/tasks/baselines) to be actioned on the specified devices
   * @param options {Object} - Object specifying optional settings for the operation (generic so it can be expanded over time as required):
   *        =======================|=======================
   *        OPTION (PROPERTY NAME) |  DESCRIPTION
   *        =======================|=======================
   *        'redirectUrl'          | Redirect to this URL upon successful creation of the deployment. Deployment ID is appended as a query param.
   *        'cancelUrl'            | Redirect to this URL upon hitting the 'Cancel' button before the deployment flow is completed.
   *        =======================|=======================
   *
   * @returns  {Promise}
   */
  takeAction = (appName, selectedDevices, selectedContent, options) => {
    const _processAction = () => {
      const content = { items: {} }
      const devices = { items: {} }
      let redirectTo = null

      if (selectedContent !== undefined && selectedContent.length > 0) {
        content.items = selectedContent
        window.sessionStorage.setItem('items', JSON.stringify(content))
      }

      devices.items = selectedDevices
      window.sessionStorage.setItem('devices', JSON.stringify(devices))

      if (options !== undefined && options !== null) {
        window.sessionStorage.setItem('takeActionOptions', JSON.stringify(options))
      }

      if (appName === 'content') {
        redirectTo = '/' + appName + '/workflow/' + selectedContent[0].siteID
      } else if (appName === 'mdm') {
        redirectTo = '/mdm/deploy'
      } else if (appName === 'mdmActionDeploy') {
        redirectTo = '/mdm/action'
      } else if (appName === 'mdmPolicyGroupServerDeploy') {
        redirectTo = '/mdm/next/policygroup/serverdeploy/' + Object.keys(selectedContent)[0]
      } else if (appName === 'mdmPolicyGroupDeploy') {
        redirectTo = '/mdm/next/policygroup/deploy/' + Object.keys(selectedContent)[0]
      } else if (appName === 'mdmPolicyGroupDeploy3') {
        redirectTo = '/mdm/next/policygroup/deploy/target'
      } else if (appName === 'bigfixAgentDeploy') {
        redirectTo = '/mdm/action/deployBigFixAgent'
      } else if (appName === 'bigfixAgentDeployCloud') {
        redirectTo = '/framework/deploybesagent'
      } else if (appName === 'mdmAgentDeploy') {
        redirectTo = '/mdm/action/bulkEnroll'
      } else if (appName === 'mdmUnenrollDeploy') {
        redirectTo = '/mdm/action/unenroll'
      } else {
        redirectTo = '/' + appName + '/workflow'
      }

      return redirectTo
    }

    return forkJoin({
      userInfo: this.bfCurrentUserInfoService.get(),
      userLimits: this.bfCurrentUserInfoService.getUserActionsLimit()
    }).pipe(
      map(({ userInfo, userLimits }) => {
        let redirectTo = null
        if (userInfo.userPermission.ApproverRoleID !== null) {
          const error = new Error('unsupportedRequiresApprover')
          error.type = 'unsupportedRequiresApprover'
          error.key = 'unsupportedRequiresApprover'
          throw error
        } else if (userLimits.device_target_limit !== -1 && selectedDevices.length > userLimits.device_target_limit) {
          const error = new Error('tooDeviceSelectedPPS')
          error.type = 'tooDeviceSelectedPPS'
          error.key = 'tooDeviceSelectedPPS'
          error.params = { maxDevices: userLimits.device_target_limit }
          throw error
        } else {
          redirectTo = _processAction()
        }
        return { redirectTo: redirectTo }
      })
    )
  }

  getDeployableApps = (props) => {
    return forkJoin([this.bfCurrentUserInfoService.get(), this.bfCurrentAppsInfoService.get(), this.bfAgentTypeInfo.getCategoryByAgentType()]).pipe(
      map((values) => {
        const userInfo = values[0]
        const currentApps = values[1]
        const categories = values[2]
        const userPermittedApps = userInfo.appPermission
        const userPermission = userInfo.userPermission

        const deployableApps = {}
        const adminActions = {}
        for (const app in currentApps) {
          if (Object.prototype.hasOwnProperty.call(currentApps, app) && currentApps[app].client.tabs && currentApps[app].client.tabs.length > 0) {
            // If the app is in this array or has the deployable flag in its 'client.json' file set to true, it is added to deployableApps
            const hackArray = ['custom', 'prfmgr', 'swd', 'patch']
            const id = currentApps[app].client.tabs[0].id
            const label = 'deploy' + currentApps[app].client.tabs[0].id.charAt(0).toUpperCase() + currentApps[app].client.tabs[0].id.slice(1)
            const route = currentApps[app].client.tabs[0].route

            if (
              userPermittedApps.indexOf(id) !== -1 &&
              userPermission.CanCreateActions &&
              (currentApps[app].client.deployable || hackArray.indexOf(id) !== -1) &&
              props.selectedItems[Object.keys(props.selectedItems)[0]].policyGroupData === undefined
            ) {
              deployableApps[id] = { id: id, label: label, route: route }
            }

            if (
              id === 'mdm' &&
              userPermittedApps.indexOf('mdm') !== -1 &&
              userPermission.CanCreateActions &&
              userPermission.CanCreateCustomContent &&
              props.selectedItems[Object.keys(props.selectedItems)[0]].policyGroupData === undefined
            ) {
              deployableApps.mdmAction = {
                id: 'mdmActionDeploy',
                label: 'deployMdmAction',
                route: '/mdm/action'
              }
              adminActions.Enroll = {
                id: 'mdmAgentDeploy',
                label: 'deployMdmAgent',
                route: '/mdm/action/bulkEnroll'
              }

              adminActions.Unenroll = {
                id: 'mdmUnenrollDeploy',
                label: 'unDeployMdmAgent',
                route: '/mdm/action/unenroll'
              }

              adminActions.agentDeploy = {
                id: 'bigfixAgentDeploy',
                label: 'deployBigFixAgent'
              }
            }
          }
        }

        if (_.isUndefined(adminActions.agentDeploy) && userPermission.CanCreateActions) {
          _.each(categories, function (category) {
            if (category === 'Cloud') {
              adminActions.agentDeploy = {
                id: 'bigfixAgentDeploy',
                label: 'deployBigFixAgent'
              }
            }
          })
        }

        if (userPermission.CanCreateActions && userPermission.CanCreateCustomContent) {
          adminActions.frameworkAction = {
            id: 'frameworkRefresh',
            label: 'sendClientRefresh',
            route: '/framework/refresh'
          }
        }

        if (
          userPermission.IsMaster &&
          !(props.selectedItems[Object.keys(props.selectedItems)[0]].policyGroupData === undefined) &&
          !(props.selectedItems[Object.keys(props.selectedItems)[0]].groupsTargeted === 'None')
        ) {
          deployableApps.mdmPolicyGroup1 = {
            id: 'mdmPolicyGroupServerDeploy',
            label: 'deployPolicyGroupToServer',
            route: '/mdm/next/policygroup/serverdeploy'
          }
        }

        // deploying from policyGroup list
        if (userPermission.IsMaster && !(props.selectedItems[Object.keys(props.selectedItems)[0]].policyGroupData === undefined)) {
          deployableApps.mdmPolicyGroup2 = {
            id: 'mdmPolicyGroupDeploy',
            label: 'deployPolicyGroup',
            route: '/mdm/next/policygroup/deploy'
          }
        }
        // deploying from devices list
        if (userPermission.IsMaster && props.selectedItems[Object.keys(props.selectedItems)[0]].policyGroupData === undefined) {
          deployableApps.mdmPolicyGroup3 = {
            id: 'mdmPolicyGroupDeploy3',
            label: 'deployPolicyGroup3'
          }
        }

        return { deployableApps: deployableApps, adminActions: adminActions }
      })
    )
  }
}

export default BfActionHandler
