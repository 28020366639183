import BfHttpClient from '../authClient/BfHttpClient'

/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/
class BfSendRefresh {
  bfHttpClient = new BfHttpClient()

  send = (selectedIDs) => {
    return this.bfHttpClient.post('/api/devices/sendRefresh', {
      deviceIDs: selectedIDs
    })
  }
}
export default BfSendRefresh
