/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/
import _ from '../../../services/utils/BfLodash'
import BfConsole from 'loglevel'
import BfUrlHandler from '../../../services/url/BfUrlHandler'

// autopatch
import ApOperatingSystemFilter from './autopatch/ApOperatingSystemFilter'
import ApPatchTypeFilter from './autopatch/ApPatchTypeFilter'
import ApStatusFilter from './autopatch/ApStatusFilter'

// reports
import ReportContentTypeFilter from './reports/reportContentTypeFilter/ReportContentTypeFilter'
import ReportVisibilityFilter from './reports/reportVisibilityFilter/ReportVisibilityFilter'
import ReportOwnerFilter from './reports/reportOwnerFilter/ReportOwnerFilter'
import ReportLastModifiedFilter from './reports/reportLastModifiedFilter/ReportLastModifiedFilter'
import ReportLastAccessedFilter from './reports/reportLastAccessedFilter/ReportLastAccessedFilter'
import ReportDescriptionFilter from './reports/reportDescriptionFilter/ReportDescriptionFilter'
import ReportFavoritesFilter from './reports/reportFavoritesFilter/ReportFavoritesFilter'

// insights
import InsightsContentCountFilter from './insights/insightsContentCountFilter/InsightsContentCountFilter'
import InsightsDeviceCountFilter from './insights/insightsDeviceCountFilter/InsightsDeviceCountFilter'
import InsightsSiteTypeFilter from './insights/insightsSiteTypeFilter/InsightsSiteTypeFilter'
import InsightsAttributeNameFilter from './insights/insightsAttributeNameFilter/InsightsAttributeNameFilter'
import InsightsAttributeCategoryFilter from './insights/insightsAttributeCategoryFilter/InsightsAttributeCategoryFilter'
import InsightsAttributeLastModifiedFilter from './insights/insightsAttributeLastModifiedFilter/InsightsAttributeLastModifiedFilter'

// framework
import BfDeviceTypeFilter from './framework/bfDeviceType/BfDeviceTypeFilter'
import BfDeviceGroupFilter from './framework/bfDeviceGroup/BfDeviceGroupFilter'
import BfOperatingSystemFilter from './framework/bfOperatingSystemFilter/BfOperatingSystemFilter'
import BfManagedByFilter from './framework/bfManagedByFilter/BfManagedByFilter'
import BfPropertyFilter from './framework/bfPropertyFilter/BfPropertyFilter'
import BfIpAddressFilter from './framework/bfIpAddressFilter/BfIpAddressFilter'
import BfAgentStatusFilter from './framework/bfAgentStatusFilter/BfAgentStatusFilter'
import BfLockStatusFilter from './framework/bfLockStatusFilter/BfLockStatusFilter'
import BfCriticalPatchesFilter from './framework/bfCriticalPatchesFilter/BfCriticalPatchesFilter'
import BfDeploymentStateCountFilter from './framework/bfDeploymentStateCountFilter/BfDeploymentStateCountFilter'
import BfDnsNameFilter from './framework/bfDnsNameFilter/BfDnsNameFilter'
import BfApplicablePatchesFilter from './framework/bfApplicablePatchesFilter/BfApplicablePatchesFilter'
import BfCloudTagsFilter from './framework/bfCloudTagsFilter/BfCloudTagsFilter'
import BfAnalysisFilter from './framework/bfAnalysisFilter/BfAnalysisFilter'
import BfSourceFilter from './framework/bfSourceFilter/BfSourceFilter'
import BfApplicableDevicesFilter from './custom/bfApplicableDevicesFilter/BfApplicableDevicesFilter'

// custom content
import BfSiteNameFilter from './custom/bfSiteNameFilter/BfSiteNameFilter'
import BfCategoryFilter from './custom/bfCategoryFilter/BfCategoryFilter'
import BfCreateByFilter from './custom/bfCreateByFilter/BfCreateByFilter'

// content
import BfContentCategoryFilter from './content/bfContentCatergoryFilter/BfContentCategoryFilter'

// swd
import BfPublisherNameFilter from './swd/bfPublisherNameFilter/BfPublisherNameFilter'
import BfOwnerNameFilter from './swd/bfOwnerNameFilter/BfOwnerNameFilter'
import BfOSFilter from './swd/bfOSFilter/BfOSFilter'
import BfModifiedDateFilter from './swd/bfModifiedDate/BfModifiedDateFilter'

// common
import BfOnlySelectedFilter from './common/bfOnlySelected/BfOnlySelectedFilter'
import BfPaginationFilter from './common/bfPagination/BfPaginationFilter'
import BfSearchFilter from './common/bfSearch/BfSearchFilter'
import BfSearchPolicyGroupsFilter from './common/bfSearch/BfSearchPolicyGroupsFilter'
import BfSimpleOSFilter from './common/bfSimpleOSFilter/BfSimpleOSFilter'
import BfSimpleOSFilterApps from './common/bfSimpleOSFilter/BfSimpleOSFilterApps'
import BfSimpleOSFilterPolicies from './common/bfSimpleOSFilter/BfSimpleOSFilterPolicies'
import BfPolicyTypes from './common/bfPolicyTypes/BfPolicyTypes'
import BfCreatorFilter from './common/bfCreatorFilter/BfCreatorFilter'
import BfGroupsTargetedFilter from './common/bfGroupsTargetedFilter/BfGroupsTargetedFilter'
import BfSortFilter from './common/bfSort/BfSortFilter'
import BfShowRelevantComputersFilter, { BfShowRelevantGroupsFilter } from './common/bfShowRelevant/BfShowRelevantFilter'

import BfCustomPropertiesColumnFilter from '../bfAddColumn/BfCustomPropertiesColumnFilter'
import BfCustomPropertiesFilter from '../bfAddColumn/BfCustomPropertiesFilter'
import BfBasicFilter from './BfBasicFilter'
// custom
import BfCustomContentTypeFilter from './custom/bfCustomContentType/BfCustomContentTypeFilter'
import BfReleaseDateFilter from './custom/bfReleaseDate/BfReleaseDateFilter'
import BfLastSeenFilter from './framework/bfLastSeenFilter/BfLastSeenFilter'
import BfRecentUserFilter from './framework/bfRecentUsers/BfRecentUserFilter'

// patch
import BfPatchCategoryFilter from './patch/BfPatchCategoryFilter'
import BfSourceSeverityFilter from './patch/BfSourceSeverityFilter'
import BfPatchOSFilter from './patch/BfPatchOSFilter'
import BfPatchSoftwareSearchFilter from './patch/BfPatchSoftwareSearchFilter'
import BfPatchReleaseDateFilter from './patch/BfPatchReleaseDateFilter'
import BfPatchVulnerableDevicesFilter from './patch/BfPatchVulnerableDevicesFilter'
import BfPatchOpenActionsFilter from './patch/BfPatchOpenActionsFilter'
import BfPatchNameSearchFilter from './patch/BfPatchNameSearchFilter'
import BfPatchContentIDSearchFilter from './patch/BfPatchContentIDSearchFilter'
import BfPatchCVEIDSearchFilter from './patch/BfPatchCVEIDSearchFilter'
import BfPatchSiteIDFilter from './patch/BfPatchSiteIDFilter'
import BfShowRelevantPackagesFilter from './common/bfShowRelevant/BfShowRelevantPackagesFilter'
import BfShowRelevantContentKeyFilter from './common/bfShowRelevant/BfShowRelevantContentKeyFilter'
import BfShowRelevantBaselineKeyFilter from './common/bfShowRelevant/BfShowRelevantBaselineKeyFilter'

class BfFilterManager extends BfBasicFilter {
  RESET_PAGINATION_SEARCH = ['search']

  constructor(search = null, enableUrl = true) {
    super()
    this.bfUrlHandler = new BfUrlHandler(search, enableUrl)
  }

  getUrlHandler = () => {
    return this.bfUrlHandler
  }

  setUrlHandler = (urlHandler) => {
    this.bfUrlHandler = urlHandler
  }

  parseURL = () => {
    this.bfUrlHandler.parseURL()
  }

  addFilter = (filter) => {
    if (_.isEmpty(filter)) {
      return
    }
    this.bfUrlHandler.addFilter(filter)
    this._removeOffset()
  }

  resetSearch = () => {
    this.bfUrlHandler.resetSearch()
  }

  addSearch = (search) => {
    if (_.isEmpty(search)) {
      return
    }
    this.bfUrlHandler.addSearch(search)
    this._removeOffsetIfNeeded(search)
  }

  resetPagination = () => {
    this._removePagination()
  }

  getSearch = () => {
    return this.bfUrlHandler.getSearch()
  }

  // When prepending with NAMESPACE/, also add to filterName in BfHTMLFiltersMap
  getFilterByName = (name, args) => {
    switch (name) {
      case BfSearchFilter.NAME:
        return new BfSearchFilter()
      case BfSearchPolicyGroupsFilter.NAME:
        return new BfSearchPolicyGroupsFilter()
      case `${BfSimpleOSFilter.NAMESPACE}/${BfSimpleOSFilter.NAME}`:
        return new BfSimpleOSFilter()
      case BfSimpleOSFilterApps.NAME:
        return new BfSimpleOSFilterApps()
      case BfSimpleOSFilterPolicies.NAME:
        return new BfSimpleOSFilterPolicies()
      case BfPolicyTypes.NAME:
        return new BfPolicyTypes()
      case BfCreatorFilter.NAME:
        return new BfCreatorFilter()
      case BfGroupsTargetedFilter.NAME:
        return new BfGroupsTargetedFilter()
      case `${BfOwnerNameFilter.NAMESPACE}/${BfOwnerNameFilter.NAME}`:
        return new BfOwnerNameFilter()
      case BfRecentUserFilter.NAME:
        return new BfRecentUserFilter()
      case BfLastSeenFilter.NAME:
        return new BfLastSeenFilter()
      case BfReleaseDateFilter.NAME:
        return new BfReleaseDateFilter()
      case BfModifiedDateFilter.NAME:
        return new BfModifiedDateFilter()
      case `${BfCustomContentTypeFilter.NAMESPACE}/${BfCustomContentTypeFilter.NAME}`:
        return new BfCustomContentTypeFilter()
      case BfAgentStatusFilter.NAME:
        return new BfAgentStatusFilter()
      case BfLockStatusFilter.NAME:
        return new BfLockStatusFilter()
      case BfCriticalPatchesFilter.NAME:
        return new BfCriticalPatchesFilter()
      case `${BfOSFilter.NAMESPACE}/${BfOSFilter.NAME}`:
        return new BfOSFilter()
      case BfDeviceTypeFilter.NAME:
        return new BfDeviceTypeFilter()
      case BfOperatingSystemFilter.NAME:
        return new BfOperatingSystemFilter()
      case BfApplicablePatchesFilter.NAME:
        return new BfApplicablePatchesFilter()
      case BfDeploymentStateCountFilter.NAME:
        return new BfDeploymentStateCountFilter()
      case BfDeviceGroupFilter.NAME:
        return new BfDeviceGroupFilter()
      case BfIpAddressFilter.NAME:
        return new BfIpAddressFilter()
      case BfDnsNameFilter.NAME:
        return new BfDnsNameFilter()
      case BfCloudTagsFilter.NAME:
        return new BfCloudTagsFilter()
      case BfSiteNameFilter.NAME:
        return new BfSiteNameFilter()
      case BfAnalysisFilter.NAME:
        return new BfAnalysisFilter()
      case BfSourceFilter.NAME:
        return new BfSourceFilter()
      case `${BfCategoryFilter.NAMESPACE}/${BfCategoryFilter.NAME}`:
        return new BfCategoryFilter()
      case `${BfCreateByFilter.NAMESPACE}/${BfCreateByFilter.NAME}`:
        return new BfCreateByFilter()
      case BfPublisherNameFilter.NAME:
        return new BfPublisherNameFilter()
      case BfManagedByFilter.NAME:
        return new BfManagedByFilter()
      case BfPropertyFilter.NAME:
        return new BfPropertyFilter(BfPropertyFilter.NAME)
      case BfCustomPropertiesColumnFilter.NAME:
        return new BfCustomPropertiesColumnFilter(args)
      case BfApplicableDevicesFilter.NAME:
        return new BfApplicableDevicesFilter()

      /** Reports */
      case ReportContentTypeFilter.NAME:
        return new ReportContentTypeFilter()
      case ReportLastAccessedFilter.NAME:
        return new ReportLastAccessedFilter()
      case ReportLastModifiedFilter.NAME:
        return new ReportLastModifiedFilter()
      case ReportOwnerFilter.NAME:
        return new ReportOwnerFilter()
      case ReportVisibilityFilter.NAME:
        return new ReportVisibilityFilter()
      case ReportFavoritesFilter.NAME:
        return new ReportFavoritesFilter()
      case ReportDescriptionFilter.NAME:
        return new ReportDescriptionFilter()

      /** AutoPatch */
      case ApPatchTypeFilter.NAME:
        return new ApPatchTypeFilter()
      case ApStatusFilter.NAME:
        return new ApStatusFilter()
      case `${ApOperatingSystemFilter.NAMESPACE}/${ApOperatingSystemFilter.NAME}`:
        return new ApOperatingSystemFilter()

      /** Patch */
      case BfSourceSeverityFilter.NAME:
        return new BfSourceSeverityFilter()
      case `${BfPatchOSFilter.NAMESPACE}/${BfPatchOSFilter.NAME}`:
        return new BfPatchOSFilter()
      case `${BfPatchSoftwareSearchFilter.NAMESPACE}/${BfPatchSoftwareSearchFilter.NAME}`:
        return new BfPatchSoftwareSearchFilter()
      case BfPatchCategoryFilter.NAME:
        return new BfPatchCategoryFilter()
      case BfPatchReleaseDateFilter.NAME:
        return new BfPatchReleaseDateFilter()
      case BfPatchVulnerableDevicesFilter.NAME:
        return new BfPatchVulnerableDevicesFilter()
      case BfPatchOpenActionsFilter.NAME:
        return new BfPatchOpenActionsFilter()
      case BfPatchNameSearchFilter.NAME:
        return new BfPatchNameSearchFilter()
      case BfPatchContentIDSearchFilter.NAME:
        return new BfPatchContentIDSearchFilter()
      case BfPatchCVEIDSearchFilter.NAME:
        return new BfPatchCVEIDSearchFilter()
      case BfPatchSiteIDFilter.NAME:
        return new BfPatchSiteIDFilter()

      /** Content */
      case `${BfContentCategoryFilter.NAMESPACE}/${BfContentCategoryFilter.NAME}`:
        return new BfContentCategoryFilter(args)

      /** Insight */
      case InsightsAttributeCategoryFilter.NAME:
        return new InsightsAttributeCategoryFilter()
      case InsightsAttributeNameFilter.NAME:
        return new InsightsAttributeNameFilter()
      case InsightsAttributeLastModifiedFilter.NAME:
        return new InsightsAttributeLastModifiedFilter()

      case BfShowRelevantComputersFilter.NAME:
        return new BfShowRelevantComputersFilter()
      case BfShowRelevantGroupsFilter.NAME:
        return new BfShowRelevantGroupsFilter()

      case BfShowRelevantPackagesFilter.NAME:
        return new BfShowRelevantPackagesFilter()
      case BfShowRelevantContentKeyFilter.NAME:
        return new BfShowRelevantContentKeyFilter()
      case BfShowRelevantBaselineKeyFilter.NAME:
        return new BfShowRelevantBaselineKeyFilter()

      default:
        BfConsole.error(`Filter name ${name} Not found`)
    }
  }

  getFilterFromURLByName = (name, args) => {
    const filter = this.getFilterByName(name, args)
    if (filter) {
      filter.buildFromURLHandler(this.bfUrlHandler)
      this._setResetFilter(filter)
      return filter
    }
  }

  getOnlySelectedFilter = () => {
    const bfOnlySelectedFilter = new BfOnlySelectedFilter()
    bfOnlySelectedFilter.buildFromURLHandler(this.bfUrlHandler)
    this._setResetFilter(bfOnlySelectedFilter)
    return bfOnlySelectedFilter
  }

  _setResetFilter = (filter) => {
    if (this.reset) {
      filter.setReset(this.reset)
    }
  }

  getFilterValueFromPath = (filterPath) => {
    return this.bfUrlHandler.getSearchPath(filterPath)
  }

  getCustomPropertiesFilter = () => {
    const bfCustomPropertiesFilter = new BfCustomPropertiesFilter()
    bfCustomPropertiesFilter.buildFromURLHandler(this.bfUrlHandler)
    this._setResetFilter(bfCustomPropertiesFilter)

    return bfCustomPropertiesFilter
  }

  getSelectedItemsIds = () => {
    const bfOnlySelectedFilter = this.getOnlySelectedFilter()
    return bfOnlySelectedFilter.getData()
  }

  getPaginationFilter = () => {
    const bfPaginationFilter = new BfPaginationFilter()
    bfPaginationFilter.buildFromURLHandler(this.bfUrlHandler)
    return bfPaginationFilter
  }

  getSortFilter = () => {
    const bfSortFilter = new BfSortFilter()
    bfSortFilter.buildFromURLHandler(this.bfUrlHandler)
    this._setResetFilter(bfSortFilter)

    return bfSortFilter
  }

  getInsightsContentCountFilter = () => {
    const insightsContentCountFilter = new InsightsContentCountFilter()
    insightsContentCountFilter.buildFromURLHandler(this.bfUrlHandler)
    this._setResetFilter(insightsContentCountFilter)
    return insightsContentCountFilter
  }

  getInsightsDeviceCountFilter = () => {
    const insightsDeviceCountFilter = new InsightsDeviceCountFilter()
    insightsDeviceCountFilter.buildFromURLHandler(this.bfUrlHandler)
    this._setResetFilter(insightsDeviceCountFilter)
    return insightsDeviceCountFilter
  }

  getInsightsSiteTypeFilter = () => {
    const insightsSiteTypeFilter = new InsightsSiteTypeFilter()
    insightsSiteTypeFilter.buildFromURLHandler(this.bfUrlHandler)
    this._setResetFilter(insightsSiteTypeFilter)
    return insightsSiteTypeFilter
  }

  _getSearchKey = (search) => {
    const keys = _.keys(search)
    return _.size(keys) > 0 ? keys[0] : null
  }

  _removeOffsetIfNeeded = (search) => {
    if (_.some(this.RESET_PAGINATION_SEARCH, (filter) => _.has(search, filter))) {
      this._removeOffset()
    }
  }

  _removePaginationIfNeeded = (search) => {
    if (_.some(this.RESET_PAGINATION_SEARCH, (filter) => _.has(search, filter))) {
      this._removePagination()
    }
  }

  _removeOffset = () => {
    const bfPaginationFilter = this.getPaginationFilter()
    bfPaginationFilter.resetOffset()
  }

  _removePagination = () => {
    this.bfUrlHandler.addSearch({ pagination: {} })
  }

  areFiltersApplied = () => {
    const filterURL = this.bfUrlHandler.getSearchPath('filter')
    const searchURL = this.bfUrlHandler.getSearchPath('search')
    if (_.isEmpty(filterURL) && _.isEmpty(searchURL)) {
      return false
    }

    if (_.isNotEmpty(searchURL)) {
      return true
    }

    const customPropertiesFilter = this.getCustomPropertiesFilter()
    if (customPropertiesFilter.areFilterApplied()) {
      return true
    }
    const otherFilters = _.omit(_.cloneDeep(filterURL), BfCustomPropertiesFilter.NAME)

    BfConsole.debug('otherFilters are ', otherFilters)

    return _.isNotEmpty(otherFilters)
  }

  getActiveFilters = (filters) => {
    return _.filter(filters, (filter) => filter.isApplied())
  }
}
export default BfFilterManager

export const createFilterManagerFromReduxState = (state, namespace) => {
  const isReset = _.isDefined(state[namespace]?.reset) ? state[namespace]?.reset : false
  const search = state[namespace]?.search
  let bfFilterManager = null
  if (_.isUndefined(search)) {
    bfFilterManager = new BfFilterManager()
  } else {
    bfFilterManager = new BfFilterManager(search.urlSearch, search.options?.updateUrl)
  }
  bfFilterManager.setReset(isReset)
  return bfFilterManager
}
