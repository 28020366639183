/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { updateSearchListUrlAction } from '../../../redux/BfFilterListUpdateURLAction'
import { createFilterManagerFromReduxState } from '../../BfFilterManager'
import BfPaginationWithFilter from './BfPaginationWithFilter'
import { BfDataGridComponentWithContext } from '../../../BfDataGridContext'

/**
 * Creates Pagination functionality for grid table
 */
const BfPaginationWithReduxAction = (WrappedComponent) => {
  return class extends Component {
    _onItemsPerPageChange = () => {
      this.props.updateSearchListUrlAction(this.props.id, this.props.filter.get())
      this.props.onChangePagination && this.props.onChangePagination()
    }

    /**
     * Handle change of page of table
     * @method
     * @param {Number} pageNumber Page number to be displayed
     */
    _onPageChange = () => {
      this.props.updateSearchListUrlAction(this.props.id, this.props.filter.get())
      this.props.onChangePagination && this.props.onChangePagination()
    }

    render() {
      const { totalItems, filter } = this.props
      return (
        <WrappedComponent onItemsPerPageChange={this._onItemsPerPageChange} onChange={this._onPageChange} totalItems={totalItems} filter={filter} />
      )
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const bfFilterManager = createFilterManagerFromReduxState(state, ownProps.id)
  const bfPaginationFilter = bfFilterManager.getPaginationFilter()

  return {
    filter: bfPaginationFilter
  }
}

const BfPaginationWithRedux = connect(mapStateToProps, {
  updateSearchListUrlAction
})(BfPaginationWithReduxAction(BfPaginationWithFilter))

export default BfDataGridComponentWithContext(BfPaginationWithRedux)
