/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2021
// All Rights Reserved
*/

import _ from '../../../../../../services/utils/BfLodash'
import BfBasicFilter from '../../../BfBasicFilter'

class BfDropDownBasicFilter extends BfBasicFilter {
  getQueryItemsIds = (selectedItemsIds) => {
    const items = {}
    _.each(selectedItemsIds, (selectedItemsId) => {
      items[selectedItemsId] = true
    })
    return items
  }
}
export default BfDropDownBasicFilter
