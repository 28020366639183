/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2021
// All Rights Reserved
*/

import { map } from 'rxjs/operators'
import BfHttpClient from '../../../../services/authClient/BfHttpClient'
import _ from '../../../../services/utils/BfLodash'
import BfDropDownSearchBasicFilter from '../common/bfBasicFilters/BfDropDownSearchFilter/BfDropDownSearchBasicFilter'

class BfBasicCustomFilter extends BfDropDownSearchBasicFilter {
  bfHttpClient = new BfHttpClient()
  fixedParams = { search: { limit: 20 } }

  getItems = (filterValue) => {
    const params = _.cloneDeep(this.fixedParams)
    params.search.query = filterValue
    return this.bfHttpClient.get(this.getSearchURL(), params).pipe(map((res) => this.processResponse(res)))
  }

  /** This method receives res data and returns a list of strings */
  /* processResponse = (res) => {
    
  } */
}
export default BfBasicCustomFilter
