/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import _ from '../../../services/utils/BfLodash'
import BfCellDeviceRender from './BfCellDeviceRender'
import BfCellPolicyGroupRender from './BfCellPolicyGroupRender'
import BfCellPrepolicyRender from './BfCellPrepolicyRender'
import BfCellDateRender from './BfCellDateRender'
import BfCellDefaultRender from './BfCellDefaultRender'
import BfCellDeploymentStateRender from './BfCellDeploymentStateRender'
import BfCellArrayRenderer from './BfCellArrayRenderer'
import BfCellStatusRender from './BfCellStatusRender'
import BfCellYesNoRender from './BfCellYesNoRender'
import BfCellApplicablePatchesRender from './BfCellApplicablePatchesRender'
import BfCellSizeRender from './BfCellSizeRender'
import BfLinkWithIconRenderer from './BfLinkWithIconRenderer'
import BfCellUrlRender from './BfCellUrlRender'
import BfHtmlRenderer from './BfHtmlRenderer'
import BfCellPatchRender from './BfCellPatchRender'
import BfCellLocalizedDateRender from './BfCellLocalizedDateRender'

/**
 * Renders Device based on it's type
 */
class BfCellRenderer {
  getRenderer(header) {
    if (_.isUndefined(header.type)) {
      return _.isUndefined(header.renderHtml) ? new BfCellDefaultRender(header).getRenderer() : header.renderHtml
    }
    switch (header.type) {
      case 'device':
        return new BfCellDeviceRender(header).getRenderer()
      case 'policyGroup':
        return new BfCellPolicyGroupRender(header).getRenderer()
      case 'prepolicy':
        return new BfCellPrepolicyRender(header).getRenderer()
      case 'date':
        return new BfCellDateRender(header).getRenderer()
      case 'deploymentState':
        return new BfCellDeploymentStateRender(header).getRenderer()
      case 'arrayCell':
        return new BfCellArrayRenderer(header).getRenderer()
      case 'arrayCellNotEmpty':
        return new BfCellArrayRenderer(header, true).getRenderer()
      case 'agentStatus':
        return new BfCellStatusRender(header).getRenderer()
      case 'criticalPatches':
        return new BfCellYesNoRender(header).getRenderer()
      case 'applicablePatches':
        return new BfCellApplicablePatchesRender(header).getRenderer()
      case 'mega':
        return new BfCellSizeRender(header).getRenderer()
      case 'linkWithIcon':
        return new BfLinkWithIconRenderer(header).getRenderer()
      case 'html':
        return new BfHtmlRenderer(header).getRenderer()
      case 'patch':
        return new BfCellPatchRender(header).getRenderer()
      case 'url':
        return new BfCellUrlRender(header).getRenderer()
      case 'localizedDate': // specific to patch release date; should display same in all time zones
        return new BfCellLocalizedDateRender(header, 'seconds', {
          utc: true
        }).getRenderer()
      case 'localizedDate2':
        return new BfCellLocalizedDateRender(header, 'seconds').getRenderer()
      case 'arrayCellSemicolon':
        return new BfCellArrayRenderer(header, true, ';').getRenderer()
      case 'arrayCellPipe':
        return new BfCellArrayRenderer(header, true, '|').getRenderer()
    }
  }
}

export default BfCellRenderer
