/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2021
// All Rights Reserved
*/

import BfSearchStringFilter from '../common/bfSearch/BfSearchStringFilter'

class BfPatchCVEIDSearchFilter extends BfSearchStringFilter {
  static NAME = 'cve_id_search'
  static PATH = `filter.${BfPatchCVEIDSearchFilter.NAME}`
  doFilter = true
  constructor(query) {
    super(BfPatchCVEIDSearchFilter.NAME, query)
  }
}

export default BfPatchCVEIDSearchFilter
