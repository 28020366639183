/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import _ from '../../../services/utils/BfLodash'

class BfBasicFilter {
  constructor(path, name, data) {
    this.reset = false
    this._t = null
    this._path = path
    if (_.isDefined(name)) {
      this.setName(name)
    }
    this[name] = data
  }

  get = () => {
    const name = this.getName()
    return _.isEmpty(this[name]) ? { [name]: {} } : { [name]: this[name] }
  }

  getName = () => {
    return this._name
  }

  setName = (name) => {
    this._name = name
  }

  getPath = () => {
    return this._path
  }

  setReset = (reset) => {
    this.reset = reset
  }

  getReset = () => {
    return this.reset
  }

  _buildFromURLHandler = (urlHandler, consumeUrl = false, defaultValue) => {
    const filterObj = urlHandler.getSearchPath(this.getPath(), consumeUrl)
    return _.isDefined(filterObj) ? filterObj : defaultValue
  }

  buildFromURLHandler = (urlHandler, consumeUrl = false) => {
    this[this.getName()] = this._buildFromURLHandler(urlHandler, consumeUrl, this[this.getName()])
  }

  getData = () => {
    return this[this.getName()]
  }

  setData = (data) => {
    this[this.getName()] = data
  }

  isApplied = () => {
    const data = this[this._name]
    return _.isNotEmpty(data)
  }

  getFilterCount = () => {
    return 1
  }

  setT = (t) => {
    this._t = t
  }

  getT = () => {
    return this._t
  }
}
export default BfBasicFilter
