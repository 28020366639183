/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/
import React from 'react'

export const BfDataGridContext = React.createContext()

export const BfDataGridComponentWithContext = (WrapperComponent) => {
  return (props) => <BfDataGridContext.Consumer>{({ id }) => <WrapperComponent id={id} {...props} />}</BfDataGridContext.Consumer>
}

export const BfDataGridComponentWithProvider = (props) => {
  return <BfDataGridContext.Provider value={{ id: props.namespace }}>{props.children}</BfDataGridContext.Provider>
}
