import React from 'react'

const BfDraggableContext = React.createContext(
  { draggedItem: {} } // default value
)

export class BfDraggableContainer extends React.Component {
  state = { item: null, replacedItem: null }
  _handleDragStart = (e, item) => {
    e.dataTransfer.effectAllowed = 'move'
    /*  setTimeout(() => {
      e.target.style.opacity = '.1'
    }, 0) */
    this.setState({ item: item })
  }

  _handleDragEnter = (e, item) => {
    if (this.state.item && this.state.item.id !== item.id) {
      e.dataTransfer.dropEffect = 'move'
      if (this.props.onDragEnter) {
        this.props.onDragEnter(this.state.item, this.state.replacedItem)
      }
      this.setState({ replacedItem: item })
    }
  }

  _handleDragOver = (e, item) => {
    if (this.state.item && this.state.item.id !== item.id) {
      e.preventDefault()
    }
  }

  _handleDragLeave = (e, item) => {
    e.dataTransfer.dropEffect = 'none'
    if (this.state.item && this.state.item.id !== item.id) {
      if (this.props.onDragLeave) {
        this.props.onDragLeave(item.id)
      }
    }
  }

  _handleDragEnd = (e, item) => {
    if (e.dataTransfer.dropEffect !== 'none' && this.props.onDragEnd) {
      this.props.onDragEnd(this.state.item, this.state.replacedItem)
      this.setState({ item: null, replacedItem: null })
    }
  }

  _handleDrop = (e, item) => {
    if (this.props.onDragDrop) {
      this.props.onDragDrop(item.id)
    }
  }

  render() {
    return (
      <BfDraggableContext.Provider
        value={{
          draggedItem: this.state.item,
          handleDragStart: this._handleDragStart,
          handleDragOver: this._handleDragOver,
          handleDragLeave: this._handleDragLeave,
          handleDragEnd: this._handleDragEnd,
          handleDragEnter: this._handleDragEnter,
          handleDrop: this._handleDrop
        }}
      >
        {this.props.children}
      </BfDraggableContext.Provider>
    )
  }
}

export const BfDraggable = (props) => {
  return (
    <BfDraggableContext.Consumer>
      {({ draggedItem, handleDragStart, handleDragLeave, handleDragOver, handleDragEnd, handleDragEnter, handleDrop }) => (
        <div
          draggable
          onDragStart={(e) => handleDragStart(e, props.item)}
          onDragOver={(e) => handleDragOver(e, props.item)}
          onDragLeave={(e) => handleDragLeave(e, props.item)}
          onDragEnd={(e) => handleDragEnd(e, props.item)}
          onDragEnter={(e) => handleDragEnter(e, props.item)}
          onDrop={(e) => handleDrop(e, props.item)}
        >
          {props.children}
        </div>
      )}
    </BfDraggableContext.Consumer>
  )
}
