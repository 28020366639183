/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import React from 'react'
import { Overflowmenu } from '@patron/patron-react/overflowmenu'
import { BfTrans } from '../../services/i18n/bfI18Utils'
import './BfNavigationBar.scss'
import { Link } from '@patron/patron-react/link'
import _ from '../../services/utils/BfLodash'
import { Tooltip } from '@patron/patron-react/tooltip'
import { ReactComponent as Logout } from './logout-icon.svg'
import { BfWithServicesConsumer } from '../providers/BfWithServicesProvider'

/**
 * Returns Settings and logout icons
 */
class NavBarSettings extends React.Component {
  componentDidMount() {
    this.props
      .getCurrentUserInfo()
      .get()
      .subscribe((user) => {
        this.userInfo = user.userName
      })
  }
  /**
   * Returns settings icon menu
   * @method
   * @returns {HTMLElement} settings menu icon
   */

  _getSettingsIconMenu() {
    if (_.isEmpty(this.props.gearApps)) {
      return
    }

    return (
      <Overflowmenu
        className='bfGearApps'
        direction='bottom-left'
        ellipsisType='vertical'
        customTemplate={
          <Link data-testid='Gear-Test_ID' className='hcl hcl-primary hcl-btn BfNavLinkButton'>
            <i className='p-hclsw p-hclsw-setting selected' />
            <i className='p-hclsw p-hclsw-angle-down selected m-1 bf-arrow-style' />
          </Link>
        }
        listItems={
          this.props.gearApps
            ? _.map(_.sortBy(this.props.gearApps, 'translationLabel'), (gearApp) => ({
                name: BfTrans(gearApp.translationLabel),
                link: gearApp.route
              }))
            : []
        }
      />
    )
  }

  _getLogoutIcon() {
    return (
      <div className='logoutContainer'>
        <Tooltip content={this.userInfo} direction='bottom' type='icon'>
          <Link className='hcl hcl-primary hcl-btn BfNavLinkButton' href='/logout'>
            <Logout className='logoutIcon' />
          </Link>
        </Tooltip>
      </div>
    )
  }

  /**
   * Returns settings and logout icons
   * @method
   * @returns {HTMLElement} settings and logout icons
   */
  render() {
    return (
      <div className='hcl-col-3 BfNavBarSettingsContainer'>
        {this._getSettingsIconMenu()}
        {this._getLogoutIcon()}
      </div>
    )
  }
}

export default BfWithServicesConsumer(NavBarSettings)
