/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import { forkJoin } from 'rxjs'
import { map } from 'rxjs/operators'
import BfCurrentUserInfoService from '../user/BfCurrentUserInfoService'
import BfCurrentAppsInfoService from './BfCurrentAppsInfoService'
import logConsole from 'loglevel'
import BfPlatformVersion from '../devices/BfPlatformVersion'
import _ from '../../services/utils/BfLodash'
/**
 * Provides services and functionalities for BF Tabs
 */
class BfTabService {
  bfCurrentAppsInfoService = new BfCurrentAppsInfoService()
  bfPlatformVersion = new BfPlatformVersion()
  appTabs = {}
  subTabApps = []
  mainMenuApps = []

  tabs = []
  currentTab = null
  user = {
    name: '',
    isMaster: false
  }

  showGearIcon = false
  showPPS = false
  showSSA = false
  showAppAdmin = false
  showReport = false
  APPS_LABEL = 'appsLabel'

  constructor(_bfCurrentUserInfoService = new BfCurrentUserInfoService()) {
    this.bfCurrentUserInfoService = _bfCurrentUserInfoService
  }

  /**
   * Checks whther the App link is active or not.
   * @method
   * @param {Object} app application information
   * @returns {Boolean} whether current app is opened or not
   */
  _isActiveApp = (app) => {
    return window.location.path().indexOf(app.route) === 0
  }

  /**
   * Checks whther the Tab link is active or not.
   * @method
   * @param {Object} tab Tab information
   * @returns {Boolean} whether current tab is active or not
   */
  _isActiveTab = (tab) => {
    if (tab.subtabs) {
      for (let i = 0; i < tab.subtabs.length; ++i) {
        if (this._isActiveApp(tab.subtabs[i])) {
          return true
        }
      }
    } else {
      return this._isActiveApp(tab)
    }

    return false
  }

  _orderTabApps = () => {
    const tabApps = _.find(this.tabs, (tab) => {
      return tab.label === this.APPS_LABEL
    })
    tabApps.subtabs = _.orderBy(tabApps.subtabs, ['label'], ['asc'])
  }

  /**
   * Creates Tabs structure
   * @method
   * @param {Object} permittedApps Apps that are permitted for the user to open
   * @param {Object} appsInfo All apps information
   * @returns {Boolean} whether current app is opened or not
   */
  _constructTabs = (permittedApps, appsInfo, isGreaterThanTen) => {
    appsInfo.map((app) => {
      if (app.client && app.client.tabs) {
        app.client.tabs.map((tab) => {
          /* if (this._isActiveApp(tab)) {
            tab.target = ''
            this.currentTab = tab
          } else {
            tab.target = '_self'
          } */
          const tabId = tab.id
          this.appTabs[tabId] = tab
          if (permittedApps.indexOf(tabId) !== -1) {
            if (tab.subMenu) {
              tab.subMenu.forEach(tab.subMenu, function (subMenuItem) {
                subMenuItem.target = '_self'
              })
              this.mainMenuApps.push(tabId)
            } else {
              this.subTabApps.push(tabId)
            }
          }
        })
      }
    })

    const navStructure = [
      'devices',
      {
        label: this.APPS_LABEL,
        subtabs: this.subTabApps
      },
      'deployments'
    ]

    if (isGreaterThanTen) {
      navStructure.push('reports')
    }
    navStructure.push({ mainMenuApps: this.mainMenuApps })

    navStructure.forEach((navItem) => {
      if (navItem.subtabs) {
        const subs = []
        let contentRoute = ''
        navItem.subtabs.forEach((subNavItem) => {
          if (this.appTabs[subNavItem]) {
            subs.push(this.appTabs[subNavItem])
            contentRoute = this.appTabs[subNavItem].route + '|' + contentRoute
          }
        })

        this.tabs.push({
          label: navItem.label,
          subtabs: subs,
          route: contentRoute.slice(0, contentRoute.lastIndexOf('|'))
        })
      } else if (this.appTabs[navItem]) {
        this.tabs.push(this.appTabs[navItem])
      } else if (navItem.mainMenuApps) {
        navItem.mainMenuApps.forEach((mainMenuApp) => {
          if (this.appTabs[mainMenuApp]) {
            this.tabs.push({
              label: this.appTabs[mainMenuApp].label,
              subtabs: this.appTabs[mainMenuApp].subMenu,
              route: this.appTabs[mainMenuApp].route
            })
          }
        })
      }
    })

    this._orderTabApps()
  }

  /**
   * Retrieves Tabs from the server
   * @method
   * @returns {Promise} Http request
   */
  getTabs = () => {
    return forkJoin([this.bfCurrentUserInfoService.get(), this.bfCurrentAppsInfoService.get(), this.bfPlatformVersion.isGreaterThanTen()]).pipe(
      map((values) => {
        const userInfo = values[0]
        const appsInfo = values[1]
        const isGreaterThanTen = values[2]
        this.userInfo = userInfo
        this.appsInfo = appsInfo

        this._constructTabs(userInfo.appPermission, appsInfo, isGreaterThanTen)
        logConsole.info({
          appTabs: this.appTabs,
          subTabApps: this.subTabApps,
          mainMenuApps: this.mainMenuApps,
          tabs: this.tabs,
          currentTab: this.currentTab
        })
        return {
          appTabs: this.appTabs,
          subTabApps: this.subTabApps,
          mainMenuApps: this.mainMenuApps,
          tabs: this.tabs,
          currentTab: this.currentTab
        }
      })
    )
  }
}

export default BfTabService
