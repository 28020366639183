/*
// Licensed Materials - Property of HCL
// © Copyright HCL Technologies Limited 2001, 2021
// All Rights Reserved
*/

import { DateTime } from 'luxon'
import { i18n as bfi18n } from '../services/i18n/bfI18n'

class BfLocalizedDate {
  constructor(format) {
    // refer to https://moment.github.io/luxon/docs/class/src/datetime.js~DateTime.html for formats
    switch (format) {
      case 'seconds':
      case 's':
        this.importer = DateTime.fromSeconds
        break
      case 'ms':
      case 'unix':
        this.importer = DateTime.fromMillis
        break
      case 'iso8601':
        this.importer = DateTime.fromISO
        break
      case 'rfc2822':
      default:
        // not recommended, better to be explicit
        this.importer = DateTime.fromRFC2822
        break
    }
  }

  getDate = (time, options) => {
    try {
      let date = this.importer(time)
      if (options.utc) {
        date = date.toUTC()
      }
      return date.setLocale(bfi18n.language).toLocaleString(DateTime.DATE_MED)
    } catch (e) {
      return time
    }
  }
}

export default BfLocalizedDate
