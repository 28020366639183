/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2021
// All Rights Reserved
*/
import React, { useReducer, useEffect, useCallback, useRef, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { forkJoin } from 'rxjs'
import { map } from 'rxjs/operators'

import { Spinner } from '@patron/patron-react/spinner'
import { Overlay } from '@patron/patron-react/overlay'
import { LinearProgressIndicator } from '@patron/patron-react/progressindicator'
import { Link } from '@patron/patron-react/link'
import { Tooltip } from '@patron/patron-react/tooltip'
import Blob from 'blob'
import JSZip from 'jszip'
import Excel from 'exceljs'
import { saveAs } from 'file-saver'
import BfConsole from 'loglevel'
import moment from 'moment'
import PropTypes from 'prop-types'

import _ from '../../../services/utils/BfLodash'
import BfHttpClient from '../../../services/authClient/BfHttpClient'
import { bfTranslate } from '../../../services/i18n/bfI18Utils'
import { i18n } from '../../../services/i18n/bfI18n'
import BfCurrentUserInfoService from '../../../services/user/BfCurrentUserInfoService'
import BfUseInterval from '../../../services/utils/customHooks/BfUseInterval'

import BfCheckbox from '../../form/BfCheckbox'
import BfForm from '../../form/BfForm'
import BfInputField from '../../form/BfInputField'
import BfRadioGroup from '../../form/BfRadioGroup'

import { updateIsExportingTypeAction, updateSelectedRowsIdsAction } from '../BfReports/redux/BfReportsUpdateAction'
import { updateShowSummaryAction } from '../BfCharts/redux/BfChartUpdateAction'
import { refreshSelectedRowsIdsAction } from '../../datagrid/redux/BfFilterListUpdateURLAction'
import { BfWithReduxStoreConsumer } from '../../mainAppContainer/bfReduxStore/BfReduxStore'
import { createFilterManagerFromReduxState } from '../../datagrid/filters/BfFilterManager'

import { specialCriteriaParser } from './helpers/BfFilterReportsHelper'
import { createReportPDF } from './helpers/BfPdfExportHelper'
import './BfReportsStyle.scss'
import BfReduxStateConstants from '../BfReduxStateConstants'
import BfExportConstants from './helpers/BfExportConstants'

const EXPORT_INTERVAL_MS = 5000 // 5 seconds
const TIMEOUT_INTERVAL_MS = 5000 // 5 seconds
const TIMEOUT_FOR_CHARTS = 30000 // 30 seconds

function BfExportReport(props) {
  const {
    defaultReportName,
    apiUrl,
    reportColumns,
    reportFunction,
    addedParams,
    nameColumnOnlyField = 'name',
    visibleColumns,
    searchObj,
    selectedRowsIds,
    isSummaryLoaded,
    showSummary,
    chartNamespace,
    namespace,
    rowCount,
    refreshSelectedRowsIdsAction,
    updateShowSummaryAction,
    updateIsExportingTypeAction,
    updateSelectedRowsIdsAction,
    progressType = 'determinate',
    hasOnlySelectedFilter = true
  } = props

  const { t } = useTranslation()
  const buttonEl = useRef(null)

  const initialState = {
    errorMessage: false,
    displayOverlay: false,
    exportType: undefined,
    isExporting: false,
    timeoutForLoadingCharts: -1,
    waitingForCharts: false,
    pdfData: undefined,
    formValues: {
      allItems: true,
      nameColOnly: false,
      includeColHeaders: true,
      reportName: ''
    },
    exportId: undefined,
    currentExport: undefined
  }

  function reducer(state, action) {
    switch (action.type) {
      case 'showOverlay':
        return {
          ...state,
          displayOverlay: true
        }
      case 'toggleOverlay':
        return {
          ...state,
          displayOverlay: !state.displayOverlay
        }
      case 'closeOverlay':
        return {
          ...state,
          displayOverlay: false
        }
      case 'exportStarted':
        return {
          ...state,
          exportType: action.payload,
          exportProgress: 0,
          progressMessage: bfTranslate(t, 'preparingToExport'),
          isExporting: true
        }
      case 'setTimeoutForLoadingCharts':
        return {
          ...state,
          timeoutForLoadingCharts: action.payload
        }
      case 'waitingForCharts':
        return {
          ...state,
          waitingForCharts: true,
          timeoutForLoadingCharts: action.payload.timeout,
          pdfData: action.payload.pdfData
        }
      case 'stopWaitingForCharts':
        return {
          ...state,
          waitingForCharts: false,
          timeoutForLoadingCharts: -1,
          pdfData: undefined
        }
      case 'updateExportId':
        return {
          ...state,
          exportId: action.payload
        }
      case 'exportCancelled': {
        return {
          ...state,
          errorMessage: false,
          isExporting: false,
          isDownloading: false,
          displayOverlay: false,
          exportId: undefined,
          currentExport: undefined
        }
      }
      case 'updateExportFailed': {
        return {
          ...state,
          errorMessage: action.payload,
          isDownloading: false,
          isExporting: action.payload === false ? state.isExporting : false,
          displayOverlay: action.payload === false ? state.displayOverlay : false
        }
      }
      case 'updateCurrentExport': {
        return { ...state, currentExport: action.payload }
      }
      case 'exportJobCompleted': {
        return {
          ...state,
          isExporting: false,
          isDownloading: false
        }
      }
      case 'exportCompleted':
        return {
          ...state,
          isExporting: false,
          isDownloading: false,
          displayOverlay: false
        }
      case 'downloadStarted': {
        return {
          ...state,
          isDownloading: true,
          displayOverlay: false
        }
      }
      case 'updateFormValues':
        return { ...state, formValues: action.payload }
      case 'setExportType':
        return { ...state, exportType: action.payload }
      default:
        BfConsole.error('action type not found')
    }
    return state
  }

  const [state, dispatch] = useReducer(reducer, initialState)
  const bfHttpClient = useMemo(() => new BfHttpClient(), [])
  const bfCurrentUserInfoService = new BfCurrentUserInfoService()

  const _showOverlayAction = () => {
    dispatch({ type: 'showOverlay' })
  }
  const _toggleOverlayAction = () => {
    dispatch({ type: 'toggleOverlay' })
  }
  const _closeOverlayAction = () => {
    dispatch({ type: 'closeOverlay' })
  }

  const _getReportTitle = useCallback(
    function (reportName) {
      if (reportName && !_.isEmpty(reportName.trim())) {
        return reportName.trim()
      }
      return defaultReportName
    },
    [defaultReportName]
  )

  const _getFileName = useCallback(
    function (extensionWithDot, reportName, userName) {
      return _getReportTitle(reportName).replaceAll(' ', '_') + '_' + moment().format('L').replaceAll(/\//g, '_') + '_' + userName + extensionWithDot
    },
    [_getReportTitle]
  )

  const _createCriteria = useCallback(
    function (filters, search, columns, returnCriteriaObj) {
      const criteriaMap = {}
      // add search string
      if (_.isDefined(search) && !_.isEmpty(search.query)) {
        criteriaMap[bfTranslate(t, 'search')] = [search.query]
      }
      const critieriaColumns = _.isObject(columns) ? columns : visibleColumns
      if (_.isObject(filters)) {
        const keys = _.keys(filters)
        _.each(keys, (key) => {
          const filter = filters[key]
          if (key === 'customProperties') {
            _.each(filter.properties, (customProperty) => {
              const propertyKeys = _.keys(customProperty)
              if (_.every(['values', 'contentKey', 'propertyID'], (key) => _.includes(propertyKeys, key))) {
                const customKey = customProperty.contentKey.siteID + '_' + customProperty.contentKey.contentID + '_' + customProperty.propertyID
                criteriaMap[customKey] = customProperty.values
              }
            })
            return
          }
          // special criteria filter case
          const filterMap = specialCriteriaParser(key, filter, t)
          if (!_.isNull(filterMap)) {
            criteriaMap[key] = filterMap
            // generic checkbox case
          } else if (_.isObject(filter)) {
            criteriaMap[key] = _.keys(filter)
            // generic string case
          } else if (_.isString(filter)) {
            criteriaMap[key] = filter
          }
          // TODO: dates
          // slider?
        })
      }

      const criteriaMapTrans = _.map(criteriaMap, function (values, key) {
        const column = _.find(critieriaColumns, (col) => {
          return col.field === key || col?.filterKey === key
        })

        let reportColumn
        let keyTrans
        if (!_.isUndefined(column)) {
          // use label defined in reportColumns if it exists
          reportColumn = _.find(reportColumns, function (reportCol) {
            return reportCol?.datagridField === column.field || reportCol.field === column.field
          })
          keyTrans = reportColumn?.label ? reportColumn.label : column.label
        } else {
          keyTrans = bfTranslate(t, key)
        }
        if (_.isArray(values)) {
          return keyTrans + ': ' + values.join(', ')
        } else if (_.isBoolean(values)) {
          return keyTrans + ': ' + (values ? bfTranslate(t, 'yes') : bfTranslate(t, 'no'))
        } else if (_.isString(values)) {
          return keyTrans + ': ' + values
        }
      })

      if (returnCriteriaObj === true) {
        return criteriaMapTrans
      } else {
        return criteriaMapTrans.join('\n')
      }
    },
    [t, visibleColumns, reportColumns]
  )

  const _getVisibleReportColumns = useCallback(
    function () {
      const columns = []

      const idColObject = _.find(reportColumns, { field: 'id' })
      if (idColObject) {
        columns.push({
          header: idColObject.label,
          key: idColObject.field
        })
      }

      const columnsInOrder = _.sortBy(visibleColumns, ['index'])
      for (const vCol of columnsInOrder) {
        for (let i = 0; i < reportColumns.length; i++) {
          const rCol = reportColumns[i]
          if (rCol?.datagridField === vCol.field || rCol.field === vCol.field) {
            columns.push({ header: rCol.label || vCol.label, key: rCol.field })
            break
          }
        }
      }
      return columns
    },
    [reportColumns, visibleColumns]
  )

  function _removeUnusedColumnsReportData(jsonData, columns) {
    const validFieldNames = columns.map((col) => col.key)
    if (Array.isArray(jsonData) && jsonData.length > 0) {
      return _.map(jsonData, function (row) {
        return _.pick(row, validFieldNames)
      })
    }
    return jsonData
  }

  const _createWorkbook = useCallback(
    function (reportData, formValues, params) {
      if (_.isFunction(reportFunction)) {
        reportData = reportFunction(reportData, t)
      }

      let columns

      if (!formValues.allItems && !hasOnlySelectedFilter) {
        reportData = reportData.filter((row) => _.includes(selectedRowsIds, row.id.toString()))
      }

      if (formValues.nameColOnly) {
        reportData = reportData.map((item) => {
          return { [nameColumnOnlyField]: item[nameColumnOnlyField] }
        })
        columns = reportColumns
          .filter((col) => col.field === nameColumnOnlyField)
          .map((col) => {
            for (const vCol of visibleColumns) {
              if (col?.datagridField ? vCol.field === col.datagridField : vCol.field === col.field) {
                return { header: col.label || vCol.label, key: col.field }
              }
            }
          })
      } else if (formValues.columns) {
        const columnsInOrder = _.sortBy(formValues.columns, ['index'])
        columns = _.map(columnsInOrder, (col) => {
          return { header: col.label, key: col.field }
        })
      } else {
        columns = _getVisibleReportColumns()
        reportData = _removeUnusedColumnsReportData(reportData, columns)
      }

      const workbook = new Excel.Workbook()
      const worksheet = workbook.addWorksheet('Report')
      worksheet.columns = columns
      worksheet.addRows(reportData)

      if (!formValues.includeColHeaders) {
        worksheet.spliceRows(1, 1)
      }

      function insertStringIntoTopRow(value) {
        const newRow = new Array(columns.length)
        newRow[0] = value
        // Extra spaces added to prevent csv file from removing columns
        newRow.fill('', 1)
        worksheet.spliceRows(1, 0, newRow)
      }
      const filters = _.isString(params.filter) && _.isNotEmpty(params.filter) ? JSON.parse(params.filter) : params.filter
      const search = _.isString(params.search) && _.isNotEmpty(params.search) ? JSON.parse(params.search) : params.search
      let reportCriteria = _createCriteria(filters, search, formValues.columns)
      if (_.isNotEmpty(reportCriteria) && formValues.includeColHeaders) {
        reportCriteria = bfTranslate(t, 'showContentWithFollowingCriteria') + ' ' + '\n' + reportCriteria
        insertStringIntoTopRow(reportCriteria)
      }

      const dateString =
        bfTranslate(t, 'date') + ': ' + moment().format('LLL ZZ') + ' (' + moment.utc().format('LLL') + ' ' + bfTranslate(t, 'utcTime') + ')'

      if (formValues.includeColHeaders) {
        insertStringIntoTopRow(dateString)
      }

      return workbook
    },
    [
      _createCriteria,
      _getVisibleReportColumns,
      nameColumnOnlyField,
      reportColumns,
      reportFunction,
      selectedRowsIds,
      t,
      visibleColumns,
      hasOnlySelectedFilter
    ]
  )

  async function _createExport(reportData, formValues, userName, params, filetype) {
    const workbook = _createWorkbook(reportData, formValues, params)
    try {
      const buffer = await workbook[filetype].writeBuffer()
      const blob = new Blob([buffer])
      const title = _getFileName('.' + filetype, formValues?.reportName, userName)
      saveAs(blob, title)
    } catch (err) {
      BfConsole.error('Failed to write to buffer ', err)
    } finally {
      dispatch({ type: 'exportCompleted' })
    }
  }

  function waitTimeout(time) {
    return new Promise(function (resolve) {
      setTimeout(resolve, time)
    })
  }

  async function _loadCharts(pdfData) {
    try {
      if (showSummary === false) {
        updateShowSummaryAction(chartNamespace, true)
      }
      if (isSummaryLoaded === false && state.waitingForCharts === false) {
        dispatch({
          type: 'waitingForCharts',
          payload: { timeout: TIMEOUT_FOR_CHARTS, pdfData: pdfData }
        })
      } else {
        await waitTimeout(TIMEOUT_INTERVAL_MS) // in case of animation
        _exportPDF(pdfData)
      }
    } catch (error) {
      dispatch({ type: 'exportCompleted' })
      BfConsole.error('Error occured while trying to export charts:')
      BfConsole.error(error)
    }
  }

  /**
   * create and export PDF report
   * @param {Obj} pdfData 
   *      pdfData = {
          data: OBJECT - report data
          userName: STRING - owner 
          formValues: OBJECT - dropdown input
          params: OBJECT - search object parameters
          pdfBlob: STRING (OPTIONAL) - base64 pdf blob
        }
   */
  const _exportPDF = useCallback(
    async function _exportPDF(pdfData) {
      const data = pdfData.data
      const userName = pdfData.userName
      const formValues = pdfData.formValues
      const params = pdfData.params
      try {
        const reportTitle = _getReportTitle(formValues.reportName)
        const rawFilters = _createCriteria(params.filter, params.search, null, true)
        const currentLanguage = _.clone(i18n.language)
        const pdfBlob = pdfData.pdfBlob ? pdfData.pdfBlob : await createReportPDF(userName, rawFilters, reportTitle, currentLanguage, t)

        if (pdfData?.createExportJob) {
          const reader = new window.FileReader()
          reader.readAsDataURL(pdfBlob)
          reader.onloadend = function () {
            const base64Data = reader.result
            bfHttpClient
              .post('/reports/export/add', { ...pdfData.jobParams, base64Pdf: base64Data })
              .pipe(
                map((res) => {
                  return res.exportId
                })
              )
              .subscribe(
                (exportId) => {
                  dispatch({ type: 'updateExportId', payload: exportId })
                },

                (error) => {
                  BfConsole.error('There was an error in creating the pdf for the export job: ')
                  dispatch({ type: 'updateExportFailed', payload: bfTranslate(t, 'exportErrorGeneral') })
                  BfConsole.error(error)
                }
              )
          }
        } else {
          const csvWorkbook = await _createWorkbook(data, formValues, params)
          const csvBuffer = await csvWorkbook.csv.writeBuffer()
          const csvBlob = new Blob([csvBuffer])
          const newZip = new JSZip()
          await newZip.file(_getFileName('.pdf', formValues?.reportName, userName), pdfBlob)
          await newZip.file(_getFileName('.csv', formValues?.reportName, userName), csvBlob)
          const zipBlob = await newZip.generateAsync({ type: 'blob' })
          await saveAs(zipBlob, _getFileName('.zip', formValues?.reportName, userName))
        }
      } catch (error) {
        BfConsole.error('Error in creating pdf report:')
        BfConsole.error(error)
      } finally {
        if (pdfData?.createExportJob) {
          dispatch({ type: 'setExportType', payload: undefined })
        } else {
          dispatch({ type: 'exportCompleted' })
        }
      }
    },
    [_createCriteria, _createWorkbook, _getFileName, _getReportTitle, t, bfHttpClient]
  )

  const _exportData = (data, userName, formValues, params) => {
    const exportType = formValues.exportType ? formValues.exportType : state.exportType
    switch (exportType) {
      case 'csv':
      case 'xlsx': {
        _createExport(data, formValues, userName, params, exportType)
        break
      }
      case 'pdf': {
        BfConsole.debug('exporting to pdf')
        const pdfData = {
          data: data,
          userName: userName,
          formValues: formValues,
          params: params
        }
        _loadCharts(pdfData)
        break
      }
      default: {
        BfConsole.error('no export type selected')
        dispatch({ type: 'exportCompleted' })
      }
    }
  }

  const _getData = (formValues) => {
    let filter = searchObj
    if (!formValues.allItems && hasOnlySelectedFilter) {
      filter.filter = { onlySelected: { Enabled: true, data: selectedRowsIds } }
    } else if (!formValues.allItems) {
      filter = {}
    }
    const params = Object.assign({}, filter, addedParams)
    forkJoin([bfHttpClient.get(apiUrl, params), bfCurrentUserInfoService.get()])
      .pipe(
        map((values) => {
          return {
            reportData: values[0],
            userName: values[1].userName
          }
        })
      )
      .subscribe(
        (response) => _exportData(response.reportData, response.userName, formValues, params),
        (error) => BfConsole.error(error)
      )
  }

  const _createExportJob = (formValues) => {
    try {
      if (!_.isUndefined(state.exportId)) {
        BfConsole.debug(`Deleting existing export ${state.exportId} before creating new export`)
        bfHttpClient.post('/reports/export/delete/' + state.exportId)
      }
      dispatch({ type: 'updateExportFailed', payload: false })
      dispatch({ type: 'updateExportId', payload: undefined })
      dispatch({
        type: 'updateCurrentExport',
        payload: { exportStarted: moment().format('LLL'), percentageCompletion: 0, exportStatus: BfExportConstants.status.PENDING }
      })
      // Pull relevant data for export job's columns
      const columns = _.map(visibleColumns, function (obj) {
        const column = _.pick(
          obj,
          'propertyName',
          'label',
          'field',
          'index',
          'siteID',
          'contentID',
          'propertyID',
          '_isCustomProperties',
          'propertyType'
        )
        if (column._isCustomProperties === true) {
          column.propertyType = 'Custom'
        }
        return column
      })

      let filter = searchObj
      if (!formValues.allItems && hasOnlySelectedFilter) {
        filter.filter = { onlySelected: { Enabled: true, data: selectedRowsIds } }
      } else if (!formValues.allItems) {
        filter = {}
      }
      formValues.exportType = state.exportType
      formValues.columns = columns
      const jobParams = Object.assign({}, filter, addedParams, {
        columns: columns,
        contentType: namespace,
        formValues: formValues
      })
      if (formValues.exportType === 'pdf') {
        const params = Object.assign({}, filter, addedParams)
        bfCurrentUserInfoService.get().subscribe((user) => {
          _loadCharts({
            userName: user.userName,
            params: params,
            jobParams: jobParams,
            formValues: formValues,
            createExportJob: true
          })
        })
      } else {
        bfHttpClient
          .post('/reports/export/add', jobParams)
          .pipe(
            map((res) => {
              return res.exportId
            })
          )
          .subscribe(
            (exportId) => {
              dispatch({ type: 'updateExportId', payload: exportId })
            },
            (error) => {
              BfConsole.error('There was an error in creating the export job on the server: ')
              dispatch({ type: 'updateExportFailed', payload: bfTranslate(t, 'exportErrorGeneral') })
              BfConsole.error(error)
            }
          )
      }
    } catch (error) {
      BfConsole.error('There was an error in creating the export job: ')
      dispatch({ type: 'updateExportFailed', payload: bfTranslate(t, 'exportErrorGeneral') })
      BfConsole.error(error)
    }
  }
  const _onCancelExport = () => {
    bfHttpClient.post('/reports/export/cancel/' + state.exportId)
    dispatch({ type: 'exportCancelled' })
  }

  const _onDeleteExport = () => {
    bfHttpClient.post('/reports/export/delete/' + state.exportId)
    dispatch({ type: 'exportCancelled' })
  }

  const hasDownloadCompleted = () => {
    return (
      !state.isExporting &&
      state.exportId !== undefined &&
      !state.errorMessage &&
      state.currentExport?.exportStatus === BfExportConstants.status.COMPLETE
    )
  }
  const isExportPending = () => {
    return state.isExporting && state.exportId === undefined
  }

  const getFormattedExportTimes = () => {
    const times = { exportFinished: bfTranslate(t, 'exportCompleted'), exportStarted: bfTranslate(t, 'exportInProgress') }
    if (_.isObject(state.currentExport)) {
      times.exportFinished = _.isString(state.currentExport.exportFinished)
        ? bfTranslate(t, 'exportFinishedAt') + '\n' + moment.utc(state.currentExport.exportFinished).local().format('LLL')
        : bfTranslate(t, 'exportTimePending')
      times.exportStarted = _.isString(state.currentExport.exportStarted)
        ? bfTranslate(t, 'exportStartedAt') + '\n' + moment.utc(state.currentExport.exportStarted).local().format('LLL')
        : bfTranslate(t, 'exportTimePending')
    }
    return times
  }

  const getExportProgressMessage = () => {
    const exportStatus = state.currentExport?.exportStatus
    let message = ''
    switch (exportStatus) {
      case BfExportConstants.status.PENDING:
        message = bfTranslate(t, 'preparingToExport')
        break
      case BfExportConstants.status.IN_PROGRESS:
        message = bfTranslate(t, 'exportInProgress')
        break
      case BfExportConstants.status.COMPLETE:
        message = bfTranslate(t, 'exportCompleted')
        break
      case BfExportConstants.status.FAILED:
        message = bfTranslate(t, 'exportFailed')
        break
      case BfExportConstants.status.CANCELLED:
        message = bfTranslate(t, 'exportCancelled')
        break
      default:
        message = bfTranslate(t, 'preparingToExport')
    }
    return message
  }

  const _onDownloadExport = () => {
    dispatch({ type: 'downloadStarted' })
    forkJoin([bfHttpClient.get('/reports/export/download/' + state.exportId), bfCurrentUserInfoService.get()])
      .pipe(
        map((values) => {
          if (values.length < 2) {
            throw new Error(`Missing information when downloading export. Current values: ${values}`)
          }
          return {
            exportData: values[0].exportData,
            exportMetadata: values[0].exportMetadata,
            pdfBase64: values[0].pdfData,
            userName: values[1].userName
          }
        })
      )
      .subscribe(
        (data) => {
          if (_.isUndefined(data.exportMetadata?.formValues) && _.isUndefined(data.exportMetadata?.filters)) {
            throw new Error(`Missing download metadata. Only data found was: ${data.exportMetadata}`)
          }
          const formValues = JSON.parse(data.exportMetadata.formValues)
          const filters = JSON.parse(data.exportMetadata.filters)
          if (formValues.exportType === 'pdf') {
            if (_.isUndefined(data.pdfBase64)) {
              throw new Error(`PDF data is missing for PDF export. Please try to export the PDF report again.`)
            }
            const pdfBinary = window.atob(data.pdfBase64.replace('data:application/pdf;base64,', ''))
            const buffer = new ArrayBuffer(pdfBinary.length)
            const pdfView = new Uint8Array(buffer)
            for (var i = 0; i < pdfBinary.length; i++) {
              pdfView[i] = pdfBinary.charCodeAt(i)
            }
            const pdfBlob = new Blob([pdfView], { type: 'application/pdf' })
            const pdfExportData = {
              data: data.exportData,
              userName: data.userName,
              formValues: formValues,
              params: filters,
              pdfBlob: pdfBlob
            }
            _exportPDF(pdfExportData)
          } else {
            _exportData(data.exportData, data.userName, formValues, filters)
          }
        },
        (error) => {
          BfConsole.error('There was an error in downloading the export: ')
          BfConsole.error(error)
        }
      )
  }

  /** Polling for export status while in progress */
  BfUseInterval(
    async () => {
      if (_.isDefined(state.exportId)) {
        bfHttpClient.get('/reports/export/status/' + state.exportId).subscribe(
          (status) => {
            const updatedExport = _.isDefined(state.currentExport) ? state.currentExport : {}
            updatedExport.percentageCompletion = status.percentageCompletion
            updatedExport.exportStatus = status.exportStatus
            if (status.exportStatus === BfExportConstants.status.COMPLETE) {
              updatedExport.exportFinished = status.exportFinished
              dispatch({ type: 'exportJobCompleted' })
              dispatch({ type: 'updateCurrentExport', payload: updatedExport })
            } else if (status.exportStatus === BfExportConstants.status.FAILED) {
              dispatch({ type: 'updateExportFailed', payload: bfTranslate(t, 'exportErrorGeneral') })
              throw new Error('Export failed on server side. See logs for details.')
            } else {
              dispatch({ type: 'updateCurrentExport', payload: updatedExport })
            }
          },
          (error) => {
            BfConsole.error('Error in trying to get current export status: ')
            BfConsole.error(error)
          }
        )
      }
    },
    state.isExporting ? EXPORT_INTERVAL_MS : null
  )

  /** same as componentDidMount */
  useEffect(() => {
    if (namespace === BfReduxStateConstants.DEVICES) {
      bfHttpClient.get('/reports/exports/get_recent_export').subscribe(
        (recentExport) => {
          if (!_.isEmpty(recentExport)) {
            const dispatchedExport = _.extend({}, recentExport)
            dispatch({ type: 'updateCurrentExport', payload: dispatchedExport })

            dispatch({ type: 'updateExportId', payload: recentExport.exportId })
            if (recentExport.exportStatus === BfExportConstants.status.COMPLETE) {
              dispatch({ type: 'exportJobCompleted' })
            } else if (recentExport.exportStatus === BfExportConstants.status.FAILED) {
              dispatch({ type: 'updateExportFailed', payload: bfTranslate(t, 'exportErrorGeneral') })
              throw new Error('Export failed on server side. See logs for details.')
            }
          }
        },
        (error) => {
          BfConsole.error('Error in getting getting recent export: ')
          BfConsole.error(error)
        }
      )
    }
  }, [bfHttpClient, namespace, t])

  useEffect(() => {
    async function _waitForCharts() {
      try {
        if (isSummaryLoaded === true) {
          const pdfData = _.cloneDeep(state.pdfData)
          dispatch({ type: 'stopWaitingForCharts' })
          await waitTimeout(TIMEOUT_INTERVAL_MS) // in case of animation
          _exportPDF(pdfData)
        } else if (state.timeoutForLoadingCharts > 0) {
          const newTimeout = state.timeoutForLoadingCharts - TIMEOUT_INTERVAL_MS
          if (newTimeout > 0) {
            BfConsole.debug('waiting for charts to load. Time left: ', newTimeout)
            await waitTimeout(TIMEOUT_INTERVAL_MS)
            dispatch({
              type: 'setTimeoutForLoadingCharts',
              payload: newTimeout
            })
          }
        } else {
          dispatch({ type: 'stopWaitingForCharts' })
          throw new Error('waiting for charts to load has timed out')
        }
      } catch (error) {
        dispatch({ type: 'stopWaitingForCharts' })
        dispatch({ type: 'exportCompleted' })
        BfConsole.error('Error occured while trying to export charts:')
        BfConsole.error(error)
      }
    }
    if (state.waitingForCharts === true) {
      _waitForCharts()
    }
  }, [state.timeoutForLoadingCharts, state.waitingForCharts, state.pdfData, isSummaryLoaded, _exportPDF])

  useEffect(() => {
    if (state.isExporting && namespace !== BfReduxStateConstants.DEVICES) {
      const timer = setTimeout(() => {
        dispatch({ type: 'closeOverlay' })
      }, 700)
      return () => clearTimeout(timer)
    }
  }, [state.isExporting, namespace])

  useEffect(() => {
    const exportType = _.clone(state.exportType)
    if (state.isExporting) {
      updateIsExportingTypeAction(BfReduxStateConstants.REPORTS, exportType)
    } else {
      updateIsExportingTypeAction(BfReduxStateConstants.REPORTS, undefined)
    }
  }, [state.isExporting, state.exportType, updateIsExportingTypeAction])

  return (
    <span className='pr-3 bfExportReport'>
      <span className='bfPDFfont'>
        <span className={i18n.language + ' bold'} />
        <span className={i18n.language} />
      </span>
      {state.isExporting && (
        <Tooltip content={<div>{getFormattedExportTimes().exportStarted}</div>}>
          <Spinner small />
        </Tooltip>
      )}
      {hasDownloadCompleted() && (
        <Tooltip content={<div>{getFormattedExportTimes().exportFinished}</div>}>
          <span>
            {state.isDownloading && <Spinner small />}
            {!state.isDownloading && <i className='p-hclsw pr-3 p-hclsw-success bfDownloadComplete selected' />}
          </span>
        </Tooltip>
      )}
      {state.errorMessage && (
        <Tooltip content={<div>{state.errorMessage}</div>}>
          <i className='p-hclsw pr-3 p-hclsw-error bfExportFailed selected' />
        </Tooltip>
      )}
      <span
        // disabled button fires no hover event
        onMouseEnter={() => {
          if (state.isExporting) {
            _showOverlayAction()
          }
        }}
      >
        <button
          id='exportButton'
          type='button'
          key='notsubmit'
          className='hcl-btn hcl-primary'
          onClick={() => _toggleOverlayAction()}
          disabled={!rowCount}
          ref={buttonEl}
        >
          {!state.isExporting && bfTranslate(t, 'Export')}
          {state.isExporting && bfTranslate(t, 'exportingButton')}
        </button>
      </span>
      <div
        className='bfOverlayContainer'
        style={{
          right: buttonEl?.current?.offsetWidth ? buttonEl.current.offsetWidth + 'px' : 0
        }}
      >
        <Overlay
          showOverlay={state.displayOverlay}
          targetElement={buttonEl.current}
          onToggle={(status) => {
            if (!status) {
              _closeOverlayAction()
            } else if (!state.isExporting) {
              refreshSelectedRowsIdsAction(namespace, updateSelectedRowsIdsAction)
            }
          }}
          direction='bottom-left'
        >
          <div aria-labelledby='Right Overflow Menu' className='hcl-overflow-menu ml-4 mr-4 bfOverflowMenu'>
            <BfForm
              initialValues={state.formValues}
              enableReinitialize
              noMargin
              handleSubmit={() => {
                if (namespace === BfReduxStateConstants.DEVICES) {
                  _createExportJob(state.formValues)
                } else {
                  _getData(state.formValues)
                }
              }}
            >
              {({ values, submitForm }) => {
                const noResults = !values.allItems && (_.isUndefined(selectedRowsIds) || _.isEmpty(selectedRowsIds))
                return (
                  <div>
                    {namespace === BfReduxStateConstants.DEVICES && !_.isUndefined(state.currentExport?.exportStatus) && (
                      <div className='pt-3 pb-3 hcl-flex bfAlignItemsCenter bfTextAlignLeft'>
                        {!state.errorMessage && (
                          <LinearProgressIndicator
                            customContent={<span>{isExportPending() ? 0 : Math.floor(state.currentExport?.percentageCompletion * 100)}%</span>}
                            label={isExportPending() ? bfTranslate(t, 'preparingToExport') : getExportProgressMessage()}
                            progress={isExportPending() ? 0 : state.currentExport?.percentageCompletion}
                            type={progressType}
                          />
                        )}
                        {state.errorMessage && (
                          <div style={{ display: 'contents' }}>
                            <LinearProgressIndicator
                              label={bfTranslate(t, 'exportFailed')}
                              progress={state.currentExport?.percentageCompletion}
                              type={progressType}
                            />
                            <span>
                              <Link className='pl-7' href='#' onClick={_onDeleteExport}>
                                {bfTranslate(t, 'clear')}
                              </Link>
                            </span>
                          </div>
                        )}
                        {!_.isUndefined(state.exportId) && !state.errorMessage && (
                          <span>
                            {state.isExporting && (
                              <Link className='pl-7' href='#' onClick={_onCancelExport}>
                                {bfTranslate(t, 'cancel')}
                              </Link>
                            )}
                            {hasDownloadCompleted() && (
                              <span>
                                <Link className='bfDownloadLink' href='#' onClick={_onDownloadExport}>
                                  <i className='pl-3 p-hclsw p-hclsw-download-file selected' />
                                </Link>
                                <Link className='bfDownloadLink' href='#' onClick={_onDeleteExport}>
                                  <i className='pl-3 p-hclsw p-hclsw-delete selected' />
                                </Link>
                              </span>
                            )}
                          </span>
                        )}
                      </div>
                    )}

                    <div className='pt-2'>
                      <BfInputField
                        id='exportName'
                        label={bfTranslate(t, 'reportName')}
                        name='reportName'
                        placeholder={defaultReportName}
                        autoComplete='off'
                        disabled={state.isExporting}
                        onChange={(e) => {
                          dispatch({
                            type: 'updateFormValues',
                            payload: {
                              ...state.formValues,
                              reportName: e.target.value
                            }
                          })
                        }}
                      />
                    </div>

                    <BfRadioGroup
                      name='allItems'
                      id='exportSelectAll'
                      options={[
                        {
                          label: bfTranslate(t, 'selectedItems'),
                          value: false
                        },
                        { label: bfTranslate(t, 'allItems'), value: true }
                      ]}
                      disabled={state.isExporting}
                      vertical
                      onChange={(value) => {
                        dispatch({
                          type: 'updateFormValues',
                          payload: { ...state.formValues, allItems: value }
                        })
                      }}
                    />
                    <BfCheckbox
                      name='nameColOnly'
                      id='exportNameColOnly'
                      disabled={state.isExporting}
                      label={bfTranslate(t, 'nameColumnOnly')}
                      onChange={(value) => {
                        dispatch({
                          type: 'updateFormValues',
                          payload: { ...state.formValues, nameColOnly: value }
                        })
                      }}
                    />
                    <BfCheckbox
                      name='includeColHeaders'
                      id='exportColHeaders'
                      label={bfTranslate(t, 'includeColHeaders')}
                      disabled={state.isExporting}
                      onChange={(value) => {
                        dispatch({
                          type: 'updateFormValues',
                          payload: {
                            ...state.formValues,
                            includeColHeaders: value
                          }
                        })
                      }}
                    />
                    <ul>
                      <li className='hcl-overflow-option'>
                        <button
                          id='csvExportButton'
                          onClick={() => {
                            dispatch({
                              type: 'exportStarted',
                              payload: 'csv'
                            })
                            submitForm()
                          }}
                          type='button'
                          className='hcl-overflow-option-item hcl-overflow-option-separator'
                          disabled={noResults || state.isExporting || state.isDownloading}
                          title={noResults ? bfTranslate(t, 'exportsNoResultTooltip') : ''}
                        >
                          CSV
                        </button>
                      </li>
                      <li className='hcl-overflow-option'>
                        <button
                          id='xlsxExportButton'
                          onClick={() => {
                            dispatch({
                              type: 'exportStarted',
                              payload: 'xlsx'
                            })
                            submitForm()
                          }}
                          type='button'
                          className='hcl-overflow-option-item'
                          disabled={noResults || state.isExporting || state.isDownloading}
                          title={noResults ? bfTranslate(t, 'exportsNoResultTooltip') : ''}
                        >
                          XLSX
                        </button>
                      </li>
                      <li className='hcl-overflow-option'>
                        <button
                          id='pdfExportButton'
                          onClick={() => {
                            dispatch({
                              type: 'exportStarted',
                              payload: 'pdf'
                            })
                            submitForm()
                          }}
                          type='button'
                          className='hcl-overflow-option-item'
                          disabled={noResults || state.isExporting || state.isDownloading}
                          title={noResults ? bfTranslate(t, 'exportsNoResultTooltip') : ''}
                        >
                          {bfTranslate(t, 'exportPdfWithCharts')}
                        </button>
                      </li>
                    </ul>
                  </div>
                )
              }}
            </BfForm>
          </div>
        </Overlay>
      </div>
    </span>
  )
}

BfExportReport.propTypes = {
  defaultReportName: PropTypes.string.isRequired,
  apiUrl: PropTypes.string.isRequired,
  reportColumns: PropTypes.arrayOf(
    PropTypes.shape({
      // field - the property of the report data from api to use, also used to check if col selected
      field: PropTypes.string.isRequired,
      label: PropTypes.string, // overrides datagrid col header from visibleColumns
      datagridField: PropTypes.string // used instead of field if supplied to compare against datagrid columns
    })
  ).isRequired,
  reportFunction: PropTypes.func, // Use to modify the results from from the api
  addedParams: PropTypes.object, // Add additional params to api call ex. {pagination: page: {}}
  nameColumnOnlyField: PropTypes.string, // Name prop to use from api data for name col
  progressType: PropTypes.oneOf(['determinate', 'indeterminate']),
  hasOnlySelectedFilter: PropTypes.bool,

  // The following are retrieved from the store in mapStateToProps below
  visibleColumns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      field: PropTypes.string, // mapped from reportColumn field or datagridField
      type: PropTypes.string,
      isDefault: PropTypes.bool,
      _isVisible: PropTypes.bool // filtered out already by visible
    })
  ),
  searchObj: PropTypes.object,
  selectedRowsIds: PropTypes.array,
  // when viewing selected only and refreshing, values are bools so it changes
  isSummaryLoaded: PropTypes.bool,
  showSummary: PropTypes.bool,
  rowCount: PropTypes.number,
  // namespace of the content (datagrid)
  namespace: PropTypes.string,
  // namespace specifically for the charts
  chartNamespace: PropTypes.string.isRequired,
  updateShowSummaryAction: PropTypes.func,
  updateIsExportingTypeAction: PropTypes.func,
  updateSelectedRowsIdsAction: PropTypes.func,
  refreshSelectedRowsIdsAction: PropTypes.func // tells datagrid to refresh selected ids
}

const mapStateToProps = (state, ownProps) => {
  BfConsole.info('mapStateToProps on BfExportReport is ', state)
  BfConsole.info('mapStateToProps namespace is ', ownProps.namespace)
  const bfFilterManager = createFilterManagerFromReduxState(state, ownProps.namespace)
  const searchObj = bfFilterManager.getSearch()

  if (ownProps.namespace in state) {
    const { rowCount, columns } = state[ownProps.namespace]
    const { selectedRowsIds } = state[BfReduxStateConstants.REPORTS]
    const storeColumns = columns?.cols

    let visibleColumns
    if (!_.isUndefined(storeColumns)) {
      visibleColumns = storeColumns.filter((col) => col._isVisible)
    }

    const isSummaryLoaded = state[ownProps.chartNamespace]?.isSummaryLoaded
    const showSummary = state[ownProps.chartNamespace]?.showSummary
    return {
      visibleColumns,
      searchObj,
      selectedRowsIds,
      isSummaryLoaded,
      showSummary,
      rowCount
    }
  } else {
    return {}
  }
}

const BfExportReportReduxComponent = connect(mapStateToProps, {
  updateShowSummaryAction,
  updateIsExportingTypeAction,
  refreshSelectedRowsIdsAction,
  updateSelectedRowsIdsAction
})(BfExportReport)

export default BfWithReduxStoreConsumer(BfExportReportReduxComponent)
