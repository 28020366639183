import React from 'react'
import BfDeviceIcons from '../bfDeviceIcons/BfDeviceIcons'
import './BfCellCloudRenderer.scss'

/**
 * Cell renderer for data grid
 */
class BfCellCloudRenderer extends React.Component {
  render() {
    const { device } = this.props

    return (
      <span className='icons-container bf-icons-nofill'>
        <BfDeviceIcons device={device} />
      </span>
    )
  }
}

export default BfCellCloudRenderer
