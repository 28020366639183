/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/
import React from 'react'
import _ from '../../../services/utils/BfLodash'

import ReportFavoritesWithAction from './reports/reportFavoritesFilter/ReportFavoritesWithAction'
import InsightsContentCountFilterWithAction from './insights/insightsContentCountFilter/InsightsContentCountFilterWithAction'
import InsightsDeviceCountFilterWithAction from './insights/insightsDeviceCountFilter/InsightsDeviceCountFilterWithAction'
import InsightsSiteTypeFilterWithAction from './insights/insightsSiteTypeFilter/InsightsSiteTypeFilterWithAction'
import BfSearchWithAction from './common/bfSearch/BfSearchWithAction'
import ApOperatingSystemFilter from './autopatch/ApOperatingSystemFilter'
import ApPatchTypeFilter from './autopatch/ApPatchTypeFilter'
import ApStatusFilter from './autopatch/ApStatusFilter'
import ReportContentTypeFilter from './reports/reportContentTypeFilter/ReportContentTypeFilter'
import ReportVisibilityFilter from './reports/reportVisibilityFilter/ReportVisibilityFilter'
import ReportOwnerFilter from './reports/reportOwnerFilter/ReportOwnerFilter'
import ReportLastModifiedFilter from './reports/reportLastModifiedFilter/ReportLastModifiedFilter'
import ReportLastAccessedFilter from './reports/reportLastAccessedFilter/ReportLastAccessedFilter'
import ReportDescriptionFilter from './reports/reportDescriptionFilter/ReportDescriptionFilter'
import ReportFavoritesFilter from './reports/reportFavoritesFilter/ReportFavoritesFilter'

import InsightsContentCountFilter from './insights/insightsContentCountFilter/InsightsContentCountFilter'
import InsightsDeviceCountFilter from './insights/insightsDeviceCountFilter/InsightsDeviceCountFilter'
import InsightsSiteTypeFilter from './insights/insightsSiteTypeFilter/InsightsSiteTypeFilter'
import InsightsAttributeNameFilter from './insights/insightsAttributeNameFilter/InsightsAttributeNameFilter'
import InsightsAttributeCategoryFilter from './insights/insightsAttributeCategoryFilter/InsightsAttributeCategoryFilter'
import InsightsAttributeLastModifiedFilter from './insights/insightsAttributeLastModifiedFilter/InsightsAttributeLastModifiedFilter'

import BfDeviceTypeFilter from './framework/bfDeviceType/BfDeviceTypeFilter'
import BfApplicablePatchesFilter from './framework/bfApplicablePatchesFilter/BfApplicablePatchesFilter'
import BfApplicableDevicesFilter from './custom/bfApplicableDevicesFilter/BfApplicableDevicesFilter'
import BfDeploymentStateCountFilter from './framework/bfDeploymentStateCountFilter/BfDeploymentStateCountFilter'
import BfOperatingSystemFilter from './framework/bfOperatingSystemFilter/BfOperatingSystemFilter'
import BfManagedByFilter from './framework/bfManagedByFilter/BfManagedByFilter'
import BfDeviceGroupFilter from './framework/bfDeviceGroup/BfDeviceGroupFilter'
import BfIpAddressFilter from './framework/bfIpAddressFilter/BfIpAddressFilter'
import BfDnsNameFilter from './framework/bfDnsNameFilter/BfDnsNameFilter'
import BfSiteNameFilter from './custom/bfSiteNameFilter/BfSiteNameFilter'
import BfCategoryFilter from './custom/bfCategoryFilter/BfCategoryFilter'
import BfCreateByFilter from './custom/bfCreateByFilter/BfCreateByFilter'
import BfAgentStatusFilter from './framework/bfAgentStatusFilter/BfAgentStatusFilter'
import BfLockStatusFilter from './framework/bfLockStatusFilter/BfLockStatusFilter'
import BfCriticalPatchesFilter from './framework/bfCriticalPatchesFilter/BfCriticalPatchesFilter'
import BfCloudTagsFilter from './framework/bfCloudTagsFilter/BfCloudTagsFilter'
import BfAnalysisFilter from './framework/bfAnalysisFilter/BfAnalysisFilter'
import BfSourceFilter from './framework/bfSourceFilter/BfSourceFilter'
import BfCustomContentTypeFilter from './custom/bfCustomContentType/BfCustomContentTypeFilter'
import BfReleaseDateFilter from './custom/bfReleaseDate/BfReleaseDateFilter'
import BfContentCategoryFilter from './content/bfContentCatergoryFilter/BfContentCategoryFilter'

import BfDateRangeFilterWithAction from './common/bfBasicFilters/bfDateRangeFilter/BfDateRangeFilterWithAction'

import BfSearchFilter from './common/bfSearch/BfSearchFilter'
import BfSearchPolicyGroupsFilter from './common/bfSearch/BfSearchPolicyGroupsFilter'
import BfSimpleOSFilter from './common/bfSimpleOSFilter/BfSimpleOSFilter'
import BfSimpleOSFilterApps from './common/bfSimpleOSFilter/BfSimpleOSFilterApps'
import BfSimpleOSFilterPolicies from './common/bfSimpleOSFilter/BfSimpleOSFilterPolicies'
import BfPolicyTypes from './common/bfPolicyTypes/BfPolicyTypes'
import BfCreatorFilter from './common/bfCreatorFilter/BfCreatorFilter'
import BfGroupsTargetedFilter from './common/bfGroupsTargetedFilter/BfGroupsTargetedFilter'

import BfPublisherNameFilter from './swd/bfPublisherNameFilter/BfPublisherNameFilter'
import BfOwnerNameFilter from './swd/bfOwnerNameFilter/BfOwnerNameFilter'
import BfOSFilter from './swd/bfOSFilter/BfOSFilter'
import BfModifiedDateFilter from './swd/bfModifiedDate/BfModifiedDateFilter'

import BfCustomPropertiesColumnFilter from '../bfAddColumn/BfCustomPropertiesColumnFilter'
import BfFilterManager from './BfFilterManager'
import BfDropDownSearchFilterWithAction from './common/bfBasicFilters/BfDropDownSearchFilter/BfDropDownSearchFilterWithAction'
import BfLastSeenFilter from './framework/bfLastSeenFilter/BfLastSeenFilter'
import BfDropDownFilterWithAction from './common/bfBasicFilters/bfDropDownFilter/BfDropDownFilterWithAction'
import BfNumberAndDropDownFilterWithAction from './common/bfBasicFilters/BfNumberAndDropDownFilter/BfNumberAndDropDownFilterWithAction'
import BfCheckBoxTreeWithAction from './common/bfBasicFilters/bfCheckboxTreeFilter/BfCheckBoxTreeWithAction'
import BfNumberInputFilterWithAction from './common/bfBasicFilters/BfNumberInputFilter/BfNumberInputFilterWithAction'
import BfRecentUserFilter from './framework/bfRecentUsers/BfRecentUserFilter'
import BfPropertyFilter from './framework/bfPropertyFilter/BfPropertyFilter'

import BfPatchCategoryFilter from './patch/BfPatchCategoryFilter'
import BfSourceSeverityFilter from './patch/BfSourceSeverityFilter'
import BfPatchSoftwareSearchFilter from './patch/BfPatchSoftwareSearchFilter'
import BfPatchReleaseDateFilter from './patch/BfPatchReleaseDateFilter'
import BfPatchVulnerableDevicesFilter from './patch/BfPatchVulnerableDevicesFilter'
import BfPatchOpenActionsFilter from './patch/BfPatchOpenActionsFilter'
import BfPatchNameSearchFilter from './patch/BfPatchNameSearchFilter'
import BfPatchContentIDSearchFilter from './patch/BfPatchContentIDSearchFilter'
import BfPatchCVEIDSearchFilter from './patch/BfPatchCVEIDSearchFilter'
import BfPatchSiteIDFilter from './patch/BfPatchSiteIDFilter'

import BfReduxStateConstants from '../../common/BfReduxStateConstants'

export default {
  apOperatingSystemHTML: <BfCheckBoxTreeWithAction filterName={`${ApOperatingSystemFilter.NAMESPACE}/${ApOperatingSystemFilter.NAME}`} />,
  apPatchTypeHTML: <BfDropDownFilterWithAction filterName={ApPatchTypeFilter.NAME} />,
  apStatusHTML: <BfDropDownFilterWithAction filterName={ApStatusFilter.NAME} />,
  reportContentTypeHTML: <BfDropDownFilterWithAction filterName={ReportContentTypeFilter.NAME} />,
  reportVisibilityHTML: <BfDropDownFilterWithAction filterName={ReportVisibilityFilter.NAME} />,
  reportOwnerHTML: <BfDropDownSearchFilterWithAction filterName={ReportOwnerFilter.NAME} />,
  reportLastModifiedHTML: <BfDateRangeFilterWithAction filterName={ReportLastModifiedFilter.NAME} />,
  reportLastAccessedHTML: <BfDateRangeFilterWithAction filterName={ReportLastAccessedFilter.NAME} />,
  reportDescriptionHTML: <BfSearchWithAction type='input' filterName={ReportDescriptionFilter.NAME} />,
  reportFavoritesHTML: <ReportFavoritesWithAction filterName={ReportFavoritesFilter.NAME} />,
  searchHTML: <BfSearchWithAction type='input' filterName={BfSearchFilter.NAME} />,
  searchPolicyGroupsHTML: <BfSearchWithAction type='input' filterName={BfSearchPolicyGroupsFilter.NAME} />,
  applicableDevicesHTML: <BfNumberAndDropDownFilterWithAction filterName={BfApplicableDevicesFilter.NAME} />,
  applicablePatchesHTML: <BfNumberInputFilterWithAction filterName={BfApplicablePatchesFilter.NAME} />,
  customContentTypeHTML: <BfDropDownFilterWithAction filterName={`${BfCustomContentTypeFilter.NAMESPACE}/${BfCustomContentTypeFilter.NAME}`} />,
  releaseDateTypeHTML: <BfDateRangeFilterWithAction filterName={BfReleaseDateFilter.NAME} />,
  modifiedDateTypeHTML: <BfDateRangeFilterWithAction filterName={BfModifiedDateFilter.NAME} />,
  deploymentStateHTML: <BfNumberInputFilterWithAction filterName={BfDeploymentStateCountFilter.NAME} />,
  deviceTypeHTML: <BfDropDownFilterWithAction filterName={BfDeviceTypeFilter.NAME} />,
  operatingSystemHTML: <BfCheckBoxTreeWithAction filterName={BfOperatingSystemFilter.NAME} />,
  simpleOSHTML: <BfCheckBoxTreeWithAction filterName={`${BfSimpleOSFilter.NAMESPACE}/${BfSimpleOSFilter.NAME}`} />,
  simpleOSAppsHTML: <BfCheckBoxTreeWithAction filterName={BfSimpleOSFilterApps.NAME} />,
  simpleOSPoliciesHTML: <BfCheckBoxTreeWithAction filterName={BfSimpleOSFilterPolicies.NAME} />,
  policyTypesHTML: <BfCheckBoxTreeWithAction filterName={BfPolicyTypes.NAME} />,
  creatorHTML: <BfCheckBoxTreeWithAction filterName={BfCreatorFilter.NAME} />,
  groupsTargetedHTML: <BfDropDownFilterWithAction filterName={BfGroupsTargetedFilter.NAME} />,
  osFilterSwdHTML: <BfDropDownFilterWithAction filterName={`${BfOSFilter.NAMESPACE}/${BfOSFilter.NAME}`} />,
  deviceGroupHTML: <BfDropDownSearchFilterWithAction filterName={BfDeviceGroupFilter.NAME} />,
  recentUsersHTML: <BfDropDownSearchFilterWithAction filterName={BfRecentUserFilter.NAME} />,
  ipAddressHTML: <BfDropDownSearchFilterWithAction filterName={BfIpAddressFilter.NAME} />,
  dnsNameHTML: <BfDropDownSearchFilterWithAction filterName={BfDnsNameFilter.NAME} />,
  agentStatusHTML: <BfDropDownFilterWithAction filterName={BfAgentStatusFilter.NAME} />,
  lockStatusHTML: <BfDropDownFilterWithAction filterName={BfLockStatusFilter.NAME} />,
  criticalPatchesHTML: <BfDropDownFilterWithAction filterName={BfCriticalPatchesFilter.NAME} />,
  cloudTagsHTML: <BfDropDownSearchFilterWithAction filterName={BfCloudTagsFilter.NAME} />,
  agentDisplayHTML: <BfCheckBoxTreeWithAction filterName={BfManagedByFilter.NAME} />,
  publisherNameHTML: <BfDropDownSearchFilterWithAction filterName={BfPublisherNameFilter.NAME} />,
  ownerNameHTML: <BfDropDownSearchFilterWithAction filterName={`${BfOwnerNameFilter.NAMESPACE}/${BfOwnerNameFilter.NAME}`} />,
  propertyNameHTML: <BfSearchWithAction type='input' filterName={BfPropertyFilter.NAME} />,
  analysisNameHTML: <BfDropDownSearchFilterWithAction filterName={BfAnalysisFilter.NAME} />,
  sourceNameHTML: <BfDropDownSearchFilterWithAction filterName={BfSourceFilter.NAME} />,
  dateRangeHTML: <BfDateRangeFilterWithAction filterName={BfLastSeenFilter.NAME} />,
  siteNameHTML: <BfDropDownSearchFilterWithAction filterName={BfSiteNameFilter.NAME} />,
  categoryHTML: <BfDropDownSearchFilterWithAction filterName={`${BfCategoryFilter.NAMESPACE}/${BfCategoryFilter.NAME}`} />,
  contentCategoryHTML: (header) => {
    const siteID = _.pick(header, ['siteID'])
    return <BfDropDownSearchFilterWithAction filterName={`${BfContentCategoryFilter.NAMESPACE}/${BfContentCategoryFilter.NAME}`} args={siteID} />
  },
  createByHTML: <BfDropDownSearchFilterWithAction filterName={`${BfCreateByFilter.NAMESPACE}/${BfCreateByFilter.NAME}`} />,
  customColumnHTML: (object) => {
    const contentKey = _.pick(object, ['siteID', 'contentID', 'propertyID'])
    return <BfSearchWithAction type='input' filterName={BfCustomPropertiesColumnFilter.NAME} args={contentKey} />
  },
  insightsContentCountHTML: <InsightsContentCountFilterWithAction />,
  insightsDeviceCountHTML: <InsightsDeviceCountFilterWithAction />,
  insightsSiteTypeHTML: <InsightsSiteTypeFilterWithAction />,
  patchCategoryHTML: <BfDropDownFilterWithAction filterName={BfPatchCategoryFilter.NAME} />,
  patchSoftwareSearchHTML: (
    <BfSearchWithAction type='input' filterName={`${BfPatchSoftwareSearchFilter.NAMESPACE}/${BfPatchSoftwareSearchFilter.NAME}`} />
  ),
  sourceSeverityHTML: <BfDropDownFilterWithAction filterName={BfSourceSeverityFilter.NAME} />,
  patchVulnerableDevicesHTML: <BfNumberInputFilterWithAction filterName={BfPatchVulnerableDevicesFilter.NAME} />,
  patchOpenActionsHTML: <BfNumberInputFilterWithAction filterName={BfPatchOpenActionsFilter.NAME} />,
  patchReleaseDateHTML: <BfDateRangeFilterWithAction filterName={BfPatchReleaseDateFilter.NAME} />,
  patchNameSearchHTML: <BfSearchWithAction type='input' filterName={BfPatchNameSearchFilter.NAME} />,
  patchContentIDSearchHTML: <BfNumberInputFilterWithAction filterName={BfPatchContentIDSearchFilter.NAME} />,
  patchCVEIDSearchHTML: <BfSearchWithAction type='input' filterName={BfPatchCVEIDSearchFilter.NAME} />,
  patchSiteIDsSearchHTML: <BfDropDownSearchFilterWithAction filterName={BfPatchSiteIDFilter.NAME} />,
  insightsAttributeHTML: <BfDropDownSearchFilterWithAction filterName={InsightsAttributeNameFilter.NAME} />,
  insightsCategoryHTML: <BfDropDownSearchFilterWithAction filterName={InsightsAttributeCategoryFilter.NAME} />,
  insightsLastModifiedHTML: <BfDateRangeFilterWithAction filterName={InsightsAttributeLastModifiedFilter.NAME} />
}

export const HTMLFiltersByNames = {
  apOperatingSystemHTML: {
    instance: () => new ApOperatingSystemFilter(),
    instanceFromUrl: (urlHandler) => {
      const bfFilterManager = _getFilterManager(urlHandler)
      return bfFilterManager.getApOperatingSystemFilter()
    },
    filterPath: ApOperatingSystemFilter.PATH,
    namespace: BfReduxStateConstants.AP_POLICIES
  },
  apPatchTypeHTML: {
    instance: () => new ApPatchTypeFilter(),
    instanceFromUrl: (urlHandler) => {
      const bfFilterManager = _getFilterManager(urlHandler)
      return bfFilterManager.getApPatchTypeFilter()
    },
    filterPath: ApPatchTypeFilter.PATH
  },
  apStatusHTML: {
    instance: () => new ApStatusFilter(),
    instanceFromUrl: (urlHandler) => {
      const bfFilterManager = _getFilterManager(urlHandler)
      return bfFilterManager.getApStatusFilter()
    },
    filterPath: ApStatusFilter.PATH
  },
  reportContentTypeHTML: {
    instance: () => new ReportContentTypeFilter(),
    instanceFromUrl: (urlHandler) => {
      const bfFilterManager = _getFilterManager(urlHandler)
      return bfFilterManager.getFilterFromURLByName(ReportContentTypeFilter.NAME)
    },
    filterPath: ReportContentTypeFilter.PATH
  },
  reportVisibilityHTML: {
    instance: () => new ReportVisibilityFilter(),
    instanceFromUrl: (urlHandler) => {
      const bfFilterManager = _getFilterManager(urlHandler)
      return bfFilterManager.getFilterFromURLByName(ReportVisibilityFilter.NAME)
    },
    filterPath: ReportVisibilityFilter.PATH
  },
  reportOwnerHTML: {
    instance: () => new ReportOwnerFilter(),
    instanceFromUrl: (urlHandler) => {
      const bfFilterManager = _getFilterManager(urlHandler)
      return bfFilterManager.getFilterFromURLByName(ReportOwnerFilter.NAME)
    },
    filterPath: ReportOwnerFilter.PATH
  },
  reportLastModifiedHTML: {
    instance: () => new ReportLastModifiedFilter(),
    instanceFromUrl: (urlHandler) => {
      const bfFilterManager = _getFilterManager(urlHandler)
      return bfFilterManager.getFilterFromURLByName(ReportLastModifiedFilter.NAME)
    },
    filterPath: ReportLastModifiedFilter.PATH
  },
  reportLastAccessedHTML: {
    instance: () => new ReportLastAccessedFilter(),
    instanceFromUrl: (urlHandler) => {
      const bfFilterManager = _getFilterManager(urlHandler)
      return bfFilterManager.getFilterFromURLByName(ReportLastAccessedFilter.NAME)
    },
    filterPath: ReportLastAccessedFilter.PATH
  },
  reportDescriptionHTML: {
    instance: () => new ReportDescriptionFilter(),
    instanceFromUrl: (urlHandler) => {
      const bfFilterManager = _getFilterManager(urlHandler)
      return bfFilterManager.getFilterFromURLByName(ReportDescriptionFilter.NAME)
    },
    filterPath: ReportDescriptionFilter.PATH
  },
  reportFavoritesHTML: {
    instance: () => new ReportFavoritesFilter(),
    instanceFromUrl: (urlHandler) => {
      const bfFilterManager = _getFilterManager(urlHandler)
      return bfFilterManager.getFilterFromURLByName(ReportFavoritesFilter.NAME)
    },
    filterPath: ReportFavoritesFilter.PATH
  },
  searchHTML: {
    instance: () => new BfSearchFilter(),
    instanceFromUrl: (urlHandler) => {
      const bfFilterManager = _getFilterManager(urlHandler)
      return bfFilterManager.getFilterFromURLByName(BfSearchFilter.NAME)
    },
    filterPath: BfSearchFilter.PATH
  },
  searchPolicyGroupsHTML: {
    instance: () => new BfSearchPolicyGroupsFilter(),
    instanceFromUrl: (urlHandler) => {
      const bfFilterManager = _getFilterManager(urlHandler)
      return bfFilterManager.getFilterFromURLByName(BfSearchPolicyGroupsFilter.NAME)
    },
    filterPath: BfSearchPolicyGroupsFilter.PATH
  },
  applicablePatchesHTML: {
    instance: () => new BfApplicablePatchesFilter(),
    instanceFromUrl: (urlHandler) => {
      const bfFilterManager = _getFilterManager(urlHandler)
      return bfFilterManager.getFilterFromURLByName(BfApplicablePatchesFilter.NAME)
    },
    filterPath: BfApplicablePatchesFilter.PATH
  },
  customContentTypeHTML: {
    instance: () => new BfCustomContentTypeFilter(),
    instanceFromUrl: (urlHandler) => {
      const bfFilterManager = _getFilterManager(urlHandler)
      return bfFilterManager.getFilterFromURLByName(BfCustomContentTypeFilter.NAME)
    },
    filterPath: BfCustomContentTypeFilter.PATH
  },
  releaseDateTypeHTML: {
    instance: () => new BfReleaseDateFilter(),
    instanceFromUrl: (urlHandler) => {
      const bfFilterManager = _getFilterManager(urlHandler)
      return bfFilterManager.getFilterFromURLByName(BfReleaseDateFilter.NAME)
    },
    filterPath: BfReleaseDateFilter.PATH
  },
  modifiedDateTypeHTML: {
    instance: () => new BfModifiedDateFilter(),
    instanceFromUrl: (urlHandler) => {
      const bfFilterManager = _getFilterManager(urlHandler)
      return bfFilterManager.getFilterFromURLByName(BfModifiedDateFilter.NAME)
    },
    filterPath: BfModifiedDateFilter.PATH
  },
  deploymentStateHTML: {
    instance: () => new BfDeploymentStateCountFilter(),
    instanceFromUrl: (urlHandler) => {
      const bfFilterManager = _getFilterManager(urlHandler)
      return bfFilterManager.getFilterFromURLByName(BfDeploymentStateCountFilter.NAME)
    },
    filterPath: BfDeploymentStateCountFilter.PATH
  },
  deviceTypeHTML: {
    instance: () => new BfDeviceTypeFilter(),
    instanceFromUrl: (urlHandler) => {
      const bfFilterManager = _getFilterManager(urlHandler)
      return bfFilterManager.getFilterFromURLByName(BfDeviceTypeFilter.NAME)
    },
    filterPath: BfDeviceTypeFilter.PATH
  },
  operatingSystemHTML: {
    instance: () => new BfOperatingSystemFilter(),
    instanceFromUrl: (urlHandler) => {
      const bfFilterManager = _getFilterManager(urlHandler)
      return bfFilterManager.getFilterFromURLByName(BfOperatingSystemFilter.NAME)
    },
    filterPath: BfOperatingSystemFilter.PATH
  },
  simpleOSHTML: {
    instance: () => new BfSimpleOSFilter(),
    instanceFromUrl: (urlHandler) => {
      const bfFilterManager = _getFilterManager(urlHandler)
      return bfFilterManager.getFilterFromURLByName(`${BfSimpleOSFilter.NAMESPACE}/${BfSimpleOSFilter.NAME}`)
    },
    filterPath: BfSimpleOSFilter.PATH
  },
  simpleOSAppsHTML: {
    instance: () => new BfSimpleOSFilterApps(),
    instanceFromUrl: (urlHandler) => {
      const bfFilterManager = _getFilterManager(urlHandler)
      return bfFilterManager.getFilterFromURLByName(BfSimpleOSFilterApps.NAME)
    },
    filterPath: BfSimpleOSFilterApps.PATH
  },
  simpleOSPoliciesHTML: {
    instance: () => new BfSimpleOSFilterPolicies(),
    instanceFromUrl: (urlHandler) => {
      const bfFilterManager = _getFilterManager(urlHandler)
      return bfFilterManager.getFilterFromURLByName(BfSimpleOSFilterPolicies.NAME)
    },
    filterPath: BfSimpleOSFilterPolicies.PATH
  },
  policyTypesHTML: {
    instance: () => new BfPolicyTypes(),
    instanceFromUrl: (urlHandler) => {
      const bfFilterManager = _getFilterManager(urlHandler)
      return bfFilterManager.getFilterFromURLByName(BfPolicyTypes.NAME)
    },
    filterPath: BfPolicyTypes.PATH
  },
  creatorHTML: {
    instance: () => new BfCreatorFilter(),
    instanceFromUrl: (urlHandler) => {
      const bfFilterManager = _getFilterManager(urlHandler)
      return bfFilterManager.getFilterFromURLByName(BfCreatorFilter.NAME)
    },
    filterPath: BfCreatorFilter.PATH
  },
  groupsTargetedHTML: {
    instance: () => new BfGroupsTargetedFilter(),
    instanceFromUrl: (urlHandler) => {
      const bfFilterManager = _getFilterManager(urlHandler)
      return bfFilterManager.getFilterFromURLByName(BfGroupsTargetedFilter.NAME)
    },
    filterPath: BfGroupsTargetedFilter.PATH
  },
  deviceGroupHTML: {
    instance: () => new BfDeviceGroupFilter(),
    instanceFromUrl: (urlHandler) => {
      const bfFilterManager = _getFilterManager(urlHandler)
      return bfFilterManager.getFilterFromURLByName(BfDeviceGroupFilter.NAME)
    },
    filterPath: BfDeviceGroupFilter.PATH
  },
  recentUsersHTML: {
    instance: () => new BfRecentUserFilter(),
    instanceFromUrl: (urlHandler) => {
      const bfFilterManager = _getFilterManager(urlHandler)
      return bfFilterManager.getFilterFromURLByName(BfRecentUserFilter.NAME)
    },
    filterPath: BfRecentUserFilter.PATH
  },
  ipAddressHTML: {
    instance: () => new BfIpAddressFilter(),
    instanceFromUrl: (urlHandler) => {
      const bfFilterManager = _getFilterManager(urlHandler)
      return bfFilterManager.getFilterFromURLByName(BfIpAddressFilter.NAME)
    },
    filterPath: BfIpAddressFilter.PATH
  },
  dnsNameHTML: {
    instance: () => new BfDnsNameFilter(),
    instanceFromUrl: (urlHandler) => {
      const bfFilterManager = _getFilterManager(urlHandler)
      return bfFilterManager.getFilterFromURLByName(BfDnsNameFilter.NAME)
    },
    filterPath: BfDnsNameFilter.PATH
  },
  siteNameHTML: {
    instance: () => new BfSiteNameFilter(),
    instanceFromUrl: (urlHandler) => {
      const bfFilterManager = _getFilterManager(urlHandler)
      return bfFilterManager.getSiteNameFilter()
    },
    filterPath: BfSiteNameFilter.PATH
  },
  categoryHTML: {
    instance: () => new BfCategoryFilter(),
    instanceFromUrl: (urlHandler) => {
      const bfFilterManager = _getFilterManager(urlHandler)
      return bfFilterManager.getCategoryFilter()
    },
    filterPath: BfCategoryFilter.PATH
  },
  contentCategoryHTML: {
    instance: (header) => {
      const siteID = _.pick(header, ['siteID'])
      return new BfContentCategoryFilter(siteID)
    },
    instanceFromUrl: (urlHandler) => {
      const bfFilterManager = _getFilterManager(urlHandler)
      return bfFilterManager.getFilterFromURLByName(`${BfContentCategoryFilter.NAMESPACE}/${BfContentCategoryFilter.NAME}`)
    },
    filterPath: BfContentCategoryFilter.PATH,
    namespace: BfReduxStateConstants.CONTENT_DATA_GRID
  },

  createByHTML: {
    instance: () => new BfCreateByFilter(),
    instanceFromUrl: (urlHandler) => {
      const bfFilterManager = _getFilterManager(urlHandler)
      return bfFilterManager.getCreateByFilter()
    },
    filterPath: BfCreateByFilter.PATH
  },
  publisherNameHTML: {
    instance: () => new BfPublisherNameFilter(),
    instanceFromUrl: (urlHandler) => {
      const bfFilterManager = _getFilterManager(urlHandler)
      return bfFilterManager.getFilterFromURLByName(BfPublisherNameFilter.NAME)
    },
    filterPath: BfPublisherNameFilter.PATH
  },
  ownerNameHTML: {
    instance: () => new BfOwnerNameFilter(),
    instanceFromUrl: (urlHandler) => {
      const bfFilterManager = _getFilterManager(urlHandler)
      return bfFilterManager.getFilterFromURLByName(BfOwnerNameFilter.NAME)
    },
    filterPath: BfOwnerNameFilter.PATH
  },
  agentStatusHTML: {
    instance: () => new BfAgentStatusFilter(),
    instanceFromUrl: (urlHandler) => {
      const bfFilterManager = _getFilterManager(urlHandler)
      return bfFilterManager.getFilterFromURLByName(BfAgentStatusFilter.NAME)
    },
    filterPath: BfAgentStatusFilter.PATH
  },
  lockStatusHTML: {
    instance: () => new BfLockStatusFilter(),
    instanceFromUrl: (urlHandler) => {
      const bfFilterManager = _getFilterManager(urlHandler)
      return bfFilterManager.getFilterFromURLByName(BfLockStatusFilter.NAME)
    },
    filterPath: BfLockStatusFilter.PATH
  },
  osFilterSwdHTML: {
    instance: () => new BfOSFilter(),
    instanceFromUrl: (urlHandler) => {
      const bfFilterManager = _getFilterManager(urlHandler)
      return bfFilterManager.getFilterFromURLByName(BfOSFilter.NAME)
    },
    filterPath: BfOSFilter.PATH
  },
  criticalPatchesHTML: {
    instance: () => new BfCriticalPatchesFilter(),
    instanceFromUrl: (urlHandler) => {
      const bfFilterManager = _getFilterManager(urlHandler)
      return bfFilterManager.getFilterFromURLByName(BfCriticalPatchesFilter.NAME)
    },
    filterPath: BfCriticalPatchesFilter.PATH
  },
  cloudTagsHTML: {
    instance: () => new BfCloudTagsFilter(),
    instanceFromUrl: (urlHandler) => {
      const bfFilterManager = _getFilterManager(urlHandler)
      return bfFilterManager.getFilterFromURLByName(BfCloudTagsFilter.NAME)
    },
    filterPath: BfCloudTagsFilter.PATH
  },
  agentDisplayHTML: {
    instance: () => new BfManagedByFilter(),
    instanceFromUrl: (urlHandler) => {
      const bfFilterManager = _getFilterManager(urlHandler)
      return bfFilterManager.getFilterFromURLByName(BfManagedByFilter.NAME)
    },
    filterPath: BfManagedByFilter.PATH
  },
  analysisNameHTML: {
    instance: () => new BfAnalysisFilter(),
    instanceFromUrl: (urlHandler) => {
      const bfFilterManager = _getFilterManager(urlHandler)
      return bfFilterManager.getFilterFromURLByName(BfAnalysisFilter.NAME)
    },
    filterPath: BfAnalysisFilter.PATH
  },
  sourceNameHTML: {
    instance: () => new BfSourceFilter(),
    instanceFromUrl: (urlHandler) => {
      const bfFilterManager = _getFilterManager(urlHandler)
      return bfFilterManager.getFilterFromURLByName(BfSourceFilter.NAME)
    },
    filterPath: BfSourceFilter.PATH
  },
  dateRangeHTML: {
    instance: () => new BfLastSeenFilter(),
    instanceFromUrl: (urlHandler) => {
      const bfFilterManager = _getFilterManager(urlHandler)
      return bfFilterManager.getFilterFromURLByName(BfLastSeenFilter.NAME)
    },
    filterPath: BfLastSeenFilter.PATH
  },
  customColumnHTML: {
    instance: (object) => {
      const contentKey = _.pick(object, ['siteID', 'contentID', 'propertyID'])
      return new BfCustomPropertiesColumnFilter(contentKey)
    },
    instanceFromUrl: (urlHandler) => {
      const bfFilterManager = _getFilterManager(urlHandler)
      return bfFilterManager.getCustomPropertiesFilter()
    },

    filterPath: BfCustomPropertiesColumnFilter.PATH
  },
  insightsContentCountHTML: {
    instance: () => new InsightsContentCountFilter(),
    instanceFromUrl: (urlHandler) => {
      const bfFilterManager = _getFilterManager(urlHandler)
      return bfFilterManager.getInsightsContentCountFilter()
    },
    filterPath: InsightsContentCountFilter.PATH
  },
  insightsDeviceCountHTML: {
    instance: () => new InsightsDeviceCountFilter(),
    instanceFromUrl: (urlHandler) => {
      const bfFilterManager = _getFilterManager(urlHandler)
      return bfFilterManager.getInsightsDeviceCountFilter()
    },
    filterPath: InsightsDeviceCountFilter.PATH
  },
  insightsSiteTypeHTML: {
    instance: () => new InsightsSiteTypeFilter(),
    instanceFromUrl: (urlHandler) => {
      const bfFilterManager = _getFilterManager(urlHandler)
      return bfFilterManager.getInsightsSiteTypeFilter()
    },
    filterPath: InsightsSiteTypeFilter.PATH
  },
  patchCategoryHTML: {
    instance: () => new BfPatchCategoryFilter(),
    instanceFromUrl: (urlHandler) => {
      const bfFilterManager = _getFilterManager(urlHandler)
      return bfFilterManager.getFilterFromURLByName(BfPatchCategoryFilter.NAME)
    },
    filterPath: BfPatchCategoryFilter.PATH
  },
  patchSoftwareSearchHTML: {
    instance: () => new BfPatchSoftwareSearchFilter(),
    instanceFromUrl: (urlHandler) => {
      const bfFilterManager = _getFilterManager(urlHandler)
      return bfFilterManager.getFilterFromURLByName(`${BfPatchSoftwareSearchFilter.NAMESPACE}/${BfPatchSoftwareSearchFilter.NAME}`)
    },
    filterPath: BfPatchSoftwareSearchFilter.PATH
  },
  sourceSeverityHTML: {
    instance: () => new BfSourceSeverityFilter(),
    instanceFromUrl: (urlHandler) => {
      const bfFilterManager = _getFilterManager(urlHandler)
      return bfFilterManager.getFilterFromURLByName(BfSourceSeverityFilter.NAME)
    },
    filterPath: BfSourceSeverityFilter.PATH
  },
  patchVulnerableDevicesHTML: {
    instance: () => new BfPatchVulnerableDevicesFilter(),
    instanceFromUrl: (urlHandler) => {
      const bfFilterManager = _getFilterManager(urlHandler)
      return bfFilterManager.getFilterFromURLByName(BfPatchVulnerableDevicesFilter.NAME)
    },
    filterPath: BfPatchVulnerableDevicesFilter.PATH
  },
  patchOpenActionsHTML: {
    instance: () => new BfPatchOpenActionsFilter(),
    instanceFromUrl: (urlHandler) => {
      const bfFilterManager = _getFilterManager(urlHandler)
      return bfFilterManager.getFilterFromURLByName(BfPatchOpenActionsFilter.NAME)
    },
    filterPath: BfPatchOpenActionsFilter.PATH
  },
  patchReleaseDateHTML: {
    instance: () => new BfPatchReleaseDateFilter(),
    instanceFromUrl: (urlHandler) => {
      const bfFilterManager = _getFilterManager(urlHandler)
      return bfFilterManager.getFilterFromURLByName(BfPatchReleaseDateFilter.NAME)
    },
    filterPath: BfPatchReleaseDateFilter.PATH
  },
  patchNameSearchHTML: {
    instance: () => new BfPatchNameSearchFilter(),
    instanceFromUrl: (urlHandler) => {
      const bfFilterManager = _getFilterManager(urlHandler)
      return bfFilterManager.getFilterFromURLByName(BfPatchNameSearchFilter.NAME)
    },
    filterPath: BfPatchNameSearchFilter.PATH
  },
  patchContentIDSearchHTML: {
    instance: () => new BfPatchContentIDSearchFilter(),
    instanceFromUrl: (urlHandler) => {
      const bfFilterManager = _getFilterManager(urlHandler)
      return bfFilterManager.getFilterFromURLByName(BfPatchContentIDSearchFilter.NAME)
    },
    filterPath: BfPatchContentIDSearchFilter.PATH
  },
  patchCVEIDSearchHTML: {
    instance: () => new BfPatchCVEIDSearchFilter(),
    instanceFromUrl: (urlHandler) => {
      const bfFilterManager = _getFilterManager(urlHandler)
      return bfFilterManager.getFilterFromURLByName(BfPatchCVEIDSearchFilter.NAME)
    },
    filterPath: BfPatchCVEIDSearchFilter.PATH
  },
  patchSiteIDsSearchHTML: {
    instance: () => new BfPatchSiteIDFilter(),
    instanceFromUrl: (urlHandler) => {
      const bfFilterManager = _getFilterManager(urlHandler)
      return bfFilterManager.getFilterFromURLByName(BfPatchSiteIDFilter.NAME)
    },
    filterPath: BfPatchSiteIDFilter.PATH
  }
}

const _getFilterManager = (urlHandler) => {
  const bfFilterManager = new BfFilterManager()
  bfFilterManager.setUrlHandler(urlHandler)
  return bfFilterManager
}

export const getFilterFromFilterPath = (filterPath, namespace) => {
  let filterTypeHTML
  const filterObject = _.find(HTMLFiltersByNames, (filterObj, key) => {
    if (
      ((_.isDefined(filterObj.namespace) && namespace === filterObj.namespace) || _.isUndefined(filterObj.namespace)) &&
      filterObj.filterPath === filterPath
    ) {
      filterTypeHTML = key
      return true
    }
  })

  return {
    filterTypeHTML: filterTypeHTML,
    filterObject: _.isDefined(filterObject) ? filterObject : null
  }
}
