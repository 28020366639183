/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import { DEFAULT_ITEMS_PER_PAGE, DEFAULT_OFFSET } from '../../../filters/BfFiltersConstants'
import BfBasicFilter from '../../BfBasicFilter'

class BfPaginationFilter extends BfBasicFilter {
  static NAME = 'pagination'
  static PATH = `${BfPaginationFilter.NAME}`

  constructor(itemsPerPage = DEFAULT_ITEMS_PER_PAGE, offset = DEFAULT_OFFSET) {
    super(BfPaginationFilter.PATH, BfPaginationFilter.NAME)
    this.pagination = { page: { itemsPerPage: itemsPerPage, offset: offset } }
  }

  get = () => {
    return { pagination: this.pagination }
  }

  buildFromURLHandler = (urlHandler, consumeUrl = false) => {
    this.pagination = this._buildFromURLHandler(urlHandler, consumeUrl, this.pagination)
  }

  getItemsPerPage = () => {
    return this.pagination.page.itemsPerPage
  }

  getOffset = () => {
    return this.pagination.page.offset
  }

  setItemsPerPage = (itemsPerPage) => {
    this.resetOffset()
    this.pagination.page.itemsPerPage = itemsPerPage
  }

  setOffset = (offset) => {
    this.pagination.page.offset = offset
  }

  resetOffset = () => {
    this.pagination.page.offset = 0
  }

  setPageNumber = (pageNumber) => {
    this.pagination.page.offset = pageNumber * this.pagination.page.itemsPerPage
  }
}

export default BfPaginationFilter
