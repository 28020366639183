import React from 'react'
import { Field } from 'formik'
import PropTypes from 'prop-types'
import './BfForm.scss'

function BfCheckbox(props) {
  const { name, label, disabled, onChange, ...rest } = props
  return (
    <div className='hcl-form-group blue_active_blue_light bfFitContent'>
      <Field name={name} {...rest}>
        {({ field, form }) => (
          <div className='hcl-checkbox-item'>
            <input
              className='hcl-checkbox'
              type='checkbox'
              checked={field.value}
              id={name}
              disabled={disabled}
              readOnly
              onClick={() => {
                onChange && onChange(!field.value)
                if (field.value) {
                  form.setFieldValue(name, false)
                } else {
                  form.setFieldValue(name, true)
                }
              }}
            />
            <label className='hcl-checkbox-label bfTextAlignLeft' htmlFor={name}>
              {label}
            </label>
          </div>
        )}
      </Field>
    </div>
  )
}

BfCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func
}

export default BfCheckbox
