/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/
import BfDropDownBasicFilter from '../../common/bfBasicFilters/bfDropDownFilter/BfDropDownBasicFilter'
import { bfTranslate } from '../../../../../services/i18n/bfI18Utils'

class ReportContentTypeFilter extends BfDropDownBasicFilter {
  static NAME = 'contentType'
  static PATH = `filter.${ReportContentTypeFilter.NAME}`
  constructor(data = {}) {
    super(ReportContentTypeFilter.PATH, ReportContentTypeFilter.NAME, data)
  }

  getItems = () => {
    const t = this.getT()

    const items = {
      values: [
        { id: 'deployment', text: bfTranslate(t, 'deployments') },
        { id: 'device', text: bfTranslate(t, 'devices') },
        { id: 'patch', text: bfTranslate(t, 'patch') }
      ]
    }

    return items.values
  }
}

export default ReportContentTypeFilter
