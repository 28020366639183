/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/
import React, { Component } from 'react'
import { Provider as ReduxProvider, ReactReduxContext } from 'react-redux'
import { bfCreateStore } from './BfReducerManager'
/* import logConsole from 'loglevel' */

export class BfReduxStoreProvider extends Component {
  constructor(props) {
    super(props)
    this.bfReduxStore = bfCreateStore()
  }

  render() {
    return <ReduxProvider store={this.bfReduxStore}>{this.props.children}</ReduxProvider>
  }
}

export const BfWithReduxStoreConsumer = (WrapperComponent) => {
  return (props) => {
    return (
      <ReactReduxContext.Consumer>
        {({ store }) => {
          return <WrapperComponent store={store} {...props} />
        }}
      </ReactReduxContext.Consumer>
    )
  }
}
