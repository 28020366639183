/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { sendMessageAction, sendDetailedMessageAction } from './BfReduxMessageActions'

const BfWithMessageProvider = (WrappedComponent) => {
  class BfWithMessageProviderConnected extends Component {
    sendMessageToShowModal = (caption, title, message) => {
      this.props.sendDetailedMessageAction(caption, title, message, 'modal')
    }

    render() {
      const { sendMessageAction, sendDetailedMessageAction, ...rest } = this.props
      return (
        <WrappedComponent
          sendDetailedMessage={sendDetailedMessageAction}
          sendMessage={sendMessageAction}
          sendMessageToShowModal={this.sendMessageToShowModal}
          {...rest}
        />
      )
    }
  }
  return connect(null, {
    sendMessageAction,
    sendDetailedMessageAction
  })(BfWithMessageProviderConnected)
}

export default BfWithMessageProvider
