/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import React, { Component } from 'react'
import BfCellWithTooltipRenderer from './BfCellWithTooltipRenderer'
import './BfLinkWithTooltip.scss'

class BfLinkWithTooltip extends Component {
  render() {
    const { href, className, value, toolTipValue, noSplit, alignRight } = this.props
    const conditionalProps = noSplit ? { noSplit: true } : {}

    return (
      <div className={`bf-link-with-tooltip hcl-flex ${className} ${alignRight ? 'bf-row-reverse' : ''}`}>
        <BfCellWithTooltipRenderer textTooltip={toolTipValue} {...conditionalProps}>
          <div className='bf-ellipsis bf-link-wrapper'>
            <a href={href}>{value}</a>
          </div>
        </BfCellWithTooltipRenderer>
      </div>
    )
  }
}
export default BfLinkWithTooltip
