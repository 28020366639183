/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import React from 'react'
import { Field } from 'formik'
import PropTypes from 'prop-types'
import './BfForm.scss'
import _ from '../../services/utils/BfLodash'
import { Dropdown } from '@patron/patron-react/dropdown'

function BfDropdown(props) {
  const {
    attachElementToBody = false,
    containerClass = '',
    defaultText = '',
    disabled = false,
    dropdownType = 'singleselect', // singleselect or multiselect
    id,
    items, // ex: [{id: 'option-1', text: 'Option 1'}]
    label = '',
    labelClass = 'hcl-label',
    name,
    onChange,
    required = false,
    selectedId,
    scrollListener = false,
    subLabel = '',
    type = 'bottom', // top or bottom
    ...rest
  } = props

  return (
    <div className='hcl-form-group'>
      <Field name={name} {...rest}>
        {({ field, form, meta }) => (
          <div className={containerClass}>
            {label && (
              <div className='bfInputLabel'>
                <label htmlFor={id || name} className={labelClass}>
                  {label} {required ? <i className='bfRequired'> *</i> : ''}
                </label>
              </div>
            )}
            {subLabel && <div className='hcl-helper-text hcl-text-left'>{subLabel}</div>}
            {meta.touched && _.isNotEmpty(meta.error) && <div className='hcl-error-msg hcl-text-left'>{meta.error}</div>}
            <Dropdown
              attachElementToBody={attachElementToBody}
              disabled={disabled}
              dropdownType={dropdownType}
              items={items}
              id={id || name}
              label={defaultText}
              onChange={async (option) => {
                form.setFieldTouched(name, true) // for validation purposes
                await form.setFieldValue(name, option.id)
                onChange && onChange(option)
              }}
              scrollListner={scrollListener}
              selectedItem={field.value}
              type={type}
            />
          </div>
        )}
      </Field>
    </div>
  )
}

BfDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  attachElementToBody: PropTypes.bool,
  defaultText: PropTypes.string,
  disabled: PropTypes.bool,
  dropdownType: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  labelClass: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  scrollListener: PropTypes.bool,
  selectedId: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  type: PropTypes.string
}

export default BfDropdown
