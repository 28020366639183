/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/
import React from 'react'
import './BfButtonCountTag.scss'

import { Tag } from '@patron/patron-react/tag'

const BfButtonCountTag = (props) => {
  const { itemsCount, ...rest } = props
  return (
    <Tag {...rest} className='bf-tag-space'>
      {itemsCount.toString()}
    </Tag>
  )
}
export default BfButtonCountTag
