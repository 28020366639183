import React, { Component } from 'react'
import { bfTranslate } from '../../services/i18n/bfI18Utils'
import { withTranslation } from 'react-i18next'
import BfReduxStateConstants from '../common/BfReduxStateConstants'
import BfExportReport from '../common/BfReports/BfExportReport'
import moment from 'moment'
import _ from '../../services/utils/BfLodash'

class BfDevicePageExportReport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reportColumns: [
        { label: bfTranslate(this.props.t, 'devicePropertyId'), field: 'id' },
        { field: 'name' },
        { field: 'criticalPatches' },
        {
          label: bfTranslate(this.props.t, 'applicablePatches'),
          field: 'relevantPatchCount'
        },
        {
          label: bfTranslate(this.props.t, 'deployments'),
          field: 'deployments',
          datagridField: 'deploymentState'
        },
        { label: bfTranslate(this.props.t, 'deviceType'), field: 'deviceType' },
        { field: 'os' },
        { field: 'groupNames' },
        { label: bfTranslate(this.props.t, 'ipAddresses'), field: 'ipAddresses' },
        { label: bfTranslate(this.props.t, 'DNSName'), field: 'DNSname', datagridField: 'dnsName' },
        { field: 'locked' },
        { field: 'lastReportTime' },
        { field: 'agentStatus', datagridField: 'agent' },
        { field: 'agentDisplay' },
        { field: 'user' }
      ]
    }
  }

  _parseDeviceReport = function (devices, t) {
    const addEmptyColPlaceholder = function (device, columnName, t) {
      if (_.has(device, columnName) && _.isEmpty(device[columnName])) {
        device[columnName] = bfTranslate(t, 'NA')
      }
    }

    return devices.map((device) => {
      addEmptyColPlaceholder(device, 'groupNames', t)
      addEmptyColPlaceholder(device, 'DNSname', t)
      if (_.has(device, 'deviceType') && _.isString(device.deviceType)) {
        if (/,/g.test(device.deviceType)) {
          const types = device.deviceType.split(',')
          const translatedTypes = []
          _.each(types, function (type) {
            type = type.trim()
            if (/\(/g.test(type)) {
              type = type.match(/(\w+)/g)[0]
              translatedTypes.push('(' + bfTranslate(t, type) + ')')
            } else {
              translatedTypes.push(bfTranslate(t, type))
            }
          })
          device.deviceType = translatedTypes.join(', ')
        } else {
          device.deviceType = bfTranslate(t, device.deviceType)
        }
      }
      if (_.has(device, 'locked') && _.isString(device.locked)) {
        if (device.locked === 'No') {
          device.locked = bfTranslate(t, 'no')
        } else if (device.locked === 'Yes') {
          device.locked = bfTranslate(t, 'yes')
        }
      }
      if (_.has(device, 'lastReportTime')) {
        const time = moment.utc(device.lastReportTime)
        device.lastReportTime = time.local().format('LLL')
      }
      if (_.has(device, 'agentType')) {
        if (device.agentType === 'Native') {
          device.agentStatus = bfTranslate(t, 'installed')
        } else {
          device.agentStatus = bfTranslate(t, 'notInstalled')
        }
      }
      if (_.has(device, 'criticalPatches')) {
        if (device.criticalPatches === 1) {
          device.criticalPatches = bfTranslate(t, 'yes')
        } else {
          device.criticalPatches = bfTranslate(t, 'no')
        }
      }
      return device
    })
  }

  render() {
    return (
      <BfExportReport
        defaultReportName={bfTranslate(this.props.t, 'deviceReport')}
        apiUrl='/devices'
        reportColumns={this.state.reportColumns}
        reportFunction={this._parseDeviceReport}
        addedParams={{ pagination: { page: {} }, exportingReport: true }}
        namespace={BfReduxStateConstants.DEVICES}
        chartNamespace={BfReduxStateConstants.DEVICE_CHARTS}
      />
    )
  }
}

export default withTranslation()(BfDevicePageExportReport)
