/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/
import React, { Component } from 'react'
import BfNumberInputFilter from '../../common/bfNumberInputFilter/BfNumberInputFilter'
import BfWithReset from '../../BfWithReset'
import InsightsDeviceCountFilter from './InsightsDeviceCountFilter'

class InsightsDeviceCountWithFilter extends Component {
  constructor(props) {
    super(props)
    this.filter = InsightsDeviceCountWithFilter.getFilter(props)
  }

  static getFilter = (props) => {
    return props.filter ? props.filter : new InsightsDeviceCountFilter()
  }

  _getInitialValue = () => {
    return this.filter.getData()
  }

  _onValueSelected = (numberInputValue) => {
    this.filter.setData(numberInputValue)
  }

  render() {
    this.filter = InsightsDeviceCountWithFilter.getFilter(this.props)

    return (
      <BfNumberInputFilter
        bfFilter={this.filter}
        minValue={0}
        inputStep={1}
        onFilterApplied={this.props.onChange}
        onValueSelected={this._onValueSelected}
        initValue={this._getInitialValue()}
      />
    )
  }
}

export default BfWithReset(InsightsDeviceCountWithFilter, InsightsDeviceCountWithFilter.getFilter)
