/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2021
// All Rights Reserved
*/

import React from 'react'
import BfLinkWithTooltip from './BfLinkWithTooltip'
import BfCellWidth from './BfCellWidth'

/**
 * Renders Patch cells, links to Patch Docs
 */
class BfCellPatchRender {
  constructor(header) {
    this.header = header
    this.name = header?.field
  }

  /**
   * @method
   * @returns {Function} Cell Renderer
   */
  getRenderer() {
    return this._getPatchRenderHTML
  }

  /**
   * Renders Device cell
   * @method
   * @param {Object} row Patch value
   * @returns {HTMLElement} Cell HTML
   */
  _getPatchRenderHTML = (row) => {
    return (
      <BfCellWidth header={this.header}>
        <BfLinkWithTooltip href={'/patch/patches/' + row.site_id + '/' + row.contentId} value={row.name} toolTipValue={row.name} />
      </BfCellWidth>
    )
  }
}

export default BfCellPatchRender
