/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import {
  UPDATE_FILTER_URL,
  UPDATE_SEARCH_URL,
  UPDATE_COLUMN,
  UPDATE_OPTIONS,
  RESET_SEARCH_URL,
  SHOW_ONLY_SELECTED_ROWS,
  SET_NEW_FILTERS_URL,
  UPDATE_INLINE_REPORT_URL,
  UPDATE_ROW_COUNT,
  REFRESH_ROWS
} from './BfFilterListActionTypes'
import BfConsole from 'loglevel'
import BfFilterManager from '../filters/BfFilterManager'
import _ from '../../../services/utils/BfLodash'

/**
 *
 * state is: {
 * search: { options: {} , urlSearch : {} }
 * columns: { cols: [], save: false }
 * }
 */
export const updateSearchListReducer =
  (namespace, initSearchOptions = {}) =>
  (
    state = {
      search: { urlSearch: initSearchOptions, options: {} },
      columns: { cols: [], save: false }
    },
    action
  ) => {
    BfConsole.debug('updateSearchListReducer action ', action)
    BfConsole.debug('updateSearchListReducer state ', state)

    switch (action.type) {
      case `${namespace}/${RESET_SEARCH_URL}`: {
        const bfFilterManager = new BfFilterManager(state.search.urlSearch, state.search.options?.updateUrl)
        bfFilterManager.resetSearch(action.search)
        return {
          ...state,
          search: { ...state.search, urlSearch: {} },
          reset: true
        }
      }
      case `${namespace}/${UPDATE_OPTIONS}`: {
        const newOptions = action.options
        const _options = _.extend(state.search.options, newOptions)
        return {
          ...state,
          search: { ...state.search, options: _options }
        }
      }
      case `${namespace}/${UPDATE_SEARCH_URL}`: {
        const bfFilterManager = new BfFilterManager(state.search.urlSearch, state.search.options?.updateUrl)
        bfFilterManager.addSearch(action.search)
        return {
          ...state,
          search: { ...state.search, urlSearch: bfFilterManager.getSearch() },
          reset: false
        }
      }
      case `${namespace}/${UPDATE_FILTER_URL}`: {
        const bfFilterManager = new BfFilterManager(state.search.urlSearch, state.search.options?.updateUrl)
        bfFilterManager.addFilter(action.filter)
        return {
          ...state,
          search: {
            ...state.search,
            urlSearch: bfFilterManager.getSearch()
          },
          reset: false
        }
      }

      case `${namespace}/${SET_NEW_FILTERS_URL}`: {
        const bfFilterManager = new BfFilterManager(state.search.urlSearch, state.search.options?.updateUrl)
        bfFilterManager.resetSearch()
        bfFilterManager.addSearch(action.search)
        bfFilterManager.addSearch(action.sort)
        bfFilterManager.addFilter(action.filter)
        return {
          ...state,
          search: {
            ...state.search,
            urlSearch: bfFilterManager.getSearch()
          },
          reset: true
        }
      }
      case `${namespace}/${REFRESH_ROWS}`: {
        return { ...state }
      }
      case `${namespace}/${UPDATE_INLINE_REPORT_URL}`: {
        const bfFilterManager = new BfFilterManager(state.search.urlSearch, state.search.options?.updateUrl)
        bfFilterManager.addSearch(action.reportDetails)
        return {
          ...state,
          search: { ...state.search, urlSearch: bfFilterManager.getSearch() },
          reset: true
        }
      }

      case `${namespace}/${UPDATE_COLUMN}`: {
        return {
          ...state,
          columns: {
            ...state.columns,
            cols: [...action.columns],
            save: action.save
          },
          reset: false
        }
      }
      case `${namespace}/${SHOW_ONLY_SELECTED_ROWS}`: {
        const { options = {} } = state.search
        options.showOnlySelected = action.showOnlySelected
        state.search.options = options
        const bfFilterManager = new BfFilterManager(state.search.urlSearch, state.search.options?.updateUrl)
        bfFilterManager.resetPagination()
        BfConsole.debug('New urlSearch ', bfFilterManager.getSearch())
        return {
          ...state,
          search: { ...state.search, urlSearch: bfFilterManager.getSearch() }
        }
      }
      case `${namespace}/${UPDATE_ROW_COUNT}`: {
        return {
          ...state,
          rowCount: action.rowCount
        }
      }
      default: {
        return state
      }
    }
  }

export const getDataGridSearchPath = (state, namespace) => {
  return _.get(state, `${namespace}.search`, {})
}
