import { createStore, applyMiddleware, combineReducers } from 'redux'
import { createEpicMiddleware, ofType } from 'redux-observable'
import BfConsole from 'loglevel'
import _ from '../../../services/utils/BfLodash'
import { BehaviorSubject, Subject } from 'rxjs'
import { mapTo, mergeMap, takeUntil } from 'rxjs/operators'

export function createReducerManager() {
  // Create an object which maps keys to reducers
  const reducers = {}

  // Create the initial combinedReducer
  let combinedReducer = null

  // An array which is used to delete state keys when reducers are removed
  let keysToRemove = []

  const _cleanUpState = (state) => {
    if (keysToRemove.length > 0) {
      state = { ...state }
      _.each(keysToRemove, (key) => {
        delete state[key]
      })
      keysToRemove = []
    }
  }

  return {
    getReducerMap: () => reducers,
    // The root reducer function exposed by this object
    // This will be passed to the store
    reduce: (state, action) => {
      // If any reducers have been removed, clean up their state first
      _cleanUpState(state)
      // Delegate to the combined reducer
      return combinedReducer ? combinedReducer(state, action) : state
    },

    // Adds a new reducer with the specified key
    add: (key, reducer) => {
      if (!key || reducers[key]) {
        // Add the reducer to the reducer mapping
        if (reducers[key]) {
          BfConsole.error('Reducer with key ' + key + ' already exists')
        }
        return
      }

      reducers[key] = reducer

      // Generate a new combined reducer
      combinedReducer = combineReducers(reducers)
    },

    // Removes a reducer with the specified key
    remove: (key, state) => {
      if (!key || !reducers[key]) {
        return
      }

      // Remove it from the reducer mapping
      delete reducers[key]

      // Remove key from the state
      delete state[key]

      // Add the key to the list of keys to clean up
      keysToRemove.push(key)

      // Generate a new combined reducer
      combinedReducer = _.isEmpty(reducers) ? null : combineReducers(reducers)
    }
  }
}

const createEpicManager = () => {
  const epicCancelFns = {}
  const emptyEpic = (action$) => action$.pipe(ofType('INIT_EPIC'), mapTo({ type: 'INITIALIZED_EPIC' }))

  const epic$ = new BehaviorSubject({
    epic: emptyEpic,
    cancelSubject$: new Subject()
  })

  const rootEpic = (action$, state$) =>
    epic$.pipe(
      mergeMap(({ epic, cancelSubject$ }) => {
        return epic(action$, state$).pipe(takeUntil(cancelSubject$.asObservable()))
      })
    )

  return {
    add: (key, epic) => {
      if (epicCancelFns[key]) {
        BfConsole.warn(`Epic with ${key} already exists`)
      }

      const cancelSubject$ = new Subject()
      epicCancelFns[key] = () => {
        cancelSubject$.next()
      }

      epic$.next({ epic, cancelSubject$ })
    },
    remove: (key, epic) => {
      if (!epicCancelFns[key]) {
        BfConsole.warn(`Epic with ${key} already removed`)
        return
      }

      epicCancelFns[key]()

      delete epicCancelFns[key]
    },
    rootEpic: () => rootEpic
  }
}

const _initializeEpicManager = (store) => {
  const epicManager = createEpicManager()

  store.epicMiddleware.run(epicManager.rootEpic())

  store.addEpicMiddleware = (key, newEpic) => {
    return store.epicManager.add(key, newEpic)
  }

  store.epicManager = epicManager
}

const addReducer = (store, key, reducer) => {
  store.reducerManager.add(key, reducer)
}

const removeReducer = (store, key) => {
  store.reducerManager.remove(key, store.getState())
}

export const bfCreateStore = (initialState = {}) => {
  const epicMiddleware = createEpicMiddleware()
  const reducerManager = createReducerManager()

  BfConsole.debug('bfCreateStore....', initialState)
  // Create a store with the root reducer function being the one exposed by the manager.
  const store = createStore(reducerManager.reduce, initialState, applyMiddleware(epicMiddleware))

  store.epicMiddleware = epicMiddleware
  _initializeEpicManager(store)

  // Optional: Put the reducer manager on the store so it is easily accessible
  store.reducerManager = reducerManager

  store.addReducer = (key, reducer) => {
    return addReducer(store, key, reducer)
  }

  store.removeReducer = (key) => {
    removeReducer(store, key)
    store.epicManager.remove(key)
  }

  BfConsole.debug('bfCreateStore')
  return store
}

/* export const store = bfCreateStore() */
