/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2021
// All Rights Reserved
*/

import React, { useState, useEffect } from 'react'
import { Tabs, Tab } from '@patron/patron-react/tab'
import { withTranslation } from 'react-i18next'
import BfDevicesGrid from './BfDevicesGrid'
import BfGroupsGrid from '../groups/BfGroupsGrid'
import { bfTranslate } from '../../services/i18n/bfI18Utils'
import _ from '../../services/utils/BfLodash'
import withBfModal from '../modals/BfWithModal'
import BfConsole from 'loglevel'
import BfWithDataGridTableFeatures from '../datagrid/BfWithDataGridTableFeatures'
import BfReduxStateConstants from '../common/BfReduxStateConstants'
import BfCurrentUserInfoService from '../../services/user/BfCurrentUserInfoService'

const TARGETS_TAB = 0
const GROUPS_TAB = 1

const BfSelectTargets = (props) => {
  const {
    t,
    onDeviceRowSelected,
    onGroupRowSelected,
    preSelectedTargetsItems,
    preSelectedGroupsItems,
    showRelevantFilter,
    currentTab,
    showPaginationIcons = true,
    noGroups,
    customFilter,
    ppsUserLimit = false,
    ...rest
  } = props
  const [groupsPPSLimit, setGroupsPPSLimit] = useState(false)
  const [devices, setDevices] = useState([])
  const [groups, setGroups] = useState([])
  const [currentTabIndex, setCurrentTabIndex] = useState(() => {
    if (_.isUndefined(currentTab)) {
      return _.size(preSelectedGroupsItems) > 0 ? GROUPS_TAB : TARGETS_TAB
    } else {
      return currentTab
    }
  })

  useEffect(() => {
    const bfCurrentUserInfoService = new BfCurrentUserInfoService()
    bfCurrentUserInfoService.getUserActionsLimit().subscribe((userLimits) => {
      setGroupsPPSLimit(userLimits.device_target_limit !== -1)
    })
  }, [])

  const _onTabChange = ({ tabIndex, label }) => {
    setCurrentTabIndex(tabIndex)
  }

  const _selectItems = (areTargets, rowSelected, rows) => {
    if (areTargets) {
      _selectDevices(rowSelected, rows)
    } else {
      _selectGroups(rowSelected, rows)
    }
  }

  /* const _needNotify = (areTargets) => {
    return (
      (currentTabIndex === TARGETS_TAB && areTargets) ||
      (currentTabIndex === GROUPS_TAB && !areTargets)
    )
  } */

  const checkAlreadySelected = (areTargets, rowSelected, rows) => {
    /** Filter only selected rows , removes unselected */
    const _selectedRows = _filterOnlySelected(rows)
    const others = areTargets ? groups : devices
    if (_.isEmpty(_selectedRows)) {
      BfConsole.debug('selected rows are empty')
      _selectItems(areTargets, rowSelected, rows)
      return
    }
    if (_.size(others) > 0) {
      props.showModal({
        title: bfTranslate(t, 'DeviceTypeMismatch'),
        labelConfirm: bfTranslate(t, 'ok'),
        labelCancel: bfTranslate(t, 'cancel'),
        showClose: false,
        message: bfTranslate(t, 'TargetingDeviceMismatch'),

        onOk: () => {
          /** Deselect others */
          BfConsole.debug('on ok')
          props.deselectRows(areTargets ? BfReduxStateConstants.GROUPS_DATA_GROUP : BfReduxStateConstants.DEVICES, others)
          _selectItems(areTargets, rowSelected, _selectedRows)
        },
        onClose: () => {
          /** Deselect these selected  */
          props.deselectRows(areTargets ? BfReduxStateConstants.DEVICES : BfReduxStateConstants.GROUPS_DATA_GROUP, _selectedRows)
          _selectItems(areTargets, {}, [])
          BfConsole.debug('on cancel')
        }
      })
    } else {
      _selectItems(areTargets, rowSelected, _selectedRows)
    }
  }

  const _selectDevices = (rowSelected, rows) => {
    setDevices(rows)
    onDeviceRowSelected && onDeviceRowSelected(rowSelected, rows)
  }

  const _selectGroups = (rowSelected, rows) => {
    setGroups(rows)
    onGroupRowSelected && onGroupRowSelected(rowSelected, rows)
  }

  const _filterOnlySelected = (rows) => {
    return _.filter(rows, (row) => row._isSelected)
  }

  const _onDeviceRowSelected = (rowSelected, rows) => {
    checkAlreadySelected(true, rowSelected, rows)
  }

  const _onGroupRowSelected = (rowSelected, rows) => {
    checkAlreadySelected(false, rowSelected, rows)
  }

  useEffect(() => {
    setDevices(preSelectedTargetsItems)
    setGroups(preSelectedGroupsItems)
  }, [preSelectedTargetsItems, preSelectedGroupsItems])

  return (
    <Tabs activeIndex={currentTabIndex} onChange={_onTabChange}>
      <Tab active label={bfTranslate(t, 'TargetByDevice')}>
        <BfDevicesGrid
          showPaginationIcons={showPaginationIcons}
          options={{
            updateUrl: false,
            showDeployButton: false,
            showSelectedOnly: true,
            showManualTargeting: true,
            save: false,
            customFilter: customFilter,
            ppsUserLimit: ppsUserLimit
          }}
          showRelevantFilter={showRelevantFilter}
          onRowSelected={_onDeviceRowSelected}
          preSelectedItems={preSelectedTargetsItems}
          {...rest}
        />
      </Tab>
      {!noGroups && !groupsPPSLimit && (
        <Tab active label={bfTranslate(t, 'TargetByGroup')}>
          <BfGroupsGrid
            options={{
              updateUrl: false,
              showDeployButton: false,
              showSelectedOnly: true
            }}
            showRelevantFilter={showRelevantFilter}
            onRowSelected={_onGroupRowSelected}
            preSelectedItems={preSelectedGroupsItems}
            {...rest}
          />
        </Tab>
      )}
    </Tabs>
  )
}
export default withTranslation()(withBfModal(BfWithDataGridTableFeatures(BfSelectTargets)))
