/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2021
// All Rights Reserved
*/
import { bfTranslate } from '../../../../services/i18n/bfI18Utils'
import BfReduxStateConstants from '../../../common/BfReduxStateConstants'

import BfDropDownBasicFilter from '../common/bfBasicFilters/bfDropDownFilter/BfDropDownBasicFilter'

class BfPatchOSFilter extends BfDropDownBasicFilter {
  static NAME = 'os'
  static PATH = `filter.${BfPatchOSFilter.NAME}`
  static NAMESPACE = BfReduxStateConstants.PATCHES
  constructor(data = {}) {
    super(BfPatchOSFilter.PATH, BfPatchOSFilter.NAME, data)
  }

  getItems = () => {
    const t = this.getT()

    const res = {
      values: [
        {
          id: 'CentOS',
          text: bfTranslate(t, 'CentOS')
        },
        {
          id: 'Debian',
          text: bfTranslate(t, 'Debian')
        },
        {
          id: 'OSX',
          text: bfTranslate(t, 'OSX')
        },
        {
          id: 'OracleLinux',
          text: bfTranslate(t, 'OracleLinux')
        },
        {
          id: 'RHEL',
          text: bfTranslate(t, 'RHEL')
        },
        {
          id: 'SUSE',
          text: bfTranslate(t, 'SUSE')
        },
        {
          id: 'Ubuntu',
          text: bfTranslate(t, 'Ubuntu')
        },
        {
          id: 'Windows',
          text: bfTranslate(t, 'Windows')
        },
        {
          id: 'Other',
          text: bfTranslate(t, 'Unspecified')
        }
      ]
    }
    return res.values
  }
}

export default BfPatchOSFilter
