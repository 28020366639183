/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { updateFilterListUrlAction } from '../../../redux/BfFilterListUpdateURLAction'
import { BfDataGridComponentWithContext } from '../../../BfDataGridContext'
import { createFilterManagerFromReduxState } from '../../BfFilterManager'
import InsightsContentCountWithFilter from './InsightsContentCountWithFilter'

const InsightsContentCountFilterWithReduxAction = (WrappedComponent) => {
  return class extends Component {
    _handleOnChange = (data) => {
      this.props.updateFilterListUrlAction(this.props.id, data)
    }

    render() {
      return <WrappedComponent filter={this.props.filter} onChange={this._handleOnChange} />
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const bfFilterManager = createFilterManagerFromReduxState(state, ownProps.id)
  const insightsContentCountFilter = bfFilterManager.getInsightsContentCountFilter()

  return {
    filter: insightsContentCountFilter
  }
}
const InsightsContentCountFilterWithRedux = connect(mapStateToProps, {
  updateFilterListUrlAction
})(InsightsContentCountFilterWithReduxAction(InsightsContentCountWithFilter))

export default BfDataGridComponentWithContext(InsightsContentCountFilterWithRedux)
