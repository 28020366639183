/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import React from 'react'
import { Trans } from 'react-i18next'
import _ from '../../services/utils/BfLodash'
import moment from 'moment'

import en from '../../locales/en.json'
import de from '../../locales/de.json'
import es from '../../locales/es.json'
import fr from '../../locales/fr.json'
import it from '../../locales/it.json'
import ja from '../../locales/ja.json'
import ko from '../../locales/ko.json'
import ptbr from '../../locales/pt-br.json'
import zhcn from '../../locales/zh-cn.json'
import zhtw from '../../locales/zh-tw.json'

import 'moment/locale/en-gb'
import 'moment/locale/de'
import 'moment/locale/es'
import 'moment/locale/fr'
import 'moment/locale/it'
import 'moment/locale/ja'
import 'moment/locale/ko'
import 'moment/locale/pt-br'
import 'moment/locale/zh-cn'
import 'moment/locale/zh-tw'

export const DEFAULT_LANGUAGES = ['de', 'en', 'es', 'fr', 'it', 'ja', 'ko', 'pt-BR', 'zh-CN', 'zh-TW']

const ALLOWED_FORMATS = ['MM/DD/YYYY', 'DD/MM/YYYY']
const languageToCalalog = {
  en: en,
  it: it,
  de: de,
  es: es,
  fr: fr,
  ja: ja,
  ko: ko,
  'pt-BR': ptbr,
  'zh-CN': zhcn,
  'zh-TW': zhtw
}

export const DEFAULT_LANGUAGE = 'en'

moment.locale(navigator.language || navigator.userLanguage)

export function loadCatalogs(languages = DEFAULT_LANGUAGES) {
  const resources = {}
  languages.forEach((language) => {
    resources[language] = { 'bfcore-react': languageToCalalog[language] }
  })
  return resources
}

const BfTransInternal = (key, params, namespace) => {
  const i18nKey = _.isDefined(namespace) ? `${namespace}:${key}` : key
  if (_.isUndefined(params)) {
    return <Trans i18nKey={i18nKey}>{en.key}</Trans>
  }
  const { count, ...rest } = params
  return (
    <Trans i18nKey={i18nKey} count={count} values={rest}>
      {en.key}
    </Trans>
  )
}

export const BfTrans = (key, params) => {
  return BfTransInternal(key, params, 'bfcore-react')
}

export const BfTransNoNamespace = (key, params) => {
  return BfTransInternal(key, params)
}

export function bfTranslate(t, key, params) {
  return t('bfcore-react:' + key, params)
}

export const getCurrentDateLocaleFormat = () => {
  const localeData = moment.localeData(navigator.language || navigator.userLanguage)

  // DIRTY FIX UNTIL PATRONUS  DATE FORMAT FIX
  // TODO: REMOVE AFTER PATRONUS FIX
  let formattedDate = _.toUpper(localeData.longDateFormat('L'))

  if (!_.includes(ALLOWED_FORMATS, formattedDate)) {
    formattedDate = 'MM/DD/YYYY'
  }
  return formattedDate
}

export const getCurrentTimeLocaleFormat = () => {
  const localeData = moment.localeData(navigator.language || navigator.userLanguage)

  return localeData.longDateFormat('LT')
}
