/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/
import _ from '../../../services/utils/BfLodash'
import BfCustomPropertiesFilter from './BfCustomPropertiesFilter'
import BfConsole from 'loglevel'

class BfCustomPropertiesColumnFilter extends BfCustomPropertiesFilter {
  static NAME = 'customColumn'
  constructor(contentKey) {
    super()
    this.contentKey = contentKey
    this.setName(`${this.contentKey.contentID}_${this.contentKey.siteID}_${this.contentKey.propertyID}`)
  }

  getName = () => {}

  _consumePropertyFromURL = (urlHandler, customProperty) => {
    const customProperties = _.get(urlHandler.getSearch(), this.getPath())
    _.remove(customProperties.properties, customProperty)
    if (_.size(customProperties.properties) === 0) {
      this.consumeCustomPropertyURLHandler(urlHandler, true)
      BfConsole.debug('consumed URL is ', urlHandler.getSearch())
    }
  }

  isApplied = () => {
    return this._hasValues(this.contentKey)
  }

  buildFromURLHandler = (urlHandler, consumeUrl = false) => {
    this.customProperties = _.cloneDeep(this._buildFromURLHandler(urlHandler, false, this.customProperties))

    if (consumeUrl) {
      const customProperty = this._findCustomPropertyByContentKey(this.contentKey)
      if (_.isNotNil(customProperty)) {
        this._consumePropertyFromURL(urlHandler, customProperty)
      }
    }
  }

  _findCustomPropertyByContentKey = (contentKey) => {
    const propertyObj = _.find(this.customProperties.properties, (property) => this._comparePropertyId(property, contentKey))
    return _.isUndefined(propertyObj) ? null : propertyObj
  }

  _addCustomProperty = () => {
    const customProperty = this._getCustomProperty(this.contentKey)
    this.customProperties.properties.push(customProperty)
    return customProperty
  }

  _setCustomPropertyValue = (customProperty, value) => {
    if (_.isEmpty(value)) {
      _.isDefined(customProperty.values) && delete customProperty.values
    } else {
      customProperty.values = [value]
    }
  }

  setQuery = (query) => {
    let customProperty = this._findCustomPropertyByContentKey(this.contentKey)

    if (_.isNull(customProperty)) {
      customProperty = this._addCustomProperty()
    }

    this._setCustomPropertyValue(customProperty, query)

    this._setFilterOptions()
  }

  _hasValues = (contentKey) => {
    if (contentKey) {
      const customProperty = this._findCustomPropertyByContentKey(contentKey)
      return _.isEmpty(customProperty) ? false : _.isDefined(customProperty.values)
    } else {
      return this.areFilterApplied()
    }
  }

  _setFilterOptions = () => {
    const areValues = this._hasValues()

    if (areValues) {
      this.customProperties.filter = true
    } else {
      this.customProperties.filter = false
    }
  }

  _getValue = () => {
    const customProperty = this._findCustomPropertyByContentKey(this.contentKey)
    if (_.isNull(customProperty)) {
      return ''
    }
    return _.isDefined(customProperty.values) && _.size(customProperty.values) > 0 ? _.first(customProperty.values) : ''
  }

  getQuery = () => {
    return this._getValue()
  }

  getFilterCount = () => {
    return this.areFilterApplied() ? 1 : 0
  }
}
export default BfCustomPropertiesColumnFilter
