/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2021
// All Rights Reserved
*/

import React from 'react'
import './BfDeviceIcons.scss'
import { withTranslation } from 'react-i18next'
import { bfTranslate } from '../../../services/i18n/bfI18Utils'
import BfUnixDateTime from '../../../filters/BfUnixDateTime'
import BfCellWithTooltipRenderer from '../bfCellTemplates/BfCellWithTooltipRenderer'
import { isOffline } from '../../devices/BfDeviceUtils'
import { ReactComponent as DeviceIcon } from './Big Fix.svg'
import { ReactComponent as CloudIcon } from './Cloud.svg'
import { ReactComponent as MDMIcon } from './MDM.svg'

/**
 * Cell renderer for data grid
 */
class BfDeviceIcons extends React.Component {
  _getToolTip = (lastReportTime) => {
    const { t } = this.props
    const lastTime = new BfUnixDateTime().getTimeFromNow(lastReportTime, true)
    const _isOffline = isOffline(lastReportTime)

    return {
      isOffline: _isOffline,
      toolTip: _isOffline ? bfTranslate(t, 'DeviceNotReported', { lastTime: lastTime }) : bfTranslate(t, 'TimeAgo', { lastTime: lastTime })
    }
  }

  /**
   * Renders agent if device has BES agent
   * @method
   * @param {Object} device Device value
   */
  _getAgentRenderer(device) {
    if (device.agent) {
      const toolTip = this._getToolTip(device.lastReportTime)
      const deviceStyle = toolTip.isOffline ? 'bf-device-offline' : ''
      return (
        <BfCellWithTooltipRenderer textTooltip={toolTip.toolTip}>
          <DeviceIcon className={deviceStyle} />
        </BfCellWithTooltipRenderer>
      )
    } else {
      return ''
    }
  }

  /**
   * Renders cloud if device is cloud based
   * @method
   * @param {Object} device Device value
   */
  _getCloudRenderer(device) {
    if (device.cloud) {
      const toolTip = this._getToolTip(device.lastReportTimeCloud)
      return (
        <BfCellWithTooltipRenderer textTooltip={toolTip.toolTip}>
          <CloudIcon />
        </BfCellWithTooltipRenderer>
      )
    } else {
      return ''
    }
  }

  /**
   * Renders MDM if device is MDM based
   * @method
   * @param {Object} device Device value
   */
  _getMDMRenderer(device) {
    if (device.mdm) {
      const toolTip = this._getToolTip(device.lastReportTimeMDM)
      return (
        <BfCellWithTooltipRenderer textTooltip={toolTip.toolTip}>
          <MDMIcon />
        </BfCellWithTooltipRenderer>
      )
    } else {
      return ''
    }
  }

  render() {
    const device = this.props.device
    return (
      <React.Fragment>
        {this._getAgentRenderer(device)}
        {this._getCloudRenderer(device)}
        {this._getMDMRenderer(device)}
      </React.Fragment>
    )
  }
}

export default withTranslation()(BfDeviceIcons)
