/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2021
// All Rights Reserved
*/
import { bfTranslate } from '../../../../services/i18n/bfI18Utils'

import BfDropDownBasicFilter from '../common/bfBasicFilters/bfDropDownFilter/BfDropDownBasicFilter'

class BfSourceSeverityFilter extends BfDropDownBasicFilter {
  static NAME = 'severity'
  static PATH = `filter.${BfSourceSeverityFilter.NAME}`
  constructor(data = {}) {
    super(BfSourceSeverityFilter.PATH, BfSourceSeverityFilter.NAME, data)
  }

  getItems = () => {
    const t = this.getT()

    const res = {
      values: [
        {
          id: 'Critical',
          text: bfTranslate(t, 'Critical')
        },
        {
          id: 'Important',
          text: bfTranslate(t, 'Important')
        },
        {
          id: 'Moderate',
          text: bfTranslate(t, 'Moderate')
        },
        {
          id: 'Low',
          text: bfTranslate(t, 'Low')
        },
        {
          id: 'Unspecified',
          text: bfTranslate(t, 'Unspecified')
        }
      ]
    }
    return res.values
  }
}

export default BfSourceSeverityFilter
