/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/
import _ from '../../../services/utils/BfLodash'
import BfBasicFilter from '../filters/BfBasicFilter'
import BfPropertiesService from '../../../services/properties/BfPropertiesService'
import { from, of } from 'rxjs'
import { mergeMap } from 'rxjs/operators'
import { makeContentKey } from './BfAddColumnHelper'

class BfCustomPropertiesFilter extends BfBasicFilter {
  static NAME = 'customProperties'
  static PATH = `filter.${BfCustomPropertiesFilter.NAME}`
  doFilter = true

  constructor() {
    super(BfCustomPropertiesFilter.PATH, BfCustomPropertiesFilter.NAME)
    this.customProperties = {
      searchBy: 'id',
      filter: false,
      properties: []
    }
  }

  getName = (onlyFilteredProperties = true) => {
    if (_.isDefined(this.customProperties)) {
      const bfPropertiesService = new BfPropertiesService()
      return bfPropertiesService
        .getPropertiesNames(onlyFilteredProperties ? this.getAppliedFilters(this.customProperties.properties) : this.customProperties.properties)
        .pipe(
          mergeMap((propertiesNames) => {
            _.each(propertiesNames, (propertyName) => {
              propertyName = _.extend(
                propertyName,
                { name: propertyName.propertyName },
                {
                  id: makeContentKey(propertyName)
                },
                { siteID: propertyName.siteID },
                { contentID: propertyName.contentID },
                { propertyID: propertyName.propertyID },
                { isCustomProperty: true },
                {
                  field: propertyName.siteID + '_' + propertyName.contentID + '_' + propertyName.propertyName
                }
              )
            })
            return from(propertiesNames)
          })
        )
    }
    return of({ name: null })
  }

  setCustomProperties = (customProperties) => {
    this.customProperties = customProperties
  }

  get = () => {
    return this._isEmpty() ? { customProperties: {} } : { customProperties: this.customProperties }
  }

  getFilter = () => {
    return { filter: this.get() }
  }

  _isEmpty = () => {
    return _.isEmpty(this.customProperties) || _.isEmpty(this.customProperties.properties)
  }

  buildFromURLHandler = (urlHandler, consumeUrl = false) => {
    this.customProperties = this._buildFromURLHandler(urlHandler, consumeUrl, this.customProperties)
  }

  consumeCustomPropertyURLHandler = (urlHandler, consumeUrl = false) => {
    this._buildFromURLHandler(urlHandler, consumeUrl)
  }

  _compareContentKey = (prop1, prop2) => {
    return (
      prop1.contentKey.siteID.toString() === prop2.contentKey.siteID.toString() &&
      prop1.contentKey.contentID === prop2.contentKey.contentID &&
      prop1.propertyID === prop2.propertyID
    )
  }

  _comparePropertyId = (prop, header) => {
    const headerContentKey = this._getCustomProperty(header)
    return this._compareContentKey(prop, headerContentKey)
  }

  add = (customPropertiesIds) => {
    _.each(customPropertiesIds, (customPropertyId) => {
      const ix = _.findIndex(this.customProperties.properties, (prop) => this._comparePropertyId(prop, customPropertyId))
      if (ix > -1 && customPropertyId._toBeRemoved) {
        this.customProperties.properties.splice(ix, 1)
      } else if (ix === -1 && !customPropertyId._toBeRemoved) {
        this.customProperties.properties.push(this._getCustomProperty(customPropertyId))
      }
    })
  }

  getData = () => {
    return this.customProperties
  }

  _getCustomProperty = (row) => {
    return {
      contentKey: {
        contentID: row.contentID,
        siteID: row.siteID
      },
      propertyID: row.propertyID
    }
  }

  areFilterApplied = () => {
    return _.size(this.getAppliedFilters()) > 0
  }

  getAppliedFilters = () => {
    if (_.isEmpty(this.customProperties) || _.isEmpty(this.customProperties.properties)) {
      return []
    }
    return _.filter(this.customProperties.properties, (property) => _.isNotEmpty(property.values))
  }

  isApplied = () => {
    return this.customProperties.filter && _.size(this.getAppliedFilters()) > 0
  }

  getFilterCount = () => {
    return _.size(this.getAppliedFilters())
  }
}
export default BfCustomPropertiesFilter
