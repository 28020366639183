/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import React, { Component } from 'react'
import { MenuList, Item } from '@patron/patron-react/menulist'
import { Overlay } from '@patron/patron-react/overlay'
import { Checkbox } from '@patron/patron-react/checkbox'
import logConsole from 'loglevel'
import './BfDropdownSearch.scss'
import './BfSimpleSearch.scss'
import { of, isObservable } from 'rxjs'
import { map } from 'rxjs/operators'
import _ from '../../services/utils/BfLodash'
import BfDebounceSubject from './BfDebounceSubject'
import BfButtonCountTag from './BfButtonCountTag'
import BfSimpleSearch from './BfSimpleSearch'
import { withTranslation } from 'react-i18next'
import { bfTranslate } from '../../services/i18n/bfI18Utils'

const createDropDownItem = (id, text) => {
  if (_.isUndefined(text)) {
    text = id
  }
  return { id: id, label: text }
}
export { createDropDownItem }

class BfDropdownSearch extends Component {
  menulistRef = React.createRef()
  thisNodeRef = React.createRef()
  bfDebounceSubjectInputChange = new BfDebounceSubject()

  state = {
    showMenu: false,
    targetElement: null,
    suggestions: [],
    text: '',
    selectedOption: {},
    showCounter: false
  }

  constructor(props) {
    super(props)
    this.bfDebounceSubjectInputChange.subscribe((e) => {
      this._displayMenuList(e)
    })
  }

  _clearSelection = (event) => {
    event.stopPropagation()
    this.props.onItemSelected(null, [])
    this.setState({
      selectedOption: {},
      text: ''
    })
  }

  _onCheckboxSelect = (item, e) => {
    var selectedOptionObj = { ...this.state.selectedOption }

    if (e.currentTarget.checked) {
      selectedOptionObj[item.id] = item
    } else {
      delete selectedOptionObj[item.id]
    }
    const values = _.values(selectedOptionObj)
    this.props.onItemSelected(
      item.id,
      _.map(values, (selectedOption) => selectedOption.id),
      values
    )

    this.setState({
      selectedOption: selectedOptionObj,
      showCounter: true
    })

    e.currentTarget.parentElement.parentElement.focus()
  }

  _getCheckedOptionsOrderedArray = (checkedOptions) => {
    if (_.isEmpty(checkedOptions)) {
      return []
    }
    const selectedOptions = _.values(checkedOptions)
    const orderderCheckedOptions = _.sortBy(selectedOptions, (selectedOption) => {
      selectedOption._isLast = false
      return selectedOption.label
    })
    const lastCheckedOption = _.last(orderderCheckedOptions)
    lastCheckedOption._isLast = true
    return orderderCheckedOptions
  }

  _getCheckedOptions = () => {
    return this.state.selectedOption
  }

  _removeCheckedOptionsFromSuggestions = (suggestions, checkedOptions) => {
    const { fixedSuggestedOptions } = this.props

    let suggestionsWithoutFixedOptions = []
    let suggestionsWithoutCheckedOptions = suggestions

    if (_.isNotEmpty(checkedOptions)) {
      suggestionsWithoutCheckedOptions = _.filter(suggestions, (suggestion) => _.isUndefined(checkedOptions[suggestion.id]))
    }

    if (_.isDefined(fixedSuggestedOptions)) {
      suggestionsWithoutFixedOptions = _.remove(suggestionsWithoutCheckedOptions, (suggestion) => {
        return _.has(fixedSuggestedOptions, suggestion.id)
      })
    }

    const sortedSuggestions = _.concat(
      _.sortBy(suggestionsWithoutFixedOptions, (suggestion) => suggestion.label),
      _.sortBy(suggestionsWithoutCheckedOptions, (suggestion) => suggestion.label)
    )

    return sortedSuggestions
  }

  _filterCheckedOptions = (checkedOptions, filterValue) => {
    let filteredCheckedOptions = checkedOptions
    if (filterValue.length > 0) {
      filteredCheckedOptions = {}
      const lowerFilterValue = _.toLower(filterValue)
      _.each(checkedOptions, (checkedOption) => {
        if (_.includes(_.toLower(checkedOption.label), lowerFilterValue)) {
          filteredCheckedOptions[checkedOption.id] = checkedOption
        }
      })
    }
    return filteredCheckedOptions
  }

  _mergeSuggestionWithCheckedOptions = (suggestions, checkedOptions, filterValue) => {
    const filteredCheckedOptions = this._filterCheckedOptions(checkedOptions, filterValue)
    logConsole.debug('filteredCheckedOptions are ', filteredCheckedOptions)

    const filteredOrderedArraySuggestions = this._removeCheckedOptionsFromSuggestions(suggestions, filteredCheckedOptions)
    logConsole.debug('filteredOrderedArraySuggestions are ', filteredOrderedArraySuggestions)

    const checkedOptionsArray = this._getCheckedOptionsOrderedArray(filteredCheckedOptions)
    return _.concat(checkedOptionsArray, filteredOrderedArraySuggestions)
  }

  _getSortedListOfOptions = (filterValue, suggestions) => {
    const checkedOptions = this._getCheckedOptions()
    logConsole.debug('checkedOptions are ', checkedOptions)

    return this._mergeSuggestionWithCheckedOptions(suggestions, checkedOptions, filterValue)
  }

  _displayMenuOnClick = (e) => {
    const filterValue = this.state.text

    this._getItemsCkb(filterValue).subscribe({
      next: (suggestions) => {
        const sortedSuggestions = this._getSortedListOfOptions(filterValue, suggestions)
        this.setState({
          suggestions: sortedSuggestions
        })
      }
    })

    this.setState({
      showMenu: true,
      showCounter: false,
      targetElement: this.thisNodeRef.current
    })
  }

  _getItemsCkb = (value) => {
    const getItemsRequest = this.props.getItems(value)
    const itemsObs = isObservable(getItemsRequest) ? getItemsRequest : of(getItemsRequest)
    return itemsObs
  }

  _searchKey = (value) => {
    this._getItemsCkb(value)
      .pipe(
        map((suggestions) => {
          return this._getSortedListOfOptions(value, suggestions)
        })
      )
      .subscribe({
        next: (suggestions) => {
          this.setState({
            suggestions: suggestions
          })
        }
      })
  }

  _onFilterInputChange = (e) => {
    this.bfDebounceSubjectInputChange.next(e.currentTarget)
  }

  _displayMenuList = (tarElm) => {
    const value = tarElm.value

    this._searchKey(value)

    this.setState({
      text: value,
      targetElement: this.thisNodeRef.current
    })
  }

  _onClose = (status) => {
    if (!status) {
      this.setState((state) => {
        const showCounter = _.isNotEmpty(state.selectedOption)
        return {
          showMenu: status,
          targetElement: null,
          text: '',
          showCounter: showCounter
        }
      })
    }
  }

  _keyDownMenu = (e) => {
    const key = e.which || e.keyCode
    var elemSelected = document.activeElement
    var menulistRefnc = this.menulistRef.current

    switch (key) {
      case 40: {
        if (elemSelected.nextElementSibling == null) {
          menulistRefnc.firstElementChild.focus()
        } else {
          elemSelected.nextElementSibling.focus()
        }

        e.preventDefault()
        break
      }
      case 38: {
        if (elemSelected.previousElementSibling == null) {
          menulistRefnc.lastElementChild.focus()
        } else {
          elemSelected.previousElementSibling.focus()
        }
        e.preventDefault()
        break
      }
      case 13: {
        const input = e.target.querySelector('input')
        var elmId = input.id.replace('item-', '')
        var elmObj = { id: elmId, item: input.value }
        var optionEnter = { ...this.state.selectedOption }
        if (!input.checked) {
          optionEnter[elmId] = elmObj
        } else {
          delete optionEnter[elmId]
        }
        this.setState({
          selectedOption: optionEnter
        })

        e.preventDefault()
        break
      }
      default:
        break
    }
  }

  _onTextInputClick = (e) => {
    if (this.state.text.length === 0 && !this.state.showMenu) {
      this._displayMenuOnClick(e)
    }
  }

  _keyDown = (e) => {
    const key = e.which || e.keyCode
    const menulistRefnc = this.menulistRef.current
    if (this.state.showMenu) {
      switch (key) {
        case 40: {
          menulistRefnc.firstElementChild.focus()
          e.preventDefault()
          break
        }
        case 38: {
          menulistRefnc.lastElementChild.focus()
          e.preventDefault()
          break
        }

        default:
          break
      }
    }
  }

  _renderSuggestions = () => {
    const { t } = this.props
    if (this.state.suggestions.length === 0) {
      return (
        <React.Fragment>
          <Item className='hcl-dropdown-item' disabled>
            {bfTranslate(t, 'NoResultsFound')}
          </Item>
        </React.Fragment>
      )
    }
    return (
      <React.Fragment>
        {this.state.suggestions.map((item) => (
          <Item key={`item-${item.id}`} className={'hcl-dropdown-item' + (item._isLast ? ' is-last-element' : '')}>
            <Checkbox
              id={`item-${item.id}`}
              label={item.label}
              value={item.label}
              checked={_.isDefined(this.state.selectedOption[item.id])}
              onChange={(e) => this._onCheckboxSelect(item, e)}
            />
          </Item>
        ))}
      </React.Fragment>
    )
  }

  _updateInitialSelectedOption = (initialSelectedItems) => {
    const initialSelectedOption = {}
    _.each(initialSelectedItems, (initialSelectedItem) => {
      initialSelectedOption[initialSelectedItem.id] = initialSelectedItem
    })
    this.setState({
      selectedOption: initialSelectedOption,
      showCounter: _.size(initialSelectedOption) > 0
    })
  }

  _onReset = () => {
    this._onClose(false)
  }

  componentDidMount = () => {
    this._updateInitialSelectedOption(this.props.initialSelectedItems)
  }

  componentDidUpdate(prevProps) {
    if (this.props.initialSelectedItems !== prevProps.initialSelectedItems) {
      this._updateInitialSelectedOption(this.props.initialSelectedItems)
    }
  }

  render() {
    const selectedOptionCount = _.size(this.state.selectedOption)
    const { placeholder } = this.props

    return (
      <div id='search-container' ref={this.thisNodeRef} className='bf-search-container'>
        {selectedOptionCount > 0 && this.state.showCounter ? (
          <BfButtonCountTag
            closable
            onClose={this._clearSelection}
            tabIndex={0}
            onClick={this._displayMenuOnClick}
            itemsCount={selectedOptionCount}
          />
        ) : (
          <div className='bf-flex-control-container hcl-form-control'>
            <BfSimpleSearch
              type='text'
              className='bf-search-input-text'
              placeholder={placeholder}
              autoComplete='off'
              onChange={this._onFilterInputChange}
              onKeyDown={this._keyDown}
              onClick={this._onTextInputClick}
              value={this.state.text}
              showReset={false}
            />
            {this.state.showMenu && (
              <button className='bf-right-control' onClick={this._onReset}>
                <i className='p-hclsw p-hclsw-close selected bf-close' />
              </button>
            )}
          </div>
        )}

        <Overlay
          showOverlay={this.state.showMenu}
          targetElement={this.state.targetElement}
          onToggle={this._onClose}
          direction='bottom-left'
          closeOnEscape
          scrollListner
          attachElementToBody
          style={{
            minWidth: this.state.targetElement ? this.state.targetElement.offsetWidth + 'px' : '0'
          }}
        >
          <MenuList className='hcl-search-overlay' ref={this.menulistRef} onKeyDown={this._keyDownMenu}>
            {this._renderSuggestions()}
          </MenuList>
        </Overlay>
      </div>
    )
  }
}

export default withTranslation()(BfDropdownSearch)
