/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import React from 'react'
import './BfLinkWithIconRenderer.scss'
import _ from '../../../services/utils/BfLodash'

import BfLinkWithTooltip from './BfLinkWithTooltip'
import BfCellWidth from './BfCellWidth'
import BfIconRenderer from '../../common/BfIconRenderer'

/**
 * Renders Custom item cells
 */
class BfLinkWithIconRenderer {
  constructor(header) {
    this.header = header
    this.name = header.field
  }

  /**
   *
   * @method
   * @returns {Function} Cell Renderer
   */
  getRenderer() {
    return this._getRenderHTML
  }

  /**
   * Renders Custom cell
   * @method
   * @param {Object} row CustomItem value
   * @returns {HTMLElement} Cell HTML
   */
  _getRenderHTML = (row) => {
    const value = _.isUndefined(row[this.name]) ? '' : row[this.name].toString()

    return (
      <BfCellWidth header={this.header}>
        <div className='bf-customRender'>
          <BfIconRenderer icon={row.icon} />

          <div className='bf-ellipsis'>
            <BfLinkWithTooltip href={row.link} value={value} toolTipValue={value} />
          </div>
        </div>
      </BfCellWidth>
    )
  }
}

export default BfLinkWithIconRenderer
