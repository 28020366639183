import React, { Component } from 'react'
import _ from '../../services/utils/BfLodash'
import { Modal } from '@patron/patron-react/modal'
import { Button } from '@patron/patron-react/button'
import { Checkbox } from '@patron/patron-react/checkbox'
import './BfModalListSearch.scss'
import { Toggle } from '@patron/patron-react/toggle'
import { withTranslation } from 'react-i18next'
import { bfTranslate } from '../../services/i18n/bfI18Utils'
import BfSearchWithFilter from '../datagrid/filters/common/bfSearch/BfSearchWithFilter'
import BfPaginationWithFilter from '../datagrid/filters/common/bfPagination/BfPaginationWithFilter'
import BfConsole from 'loglevel'

class BfModalListSearch extends Component {
  state = {
    selectedItems: {},
    showOnlySelected: false,
    count: 0,
    items: []
  }

  _setSelectedItems = (newSelectedItems) => {
    this.setState((state) => {
      const { selectedItems } = state
      _.each(newSelectedItems, (seletedElem) => {
        selectedItems[seletedElem] = true
      })
      return { selectedItems: selectedItems }
    })
  }

  componentDidMount = () => {
    this._setSelectedItems(this.props.selectedItems)
    this.props.bfSearchListData.get(this._onReceivedData)
  }

  _onReceivedData = (data) => {
    this.setState({ count: data.count, items: data.data })
  }

  _search = (searchObj) => {
    BfConsole.log('Searching for', searchObj)
    this.props.bfSearchListData.updateUrlAndSearch(searchObj, this._onReceivedData)
  }

  _onChangePagination = (searchObj) => {
    BfConsole.log('Searching for', searchObj)
    this._search(searchObj)
  }

  areAllSelected = () => {
    return _.every(this.state.items, (item) => {
      return _.has(this.state.selectedItems, item.id)
    })
  }

  _onToggleOnlySelected = () => {
    this.setState((state) => ({
      showOnlySelected: !state.showOnlySelected
    }))
  }

  _onSelectAll = (event) => {
    const checked = event.target.checked
    this.setState((state) => {
      const { selectedItems } = state
      if (checked) {
        _.each(this.state.items, (item) => {
          selectedItems[item.id] = true
        })
      } else {
        _.each(this.state.items, (item) => {
          delete selectedItems[item.id]
        })
      }
      return { selectedItems: selectedItems }
    })
  }

  _onChangeCheckbox = (event) => {
    const itemId = event.target.id
    this.setState((state) => {
      const { selectedItems } = state
      if (selectedItems[itemId]) {
        delete state.selectedItems[itemId]
      } else {
        selectedItems[itemId] = true
      }
      return { selectedItems: selectedItems }
    })
  }

  _onOk = () => {
    BfConsole.log(_.keys(this.state.selectedItems))
    this.props.onSave(_.keys(this.state.selectedItems))
  }

  render() {
    const { onClose, t, bfSearchListData } = this.props

    return (
      <Modal
        actions={[
          {
            disabled: false,
            label: bfTranslate(t, 'ok'),
            primary: true,
            handler: this._onOk
          },
          {
            label: bfTranslate(t, 'cancel'),
            primary: false,
            handler: onClose
          }
        ]}
        className='bfModal'
        heading={this.props.title}
        keyboard
        onClose={onClose}
        type='default'
      >
        <div className='hcl-row bfTopBarRow'>
          <div className='hcl-col-8'>
            <Toggle
              aria-label={bfTranslate(t, 'ViewSelectedOnly')}
              disabled={_.keys(this.state.selectedItems).length < 1 && !this.state.onlySelected}
              id='onlySelected'
              labelOff=''
              labelOn=''
              onChange={this._onToggleOnlySelected}
              small
            />
            {bfTranslate(t, 'ViewSelectedOnly')}
          </div>
          <Button onClick={this.props.onReset} title='Default' type='primary'>
            Reset
          </Button>
          <div className='hcl-col-4'>
            <BfSearchWithFilter onSearch={this._search} bfSortFilter={bfSearchListData.getSortFilter()} />
          </div>
        </div>
        <div className='hcl-row bfPaginationRow'>
          <BfPaginationWithFilter
            onChange={this._onChangePagination}
            totalItems={this.state.count}
            bfPaginationFilter={bfSearchListData.getPaginationFilter()}
          />
        </div>
        <div className='hcl-row'>
          <div className='hcl-col-12 bfCheckboxContainer'>
            <Checkbox
              checked={this.areAllSelected()}
              className=''
              disabled={false}
              id='selectAllCheckbox'
              label={bfTranslate(t, 'selectAll')}
              onChange={this._onSelectAll}
            />
          </div>
        </div>
        <div className='hcl-row'>
          {this.state.items
            .filter((item) => (this.state.showOnlySelected ? this.state.selectedItems[item.id] : true))
            .map((item) => (
              <React.Fragment key={item.id}>
                <div className='hcl-col-3 bfCheckboxContainer'>
                  <Checkbox
                    checked={!_.isUndefined(this.state.selectedItems[item.id])}
                    id={item.id}
                    label={this.props.getItemDisplayName(item)}
                    onChange={this._onChangeCheckbox}
                  />
                </div>
              </React.Fragment>
            ))}
        </div>
      </Modal>
    )
  }
}

export default withTranslation()(BfModalListSearch)
