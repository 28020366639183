/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import _ from '../../../../../../services/utils/BfLodash'
import BfBasicFilter from '../../../BfBasicFilter'

class BfDateRangeBasicFilter extends BfBasicFilter {
  getEndDate = () => {
    const name = this.getName()
    return this[name] && this[name].endDate ? new Date(this[name].endDate * 1000) : null
  }

  getStartDate = () => {
    const name = this.getName()
    return this[name] && this[name].startDate ? new Date(this[name].startDate * 1000) : null
  }

  setDateRange = (start, end) => {
    const name = this.getName()

    this[name] = _.assign({}, start && { startDate: start }, end && { endDate: end })
  }
}

export default BfDateRangeBasicFilter
