/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import React, { Component } from 'react'
import './BfModalDataGrid.scss'
import ReactDOM from 'react-dom'
import BfDataGridTable from '../datagrid/BfDataGridTable'
import BfModalOkCancel from './BfModalOkCancel'

class BfModalDataGrid extends Component {
  selectedItems = []
  state = { modalRef: null, errorMessage: null, errorType: null }

  componentDidMount() {
    this.setState({
      modalRef: ReactDOM.findDOMNode(this)
    })
  }

  _onError = (code, errorMessage, errorType) => {
    this.setState({
      errorMessage: errorMessage,
      errorType: errorType
    })
  }

  render() {
    const { onClose, onSave, heading, labelConfirm, ...rest } = this.props
    const DataGridTableComponent = this.props.datagridTable ? this.props.datagridTable : BfDataGridTable
    return (
      <BfModalOkCancel
        onClose={this.props.onClose}
        onOk={this.props.onSave}
        title={heading}
        labelConfirm={labelConfirm}
        classStyle='bfmodalstyle'
        errorMessage={this.state.errorMessage}
        errorType={this.state.errorType}
      >
        <DataGridTableComponent
          displayContainer={{
            type: 'modal',
            containerRef: this.state.modalRef
          }}
          onError={this._onError}
          {...rest}
        />
      </BfModalOkCancel>
    )
  }
}

export default BfModalDataGrid
