/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import { map } from 'rxjs/operators'
import BfCachedRequest from '../../../../../services/utils/BfCachedRequest'
import _ from '../../../../../services/utils/BfLodash'
import BfBasicFilter from '../../BfBasicFilter'
import { createOsDropDownItem } from '../../common/BfCheckboxTree/BfCheckboxTree'

class BfOperatingSystemFilter extends BfBasicFilter {
  bfCachedRequest = new BfCachedRequest()
  static NAME = 'operatingSystem'
  static PATH = `filter.${BfOperatingSystemFilter.NAME}`
  constructor(data = {}) {
    super(BfOperatingSystemFilter.PATH, BfOperatingSystemFilter.NAME, data)
  }

  getSelectedItems = () => {
    return _.size(this.operatingSystem)
  }

  getItems = () => {
    return this.bfCachedRequest.get('/api/framework/osFilters').pipe(
      map((res) => {
        return createOsDropDownItem(res.items)
      })
    )
  }
}

export default BfOperatingSystemFilter
