/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import BfReduxStateConstants from '../../../../common/BfReduxStateConstants'
import BfDropDownSearchBasicFilter from '../../common/bfBasicFilters/BfDropDownSearchFilter/BfDropDownSearchBasicFilter'

class BfOwnerNameFilter extends BfDropDownSearchBasicFilter {
  static NAME = 'owner'
  static PATH = `filter.${BfOwnerNameFilter.NAME}`
  static NAMESPACE = BfReduxStateConstants.DEVICES

  constructor(data = {}) {
    super(BfOwnerNameFilter.PATH, BfOwnerNameFilter.NAME)
    this[this.getName()] = data
  }

  getSearchURL = () => {
    return '/swd/api/packages/owners'
  }
}

export default BfOwnerNameFilter
