/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/
import React, { Component } from 'react'
import { map } from 'rxjs/operators'
import { isObservable, of } from 'rxjs'
import _ from '../../../../../../services/utils/BfLodash'
import { withTranslation } from 'react-i18next'
import BfHttpClient from '../../../../../../services/authClient/BfHttpClient'
import BfDropdownSearch, { createDropDownItem } from '../../../../../common/BfDropdownSearch'
import BfDebounceSubject from '../../../../../common/BfDebounceSubject'

class BfDropDownSearchWithFilter extends Component {
  bfHttpClient = new BfHttpClient()
  fixedParams = { search: { limit: 20 } }
  bfDebounceFilter = new BfDebounceSubject()

  constructor(props) {
    super(props)
    this.state = {}

    this.bfDebounceFilter.subscribe({
      next: (data) => {
        this.props.onChange(data)
      }
    })
  }

  _getInitialSelectedItems = () => {
    const { filter } = this.props
    let selectedItems = []
    const data = filter.getData()
    if (_.isNotEmpty(data)) {
      if (_.isDefined(filter.getInitialSelectedItems)) {
        selectedItems = filter.getInitialSelectedItems()
      } else if (_.isArray(data) && this.state.allItems) {
        selectedItems = this.state.allItems
          .filter((value) => {
            return _.includes(data, value.id)
          })
          .map((val) => createDropDownItem(val.id, val.name))
      } else if (_.isObject(data)) {
        selectedItems = _.map(_.keys(data), (value) => createDropDownItem(value))
      }
    }
    return selectedItems
  }

  _wrapObservable = (getItemsRequest) => {
    return isObservable(getItemsRequest) ? getItemsRequest : of(getItemsRequest)
  }

  _invokeGetItems = (params) => {
    const { filter } = this.props
    let _getItemObservable
    if (_.isDefined(filter.getItems)) {
      _getItemObservable = this._wrapObservable(filter.getItems(params?.search?.query))
    } else {
      _getItemObservable = this.bfHttpClient.get(filter.getSearchURL(), params)
    }
    return _getItemObservable
  }

  _getItems = (filterValue) => {
    const params = _.cloneDeep(_.isDefined(this.props.filter.fixedParams) ? this.props.filter.fixedParams : this.fixedParams)
    if (_.isNotEmpty(filterValue)) {
      params.search.query = filterValue
    }

    return this._invokeGetItems(params).pipe(
      map((res) => {
        return _.map(res.values, (value) => {
          if (_.isString(value)) return createDropDownItem(value)
          return value
        })
      })
    )
  }

  componentDidMount = () => {
    if (this.props.filter.isIDArray && this.props.filter.getAllURL()) {
      this.bfHttpClient.get(this.props.filter.getAllURL()).subscribe((allItems) => {
        this.setState({ allItems: allItems, initialLoaded: true })
      })
    } else {
      this.setState({ initialLoaded: true })
    }
  }

  _triggerFilterUpdate = (data) => {
    this.props.filter.setData(data)
    this.bfDebounceFilter.next(this.props.filter.get())
  }

  _onItemSelected = (item, selectedItemsIds, selectedItems) => {
    const queryParamsIds = this.props.filter.getQueryItemsIds(selectedItemsIds, selectedItems)
    if (isObservable(queryParamsIds)) {
      queryParamsIds.subscribe({
        next: (data) => this._triggerFilterUpdate(data)
      })
    } else {
      this._triggerFilterUpdate(queryParamsIds)
    }
  }

  render() {
    const { filter, t } = this.props
    filter.setT(t)
    return (
      <BfDropdownSearch
        onItemSelected={this._onItemSelected}
        getItems={this._getItems}
        initialSelectedItems={this.state.initialLoaded ? this._getInitialSelectedItems() : []}
      />
    )
  }
}

export default withTranslation()(BfDropDownSearchWithFilter)
