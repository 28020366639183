/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/
import React, { Component } from 'react'
import BfConsole from 'loglevel'
import BfDateRangeSelectorWithOptions from '../../../../../common/bfDateRangeSelector/BfDateRangeSelectorWithOptions'
import { BfReset } from '../../../BfWithReset'

class BfDateRangeWithFilter extends Component {
  _onDateRangeSelect = (startDate, endDate) => {
    BfConsole.debug(`Start date is: ${startDate}, end date is : ${endDate}`)

    this.props.filter.setDateRange(startDate && Math.floor(startDate.getTime() / 1000), endDate && Math.floor(endDate.getTime() / 1000))
    this.props.onChange(this.props.filter.get())
  }

  render() {
    const { filter } = this.props

    const startDate = filter.getStartDate()
    const endDate = filter.getEndDate()
    BfConsole.debug(`Start date is: ${startDate}, end date is : ${endDate}`)
    return (
      <BfReset filter={filter}>
        {(key) => <BfDateRangeSelectorWithOptions key={key} onDateRangeSelected={this._onDateRangeSelect} startDate={startDate} endDate={endDate} />}
      </BfReset>
    )
  }
}

export default BfDateRangeWithFilter
