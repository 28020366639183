/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import BfConsole from 'loglevel'
import BfForm from '../../../form/BfForm'
import BfInputField from '../../../form/BfInputField'
import { bfTranslate } from '../../../../services/i18n/bfI18Utils'
import BfModalSubmit from '../../../modals/BfModalSubmit'
import * as Yup from 'yup'
import './BfSendMessageModal.scss'
import BfSendMessageService from '../../../../services/devices/BfSendMessageService'
import BfEditorField from '../../../form/BfEditorField'
import BfWithMessageProvider from '../../../common/bfMessage/BfWithMessageProvider'

class BfSendMessageModal extends Component {
  state = { errorMessage: '' }
  bfSendMessageService = new BfSendMessageService()

  EDITOR_BUTTONS = {
    buttonList: [
      ['bold', 'italic', 'underline', 'removeFormat'],
      ['strike', 'superscript', 'subscript'],
      ['fontColor', 'hiliteColor'],
      ['list', 'align'],
      ['link', 'horizontalRule', 'table'],
      ['codeView']
    ]
  }

  constructor(props) {
    super(props)

    this.validationSchema = Yup.object().shape({
      subject: Yup.string().required(bfTranslate(props.t, 'fieldRequired')).max(240, bfTranslate(props.t, 'MaxCharacter')),
      message: Yup.string().required(bfTranslate(props.t, 'fieldRequired'))
    })
  }

  _handleSubmit = async (formValues) => {
    const promise = new Promise((resolve, reject) => {
      this._internalHandleSubmit(formValues, resolve, reject)
    })
    return promise
  }

  _internalHandleSubmit = (formValues, resolve, reject) => {
    const { t } = this.props
    BfConsole.log('_handleSubmit')
    const { subject, message } = formValues
    const { selectedDevices } = this.props
    return this.bfSendMessageService.send(selectedDevices, subject, message).subscribe({
      next: (response) => {
        BfConsole.debug(response)
        const titleMessage = bfTranslate(t, 'SendMessageTitle')
        const resultMessage = bfTranslate(t, 'SendMessageSuccessMessage')
        BfConsole.log(resultMessage)
        this.props.sendDetailedMessage('', titleMessage, resultMessage, 'success')
        resolve(resultMessage)
        this.props.onClose()
      },
      error: (errorResponse) => {
        const err = errorResponse.data
        BfConsole.error('error while sending message - takeaction: ', err)
        let errorMessage = ''
        if (err.errorObj && err.errorObj.type === 'encoding') {
          var codePage = 'Language' + err.errorObj.codePage.codePageLanguage
          var message =
            bfTranslate(t, 'InvalidCharacterWarning', {
              message: err.errorObj.message
            }) +
            ' ' +
            bfTranslate(t, 'SupportedLanguageWarning', { codePage: codePage })

          errorMessage = bfTranslate('EncodingError') + ' : ' + message
        } else {
          errorMessage = bfTranslate(t, 'SendMessageErrorTitle') + ' : ' + bfTranslate(t, 'SendMessageErrorMessage')
        }
        this.setState({ errorMessage: errorMessage })
        resolve(errorMessage)
      }
    })
  }

  render() {
    const { onClose, t } = this.props

    return (
      <BfForm
        initialValues={{
          subject: '',
          message: ''
        }}
        handleSubmit={this._handleSubmit}
        validationSchema={this.validationSchema}
        noMargin
      >
        {(form) => {
          return (
            <BfModalSubmit
              labelSubmit='Send'
              onClose={onClose}
              onSubmit={async () => {
                this.setState({ errorMessage: '' })
                await form.submitForm()
              }}
              errorMessage={this.state.errorMessage}
              disableSubmit={!form.isValid || !form.dirty}
              title={bfTranslate(t, 'SendMessageTitle')}
              classStyle='bfSendMessageModal'
              t={t}
              labelLoading={bfTranslate(t, 'labelLoadingSendMessage')}
            >
              <BfInputField
                id='subject'
                name='subject'
                label={bfTranslate(t, 'MessageSubject')}
                placeholder={bfTranslate(t, 'phInputSubject')}
                autoComplete='off'
                required
                maxLength={240}
                ellipsis
                autofocus
              />
              <BfEditorField
                name='message'
                id='message'
                required
                label={bfTranslate(t, 'EnterMessageDescription')}
                height='auto'
                options={this.EDITOR_BUTTONS}
                oldStyle
              />
            </BfModalSubmit>
          )
        }}
      </BfForm>
    )
  }
}
export default withTranslation()(BfWithMessageProvider(BfSendMessageModal))
