/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import BfConsole from 'loglevel'

import { UPDATE_SHOW_SUMMARY, UPDATE_SUMMARY_LOADED } from './BfChartsActionTypes'

export const BfChartsReducer =
  (namespace) =>
  (state = { showSummary: false, isSummaryLoaded: false }, action) => {
    BfConsole.info('BfChartsReducer action ', action)
    BfConsole.info('BfChartsReducer state ', state)

    switch (action.type) {
      case `${namespace}/${UPDATE_SHOW_SUMMARY}`: {
        return {
          ...state,
          showSummary: action.showSummary
        }
      }
      case `${namespace}/${UPDATE_SUMMARY_LOADED}`: {
        return {
          ...state,
          isSummaryLoaded: action.isSummaryLoaded
        }
      }
      default: {
        return state
      }
    }
  }
