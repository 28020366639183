/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/
import _ from '../../../../../services/utils/BfLodash'

import { DEFAULT_SORT_ORDER, DEFAULT_SORT_METHOD } from '../../../filters/BfFiltersConstants'
import BfBasicFilter from '../../BfBasicFilter'

/** WARNING: LastReportTime sorting is inverted (BW-22425) */
const MAP_OVERRIDE_SORT_ORDER = {
  lastReportTime: {
    asc: 'desc',
    desc: 'asc'
  },
  lastModificationTime: {
    asc: 'desc',
    desc: 'asc'
  },
  lastModifiedDate: {
    asc: 'desc',
    desc: 'asc'
  }
}
class BfSortFilter extends BfBasicFilter {
  static NAME = 'sort'
  static PATH = `${BfSortFilter.NAME}`
  constructor(order = DEFAULT_SORT_ORDER, method = DEFAULT_SORT_METHOD) {
    super(BfSortFilter.PATH, BfSortFilter.NAME)
    this.sort = this._buildSort(order, method)
  }

  _buildSort = (order, method) => {
    order = (MAP_OVERRIDE_SORT_ORDER && MAP_OVERRIDE_SORT_ORDER[method] && MAP_OVERRIDE_SORT_ORDER[method][order]) || order
    return { order: order, method: method }
  }

  get = () => {
    return this._isEmpty() ? { sort: {} } : { sort: this.sort }
  }

  _isEmpty = () => {
    return _.isEmpty(this.sort) && _.isEmpty(this.sort.order) && _.isEmpty(this.sort.method)
  }

  buildFromURLHandler = (urlHandler, consumeUrl = false) => {
    const sort = this._buildFromURLHandler(urlHandler, consumeUrl)
    if (_.isNotEmpty(sort)) {
      this.sort = this._buildSort(sort.order, sort.method)
    }
  }

  getMethod = () => {
    return this.sort.method
  }

  setMethod = (method) => {
    return (this.sort.method = method)
  }

  getOrder = () => {
    return this.sort.order
  }

  setOrder = (order) => {
    return (this.sort.order = order)
  }
}

export default BfSortFilter
