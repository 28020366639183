/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/
import { map } from 'rxjs/operators'
import BfCloudTagsInfo from '../../../../../services/devices/BfCloudTagsInfo'
import _ from '../../../../../services/utils/BfLodash'
import { createDropDownItem } from '../../../../common/BfDropdownSearch'
import BfDropDownSearchBasicFilter from '../../common/bfBasicFilters/BfDropDownSearchFilter/BfDropDownSearchBasicFilter'

class BfCloudTagsFilter extends BfDropDownSearchBasicFilter {
  static NAME = 'tags'
  static PATH = `filter.${BfCloudTagsFilter.NAME}`
  TAG_SEPARATOR = '::'
  bfCloudTagsInfo = new BfCloudTagsInfo()

  constructor(data = {}) {
    super(BfCloudTagsFilter.PATH, BfCloudTagsFilter.NAME, data)
  }

  getInitialSelectedItems = () => {
    let selectedItems = []
    const data = this.getData()

    if (_.isNotEmpty(data.ftags)) {
      selectedItems = _.map(data.ftags, (tag) => createDropDownItem(tag.key + this.TAG_SEPARATOR + tag.value))
    }
    return selectedItems
  }

  getSearchURL = () => {
    return '/framework/devices/tags'
  }

  _getCloudTags = (selectedItemsIds) => {
    return _.map(selectedItemsIds, (selectedItem) => {
      const splittedTag = selectedItem.split(this.TAG_SEPARATOR)
      return { key: splittedTag[0], value: splittedTag[1] }
    })
  }

  _getAgentTypesContentKeys = () => {
    return this.bfCloudTagsInfo.getShowTags().pipe(map((showTagsObj) => showTagsObj.agentTypes))
  }

  getQueryItemsIds = (selectedItemsIds) => {
    return this._getAgentTypesContentKeys().pipe(
      map((agentTypes) => {
        const tagFilter =
          selectedItemsIds.length > 0
            ? {
                fagentTypes: agentTypes,
                ftags: this._getCloudTags(selectedItemsIds)
              }
            : {}
        return tagFilter
      })
    )
  }
}

export default BfCloudTagsFilter
