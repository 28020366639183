/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2021
// All Rights Reserved
*/

import React from 'react'
import { useDispatch } from 'react-redux'
import { deselectRowsAction, selectRowsAction, toggleSelectedOnly } from './redux/BfFilterListUpdateURLAction'

const BfWithDataGridTableFeatures = (WrappedComponent) => {
  const BfDataGridFeatureComponent = (props) => {
    const dispatch = useDispatch()
    const _deselectRows = (dataGridId, rowsIds) => {
      dispatch(deselectRowsAction(dataGridId, rowsIds))
    }

    const _selectRows = (dataGridId, rowsIds) => {
      dispatch(selectRowsAction(dataGridId, rowsIds))
    }

    const _toggleSelectedOnly = (dataGridId, selected) => {
      dispatch(toggleSelectedOnly(dataGridId, selected))
    }

    return <WrappedComponent selectRows={_selectRows} deselectRows={_deselectRows} toggleSelectedOnly={_toggleSelectedOnly} {...props} />
  }

  return BfDataGridFeatureComponent
}

export default BfWithDataGridTableFeatures
