/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

export const DEFAULT_ITEMS_PER_PAGE = 20
export const DEFAULT_OFFSET = 0
export const DEFAULT_SORT_ORDER = 'desc'
export const DEFAULT_SORT_METHOD = 'relevantPatchCount'

export const SORT_METHODS = {
  NAME: 'name',
  LAST_REPORT_TIME: 'lastReportTime',
  RELEVANT_PATCH_COUNT: 'relevantPatchCount'
}

export const ONLY_SELECTED_FILTER = 'onlySelected'

export const OS_VERSIONS = Object.freeze({
  Windows: ['Win2019', 'Win2016', 'Win2012R2', 'Win2012', 'Win2008R2', 'Win2008', 'Win2003', 'Win10', 'Win8.1', 'Win8', 'Win7', 'WinVista', 'WinXP'],
  RHEL: ['RHEL8', 'RHEL7', 'RHEL6', 'RHEL5'],
  CentOS: ['CENTOS8', 'CENTOS7', 'CENTOS6'],
  OracleLinux: ['ORACLE8', 'ORACLE7', 'ORACLE6'],
  Ubuntu: ['UBUNTU20', 'UBUNTU18', 'UBUNTU16', 'UBUNTU14'],
  SUSE: ['SLE15', 'SLE12_PPC', 'SLE12_SYSZ', 'SLE12', 'SLE11'],
  Debian: ['DEBIAN7'],
  OSX: ['OSX10.15', 'OSX10.14', 'OSX10.13', 'OSX10.12', 'OSX10.11', 'OSX10.10', 'OSX10.9', 'OSX10.8', 'OSX10.7', 'OSX10.6']
})
