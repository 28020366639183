/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2021
// All Rights Reserved
*/
import React from 'react'
import { Field } from 'formik'
import PropTypes from 'prop-types'
import './BfForm.scss'

import { DateRangeSelector } from '@patron/patron-react/daterangeselector'

import './BfDateRangeSelector.scss'

function BfDateRangeSelector(props) {
  const { id, name } = props

  const onDateRangeSelect = (form) => (dateRange) => {
    form.setFieldValue(`${name}.start`, dateRange.start.toISOString())
    form.setFieldValue(`${name}.end`, dateRange.end.toISOString())
  }

  return (
    <div className='hcl-form-group'>
      <Field name={name}>
        {({ field, form }) => (
          <div>
            <DateRangeSelector
              attachElementToBody={false}
              direction='bottom-left'
              format='mm/dd/yyyy'
              id={id || name}
              defaultStartDate={field.value?.start && new Date(field.value.start)}
              defaultEndDate={field.value?.end && new Date(field.value.end)}
              maxDate={new Date('+010000-01-31T08:00:00.000Z')}
              minDate={new Date('1000-01-01T07:52:58.000Z')}
              months={['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']}
              onDateRangeSelect={onDateRangeSelect(form)}
              type='rangepicker'
              weekDays={['S', 'M', 'T', 'W', 'Th', 'F', 'S']}
            />
          </div>
        )}
      </Field>
    </div>
  )
}

BfDateRangeSelector.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired
}

export default BfDateRangeSelector
