/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2021
// All Rights Reserved
*/

import { map } from 'rxjs/operators'
import BfCachedRequest from '../../../../../services/utils/BfCachedRequest'
import _ from '../../../../../services/utils/BfLodash'
import BfBasicFilter from '../../BfBasicFilter'
import { createOsDropDownItem } from '../../common/BfCheckboxTree/BfCheckboxTree'

class BfCreatorFilter extends BfBasicFilter {
  bfCachedRequest = new BfCachedRequest()
  static NAME = 'creator'
  static PATH = `filter.${BfCreatorFilter.NAME}`
  constructor(data = {}) {
    super(BfCreatorFilter.PATH, BfCreatorFilter.NAME, data)
  }

  getSelectedItems = () => {
    return _.size(this.creator)
  }

  getItems = () => {
    return this.bfCachedRequest.get('/api/policyGroupsUnpaginated').pipe(
      map((res) => {
        var creatorOptions = []
        for (var i = 0; i < res.data.length; i += 1) {
          if (creatorOptions.filter((e) => e.name === res.data[i].createdBy).length === 0) {
            creatorOptions.push({
              name: res.data[i].createdBy,
              id: res.data[i].createdBy
            })
          }
        }
        return createOsDropDownItem(creatorOptions)
      })
    )
  }
}

export default BfCreatorFilter
