/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/
import React, { Component } from 'react'
import _ from '../../../../../../services/utils/BfLodash'
import BfDropDownFilter from './BfDropDownFilter'
import { withTranslation } from 'react-i18next'
import { BfReset } from '../../../BfWithReset'

class BfDropDownWithFilter extends Component {
  _getInitialSelectedItems = () => {
    let selectedItems = []
    const data = this.props.filter.getData()

    if (!_.isEmpty(data)) {
      selectedItems = _.keys(data)
    }
    return selectedItems
  }

  _onItemsSelected = (item, selectedItemsIds) => {
    const queryParamsIds = this.props.filter.getQueryItemsIds(selectedItemsIds)
    this.props.filter.setData(queryParamsIds)
  }

  render() {
    const { filter, t } = this.props
    filter.setT(t)
    return (
      <BfReset filter={filter}>
        {(key) => (
          <BfDropDownFilter
            key={key}
            bfFilter={filter}
            onItemsSelected={this._onItemsSelected}
            getItemsRequest={filter.getItems}
            initialSelectedItems={this._getInitialSelectedItems()}
            onFilterApplied={this.props.onChange}
          />
        )}
      </BfReset>
    )
  }
}

export default withTranslation()(BfDropDownWithFilter)
