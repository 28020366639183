/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

/* eslint-disable no-restricted-imports */
import _ from 'lodash'

_.mixin({
  isDefined: (obj) => !_.isUndefined(obj),
  isNotEmpty: (obj) => !_.isEmpty(obj),
  isNotNull: (obj) => !_.isNull(obj),
  isNotNil: (obj) => !_.isNil(obj),
  isTrue: (obj) => !!obj,
  insertAfter: (array, elem, afterElem) => {
    const newArray = [...array]
    const removedElem = _.remove(newArray, (_elem) => _elem.id === elem.id)
    const afterIndex = _.findIndex(newArray, (_elem) => _elem.id === afterElem.id)
    const left = _.slice(newArray, 0, afterIndex + 1)
    const right = _.slice(newArray, afterIndex + 1)
    return _.concat(left, removedElem, right)
  },
  move: (array, movedElement, toReplaceElement) => {
    const newArray = [...array]
    const fromIndex = _.findIndex(newArray, (_elem) => _elem.id === movedElement.id)
    const toIndex = _.findIndex(newArray, (_elem) => _elem.id === toReplaceElement.id)

    if (fromIndex === toIndex) return newArray

    const target = newArray[fromIndex]
    const inc = toIndex < fromIndex ? -1 : 1

    for (let i = fromIndex; i !== toIndex; i += inc) {
      newArray[i] = newArray[i + inc]
    }

    newArray[toIndex] = target

    return newArray
  },
  swap: (array, toBeReplaced, replacement) => {
    let toBeReplacedIndex = -1
    let replacementIndex = -1
    const newArray = [...array]
    _.each(array, (elem, index) => {
      if (elem.id === toBeReplaced.id) {
        toBeReplacedIndex = index
      }
      if (elem.id === replacement.id) {
        replacementIndex = index
      }
      if (toBeReplacedIndex !== -1 && replacementIndex !== -1) {
        return false
      }
    })
    if (toBeReplacedIndex !== -1 && replacementIndex !== -1) {
      const tmp = newArray[toBeReplacedIndex]
      newArray[toBeReplacedIndex] = newArray[replacementIndex]
      newArray[replacementIndex] = tmp
    }
    return newArray
  },
  // eslint-disable-next-line no-extra-boolean-cast
  isFalse: (obj) => !(!!obj) // prettier-ignore
})

export default _
