/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/
import _ from '../../../../../services/utils/BfLodash'
import BfBasicFilter from '../../BfBasicFilter'

class BfSearchBasicFilter extends BfBasicFilter {
  constructor(path, name, query) {
    super(path, name)
    this[this.getName()] = _.isDefined(query) ? { query: query } : {}
  }

  get = () => {
    return _.isEmpty(this[this.getName()].query) ? { search: {} } : { search: this[this.getName()] }
  }

  setQuery = (query) => {
    this[this.getName()].query = query
  }

  getQuery = () => {
    return this[this.getName()].query
  }
}

export default BfSearchBasicFilter
