/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/
import BfBasicCustomFilter from '../custom/BfBasicCustomFilter'
import _ from '../../../../services/utils/BfLodash'
import { createDropDownItem } from '../../../common/BfDropdownSearch'

class BfPatchSiteIDFilter extends BfBasicCustomFilter {
  static NAME = 'siteIDs'
  static PATH = `filter.${BfPatchSiteIDFilter.NAME}`
  isIDArray = true
  constructor(data = {}) {
    super(BfPatchSiteIDFilter.PATH, BfPatchSiteIDFilter.NAME)
    this[BfPatchSiteIDFilter.NAME] = data
  }

  getQueryItemsIds = (selectedItemsIds) => {
    return selectedItemsIds
  }

  processResponse = (res) => {
    return { values: _.map(res, (value) => createDropDownItem(value.id, value.name)) }
  }

  getSearchURL = () => {
    return '/api/sites/getExternalFixletSites'
  }

  getAllURL = () => {
    return '/api/sites/getExternalFixletSites'
  }
}

export default BfPatchSiteIDFilter
