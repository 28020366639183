/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2021
// All Rights Reserved
*/

import React, { useEffect, useState } from 'react'

import { PieChart } from '@patron/patron-react-chart/piechart'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import logConsole from 'loglevel'

import { bfTranslate } from '../../../services/i18n/bfI18Utils'
import _ from '../../../services/utils/BfLodash'
import { CHART_COLORS } from './BfChartConstants'
import BfChartList from './BfChartList/BfChartList'
import BfChartContainer from './BfChartContainer/BfChartContainer'
import './BfSharedChartStyles.scss'

const BfOSFamilyChart = ({ osData, gettingChartData, updateFilterListUrlAction, updateChartLoaded, chartId, namespace, isLoaded }) => {
  const [state, setState] = useState(undefined)
  const { t } = useTranslation()
  const OTHER_NAME = 'Other'
  const MAX_ITEMS = 10

  const _onArcClick = (arc) => {
    if (arc.data.filters) {
      updateFilterListUrlAction(namespace, arc.data.filters)
    } else {
      logConsole.log('no filters')
    }
  }

  const _onListClick = (item) => {
    logConsole.log(item)
    if (item?.filters) {
      updateFilterListUrlAction(namespace, item.filters)
    } else {
      logConsole.log('no filters')
    }
  }

  useEffect(() => {
    if (!_.isUndefined(osData) && gettingChartData === false) {
      let sortedOSData = _.orderBy(osData, ['count', 'name'], ['desc', 'asc'])

      if (sortedOSData.length > MAX_ITEMS) {
        const overflowItems = sortedOSData.slice(MAX_ITEMS - 1)
        sortedOSData = sortedOSData.slice(0, MAX_ITEMS - 1)

        let overflowFilters = {}
        let overflowCount = 0
        overflowItems.forEach((item) => {
          overflowFilters = {
            ...overflowFilters,
            ...item?.filters?.operatingSystem
          }
          overflowCount += item.count
        })
        sortedOSData.push({
          name: OTHER_NAME,
          count: overflowCount,
          ...(!_.isEmpty(overflowFilters) && {
            filters: { operatingSystem: overflowFilters }
          })
        })
      }

      sortedOSData = sortedOSData.map((data) => {
        return {
          ...data,
          label: bfTranslate(t, data.name)
        }
      })

      const newColorArr = []
      let i = 0
      const newRowData = []

      if (sortedOSData.length !== 0) {
        sortedOSData.forEach((data) => {
          newColorArr.push({ color: CHART_COLORS[i], label: data.label })
          newRowData.push({
            label: data.label,
            key: data.name,
            color: CHART_COLORS[i++],
            value: data.count,
            ...(data.filters && { filters: data.filters })
          })
        })
      } else {
        const placeholderOSKeys = ['Linux', 'OSX', 'Windows', 'Other']
        placeholderOSKeys.forEach((key) => {
          newColorArr.push({
            color: CHART_COLORS[i],
            label: bfTranslate(t, key)
          })
          newRowData.push({
            label: bfTranslate(t, key),
            key: key,
            color: CHART_COLORS[i++],
            value: 0
          })
        })
      }

      setState({
        rowData: newRowData,
        colorArr: newColorArr,
        arrData: sortedOSData
      })
    }
  }, [osData, gettingChartData, t])

  useEffect(() => {
    if (gettingChartData) {
      setState(undefined)
    }
  }, [gettingChartData])

  useEffect(() => {
    if (state && !gettingChartData && !isLoaded) {
      updateChartLoaded(chartId)
    }
  }, [state, gettingChartData, updateChartLoaded, chartId, isLoaded])

  function _isLoading() {
    return !state || gettingChartData
  }

  return (
    <BfChartContainer isLoading={_isLoading()} chartTitle={bfTranslate(t, 'byOSFamily')}>
      {!_isLoading() && (
        <React.Fragment>
          <div className='bfPieChartContainer'>
            <PieChart
              chartdata={state.arrData}
              chartHeight={360}
              chartLabel={{
                label: {
                  mapsTo: 'label'
                },
                symbol: '',
                value: {
                  mapsTo: 'count'
                }
              }}
              chartType='donut'
              colordata={state.colorArr}
              donutSize={0.25}
              onArcClick={_onArcClick}
              showTooltip={{
                tooltipDisplay: true,
                showTooltipData: {
                  name: true,
                  count: true
                }
              }}
            />
          </div>

          <div>
            <BfChartList rowData={state.rowData} onListClick={_onListClick} />
          </div>
        </React.Fragment>
      )}
    </BfChartContainer>
  )
}

BfOSFamilyChart.propTypes = {
  gettingChartData: PropTypes.bool,
  osData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      count: PropTypes.number,
      filters: PropTypes.shape({
        operatingSystem: PropTypes.objectOf(PropTypes.bool)
      })
    })
  ),
  updateFilterListUrlAction: PropTypes.func,
  updateChartLoaded: PropTypes.func,
  namespace: PropTypes.string,
  chartId: PropTypes.string,
  isLoaded: PropTypes.bool
}

export default BfOSFamilyChart
