/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import React, { useState } from 'react'
import BfCurrentUserInfoService from '../../services/user/BfCurrentUserInfoService'

const BfServicesProviderContext = React.createContext({ getCurrentUserInfo: () => {} })

const BfWithServicesProvider = (WrappedComponent) => {
  const BfWithServicesProviderInternal = (props) => {
    const [bfCurrentUserInfoService] = useState(new BfCurrentUserInfoService())

    const getCurrentUserInfo = () => {
      return bfCurrentUserInfoService
    }

    return (
      <BfServicesProviderContext.Provider value={{ getCurrentUserInfo: getCurrentUserInfo }}>
        <WrappedComponent {...props} />
      </BfServicesProviderContext.Provider>
    )
  }
  return BfWithServicesProviderInternal
}

export default BfWithServicesProvider

export const BfWithServicesConsumer = (WrapperComponent) => {
  return (props) => {
    return (
      <BfServicesProviderContext.Consumer>
        {({ getCurrentUserInfo }) => {
          return <WrapperComponent getCurrentUserInfo={getCurrentUserInfo} {...props} />
        }}
      </BfServicesProviderContext.Consumer>
    )
  }
}
