/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import BfAuthClient from '../authClient/BfHttpClient'

class BfAnalyses {
  http = new BfAuthClient()

  get(params, ...callbacks) {
    const [onLoad, onError, onComplete] = callbacks
    return this.http.get('/framework/analyses', params).subscribe({
      next: onLoad,
      error: onError,
      complete: onComplete
    })
  }

  getByName(params, ...callbacks) {
    const [onLoad, onError, onComplete] = callbacks
    const observable = this.http.get('/framework/analyses/names', params)
    if (callbacks && callbacks.length) {
      observable.subscribe({
        next: onLoad,
        error: onError,
        complete: onComplete
      })
    } else {
      return observable
    }
  }
}

export default BfAnalyses
