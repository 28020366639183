/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/
import moment from 'moment'
import BfConstants from './BfConstants'

class BfUnixDateTime {
  getDateTime = (dateTime) => {
    if (dateTime) {
      if (moment.unix(dateTime).isValid()) {
        var dateTimeFormatted = moment.unix(dateTime).format(new BfConstants().TIMEFORMAT.DATE_TIME)
        return dateTimeFormatted
      } else {
        return dateTime
      }
    } else {
      return null
    }
  }

  getTimeFromNow = (unixTime, suffix = false) => {
    return moment(unixTime).fromNow(suffix)
  }
}

export default BfUnixDateTime

export const isMoreThanAllowedTime = (referenceTimeStr, maxTime = 60) => {
  const dateTime = moment(referenceTimeStr)
  var maxMinutesAgo = moment()
  maxMinutesAgo.subtract(maxTime, 'minutes')
  return maxMinutesAgo.isAfter(dateTime)
}
