import React from 'react'
import _ from '../../../services/utils/BfLodash'
import { BfTrans } from '../../../services/i18n/bfI18Utils'
import BfLinkWithTooltip from './BfLinkWithTooltip'
import BfCellWidth from './BfCellWidth'

/**
 * Renders Deployment State cells
 */
class BfCellDeploymentStateRender {
  constructor(header) {
    this.header = header
    this.name = header.field
  }

  getRenderer() {
    return this._getDeploymentStateRenderHTML
  }

  /**
   * Renders Deployment State cell
   * @method
   * @param {Object} row Deployment State
   * @returns {HTMLElement} Cell HTML
   */
  _getDeploymentStateRenderHTML = (row) => {
    const deploymentState = row[this.name]
    const summedValue = _.sum(_.values(deploymentState)).toString()

    const formatted = BfTrans('deploymentStateCounts', {
      openCount: deploymentState.openAction,
      stopCount: deploymentState.stopAction,
      expiredCount: deploymentState.expiredAction
    })

    return (
      <BfCellWidth header={this.header}>
        <BfLinkWithTooltip href={'/framework/devices/' + row.id + '#deployments'} alignRight value={summedValue} toolTipValue={formatted} noSplit />
      </BfCellWidth>
    )
  }
}
export default BfCellDeploymentStateRender
