import BfCurrentUserInfoService from '../user/BfCurrentUserInfoService'
import { map } from 'rxjs/operators'

class BfUserCheck {
  constructor(_bfCurrentUserInfoService = new BfCurrentUserInfoService()) {
    this.bfCurrentUserInfoService = _bfCurrentUserInfoService
  }

  checkNmoLimitRequirements = (selectedDevices) => {
    return this.bfCurrentUserInfoService.getUserActionsLimit().pipe(
      map((userLimits) => {
        if (userLimits.device_target_limit !== -1 && selectedDevices.length > userLimits.device_target_limit) {
          const error = new Error('tooDeviceSelectedPPS')
          error.type = 'tooDeviceSelectedPPS'
          error.key = 'tooDeviceSelectedPPS'
          error.params = { maxDevices: userLimits.device_target_limit }
          throw error
        }

        return true
      })
    )
  }
}

export default BfUserCheck
