/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import BfProperiesFilterService from '../../../../../services/properties/BfProperiesFilterService'
import BfDropDownSearchBasicFilter from '../../common/bfBasicFilters/BfDropDownSearchFilter/BfDropDownSearchBasicFilter'

class BfAnalysisFilter extends BfDropDownSearchBasicFilter {
  static NAME = 'analysis'
  static PATH = `filter.${BfAnalysisFilter.NAME}`
  bfProperiesFilterService = new BfProperiesFilterService('analysis', 'source', 20)

  constructor(data = {}) {
    super(BfAnalysisFilter.PATH, BfAnalysisFilter.NAME, data)
  }

  getRequiredFilters = () => {
    return ['site']
  }

  getItems = (filterValue) => {
    return this.bfProperiesFilterService.getItems(filterValue, this.siteFilter.getData())
  }
}

export default BfAnalysisFilter
