/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2021
// All Rights Reserved
*/

import React from 'react'
import { bfTranslate } from '../../../services/i18n/bfI18Utils'

import BfIconRenderer from '../../common/BfIconRenderer'
import BfReduxStateConstants from '../../common/BfReduxStateConstants'
import { BfDataGridComponentWithContext } from '../BfDataGridContext'
import BfDeviceIcons from '../bfDeviceIcons/BfDeviceIcons'
import { withTranslation } from 'react-i18next'
import './BfCellModalHeaderSection.scss'
import _ from '../../../services/utils/BfLodash'

const BfCellModalHeaderSection = (props) => {
  const { id: dataGridType, row, t } = props

  const _getDeviceIcon = () => {
    return <BfDeviceIcons device={row} />
  }

  const _getCustomIcon = () => {
    return <BfIconRenderer icon={row.icon} />
  }

  const _showIcon = () => {
    switch (dataGridType) {
      case BfReduxStateConstants.DEVICES:
        return _getDeviceIcon()
      case BfReduxStateConstants.PATCHES:
        return null
      default:
        return _getCustomIcon()
    }
  }

  const _getSectionTitle = () => {
    switch (dataGridType) {
      case BfReduxStateConstants.DEVICES:
        return bfTranslate(t, 'deviceName')
      case BfReduxStateConstants.CUSTOM_DATA_GRID:
        return bfTranslate(t, 'CustomItem')
      case BfReduxStateConstants.SOFTWARE_DATA_GRID:
        return bfTranslate(t, 'SoftwareItem')
      case BfReduxStateConstants.PATCHES:
        return bfTranslate(t, 'patchNameColumnHeader')
      default:
        return null
    }
  }

  const _getItemName = () => {
    return _.isNil(row.name) ? row.id : row.name
  }

  return (
    <div>
      <div className='hcl-row bf-no-gutters'>
        <div className='hcl-col-12'>
          <span className='hcl-font-bold'>{_getSectionTitle()}</span>
        </div>
      </div>
      <div className='hcl-row bf-no-gutters'>
        <div className='hcl-col-12 bf-display-flex'>
          {_showIcon()}
          <span className='bf-item-name'>{_getItemName()}</span>
        </div>
      </div>
    </div>
  )
}
export default BfDataGridComponentWithContext(withTranslation()(BfCellModalHeaderSection))
