import React, { Component } from 'react'
import './BfModalDevicesGrid.scss'
import logConsole from 'loglevel'
import BfDevicesGrid from './BfDevicesGrid'
import BfModalDataGrid from '../modals/BfModalDataGrid'

class BfModalDevicesGrid extends Component {
  selectedItems = []

  _onRowSelected = (rowSelected, rows) => {
    logConsole.debug('Selected row is ', rowSelected)
    logConsole.debug('rows are ', rows)
    this.selectedItems = rows
  }

  render() {
    const { onClose, onSave, title } = this.props
    return (
      <BfModalDataGrid
        onClose={onClose}
        onSave={onSave}
        heading={title}
        datagridTable={BfDevicesGrid}
        id='modal_devices'
        options={{ updateUrl: false }}
        onRowSelected={this._onRowSelected}
      />
    )
  }
}

export default BfModalDevicesGrid
