/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import { map } from 'rxjs/operators'
import _ from '../../services/utils/BfLodash'
import BfCachedRequest from '../utils/BfCachedRequest'

/**
 * Provides functionalities for Current user Information
 */
class BfCurrentUserInfoService {
  bfCachedRequest = new BfCachedRequest()
  bfUserActionsLimit = new BfCachedRequest()

  /**
   * Retrieves Current User information from the server
   * @method
   * @returns {Promise} Http request
   */
  _getCurrentUserInfo() {
    return this.bfCachedRequest.get('/me').pipe(
      map((res) => {
        if (_.isEmpty(res)) {
          throw new Error('user info data empty')
        }

        const currentUserInfo = res

        if (currentUserInfo.userPermission.ApproverRoleID === undefined) {
          currentUserInfo.userPermission.ApproverRoleID = null
        }

        return currentUserInfo
      })
    )
  }

  getUserActionsLimit = () => {
    return this.bfUserActionsLimit.get('/pps/api/permission/common').pipe(
      map((res) => {
        if (_.isEmpty(res)) {
          throw new Error('permission common data empty')
        }

        return res
      })
    )
  }

  /**
   * Retrieves Menu applicable for current user from the server
   * @method
   * @returns {Promise} Http request
   */
  getUserMenu = () => {
    return this.bfCachedRequest.get('/getUserMenu').pipe(
      map((res) => {
        if (Array.isArray(res.data) && res.data.length > 0) {
          const userMenu = res.data
          return userMenu.push({
            label: 'logoutLabel',
            route: '/logout'
          })
        } else {
          return []
        }
      })
    )
  }

  /**
   * Provides service for callbacks after user information is retrieved
   * @method
   * @param {Array<Function>} callbacks onLoad, OnError and OnComplete callback functions
   * @returns {Promise} Http request
   */
  load(...callbacks) {
    const [onLoad, onError, onComplete] = callbacks
    this._getCurrentUserInfo().subscribe(onLoad, onError, onComplete)
  }

  /**
   * Initiates Http request for Current User information from the server
   * @method
   * @returns {Promise} Http request
   */
  get() {
    return this._getCurrentUserInfo()
  }
}

export default BfCurrentUserInfoService
