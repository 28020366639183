/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/
import _ from '../../../../../services/utils/BfLodash'
import BfBasicFilter from '../../BfBasicFilter'

class InsightsDeviceCountFilter extends BfBasicFilter {
  static NAME = 'devicesSubscribed'
  static PATH = `filter.${InsightsDeviceCountFilter.NAME}`

  constructor(data = {}) {
    super(InsightsDeviceCountFilter.PATH, InsightsDeviceCountFilter.NAME)
    this.devicesSubscribed = data
  }

  get = () => {
    return _.isEmpty(this.devicesSubscribed) ? { devicesSubscribed: {} } : { devicesSubscribed: this.devicesSubscribed }
  }

  buildFromURLHandler = (urlHandler, consumeUrl = false) => {
    this.devicesSubscribed = this._buildFromURLHandler(urlHandler, consumeUrl, this.devicesSubscribed)
  }

  getData = () => {
    return this.devicesSubscribed
  }

  setData = (data) => {
    this.devicesSubscribed = data
  }
}

export default InsightsDeviceCountFilter
