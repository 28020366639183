/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import React from 'react'
import BfGetDeviceProperty from '../../../services/devices/BfGetDeviceProperty'
import logConsole from 'loglevel'
import { map } from 'rxjs/operators'
import BfDataGridTable from '../BfDataGridTable'
import _ from '../../../services/utils/BfLodash'
import { withTranslation } from 'react-i18next'
import { bfTranslate } from '../../../services/i18n/bfI18Utils'
import { createCustomPropertyColumn } from './BfAddColumnHelper'
import HTML_COLUMN_TYPES from '../filters/BfHTMLFiltersMap'
import { mergeOptions } from '../../../filters/BfUtility'
import BfOnlySelectedFilter from '../filters/common/bfOnlySelected/BfOnlySelectedFilter'

class BfAddColumnProperties extends React.Component {
  constructor(props) {
    super(props)
    const { t } = props
    this.DEFAULT_PROPERTIES_TABLE_HEADER_NAMES = [
      {
        label: bfTranslate(t, 'propertyName'),
        field: 'propertyName',
        isDefault: true,
        width: '200px',
        sortable: true,
        columnHtmlType: 'propertyNameHTML'
      },
      {
        label: bfTranslate(t, 'analysisName'),
        field: 'analysisName',
        isDefault: true,
        width: '200px',
        columnHtmlType: 'analysisNameHTML'
      },
      {
        label: bfTranslate(t, 'sourceName'),
        field: 'siteName',
        isDefault: true,
        columnHtmlType: 'sourceNameHTML'
      }
    ]
    this.MAX_SELECTABLE_ITEMS = 30
  }

  _isDefaultProperty = (property) => {
    const { defaultHeaders } = this.props
    return _.find(
      defaultHeaders,
      (header) => header.siteID === property.siteID && header.contentID === property.contentID && header.propertyID === property.propertyID
    )
  }

  _getOnlySelectedOptions = (options) => {
    if (options.showOnlySelected) {
      const bfOnlySelectedFilter = new BfOnlySelectedFilter(true, options.selectedItemsIds)
      return bfOnlySelectedFilter.getFilter()
    }
    return null
  }

  _getOptions = (searchOption, visibleColumns, options) => {
    const _searchOption = _.cloneDeep(searchOption)
    const onlySelectedOption = this._getOnlySelectedOptions(options)
    if (onlySelectedOption) {
      mergeOptions(_searchOption, onlySelectedOption)
    }
    return _searchOption
  }

  _getProperties = (search, visibleColumns, options) => {
    logConsole.debug('options are', search)
    logConsole.debug('columns are', visibleColumns)
    logConsole.debug('options are', options)
    search = this._getOptions(search, visibleColumns, options)
    logConsole.debug('after options are', search)
    const bfDeviceProperties = new BfGetDeviceProperty()
    return bfDeviceProperties.getAllPropertiesWithAggregated(search).pipe(
      map((data) => {
        return { totalCount: data.count, rows: data.data }
      })
    )
  }

  _getPropertyHeader = (prop) => {
    const contentKey = _.pick(prop, ['siteID', 'contentID', 'propertyID'])
    const customColum = createCustomPropertyColumn(prop.propertyName, contentKey)
    customColum.columnHtml = HTML_COLUMN_TYPES.customColumnHTML(contentKey)
    return customColum
  }

  _getRowSelected = (rowSelected, isSelected) => {
    const { onRowSelected } = this.props
    const headerProperty = this._getPropertyHeader(rowSelected)

    const defaultProperty = this._isDefaultProperty(rowSelected)

    if (defaultProperty) {
      headerProperty.defaultField = defaultProperty.field
    } else {
      headerProperty._isCustomProperties = true
    }

    onRowSelected(headerProperty, isSelected)
  }

  _onRowsSelected = (selectedRows) => {
    _.each(selectedRows, (selectedRow) => this._getRowSelected(selectedRow, selectedRow._isSelected))
  }

  render() {
    const { displayContainer, t } = this.props
    return (
      <BfDataGridTable
        selectableRow
        headers={this.DEFAULT_PROPERTIES_TABLE_HEADER_NAMES}
        getRows={this._getProperties}
        options={{
          updateUrl: false,
          showSelectedOnly: true
        }}
        initSearchOptions={{
          filter: {
            site: {
              [bfTranslate(t, 'sourceAggregated')]: true,
              [bfTranslate(t, 'sourceReserved')]: true
            }
          }
        }}
        id='modal_properties'
        permanentRows={this.props.permanentRows}
        onRowsSelected={this._onRowsSelected}
        preSelectedItems={this.props.selectedItems}
        displayContainer={displayContainer}
        noDraggableColumn={this.props.noDraggableColumn}
        maxSelectableItems={this.MAX_SELECTABLE_ITEMS}
        onError={this.props.onError}
      />
    )
  }
}
export default withTranslation()(BfAddColumnProperties)
