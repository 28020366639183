/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2021
// All Rights Reserved
*/

import { map } from 'rxjs/operators'
import BfCachedRequest from '../../../../../services/utils/BfCachedRequest'
import _ from '../../../../../services/utils/BfLodash'
import BfBasicFilter from '../../BfBasicFilter'
import { createOsDropDownItem } from '../../common/BfCheckboxTree/BfCheckboxTree'

class BfSimpleOSFilterApps extends BfBasicFilter {
  bfCachedRequest = new BfCachedRequest()
  static NAME = 'operatingSystemApps'
  static PATH = `filter.${BfSimpleOSFilterApps.NAME}`
  constructor(data = {}) {
    super(BfSimpleOSFilterApps.PATH, BfSimpleOSFilterApps.NAME, data)
  }

  getSelectedItems = () => {
    return _.size(this.operatingSystemApps)
  }

  getItems = () => {
    return this.bfCachedRequest.get('/api/showMDMApplications').pipe(
      map((res) => {
        var osOptions = []
        for (var i = 0; i < res.length; i += 1) {
          if (osOptions.filter((e) => e.name === res[i].os).length === 0) {
            osOptions.push({
              name: res[i].os,
              id: res[i].os
            })
          }
        }
        return createOsDropDownItem(osOptions)
      })
    )
  }
}

export default BfSimpleOSFilterApps
