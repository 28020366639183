import React from 'react'
import { withTranslation } from 'react-i18next'
import { bfTranslate } from '../../../services/i18n/bfI18Utils'
import BfWithMessageProvider from '../../common/bfMessage/BfWithMessageProvider'
import withBfModal from '../../modals/BfWithModal'
import _ from '../../../services/utils/BfLodash'
import { List } from '@patron/patron-react/list'
const BfDevicesNotRecognized = (props) => {
  const { invalidDevices, t } = props
  const warningTitle = bfTranslate(t, 'devicesNotRecognizedTitle', {
    count: _.size(invalidDevices)
  })
  const showAll = bfTranslate(t, 'showAll')
  const warningDescription = bfTranslate(t, 'devicesNotRecognizedDescription')

  const _getInvalidDevices = () => {
    return (
      <React.Fragment>
        <p className='hcl-type-omega mb-4'>{warningDescription}</p>
        <List
          listItems={_.map(invalidDevices, (device) => {
            return {
              name: device,
              value: device
            }
          })}
          type='disc'
        />
      </React.Fragment>
    )
  }

  const _onClickShowModal = () => {
    props.sendMessageToShowModal('', warningTitle, _getInvalidDevices())
  }

  return (
    <React.Fragment>
      {warningTitle}
      <p>
        <a className='hcl-type-legal' onClick={_onClickShowModal}>
          {showAll}
        </a>
      </p>
    </React.Fragment>
  )
}

export default withBfModal(withTranslation()(BfWithMessageProvider(BfDevicesNotRecognized)))
