import React from 'react'
import _ from '../../services/utils/BfLodash'
import { useField } from 'formik'

import { withTranslation } from 'react-i18next'

import BfInput from '../common/inputs/BfInput'

const BfInputField = (props) => {
  const { ...rest } = props
  const [field, meta] = useField(props)
  const isInvalid = meta.touched && _.isNotEmpty(meta.error)

  return <BfInput field={field} isInvalid={isInvalid} errorMsg={meta.error} {...rest} />
}

export default withTranslation()(BfInputField)
