import BfCachedRequest from '../utils/BfCachedRequest'
import BfHttpClient from '../authClient/BfHttpClient'

class BfAgentInstallationServices {
  constructor() {
    this._init()
  }

  _init = () => {
    this.bfCloudDevices = new BfCachedRequest()
    this.bfMDMDevices = new BfCachedRequest()
    this.bfRelevantCloudDevices = new BfHttpClient()
    this.bfRelevantCloudDevicesCount = new BfHttpClient()
  }

  invalidateCache = () => {
    this._init()
  }

  getDeploymentPoints = () => {
    return this.bfCdtDeploymentPoints.get('/framework/cdt/getdeploymentpoints')
  }

  getCloudDevices = (siteID, contentID) => {
    return this.bfCloudDevices.get('/devices/count', {
      filter: {
        contentKey: [{ contentID: contentID, siteID: siteID }],
        agentStatus: { agentNotInstalled: true }
      }
    })
  }

  getRelevantCloudDevices = (siteID, contentID, selectedDevices) => {
    return this.bfRelevantCloudDevices.get('/devices/', {
      filter: {
        contentKey: [{ contentID: contentID, siteID: siteID }],
        onlySelected: { Enabled: true, data: selectedDevices }
      },
      pagination: { page: { itemsPerPage: 50, offset: 0 } }
    })
  }

  getRelevantCloudDevicesCount = (siteID, contentID, selectedDevices) => {
    return this.bfRelevantCloudDevicesCount.get('/devices/count', {
      filter: {
        contentKey: [{ contentID: contentID, siteID: siteID }],
        onlySelected: { Enabled: true, data: selectedDevices }
      }
    })
  }

  getMDMDevices = () => {
    return this.bfMDMDevices.get('/devices/count', {
      filter: {
        agentType: {
          'Proxy - MDM - MacOS': true,
          'Proxy - MDM - Windows': true
        },
        agentStatus: { agentNotInstalled: true }
      }
    })
  }
}

export default BfAgentInstallationServices
