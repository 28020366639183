/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import BfMoreApps from '../../../../../services/devices/BfMoreApps'
import { map } from 'rxjs/operators'
import _ from '../../../../../services/utils/BfLodash'
import BfDropDownBasicFilter from '../../common/bfBasicFilters/bfDropDownFilter/BfDropDownBasicFilter'
import BfReduxStateConstants from '../../../../common/BfReduxStateConstants'

class BfCustomContentTypeFilter extends BfDropDownBasicFilter {
  static NAME = 'type'
  static PATH = `filter.${BfCustomContentTypeFilter.NAME}`
  static NAMESPACE = BfReduxStateConstants.DEVICES

  constructor(data = {}) {
    super(BfCustomContentTypeFilter.PATH, BfCustomContentTypeFilter.NAME)
    this[this.getName()] = data
  }

  getItems = () => {
    const bfMoreApps = new BfMoreApps() //  Check if SWD is permitted and add SSA filter to DEFAULT_LIST_FILTERS
    return bfMoreApps.getPermittedApps().pipe(
      map((permittedApps) => {
        const items = [
          { id: 'Baseline', text: 'Baseline' },
          { id: 'OnlyTask', text: 'Single Task' },
          { id: 'OnlyFixlet', text: 'Fixlet' }
        ]

        if (_.isArray(permittedApps) && _.includes(permittedApps, 'swd')) {
          items.push({ id: 'SSA', text: 'SSA' })
        }

        return items
      })
    )
  }
}

export default BfCustomContentTypeFilter
