/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2021
// All Rights Reserved
*/

import React from 'react'
import './BfSharedChartStyles.scss'
import { Spinner } from '@patron/patron-react/spinner'
import { bfTranslate } from '../../../services/i18n/bfI18Utils'
import { useTranslation } from 'react-i18next'

function BfChartsContainer({ showSummary, isExportingType, isSummaryLoaded, children, className }) {
  const { t } = useTranslation()
  return (
    <React.Fragment>
      <div className={`bfChartsContainer ${className} ${showSummary ? '' : 'bfHideCharts'} ${isExportingType === 'pdf' ? 'bfExportCharts' : ''}`}>
        {children}
      </div>
      {isExportingType === 'pdf' && showSummary && isSummaryLoaded ? (
        <div className='bfExportingCharts'>
          <Spinner> </Spinner>
          {bfTranslate(t, 'exportingCharts')}
          <p className='pt-4'>{bfTranslate(t, 'doNotLeavePageWarning')}</p>
        </div>
      ) : (
        ''
      )}
    </React.Fragment>
  )
}

export default BfChartsContainer
