/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import React, { Component } from 'react'

import withBfModal from '../../modals/BfWithModal'
import BfWithMessageProvider from './BfWithMessageProvider'

const withShowMessage = (WrapperComponent) => {
  class BfShowMessage extends Component {
    showMessage = (type, title, message, onOkCallback, okLabel, cancelLabel) => {
      if (type === 'success') {
        this.props.sendDetailedMessage('', title, message, type)
      } else {
        this.props.showModal({
          message: message,
          title: title,
          type: type,
          labelConfirm: okLabel,
          labelCancel: cancelLabel,
          onOk: onOkCallback
        })
      }
    }

    render() {
      return <WrapperComponent showMessage={this.showMessage} {...this.props} />
    }
  }
  return BfWithMessageProvider(withBfModal(BfShowMessage))
}

export default withShowMessage
