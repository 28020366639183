/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2021
// All Rights Reserved
*/

import BfHttpClient from '../authClient/BfHttpClient'
import BfCachedRequest from '../utils/BfCachedRequest'
import { map } from 'rxjs/operators'

/**
 * Provides functionalities to fetch patch data
 */
class BfPreappsService {
  bfCachedRequest = new BfCachedRequest(true)
  http = new BfHttpClient()

  /**
   * Triggers http request to fetch patch data from server
   * @method
   * @param {Object} options Pagination and Sorting options
   * @returns {Promise} Patch data
   */
  getPreapps(options = {}) {
    return this.http.get('/api/showMDMApplications', options).pipe(
      map((data) => {
        // Adds an ID field to application info so it can properly be selected.
        for (var x = 0; x < data.length; x += 1) {
          data[x].id = data[x].appName
        }
        return { totalCount: data.length, rows: data }
      })
    )
  }
}

export default BfPreappsService
