/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2021
// All Rights Reserved
*/

import { bfTranslate } from '../../../../services/i18n/bfI18Utils'
import BfDropDownBasicFilter from '../common/bfBasicFilters/bfDropDownFilter/BfDropDownBasicFilter'

class BfPatchCategoryFilter extends BfDropDownBasicFilter {
  static NAME = 'category'
  static PATH = `filter.${BfPatchCategoryFilter.NAME}`
  constructor(data = {}) {
    super(BfPatchCategoryFilter.PATH, BfPatchCategoryFilter.NAME, data)
  }

  getItems = () => {
    const t = this.getT()

    const res = {
      values: [
        {
          id: 'Audit',
          text: bfTranslate(t, 'Audit')
        },
        {
          id: 'Bug Fix',
          text: bfTranslate(t, 'Bug Fix')
        },
        {
          id: 'Configuration',
          text: bfTranslate(t, 'Configuration')
        },
        {
          id: 'Enhancement',
          text: bfTranslate(t, 'Enhancement')
        },
        {
          id: 'Security',
          text: bfTranslate(t, 'Security')
        },
        {
          id: 'Service Pack',
          text: bfTranslate(t, 'Service Pack')
        },
        {
          id: 'Other',
          text: bfTranslate(t, 'Other')
        }
      ]
    }
    return res.values
  }
}

export default BfPatchCategoryFilter
