import React from 'react'
import BfCellWidth from './BfCellWidth'
import BfCellWithTooltipRenderer from './BfCellWithTooltipRenderer'

/**
 * Renders Agent Status cells
 */
class BfCellStatusRender {
  constructor(header) {
    this.header = header
    this.name = header.field
  }

  getRenderer() {
    return this._getStatusRenderHTML
  }

  /**
   * Renders Status cell
   * @method
   * @param {Object} row Status
   * @returns {HTMLElement} Cell HTML
   */
  _getStatusRenderHTML = (row) => {
    const status = row[this.name] ? 'Installed' : 'Not Installed'
    return (
      <BfCellWidth header={this.header}>
        <BfCellWithTooltipRenderer textTooltip={status}>{status}</BfCellWithTooltipRenderer>
      </BfCellWidth>
    )
  }
}
export default BfCellStatusRender
