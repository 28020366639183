/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import React, { createRef, useEffect, useState } from 'react'
import './BfDateRangeSelector.scss'
import _ from '../../../services/utils/BfLodash'
import { useTranslation } from 'react-i18next'
import { bfTranslate } from '../../../services/i18n/bfI18Utils'
import { FormHelperText } from '@patron/patron-react/formhelpertext'
import BfDateSelector from '../BfDateSelector/BfDateSelector'

const BfDateRangeSelector = (props) => {
  const { t } = useTranslation()

  const DEFAULT_MIN_DATE = new Date('0999-12-31T23:10:04.000Z')
  const DEFAULT_MAX_DATE = new Date()
  const containerRef = createRef()
  const [startDate, setStartDate] = useState(props.startDate)
  const [endDate, setEndDate] = useState(props.endDate)

  const [defaultStartDate] = useState(props.startDate)
  const [defaultEndDate] = useState(props.endDate)

  const [validDate, setValidDate] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')

  const _checkDatesConsistency = (startDate, endDate) => {
    if (_.isNil(startDate) || _.isNil(endDate)) {
      return true
    }
    const diffTime = endDate.getTime() - startDate.getTime()
    setValidDate(diffTime >= 0)
    return diffTime >= 0
  }

  const _onStartDateSelected = (date) => {
    if (!_checkDatesConsistency(date, endDate)) {
      setErrorMessage('startDateRangeError')
      return
    }
    setStartDate(date)
    props.onDateRangeSelected && props.onDateRangeSelected({ startDate: date, endDate: endDate })
    const endDateInputField = containerRef.current.querySelector('.bf-end-date.hcl-dateSelector-input')
    if (_.isNil(endDate)) {
      endDateInputField.click()
    }
  }

  const _onEndDateSelected = (date) => {
    if (!_checkDatesConsistency(startDate, date)) {
      setErrorMessage('endDateRangeError')
      return
    }
    setEndDate(date)
    props.onDateRangeSelected && props.onDateRangeSelected({ startDate: startDate, endDate: date })
  }

  useEffect(() => {
    if (props.openCalendar) {
      const startDateInputField = containerRef.current.querySelector('.bf-start-date.hcl-dateSelector-input')
      if (startDateInputField) {
        startDateInputField.click()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.openCalendar])

  return (
    <div>
      <div className='bf-date-range-container' ref={containerRef}>
        <BfDateSelector
          className='bf-start-date'
          attachElementToBody={false}
          defaultDate={defaultStartDate}
          direction='bottom-left'
          format='mm/dd/yyyy'
          id='date-selector-id-start'
          minDate={DEFAULT_MIN_DATE}
          maxDate={endDate || DEFAULT_MAX_DATE}
          onDateSelect={_onStartDateSelected}
          scrollListner={false}
          sidePanel={null}
        />
        <div className='bf-date-range-separator'>-</div>
        <BfDateSelector
          className='bf-end-date'
          attachElementToBody={false}
          defaultDate={defaultEndDate}
          direction='bottom-right'
          format='mm/dd/yyyy'
          id='date-selector-id-end'
          minDate={startDate || DEFAULT_MIN_DATE}
          maxDate={new Date()}
          onDateSelect={_onEndDateSelected}
          scrollListner={false}
          sidePanel={null}
        />
      </div>
      {!validDate && <FormHelperText className='error-msg'>{bfTranslate(t, errorMessage)}</FormHelperText>}
    </div>
  )
}

export default BfDateRangeSelector
