import BfHttpClient from '../authClient/BfHttpClient'
import _ from '../utils/BfLodash'

class BfProperiesFilterService {
  bfHttpClient = new BfHttpClient()

  constructor(filterType, associatedFilterType, itemsLimit) {
    this.itemsLimit = itemsLimit
    this.filterType = filterType
    this.associatedFilterType = associatedFilterType
  }

  _buildFilterParams = (filterValue, associatedFilterValue) => {
    const params = {
      filter: { select: this.filterType, limit: this.itemsLimit }
    }
    if (_.isNotEmpty(filterValue)) {
      params.filter[this.filterType] = { [filterValue]: true }
    }
    if (_.isNotEmpty(associatedFilterValue)) {
      params.filter[this.associatedFilterType] = associatedFilterValue
    }

    return params
  }

  getItems = (filterValue, associatedFilterValue) => {
    const params = this._buildFilterParams(filterValue, associatedFilterValue)
    return this.bfHttpClient.get('/framework/propertieswithaggregated/namesof', params)
  }
}

export default BfProperiesFilterService
