/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import _ from '../../services/utils/BfLodash'
import { map } from 'rxjs/operators'
import BfCachedRequest from '../utils/BfCachedRequest'

/**
 * Retrieves from server and returns Agent info
 * @component
 */
class BfAgentTypeInfo {
  subject = null
  agentTypes = null
  bfCachedRequest = new BfCachedRequest(true)

  /**
   * Returns Agent type
   * @method
   */
  getAgentTypes() {
    return this.bfCachedRequest.get('/api/devices/agenttype')
  }

  /**
   * Returns Categories based on Agent type
   * @method
   * @returns {Promise}
   */
  getCategoryByAgentType() {
    return this.bfCachedRequest.get('/api/devices/agenttype').pipe(
      map((agentTypesValues) => {
        const agentTypes = agentTypesValues.values
        var categoryByAgentType = {}
        _.each(agentTypes, function (agentType) {
          categoryByAgentType[agentType.AgentType] = agentType.Category
        })
        return categoryByAgentType
      })
    )
  }
}

export default BfAgentTypeInfo
