import React from 'react'
import _ from '../../../services/utils/BfLodash'
import BfCellWidth from './BfCellWidth'
import BfLinkWithTooltip from './BfLinkWithTooltip'

/**
 * Renders Url cells
 */
class BfCellUrlRender {
  constructor(header) {
    this.header = header
    this.name = header.field
  }

  getRenderer() {
    return this._getUrlRenderHTML
  }

  _replaceParamsURL = (url, row) => {
    const regex = /\[(.*?)\]/g
    let urlCpy = url
    let result
    while ((result = regex.exec(url)) !== null) {
      const attr = result[1]
      if (_.isDefined(attr)) {
        urlCpy = urlCpy.replace(`[${attr}]`, row[attr])
      }
    }
    return urlCpy
  }

  /**
   * Renders Url cell
   * @method
   * @param {Object} row row
   * @returns {HTMLElement} Cell HTML
   */
  _getUrlRenderHTML = (row) => {
    const value = _.isUndefined(row[this.name]) ? '0' : row[this.name].toString()

    const url = this.header.url
    const replacedUrl = this._replaceParamsURL(url, row)
    let className = ''
    let isRightAligned = false
    if (_.isDefined(this.header.classes)) {
      className = this.header.classes
    }
    if (!isNaN(value)) {
      isRightAligned = true
    }
    if ('isRightAligned' in this.header) {
      isRightAligned = this.header.isRightAligned
    }

    return (
      <BfCellWidth header={this.header}>
        <BfLinkWithTooltip href={replacedUrl} className={className} alignRight={isRightAligned} value={value} toolTipValue={value} />
      </BfCellWidth>
    )
  }
}
export default BfCellUrlRender
