import React, { Component } from 'react'
import { Pagination } from '@patron/patron-react/pagination'
import BfPaginationFilter from './BfPaginationFilter'
import BfWithReset from '../../BfWithReset'
import BfConsole from 'loglevel'
import { bfTranslate } from '../../../../../services/i18n/bfI18Utils'
import { withTranslation } from 'react-i18next'
/**
 * Creates Pagination functionality for grid table
 */
class BfPaginationWithFilter extends Component {
  static getFilter = (props) => {
    return props.filter ? props.filter : new BfPaginationFilter()
  }

  _onItemsPerPageChange = (itemsPerPage) => {
    this.filter.setItemsPerPage(itemsPerPage)
    this.props.onItemsPerPageChange(this.filter.get())
  }

  /**
   * Handle change of page of table
   * @method
   * @param {Number} pageNumber Page number to be displayed
   */
  _onPageChange = (pageNumber) => {
    --pageNumber
    this.filter.setPageNumber(pageNumber)
    this.props.onChange(this.filter.get())
  }

  render() {
    const { totalItems, t } = this.props
    this.filter = BfPaginationWithFilter.getFilter(this.props)
    const currentPage = totalItems === 0 ? 0 : Math.floor(parseInt(this.filter.getOffset()) / parseInt(this.filter.getItemsPerPage()) + 1)
    BfConsole.debug('current page is ', currentPage)
    return (
      <Pagination
        currentPage={currentPage}
        itemsValuesPerPage={[20, 50, 100]}
        itemsPerPageText={bfTranslate(t, 'itemsPerPageText')}
        pagePrepositionText={bfTranslate(t, 'pagePrepositionText')}
        pageNumberInfoText={bfTranslate(t, 'pageNumberInfoText')}
        itemsStepperLimit={100}
        itemsPerPageToSelect={this.filter.getItemsPerPage()}
        onItemsPerPageChange={this._onItemsPerPageChange}
        onPageChange={this._onPageChange}
        position={{
          left: ['itemsPerPageSelection'],
          right: ['pageNumberSelection', 'pageNumberInfo']
        }}
        totalItems={totalItems}
      />
    )
  }
}

export default withTranslation()(BfWithReset(BfPaginationWithFilter, BfPaginationWithFilter.getFilter))
