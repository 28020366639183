/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import React from 'react'
import { map } from 'rxjs/operators'
import BfGroupService from '../../services/groups/BfGroupService'
import _ from '../../services/utils/BfLodash'
import BfDataGridTable from '../datagrid/BfDataGridTable'
import logConsole from 'loglevel'
import BfOnlySelectedFilter from '../datagrid/filters/common/bfOnlySelected/BfOnlySelectedFilter'
import { mergeOptions } from '../../filters/BfUtility'
import BfReduxStateConstants from '../common/BfReduxStateConstants'
import BfShowRelevantItems from '../devices/BfShowRelevantItems'
import { bfTranslate } from '../../services/i18n/bfI18Utils'
import { useTranslation } from 'react-i18next'

const BfGroupsGrid = (props) => {
  const groupService = new BfGroupService()
  const {
    options = {
      updateUrl: false,
      showDeployButton: false,
      showSelectedOnly: true
    },
    showRelevantFilter,
    ...rest
  } = props
  const { t } = useTranslation()
  const DEFAULT_GROUP_TABLE_HEADER_NAMES = [
    {
      label: bfTranslate(t, 'groupName'),
      field: 'name',
      propertyName: 'Group Name',
      isDefault: true,
      pinned: 'left',
      styles: { left: '40px' },
      width: '300px',
      sortable: true,
      columnHtmlType: 'searchHTML',
      type: 'url',
      classes: 'hcl-float-left',
      url: '/framework/next/devices?filter={"groups":{"[name]":true}}'
    },
    {
      label: bfTranslate(t, 'memberCount'),
      field: 'memberCount',
      propertyName: 'Applicable devices',
      isDefault: true,
      sortable: true,
      type: 'url',
      classes: 'hcl-float-left',
      url: '/framework/next/devices?filter={"groups":{"[name]":true}}'
    }
  ]

  const _getOnlySelectedOptions = (options) => {
    if (options.showOnlySelected) {
      const bfOnlySelectedFilter = new BfOnlySelectedFilter(true, options.selectedItemsIds)
      return bfOnlySelectedFilter.getFilter()
    }
    return null
  }

  const _getOptions = (searchOption, visibleColumns, options) => {
    const _searchOption = _.cloneDeep(searchOption)
    const onlySelectedOption = _getOnlySelectedOptions(options)
    if (onlySelectedOption) {
      mergeOptions(_searchOption, onlySelectedOption)
    }
    return _searchOption
  }

  const _getGroups = (searchOption, visibleColumns, options) => {
    logConsole.debug('options are', searchOption)
    logConsole.debug('columns are', visibleColumns)
    logConsole.debug('options are', options)
    searchOption = _getOptions(searchOption, visibleColumns, options)
    logConsole.debug('after options are', searchOption)
    return groupService.getGroups(searchOption).pipe(
      map((groups) => {
        const rows = groups.data
        return { totalCount: groups.count, rows: rows }
      })
    )
  }

  return (
    <BfDataGridTable
      selectableRow
      headers={DEFAULT_GROUP_TABLE_HEADER_NAMES}
      getRows={_getGroups}
      id={BfReduxStateConstants.GROUPS_DATA_GROUP}
      paginationBarComponents={
        _.isEmpty(showRelevantFilter)
          ? null
          : [
              <BfShowRelevantItems
                key='showRelevantItemsGroup'
                showRelevantFilter={showRelevantFilter}
                id={BfReduxStateConstants.GROUPS_DATA_GROUP}
              />
            ]
      }
      options={options}
      noDraggableColumn
      showPaginationIcons
      {...rest}
    />
  )
}

export default BfGroupsGrid
