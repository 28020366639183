/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/
import axios from 'axios'
import { Observable } from 'rxjs'
import { removeBeforeEventListener } from '../beforeUnload/BfBeforeUnloadEvent'

export const HttpMethod = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE'
}

export class BfHttpClient {
  _httpClient = null

  constructor(config) {
    this._httpClient = axios.create(config)
    this._setInterceptors()
  }

  _doReq(args) {
    const { method, url, queryParams, payload } = args
    let request = null
    switch (method) {
      case HttpMethod.GET:
        request = this._httpClient.get(url, { params: queryParams })
        break
      case HttpMethod.POST:
        request = this._httpClient.post(url, payload)
        break
      case HttpMethod.PUT:
        request = this._httpClient.put(url, payload)
        break
      case HttpMethod.PATCH:
        request = this._httpClient.patch(url, payload)
        break
      case HttpMethod.DELETE:
        request = this._httpClient.delete(url)
        break

      // default:
      //   throw new Error('Method not supported');
    }
    return new Observable((subscriber) => {
      request
        .then((response) => {
          subscriber.next(response.data)
        })
        .catch((err) => {
          subscriber.error(err)
        })
        .finally(() => {
          subscriber.complete()
        })
    })
  }

  _onServerResponse = (response) => {
    let result = true
    var iemLoginRequired = response.headers['iem-login-required']
    if (response && (response.status === 401 || iemLoginRequired)) {
      //  Block any dialog prompts if we're redirecting back to login.
      //  Need to set this first before updating $window.location.href because otherwise,
      //  IE will load the URL and trigger a dialog prompt if it exists.
      removeBeforeEventListener()

      window.location.href = '/timeout?nextpage=' + encodeURIComponent(window.location.pathname + window.location.search)
      result = false
    }
    return result
  }

  _setInterceptors = () => {
    this._httpClient.interceptors.response.use(
      (response) => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data

        return this._onServerResponse(response) ? response : null
      },
      (error) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        /* return Promise.reject(error) */
        const { response } = error

        return this._onServerResponse(response) ? Promise.reject(response) : null
      }
    )
  }

  get = (url, queryParams) => {
    return this._doReq({ method: HttpMethod.GET, url, queryParams })
  }

  post = (url, payload) => {
    return this._doReq({
      method: HttpMethod.POST,
      url,
      payload
    })
  }

  put = (url, payload) => {
    return this._doReq({
      method: HttpMethod.PUT,
      url,
      payload
    })
  }

  patch = (url, payload) => {
    return this._doReq({
      method: HttpMethod.PATCH,
      url,
      payload
    })
  }

  delete = (url) => {
    return this._doReq({
      method: HttpMethod.DELETE,
      url
    })
  }

  doRequest = (method, url, params) => {
    const param = method === HttpMethod.GET ? 'queryParams' : 'payload'
    return this._doReq({ method: method, url, [param]: params })
  }
}

export default BfHttpClient
