/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import _ from './BfLodash'

class BfHelpers {
  isJSONString = (str) => {
    if (_.isObject(str)) {
      return false
    }

    return /^[\],:{}\s]*$/.test(
      str
        .replace(/\\["\\/bfnrtu]/g, '@')
        .replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?/g, ']')
        .replace(/(?:^|:|,)(?:\s*\[)+/g, '')
    )
  }

  /**
   * Converts hex color value to rgba with the provided alpha
   * @param {string} hex - Hex color value including #
   * @param {number} [alpha=1] - Opacity from 0 - 1
   */
  hexToRgba = function (hex, alpha = 1) {
    const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16))
    return `rgba(${r},${g},${b},${alpha})`
  }

  /**
   * Encode html splecial chars: '+', '&', '#'
   * @param {*} string string with characters to encode
   */
  encodeHTMLSpecialChars = (string) => {
    const specialCharsRegex = /\+|&|#/g

    return string.replace(specialCharsRegex, (match) => {
      return encodeURIComponent(match)
    })
  }
}
export default BfHelpers
