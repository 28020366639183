/*
// Licensed Materials - Property of HCL
// (C) Copyright HCL Technologies Limited 2001, 2020
// All Rights Reserved
*/

import React from 'react'
import './BfSimpleSearch.scss'
import _ from '../../services/utils/BfLodash'

import { TextInput } from '@patron/patron-react/textinput'

const BfSimpleSearch = (props) => {
  const { showReset, className = '', ...rest } = props
  const classNames = className

  const _onClick = () => {
    if (props.onReset) {
      props.onReset()
    }
  }

  const _showResetButton = () => {
    if (_.isDefined(showReset)) {
      return showReset
    }

    return _.isDefined(props.value) && _.size(props.value) > 0
  }

  return (
    <div className='bf-flex-control-container bf-simple-search-container'>
      <TextInput
        className={classNames}
        value={props.value}
        {...rest}
        onChange={(event) => {
          if (props.onChange) {
            props.onChange(event)
          }
        }}
      />
      {_showResetButton() && (
        <button className='bf-right-control' onClick={_onClick}>
          <i className='p-hclsw p-hclsw-close selected bf-close' />
        </button>
      )}
    </div>
  )
}

export default BfSimpleSearch
